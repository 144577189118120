<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{deviceBrand?.Name}}
        </span>
    </div>

    <form [formGroup]="form">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>

        <div>
            <button mat-button class="btn actionButton"
                    type="button" id="updateButton" (click)="update()">
                {{ 'GENERAL.UPDATE' | translate }}
            </button>
        </div>
    </form>

    <div class="row mb-1 mt-5 d-flex justify-content-between align-items-center pb-0 pt-0 m-0">
        <span class="h5 mb-1 mt-1">
            {{ 'DEVICE_BRAND.MODELS' | translate }}
        </span>

        <button mat-button class="btn actionButton"
                type="button" id="addModelButton" (click)="addModel()">
            {{ 'DEVICE_BRAND.ADD_MODEL' | translate }}
        </button>
    </div>

    <div class="customTable mt-5">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight" *ngIf="deviceModels?.length > 0">
            <table [dataSource]="filteredData | paginate: config"
                mat-table class="w-100">
                <ng-container matColumnDef="Icon">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 5%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <span class="mb-2" matTooltip="{{'DEVICE_MODEL.GO_TO_MODELS' | translate }}"
                                (click)="openDeviceModelList(element)"
                                class="urbanicon-{{ element.Type?.Icon }}"></span>
                        </div>
                    </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 15%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i class="mr-2" matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                (click)="modelDetail(element)" class="iconInfoButton urbanicon-info-outline">
                            </i>
                            <i class="mr-2" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                (click)="modelDelete(element)" class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="modelColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: modelColumns;"></tr>
            </table>
        </div>

        <div class="m-3 text-center" *ngIf="deviceModels?.length === 0">
            {{ 'DEVICE_BRAND.NO_MODELS_MESSAGE' | translate}}
        </div>

        <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
