import {InvisibleChildComponent} from './../../shared/components/invisible-child/invisible-child.component';
import {NavigationInfo} from './../../shared/models/navigationExtra';
import {DomainProperty} from './../../shared/models/domainProperty';
import {Component, HostBinding, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription, forkJoin} from 'rxjs';

import {Store} from '@ngrx/store';
import * as MainSelectors from '../../store/main/main.selectors';
import * as MainActions from '../../store/main/main.actions';
import * as AuthActions from '../../store/auth/auth.actions';
import * as AuthSelectors from '../../store/auth/auth.selectors';
import * as AppSelectors from '../../store/app/app.selectors';

import {DeviceBrand} from '../../shared/models/deviceBrand';
import {DeviceModel} from '../../shared/models/deviceModel';
import {MainState} from '../../store/main/main.reducer';
import {Resource} from '../../shared/models/resource';

import {DeviceCreateRequest} from '../../shared/models/deviceCreateRequest';
import {DeviceDeleteRequest} from '../../shared/models/deviceDeleteRequest';
import {DeviceUpdateRequest} from '../../shared/models/deviceUpdateRequest';
import {ChangePasswordRequest} from '../../shared/models/changePasswordRequest';
import {MatDrawer, MatSidenav} from '@angular/material/sidenav';
import {Device} from '../../shared/models/device';
import {PositionUpdater} from '../../shared/models/positionUpdater';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Domain} from '../../shared/models/domain';
import {MainSubscriptionsService} from '../../shared/services/main-subscriptions/main-subscriptions.service';
import {PassDataService} from '../../shared/services/pass-data/pass-data.service';
import {TranslateService} from '@ngx-translate/core';
import {SetEntityStateRequest} from '../../shared/models/siralab/setEntityStateRequest';
import {SiralabSubscriptionsService} from '../../shared/services/siralab-subscriptions/siralab-subscriptions.service';
import {
  WeatherStationSubscriptionsService
} from '../../shared/services/weatherstation-subscriptions/weatherstation-subscriptions.service';
import {WeatherStationRequest} from '../../shared/models/weatherstation/weatherstationRequest';
import {OverlayContainer} from '@angular/cdk/overlay';
import {AuthState} from '../../store/auth/auth.reducer';
import {User} from '../../shared/models/loginResponse';
import {takeUntil} from 'rxjs/operators';
import {Role} from '../../shared/models/userRoles';
import {Router} from '@angular/router';
import {UVNotification} from '../../shared/models/notification';
import {NotificationApiService} from '../../shared/services/notification-api.service';
import {MatDialog} from '@angular/material/dialog';
import {NotificationDialogComponent} from '../../shared/dialogs/notification-dialog/notification-dialog.component';
import {AppState} from '../../store/app/app.state';
import {Configuration} from '../../shared/models/configuration';
import {SetUserPropertyRequest} from '../../shared/models/setUserPropertyRequest';
import {Note} from '../../shared/models/Note';
import {Location} from '../../shared/models/location';
import {LoaderService} from '../../shared/services/loader/loader.service';
import {WINDOW} from "../../window.provider";
import { ApiService } from 'src/app/shared/services/api.service';
import { Language } from 'src/app/shared/models/language';
import { Dashboard, DashboardRequest, SelectedDashboardUpdater } from 'src/app/shared/models/dashboard';
import { Widget, WidgetToHandle } from 'src/app/shared/models/widget';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'urban-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  //providers: [ApiService]
})
export class MainComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('userSidebar', {static: true}) public userSidebar: MatDrawer;

  sidebarOpen: boolean = false;
  lockSidebar: boolean = false;
  returnedDashboardList: Dashboard[] = [];
  subscriptionDeleteDashboard: Subscription;
  subscriptionAddWidget: Subscription;
  subscriptionRemoveWidget: Subscription;
  subscriptionAddDevice: Subscription;
  subscriptionDeleteDevice: Subscription;
  subscriptionUpdateDevice: Subscription;
  subscriptionChangePassword: Subscription;
  subscriptionGoToSelectedDashboard: Subscription;
  subscriptionUpdateWidgetPosition: Subscription;
  subscriptionGetDeviceReport: Subscription;
  subscriptionSetEntityState: Subscription;
  subscriptionDeleteRemoteControlState: Subscription;
  subscriptionChangeProfileImage: Subscription;
  subscriptionRemoveProfileImage: Subscription;
  subscriptionUpdateAllDataAndNavigate: Subscription;
  subscriptionUpdateAllDataAndGoToMain: Subscription;
  subscriptionGetWSSensors: Subscription;
  subscriptionSetSuccessMessage: Subscription;
  subscriptionChangeProfileLanguage: Subscription;
  subscriptionSetNotificationToShow: Subscription;
  subscriptionChangeProfilePhoneNumber: Subscription;
  subscriptionChangeUserPhoneNumber: Subscription;
  subscriptionChangeProfileNotes: Subscription;
  subscriptionAddLocation: Subscription;
  subscriptionUpdateLocation: Subscription;
  subscriptionSetNavigationInfo: Subscription;
  subscriptionCreateDashboard: Subscription;
  subscriptionUpdateDashboard: Subscription;
  subscriptionUpdateAndReInitDashboard: Subscription;
  subscriptionChangeSelectedDashboard: Subscription;
  subscriptionReInitOnlyDashboards: Subscription;
  subscriptionSetCustomError: Subscription;

  /* public resources$: Observable<Resource[]>
  public dashboards$: Observable<Dashboard[]>;
  public devices$: Observable<Device[]>;
  public myDevices: Device[]; */

  public subscribedAppConfiguration: Configuration;
  public subscribedDomainLogo: string;
  public subscribedThemeTitle: string;
  public subscribedDevices: Device[];
  public subscribedDashboards: Dashboard[];
  public subscribedResources: Resource[];
  public sidebarResources: Resource[];
  public subscribedWidgets: Widget[];
  public subscribedDomains: Domain[];
  public subscribedBrands: DeviceBrand[];
  public subscribedModels: DeviceModel[];
  public subscribedLocations: Location[];
  public subscribedNavigationInfo: NavigationInfo;
  public subscribedDarkModeStatus: boolean = false;
  public subscribedCurrentDomain: Domain;
  public subscribedCurrentDomainProperties: DomainProperty[];
  public subscribedUser: User;
  public subscribedProfileImage: string;
  public subscribedCurrentUserNotes: Note[];
  public subscribedCurrentUserRoles: Role[];
  public availableLanguages: Language[] = [];
  public isDarkModeActive: boolean;
  public isLittleScreen: boolean;
  public activeRightbar: string = '';
  public activatedRouteSignal: Subject<void> = new Subject<void>();
  public appVersion: string = environment.appVersion;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @HostBinding('class') className = '';

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 992) {
      if (!this.sidenav.opened) {
        this.sidenav.open();
      }

      this.lockSidebar = true;
      this.isLittleScreen = false;
    } else {
      this.lockSidebar = false;
      this.isLittleScreen = true;
    }
  }

  constructor(
    private mainService: MainSubscriptionsService,
    private siralabSubscriptionsService: SiralabSubscriptionsService,
    private passDataService: PassDataService,
    private appStore: Store<AppState>,
    private store: Store<MainState>,
    private authStore: Store<AuthState>,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private weatherStationSubscriptionService: WeatherStationSubscriptionsService,
    private overlay: OverlayContainer,
    private router: Router,
    private notificationApi: NotificationApiService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private readonly loaderService: LoaderService,
    @Inject(WINDOW) private window: Window
  ) {
    //this.mainService.updateAllDataAndNavigateComand()
    if (window.innerWidth > 992) {
      this.sidebarOpen = true;
      this.lockSidebar = true;
      this.isLittleScreen = false;
    } else {
      this.lockSidebar = false;
      this.isLittleScreen = true;
    }
    // these rows of code make the snackbar disappear if a navigation starts.
    /* this.router.events.pipe(takeUntil(this.ngUnsubscribe)).pipe(filter(evt => evt instanceof NavigationStart)).subscribe(() => {
      this.snackBar.dismiss();
    }) */
  }

  ngOnInit(): void {
    this.authStore.select(AuthSelectors.getUser).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res?.Properties?.hasOwnProperty('FavouriteLanguage')) {
        this.translate.use(res.Properties.FavouriteLanguage)
      }
    });
    this.checkDarkTheme();
    this.initialize();
    this.hideSplashScreen();
  }

  private hideSplashScreen() : void{
      const splashScreen = document.getElementById('splash-screen');

      if(splashScreen){
        splashScreen.style.visibility = 'hidden';
      }
  }

  public checkDarkTheme(): void {
    this.authStore.select(AuthSelectors.getUserProperties).pipe(takeUntil(this.ngUnsubscribe)).subscribe(properties => {
      const darkClassName = 'darkMode';
      if (properties === undefined || properties === null || properties.DarkThemeActive == undefined || properties.DarkThemeActive == null) {
        this.isDarkModeActive = false;
        this.overlay.getContainerElement().classList.remove(darkClassName);
      } else {
        this.isDarkModeActive = properties.DarkThemeActive === "true";
        this.className = this.isDarkModeActive ? darkClassName : '';
        let dragPreviewBG: string;
        let dragPreviewColor: string;
        if (this.isDarkModeActive) {
          dragPreviewBG = this.getColor('dark', 'primary', 'panel');
          dragPreviewColor = this.getColor('dark', 'primary', 'pageBG-contrast');
          this.overlay.getContainerElement().classList.add(darkClassName);
        } else {
          dragPreviewBG = this.getColor('light', 'primary', 'panel');
          dragPreviewColor = this.getColor('light', 'primary', 'pageBG-contrast');
          this.overlay.getContainerElement().classList.remove(darkClassName);
        }
        document.documentElement.style.setProperty('--drag-preview-background', dragPreviewBG);
        document.documentElement.style.setProperty('--drag-preview-color', dragPreviewColor);
      }
    });
  }

  public checkCustomDomainStyle(): void {
    this.store.select(MainSelectors.getCustomDomainStyle).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res?.length > 0) {
        res.forEach(element => {
          document.documentElement.style.setProperty(element.Variable, element.Value);
        });
      }
    });
  }

  private initialize(): void {
    this.appStore.select(AppSelectors.getConfiguration).pipe(takeUntil(this.ngUnsubscribe)).subscribe(config => {
      this.subscribedAppConfiguration = config;
    });
    this.checkCustomDomainStyle();
    this.authStore.select(AuthSelectors.getCurrentDomainLogo).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.subscribedDomainLogo = res
    });
    this.appStore.select(AppSelectors.getTheme).pipe(takeUntil(this.ngUnsubscribe)).subscribe(theme => {
      if (theme && theme?.Logo)
        this.subscribedDomainLogo = theme.Logo
      if (theme && theme?.Translations?.find(x => x.Key === 'title'))
        this.subscribedThemeTitle = theme?.Translations.find(x => x.Key === 'title')?.Value;
    });

    this.apiService.getLanguages().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x && x.List) {
        this.availableLanguages = x.List;
      }
    });

    let subjects: Subject<void>[] = [new Subject<void>(), new Subject<void>()];
    forkJoin(subjects).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      let allowedResourcesProperty: DomainProperty = this.subscribedCurrentDomainProperties.find(property => property.Key === 'sidebarResources');
      if (allowedResourcesProperty?.Value) {
        let allowedResources: string[];
        try  {
          allowedResources = JSON.parse(allowedResourcesProperty.Value) as string[];
          let administrationResource: Resource = this.subscribedResources.find(resource => resource.Url === 'administration');
          this.sidebarResources = this.subscribedResources.filter(resource =>
            allowedResources.some(resourceUrl => resource.Url === resourceUrl) ||
            (administrationResource && (
              resource.Id === administrationResource.Id ||
              resource.ParentId === administrationResource.Id
            ))
          );
        }
        catch {
          this.sidebarResources = this.subscribedResources;
        }
      }
      else {
        this.sidebarResources = this.subscribedResources;
      }
    });

    this.store.select(MainSelectors.getResources).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.subscribedResources = res;
      subjects[0].next();
      subjects[0].complete();
    });
    this.store.select(MainSelectors.getGoogleMapsKey).pipe(takeUntil(this.ngUnsubscribe)).subscribe(googleMapsKey => {
      const googleMapsScript = document.getElementById("googleMapsScript");
      googleMapsScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=' + googleMapsKey + '&callback=Function.prototype');
      googleMapsScript.onload = () => this.passDataService.loadMap();
    });
    this.store.select(MainSelectors.getDashboards).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.subscribedDashboards = res
    });
    this.store.select(MainSelectors.getDashboardToShow).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        this.passDataService.loadDashboardToShow(res);
      }
    });
    this.store.select(MainSelectors.getRCServices).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        this.passDataService.loadRCServices(res);
      }
    })
    this.store.select(MainSelectors.getDevices).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.subscribedDevices = res;
      this.passDataService.addDevices(this.subscribedDevices);
    });
    this.store.select(MainSelectors.getLocations).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.subscribedLocations = res;
      this.passDataService.addLocations(this.subscribedLocations);
    });
    this.store.select(MainSelectors.getNavigationInfo).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.subscribedNavigationInfo = res;
      this.passDataService.setNavigationInfo(this.subscribedNavigationInfo);
    });
    this.store.select(MainSelectors.getWidgets).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.subscribedWidgets = res
    });
    this.store.select(MainSelectors.getBrands).pipe(takeUntil(this.ngUnsubscribe)).subscribe(brands => this.subscribedBrands = brands);
    this.store.select(MainSelectors.getModels).pipe(takeUntil(this.ngUnsubscribe)).subscribe(models => this.subscribedModels = models);
    this.authStore.select(AuthSelectors.getDomains).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.subscribedDomains = res
    });
    this.authStore.select(AuthSelectors.getDomain).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.subscribedCurrentDomain = res
    });
    this.authStore.select(AuthSelectors.getCurrentDomainProperties).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.subscribedCurrentDomainProperties = res;
      subjects[1].next();
      subjects[1].complete();
    });
    this.authStore.select(AuthSelectors.getUserRoles).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.subscribedCurrentUserRoles = res
    });
    //this.authStore.select(AuthSelectors.getUserProperties).subscribe(res => { this.subscribedProfileImage = res.ProfileImage});
    this.authStore.select(AuthSelectors.getUser).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        res.Properties.hasOwnProperty('ProfileImage') ? this.subscribedProfileImage = res.Properties.ProfileImage : null;
        res.Properties.hasOwnProperty('ProfileNotes') ? this.subscribedCurrentUserNotes = JSON.parse(res.Properties.ProfileNotes) : [];
        this.subscribedUser = res;
        this.passDataService.loadUser(this.subscribedUser);
        if (this.subscribedCurrentUserNotes !== undefined) {
          //this handles the updated values of dashboard's notes.
          this.passDataService.loadCurrentUserNotes(this.subscribedCurrentUserNotes);
        }
        ;
      }
    });

    this.authStore.select(AuthSelectors.getDarkThemeStatus).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        this.subscribedDarkModeStatus = res === "true";
        this.passDataService.loadDarkModeStatus(this.subscribedDarkModeStatus);
      }
    });

    //-----------------Gestione degli errori------------------------
    this.store.select(MainSelectors.getError).pipe(takeUntil(this.ngUnsubscribe)).subscribe(err => {
      if (err && err != '') {
        if (err == 'customError' || err.includes('customError: ')) {
          //Nel caso in cui l'errore non fosse esposto dal Server, abbiamo fatto sì che gli errori diventino 'customError'.
          //Ora sfruttiamo il metodo get del TranslateService che ci torna come Observable, la stringa con la chiave specificata a cui fare il subscribe.
          //A questo punto la snackBar mostrerà il risultato del nostro subscribe.
          if (err.includes('customError: ')) {
            err = 'ERROR.' + err.replace('customError: ', '');
          }
          else {
            err = 'ERROR.SOMETHING_WRONG';
          }

          this.translate.get(err).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.snackBar.open(res, 'OK', {
              duration: 30000,
              verticalPosition: 'top',
              panelClass: ['mat-toolbar', 'mat-warn']
            }).afterOpened().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.store.dispatch(MainActions.setError({error: null}))
            });
          });
        }
        else {
          //caso in cui l'errore sia già esposto dal Server.
          this.snackBar.open(err, 'OK', {
            duration: 30000,
            verticalPosition: 'top',
            panelClass: ['mat-toolbar', 'mat-warn']
          }).afterOpened().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.store.dispatch(MainActions.setError({error: null}))
          });
        }
      }
    })

    //-----------------Gestione dei successi------------------------
    this.store.select(MainSelectors.getSuccess).pipe(takeUntil(this.ngUnsubscribe)).subscribe(succ => {
      if (succ && succ !== undefined) {
        if (succ == 'messageSent') {
          //Nel caso in cui l'errore non fosse esposto dal Server, abbiamo fatto sì che gli errori diventino 'customError'.
          //Ora sfruttiamo il metodo get del TranslateService che ci torna come Observable, la stringa con la chiave specificata a cui fare il subscribe.
          //A questo punto la snackBar mostrerà il risultato del nostro subscribe.
          this.translate.get('MESSAGE.MESSAGE_SENT').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.snackBar.open(res, 'OK', {
              duration: 10000,
              verticalPosition: 'top',
              panelClass: ['mat-toolbar', 'mat-accent']
            }).afterOpened().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.store.dispatch(MainActions.setSuccessMessage({success: null}))
            });
          })
        } else if (succ === '') {
          this.translate.get('GENERAL.SUCCESS').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.snackBar.open(res, 'OK', {
              duration: 10000,
              verticalPosition: 'top',
              panelClass: ['mat-toolbar', 'mat-accent']
            }).afterOpened().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.store.dispatch(MainActions.setSuccessMessage({success: null}))
            });
          })
        } else {
          this.snackBar.open(succ, 'OK', {
            duration: 10000,
            verticalPosition: 'top',
            panelClass: ['mat-toolbar', 'mat-accent']
          }).afterOpened().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.store.dispatch(MainActions.setSuccessMessage({success: null}))
          });
        }
      }
    })


    //-----------------Gestione delle notifiche------------------------
    /* setTimeout(() => {
      this.notificationApi.getNotificationsList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        console.log(res.Notifications)
        if(res?.Notifications && res.Notifications.length > 0) {
          this.openNotification(res.Notifications);
        }
      });
    }, 5000);
 */
    /* this.notificationApi.getNotificationsList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      setTimeout(() => {
        console.log(res.Notifications)
        if(res?.Notifications && res.Notifications.length > 0) {
          this.openNotification(res.Notifications);
        }
      }, 3000)
    }); */

    if (this.subscribedCurrentUserRoles.some(x => x.Name !== 'Translator')) {
      this.notificationApi.getNotificationsList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if (res?.Notifications && res.Notifications.length > 0) {
          this.openNotification(res.Notifications);
        }

        this.loaderService.hide();
      });
    }

    if (this.subscribedAppConfiguration !== undefined) {
      this.passDataService.loadAppConfiguration(this.subscribedAppConfiguration);
    }
    ;
    if (this.subscribedResources !== undefined) {
      this.passDataService.addResources(this.subscribedResources);
    }
    ;
    if (this.subscribedDashboards !== undefined) {
      this.passDataService.addDashboards(this.subscribedDashboards);
    }
    ;
    /* if (this.subscribedDevices !== undefined) {
      this.passDataService.addDevices(this.subscribedDevices);
    }; */
    if (this.subscribedWidgets !== undefined) {
      this.passDataService.addWidgets(this.subscribedWidgets);
    }
    ;
    if (this.subscribedBrands !== undefined) {
      this.passDataService.addDeviceBrands(this.subscribedBrands);
    }
    ;
    if (this.subscribedModels !== undefined) {
      this.passDataService.addDeviceModels(this.subscribedModels);
    }
    ;
    if (this.subscribedDomains !== undefined) {
      this.passDataService.addAvailableDomains(this.subscribedDomains);
    }
    ;
    if (this.subscribedCurrentDomain !== undefined) {
      this.passDataService.addCurrentDomain(this.subscribedCurrentDomain);
    }
    ;
    if (this.subscribedCurrentDomainProperties !== undefined) {
      this.passDataService.loadCurrentDomainProperties(this.subscribedCurrentDomainProperties);
    }
    if (this.subscribedCurrentUserRoles !== undefined) {
      this.passDataService.loadCurrentUserRoles(this.subscribedCurrentUserRoles);
    }
    ;
    /* if (this.subscribedCurrentUserNotes !== undefined) {
      this.passDataService.loadCurrentUserNotes(this.subscribedCurrentUserNotes);
    }; */
    //this.passDataService.loadDarkModeStatus(this.subscribedDarkModeStatus);


    this.subscriptionDeleteDashboard = this.mainService.getDeleteDashboardComand().subscribe(target => {
      if (target) this.deleteDashboard(target);
    });
    this.subscriptionAddWidget = this.mainService.getAddWidgetsComand().subscribe(target => {
      if (target) this.addWidgets(target);
    });
    this.subscriptionRemoveWidget = this.mainService.getRemoveWidgetComand().subscribe(target => {
      if (target) this.removeWidget(target);
    });
    this.subscriptionAddDevice = this.mainService.getAddDeviceComand().subscribe(target => {
      if (target) this.addDevice(target);
    });
    this.subscriptionDeleteDevice = this.mainService.getDeleteDeviceComand().subscribe(target => {
      if (target) this.deleteDevice(target);
    });
    this.subscriptionUpdateDevice = this.mainService.getUpdateDeviceComand().subscribe(target => {
      if (target) this.updateDevice(target);
    });
    this.subscriptionChangePassword = this.mainService.getChangePasswordComand().subscribe(target => {
      if (target) this.changePassword(target);
    });
    this.subscriptionGoToSelectedDashboard = this.mainService.getGoToSelectedDashboardComand().subscribe(target => {
      if (target) this.goToSelectedDashboard(target);
    });
    this.subscriptionUpdateWidgetPosition = this.mainService.getUpdateWidgetPositionComand().subscribe(target => {
      if (target) this.updateWidgetPosition(target);
    });
    this.subscriptionGetDeviceReport = this.siralabSubscriptionsService.getGetDeviceReportComand().subscribe(target => {
      if (target) this.getDeviceReport(target);
    });
    this.subscriptionSetEntityState = this.siralabSubscriptionsService.getSetEntityStateComand().subscribe(target => {
      if (target) this.setEntityState(target);
    });
    this.subscriptionDeleteRemoteControlState = this.siralabSubscriptionsService.getDeleteRemoteControlStateComand().subscribe(target => {
      if (target) this.deleteRemoteControlState();
    });
    this.subscriptionChangeProfileImage = this.mainService.getChangeProfileImageComand().subscribe(image => {
      if (image) this.changeProfileImage(image);
    })
    this.subscriptionRemoveProfileImage = this.mainService.getRemoveProfileImageComand().subscribe(() => {
      this.changeProfileImage('');
    })
    this.subscriptionUpdateAllDataAndNavigate = this.mainService.getUpdateAllDataAndNavigateComand().subscribe(path => {
      if (path) {
        this.updateAllDataAndNavigate(path);
      } else {
        this.updateAllDataAndNavigate();
      }
    });
    this.subscriptionUpdateAllDataAndGoToMain = this.mainService.getUpdateAllDataAndGoToMainComand().subscribe(target => {
      if (target) this.updateAllDataAndGotToMain();
    });
    this.subscriptionGetWSSensors = this.weatherStationSubscriptionService.getGetWSSensorsComand().subscribe(target => {
      if (target) this.getWSSensors(target);
    });
    this.subscriptionSetSuccessMessage = this.mainService.getSetSuccessMessageComand().subscribe(target => {
      if (target) this.setSuccessMessage(target);
    });
    this.subscriptionChangeProfileLanguage = this.mainService.getChangeProfileLanguageComand().subscribe(target => {
      if (target) this.changeProfileLanguage(target);
    });
    this.subscriptionSetNotificationToShow = this.mainService.getSetNotificationToShowComand().subscribe(target => {
      if (target) this.setNotificationToShow(target);
    });
    this.subscriptionChangeProfilePhoneNumber = this.mainService.getChangeProfilePhoneNumberComand().subscribe(target => {
      if (target) this.changeProfilePhoneNumber(target);
    });
    this.subscriptionChangeUserPhoneNumber = this.mainService.getChangeUserPhoneNumberComand().subscribe(target => {
      if (target) this.changeUserPhoneNumber(target);
    });
    this.subscriptionChangeProfileNotes = this.mainService.getChangeProfileNotesComand().subscribe(target => {
      if (target) this.changeProfileNotes(target);
    });
    this.mainService.getSetCurrentDomainPropertyComand().pipe(takeUntil(this.ngUnsubscribe)).subscribe(target => {
      if (target) this.setCurrentDomainProperty(target)
    });
    this.subscriptionAddLocation = this.mainService.getAddLocationComand().subscribe(target => {
      if (target) this.addLocation(target);
    });
    this.subscriptionUpdateLocation = this.mainService.getUpdateLocationComand().subscribe(target => {
      if (target) this.updateLocation(target);
    });
    this.subscriptionSetNavigationInfo = this.mainService.getSetNavigationInfoComand().subscribe(navigationInfo => {
      if (navigationInfo) {
        //use info sent by a component to merge them into info in the store
        navigationInfo = this.createNewNavigationInfo(navigationInfo);
        //send the navigation setting to the Store
        this.setNavigationInfo(navigationInfo);
      } else {
        this.setNavigationInfo(null);
      }
    });
    this.subscriptionCreateDashboard = this.mainService.getCreateDashboardComand().subscribe(target => {
      this.createDashboard(target);
    });
    this.subscriptionUpdateDashboard = this.mainService.getUpdateDashboardComand().subscribe(target => {
      this.updateDashboard(target);
    });
    this.subscriptionUpdateAndReInitDashboard = this.mainService.getUpdateAndReInitDashboardComand().subscribe(target => {
      this.updateAndReInitDashboard(target);
    });
    this.subscriptionChangeSelectedDashboard = this.mainService.getChangeSelectedDashboardComand().subscribe(target => {
      this.changeSelectedDashboard(target);
    });
    this.subscriptionReInitOnlyDashboards = this.mainService.getReInitOnlyDashboardsComand().subscribe(() => {
      this.reInitOnlyDashboards();
    });
    this.subscriptionSetCustomError = this.mainService.getSetCustomErrorComand().subscribe(error => {
      if (error) this.store.dispatch(MainActions.setError({error: error}));
    })
  }

  private getColor(theme: 'light' | 'dark', palette: 'primary' | 'accent' | 'warn', colorName: string = 'main'): string {
    let colorVariable: string = `--custom-${theme}-${palette}-${colorName}-color`
    let color: string = window.getComputedStyle(document.body).getPropertyValue(colorVariable);
    color = color.trim(); //remove eventual spaces
    return color;
  }

  public openNotification(notifications: UVNotification[]) {
    for (let notif of notifications) {
      const automaticNotificationDialog = this.dialog.open(NotificationDialogComponent, {
        disableClose: true,
        data: {
          singleNotification: notif
        }
      });
      automaticNotificationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(notificationId => {
        if (notificationId) {
          this.notificationApi.readNotification(notificationId).pipe(takeUntil(this.ngUnsubscribe)).subscribe();
        }
      });
    }
  }

  public sidebarToggler(): void {
    if (!this.sidenav.opened) {
      this.sidenav.open();
    }

    this.sidebarOpen = !this.sidebarOpen;
  }

  public sidebarCloserOnLittleScreen(): void {
    if (this.isLittleScreen) {
      this.sidebarOpen = !this.sidebarOpen;
      this.sidenav.close();
      this.sidebarOpen = false;
    }
  }

  public userSidebarToggler(component: string): void {
    if (this.activeRightbar === '') {
      this.activeRightbar = component;
      this.userSidebar.toggle().then(_ => _);
      return;
    } else if ((this.activeRightbar === 'profile' && component !== 'profile')
                || (this.activeRightbar === 'settings' && component !== 'settings')
                || (this.activeRightbar === 'dashboard' && component !== 'dashboard')) {
      this.activeRightbar = component;
    } else {
      this.activeRightbar === '';
      this.userSidebar.toggle().then(_ => _);
      return;
    }

    if (!this.userSidebar.opened) {
      this.userSidebar.toggle().then(_ => _);
    }
  }

  public createNewNavigationInfo(navigationInfo: NavigationInfo): NavigationInfo {
    if (navigationInfo.SidebarActive) {  //sidebar saves its active button
      navigationInfo = {...this.subscribedNavigationInfo, SidebarActive: navigationInfo.SidebarActive};
      return navigationInfo;
    }

    let backRoutes: string[] = this.subscribedNavigationInfo?.BackRoute ? JSON.parse(this.subscribedNavigationInfo.BackRoute) : [];

    if (navigationInfo.Back) { //header back button is clicked
      //set all the parameters of last route (from backRoute) into current navigationInfo
      const lastBackRoute: string = backRoutes.pop();
      const precBackRoutes: string = backRoutes.length > 0 ? JSON.stringify(backRoutes) : undefined;
      if (lastBackRoute.includes(";")) {
        const oldNavigationString: string = lastBackRoute.split(";", 2)[1];
        navigationInfo = JSON.parse(oldNavigationString);
        navigationInfo.Back = true;
      }
      navigationInfo.BackRoute = precBackRoutes;
    } else { //navigation starts from a component to another one
      //save current navigationInfo into backRoute
      navigationInfo.Back = false;

      if (backRoutes.length > 0) {
        let oldNavigationInfo: NavigationInfo = {};
        for (let infoKey in this.subscribedNavigationInfo) {
          if (!["BackRoute", "Back"].includes(infoKey)) {
            oldNavigationInfo[infoKey] = this.subscribedNavigationInfo[infoKey];
          }
        }
        const oldNavigationString: string = JSON.stringify(oldNavigationInfo);

        navigationInfo.BackRoute += `;${oldNavigationString}`;
      }

      //set new current navigationInfo
      backRoutes.push(navigationInfo.BackRoute)
      const backToPass: string = JSON.stringify(backRoutes);
      navigationInfo = {...navigationInfo, BackRoute: backToPass};

      if (this.subscribedNavigationInfo.SidebarActive) {
        navigationInfo.SidebarActive = this.subscribedNavigationInfo.SidebarActive;
      }
    }

    return navigationInfo;
  }

  private searchDevice(deviceId: string, devices: Device[]): Device {
    let device: Device;

    devices.forEach((singleDevice: Device) => {
      if (singleDevice.Id === deviceId) {
        device = singleDevice;
        return;
      }
      if (singleDevice.Childs?.length > 0) {
        let childDevice: Device;
        if ((childDevice = this.searchDevice(deviceId, singleDevice.Childs)) !== undefined) {
          device = childDevice;
          return;
        }
      }
    });

    return device;
  }

  public signalRouteActivation(componentRef: Component): void {
    if (!(componentRef instanceof InvisibleChildComponent)) {
      this.activatedRouteSignal.next();
    }
  }

  public darkModeToggler(): void {
    this.authStore.dispatch(AuthActions.toggleDarkTheme({darkModeChange: String(!this.isDarkModeActive)}));
  }

  public reInitOnlyDashboards(): void {
    this.store.dispatch(MainActions.reInitOnlyDashboards());
  }

  public createDashboard(dashboard: DashboardRequest): void {
    this.store.dispatch(MainActions.createDashboard({dashboard}));
  }

  public updateDashboard(dashboard: DashboardRequest): void {
    this.store.dispatch(MainActions.updateDashboard({dashboard}));
  }

  public updateAndReInitDashboard(dashboard: DashboardRequest): void {
    this.store.dispatch(MainActions.updateAndReInitDashboard({dashboard}));
  }

  public deleteDashboard(dashboardId: string): void {
    this.store.dispatch(MainActions.deleteDashboard({dashboardId}));
  }

  public changeSelectedDashboard(dashboardsToEdit: SelectedDashboardUpdater): void {
    this.store.dispatch(MainActions.changeSelectedDashboard({dashboardsToEdit}));
  }

  public addWidgets(widgets: WidgetToHandle[]): void {
    this.store.dispatch(MainActions.addWidgetsToDashboard({widgets}));
  }

  public removeWidget(widget: WidgetToHandle): void {
    this.store.dispatch(MainActions.removeWidgetToDashboard({widget}));
  }

  public addDevice(device: DeviceCreateRequest): void {
    this.store.dispatch(MainActions.addDeviceToMap({device}));
  }

  public deleteDevice(device: DeviceDeleteRequest): void {
    this.store.dispatch(MainActions.deleteDeviceFromMap({device}));
  }

  public updateDevice(device: DeviceUpdateRequest): void {
    this.store.dispatch(MainActions.updateDeviceMap({device}));
  }

  public logout(): void {
    this.authStore.dispatch(AuthActions.logout());
  }

  public changePassword(changePasswordRequest: ChangePasswordRequest): void {
    this.store.dispatch(MainActions.changePassword({changePasswordRequest}));
  }

  public goToSelectedDashboard(clickedDashboard: Dashboard): void {
    this.store.dispatch(MainActions.setDashboardToShow(clickedDashboard));
  }

  public updateWidgetPosition(makeUpdatePosition: PositionUpdater): void {
    this.store.dispatch(MainActions.updateWidgetPosition(makeUpdatePosition));
  }

  public getDeviceReport(deviceId: string, delayMilliseconds: number = 0): void {
    this.store.dispatch(MainActions.getDeviceReport({deviceId: deviceId, delayMilliseconds: delayMilliseconds}));
  }

  public setEntityState(request: SetEntityStateRequest): void {
    this.store.dispatch(MainActions.setEntityState({request: request}));
  }

  public deleteRemoteControlState(): void {
    this.store.dispatch(MainActions.setRCServices({remoteControlServices: []}));
  }

  public updateAllDataAndNavigate(path?: string): void {
    this.store.dispatch(MainActions.reInitDevices({path: path}));
  }

  public updateAllDataAndGotToMain(): void {
    this.store.dispatch(MainActions.initDevices());
  }

  public getWSSensors(request: WeatherStationRequest): void {
    this.store.dispatch(MainActions.getWSSensors({request: request}));
  }

  public changeProfileImage(image: string): void {
    this.authStore.dispatch(AuthActions.changeProfileImage({base64Image: image}));
  }

  public RemoveProfileImage(image: string): void {
    this.authStore.dispatch(AuthActions.changeProfileImage({base64Image: image}));
  }

  public setSuccessMessage(successToSet: string): void {
    this.store.dispatch(MainActions.setSuccessMessage({success: successToSet}));
  }

  public changeProfileLanguage(translateParameter: string): void {
    this.authStore.dispatch(AuthActions.changeProfileLanguage({favouriteLanguage: translateParameter}));
  }

  public setNotificationToShow(notificationToShow: UVNotification): void {
    this.store.dispatch(MainActions.setNotificationToShow(notificationToShow));
  }

  public changeProfilePhoneNumber(newPhoneNumber: string): void {
    this.authStore.dispatch(AuthActions.changeProfilePhoneNumber({phoneNumber: newPhoneNumber}));
  }

  public changeUserPhoneNumber(request: SetUserPropertyRequest): void {
    this.authStore.dispatch(AuthActions.changeUserPhoneNumber({request: request}));
  }

  public changeProfileNotes(newNotes: Note[]): void {
    this.authStore.dispatch(AuthActions.changeProfileNotes({notes: JSON.stringify(newNotes)}));
  }

  public setCurrentDomainProperty(domain: Domain): void {
    this.authStore.dispatch(AuthActions.setCurrentDomainProperty({domain: domain}));
  }

  public addLocation(location: Location): void {
    this.store.dispatch(MainActions.addLocationToMap({location}));
  }

  public updateLocation(location: Location): void {
    this.store.dispatch(MainActions.updateLocationOnMap({location}));
  }

  public setNavigationInfo(navigationInfo: NavigationInfo): void {
    this.store.dispatch(MainActions.setNavigationInfo({navigationInfo}));
  }

  ngOnDestroy() {
    this.activatedRouteSignal.complete();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    // unsubscribe to ensure no memory leaks
    this.subscriptionDeleteDashboard.unsubscribe();
    this.subscriptionAddWidget.unsubscribe();
    this.subscriptionRemoveWidget.unsubscribe();
    this.subscriptionAddDevice.unsubscribe();
    this.subscriptionDeleteDevice.unsubscribe();
    this.subscriptionUpdateDevice.unsubscribe();
    this.subscriptionChangePassword.unsubscribe();
    this.subscriptionGoToSelectedDashboard.unsubscribe();
    this.subscriptionUpdateWidgetPosition.unsubscribe();
    this.subscriptionGetDeviceReport.unsubscribe();
    this.subscriptionSetEntityState.unsubscribe();
    this.subscriptionDeleteRemoteControlState.unsubscribe();
    this.subscriptionChangeProfileImage.unsubscribe();
    this.subscriptionRemoveProfileImage.unsubscribe();
    this.subscriptionUpdateAllDataAndNavigate.unsubscribe();
    this.subscriptionUpdateAllDataAndGoToMain.unsubscribe();
    this.subscriptionGetWSSensors.unsubscribe();
    this.subscriptionSetSuccessMessage.unsubscribe();
    this.subscriptionChangeProfileLanguage.unsubscribe();
    this.subscriptionSetNotificationToShow.unsubscribe();
    this.subscriptionChangeProfilePhoneNumber.unsubscribe();
    this.subscriptionChangeUserPhoneNumber.unsubscribe();
    this.subscriptionChangeProfileNotes.unsubscribe();
    this.subscriptionAddLocation.unsubscribe();
    this.subscriptionUpdateLocation.unsubscribe();
    this.subscriptionSetNavigationInfo.unsubscribe();
    this.subscriptionCreateDashboard.unsubscribe();
    this.subscriptionUpdateDashboard.unsubscribe();
    this.subscriptionUpdateAndReInitDashboard.unsubscribe();
    this.subscriptionChangeSelectedDashboard.unsubscribe();
    this.subscriptionReInitOnlyDashboards.unsubscribe();
  }
}


/* this is usefull for style override. Remeber to initialize
    // if (document.documentElement.style !== undefined)
    //   document.documentElement.style.setProperty('--custom-dark-primary-main-color', '#58F556');

*/
