import { MainSubscriptionsService } from './../../../shared/services/main-subscriptions/main-subscriptions.service';
import { Router } from '@angular/router';
import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { PassDataService } from './../../../shared/services/pass-data/pass-data.service';
import { MatSliderChange } from '@angular/material/slider';
import { GoogleMarker } from '../../../shared/models/googleMarker';
import { MostDangerousEvent, MostDangerousEventsRequest } from '../../../shared/models/roadRisk';
import { DangerRateDomainRequest, DangerRateEvent } from '../../../shared/models/roadRisk';
import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { HeatmapElement } from '../../../shared/models/heatmapElement';
import { CrosswalkEvent, CrosswalkEventAverage } from '../../../shared/models/smartCrosswalk';
import { TrafficCategory, TrafficEvent, TrafficEventAverage } from '../../../shared/models/smartTraffic';
import { ApiService } from '../../../shared/services/api.service';
import { AuthState } from '../../../store/auth/auth.reducer';
import * as AuthSelectors from '../../../store/auth/auth.selectors';
import { TranslateService } from '@ngx-translate/core';
import { DeviceEventRequest, DeviceEventsDevice, DeviceEventsEvent, DeviceEventsResponse } from 'src/app/shared/models/deviceEvent';
import { Device } from 'src/app/shared/models/device';
import { EventParsedBody } from 'src/app/shared/models/administrator/eventBody';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';
import introJs from 'intro.js';

@Component({
  selector: 'urban-road-risk',
  templateUrl: './road-risk.component.html',
  styleUrls: ['./road-risk.component.scss']
})
export class RoadRiskComponent implements OnInit, OnDestroy {
  @HostBinding('style.--boxes-div-height') private boxesDivHeight: string;

  @ViewChild('boxesDiv') private boxesDiv: ElementRef;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private trafficDevices: Device[] = [];
  public averageTrafficEvents: TrafficEventAverage[] = [];
  private crosswalkDevices: Device[] = [];
  public averageCrosswalkEvents: CrosswalkEventAverage[] = [];
  private eventBodies: Record<string, EventParsedBody> = {};
  private mostDangerousEventsRequest: MostDangerousEventsRequest;
  public dataForHeatmap: HeatmapElement[];
  public mapReady: boolean;
  public maxValueForHeatmap: number = 250; // 500 is is the case of 100% red cross on a street with 100% traffic
  public lastUpdate: number;
  public totalEvents: number = null;
  public dangerEvents: number = null;
  public dangerRateChecked: boolean = false;
  private mostDangerousEvents: MostDangerousEvent[];
  public mostDangerousPlaces: GoogleMarker[];
  public maxNumberOfMarkers: number = 30;
  public loadingBoxesData: boolean = false;
  public isDarkActive: boolean;
  private introJS = introJs();

  constructor(
    private apiService: ApiService,
    private authStore: Store<AuthState>,
    private router: Router,
    private mainService: MainSubscriptionsService,
    private translate: TranslateService,
    private passDataService: PassDataService,
    private apiSync: ApiSynchronizerService,
    private eventBodyService: EventBodyUtilityService
  ) { }

  ngOnInit(): void {
    this.authStore.select(AuthSelectors.getCurrentDomainProperties).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        //.find(function (x) { return x.Key === 'Logo' })?.Value;
        //TODO selezionare dallo store e parsare il JSON
      }
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res == true;
    });

    this.mostDangerousEventsRequest = {
      Latitude: 41.9064534427033,
      Longitude: 12.4971286557419,
      Range: 100,
      Rate: 3
    };

    this.loadData();
  }

  private loadData(): void {
    //Api synchronizer initializations
    let trafficFeature: number;
    let crosswalkFeature: number;
    const dataContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(4, dataContext);

    let eventsFeature: number;
    const mapContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, mapContext);

    //Api synchronizer executions
    this.apiSync.waitFeaturesAndThen((checkValues: Boolean[], data: any) => {
      this.dataForHeatmap = [];
      if (checkValues.every((singleCheck: boolean) => singleCheck)) {
        let trafficEvents: TrafficEvent[] = this.mapToTrafficEvents(data[trafficFeature], this.trafficDevices, this.eventBodies);
        let crosswalkEvents: CrosswalkEvent[] = this.mapToCrosswalkEvents(data[crosswalkFeature], this.crosswalkDevices, this.eventBodies);

        if(trafficEvents.length > 0 && crosswalkEvents.length > 0) {
          this.averageTrafficEvents = this.calculateTrafficEventsAverages(trafficEvents, this.trafficDevices);
          this.averageCrosswalkEvents = this.calculateCrosswalkEventsAverages(crosswalkEvents, this.crosswalkDevices);

          this.lastUpdate = this.averageTrafficEvents[this.averageTrafficEvents.length - 1].Created;
          this.dataForHeatmap = this.generateData(this.averageCrosswalkEvents, this.averageTrafficEvents);
        }
      }
    },
    dataContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if(checkValues.every(value => value)) {
        this.mostDangerousEvents = [];
        this.mostDangerousPlaces = [];
        data[eventsFeature].forEach((singleEvent) => {
          if (['Latitude', 'Longitude', 'Rate'].every((key: string) => Object.keys(singleEvent).includes(key))) {
            this.mostDangerousEvents.push(singleEvent);
            if (this.mostDangerousPlaces.length < this.maxNumberOfMarkers) {
              this.mostDangerousPlaces.push(
                new GoogleMarker(
                  new google.maps.LatLng(singleEvent.Latitude, singleEvent.Longitude),
                  {
                    color: 'black',
                    text: 'text',
                    fontSize: '0px',
                    fontWeight: 'normal',
                    scaledSize: new google.maps.Size(80, 80),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(20, 0),
                    labelOrigin: null
                  },
                  null,
                  {
                    animation: google.maps.Animation.DROP,
                    icon: {
                      url: "/assets/img/icons/pin-danger-accident.png",
                      labelOrigin: new google.maps.Point(90, 20)
                    }
                  },
                  singleEvent.Rate.toString(),
                  []
                )
              );
            }
          }
        });
      }
    },
    mapContext);

    //Data context loading
    this.apiService.getDevicesByType('traffic-monitor').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: Device[]) => {
      if(res && res.length > 0) {
        this.trafficDevices = res;
        this.eventBodies = { ...this.eventBodies, ...this.eventBodyService.initEventBody(this.trafficDevices)};

        this.apiSync.loadedFeature(dataContext);
      }
      else {
        this.apiSync.failedFeature(dataContext);
      }
    });

    this.apiService.getDevicesByType('crosswalk-monitor').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: Device[]) => {
      if(res && res.length > 0) {
        this.crosswalkDevices = res;
        this.eventBodies = { ...this.eventBodies, ...this.eventBodyService.initEventBody(this.crosswalkDevices)};

        this.apiSync.loadedFeature(dataContext);
      }
      else {
        this.apiSync.failedFeature(dataContext);
      }
    });

    let trafficEventsRequest: DeviceEventRequest = {
      DeviceType: 'traffic-monitor'
    };

    this.apiService.getDeviceEvents(trafficEventsRequest)
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
        if(res && res.Devices?.length > 0) {
          trafficFeature = this.apiSync.loadedFeatureWithData(res, dataContext);
        }
        else {
          trafficFeature = this.apiSync.failedFeature(dataContext);
        }
    });

    let crosswalkEventsRequest: DeviceEventRequest = {
      DeviceType: 'crosswalk-monitor'
    };

    this.apiService.getDeviceEvents(crosswalkEventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
      if (res && res.Devices?.length > 0) {
        crosswalkFeature = this.apiSync.loadedFeatureWithData(res, dataContext);
      }
      else {
        crosswalkFeature = this.apiSync.failedFeature(dataContext);
      }
    });

    //Map context loading
    this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe), takeWhile(() => this.mapReady !== true)).subscribe(mapLoading => {
      this.mapReady = mapLoading;
      if(mapLoading) {
        this.apiSync.loadedFeature(mapContext);
      }
    });

    this.apiService.getMostDangerousEvents(this.mostDangerousEventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: MostDangerousEvent[]) => {
      if (res && res.length > 0) {
        eventsFeature = this.apiSync.loadedFeatureWithData(res, mapContext);
      }
      else {
        this.apiSync.failedFeature(mapContext);
      }
    });

    //Boxes data
    this.getDataForBoxes();
  }

  public getDataForBoxes(): void {
    let dangerRateDomainRequest: DangerRateDomainRequest = {
      Latitude: this.mostDangerousEventsRequest.Latitude,
      Longitude: this.mostDangerousEventsRequest.Longitude,
      Range: this.mostDangerousEventsRequest.Range
    }
    this.apiService.getDangerRateDomain(dangerRateDomainRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DangerRateEvent) => {
      if (res && ['TotalEvents', 'DangerEvents'].every((key: string) => Object.keys(res).includes(key))) {
        this.totalEvents = res.TotalEvents;
        this.dangerEvents = res.DangerEvents;
      }
      else {
        this.totalEvents = null;
        this.dangerEvents = null;
      }
      this.dangerRateChecked = true;
      this.loadingBoxesData = false;
    });
  }

  private getDangerousPlaces(): void {
    this.apiService.getMostDangerousEvents(this.mostDangerousEventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: MostDangerousEvent[]) => {
      this.mostDangerousPlaces = [];
      this.mostDangerousEvents = [];
      if (res && res.length > 0 && this.mapReady) {
        res.forEach((singleEvent) => {
          if (['Latitude', 'Longitude', 'Rate'].every((key: string) => Object.keys(singleEvent).includes(key))) {
            this.mostDangerousEvents.push(singleEvent);
            if (this.mostDangerousPlaces.length <= this.maxNumberOfMarkers) {
              this.mostDangerousPlaces.push(
                new GoogleMarker(
                  new google.maps.LatLng(singleEvent.Latitude, singleEvent.Longitude),
                  {
                    color: 'black',
                    text: 'text',
                    fontSize: '0px',
                    fontWeight: 'normal',
                    scaledSize: new google.maps.Size(80, 80),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(20, 0),
                    labelOrigin: null
                  },
                  null,
                  {
                    animation: google.maps.Animation.DROP,
                    icon: {
                      url: "/assets/img/icons/pin-danger-accident.png",
                      labelOrigin: new google.maps.Point(90, 20)
                    }
                  },
                  singleEvent.Rate.toString(),
                  []
                )
              );
            }
          }
        });
      }
    });
  }

  private generateData(crosswalkAverages: CrosswalkEventAverage[], trafficAverages: TrafficEventAverage[]): HeatmapElement[] {
    let heatmapElements: HeatmapElement[] = [];

    if (crosswalkAverages.length > 0 && this.averageTrafficEvents.length > 0) {
      trafficAverages.forEach(tEvent => {
        let singleDeviceCrosswalkEvents: CrosswalkEventAverage[] = crosswalkAverages.filter(x => x.Device.ParentId == tEvent.Device.ParentId);
        let singleEvent: CrosswalkEventAverage;
        let greenTotal: number, yellowTotal: number, redTotal: number;
        //greenEvent
        singleEvent = singleDeviceCrosswalkEvents.find(x => x.Status == 2)
        greenTotal = singleEvent === undefined ? 0 : singleEvent.Total;
        //yellowEvent
        singleEvent = singleDeviceCrosswalkEvents.find(x => x.Status == 1)
        yellowTotal = singleEvent === undefined ? 0 : singleEvent.Total;
        //redEvent
        singleEvent = singleDeviceCrosswalkEvents.find(x => x.Status == 0)
        redTotal = singleEvent === undefined ? 0 : singleEvent.Total;
        //total
        let totalCrosswalks: number = greenTotal + yellowTotal + redTotal;

        let equation: number = (tEvent.Level / 100) * ((greenTotal * 100 / totalCrosswalks) * 0.1 + (yellowTotal * 100 / totalCrosswalks) * 1 + (redTotal * 100 / totalCrosswalks) * 5);

        heatmapElements.push({
          Latitude: tEvent.Device.Latitude,
          Longitude: tEvent.Device.Longitude,
          Value: equation
        });
      });
    }

    return heatmapElements;
  }

  private mapToTrafficEvents(res: DeviceEventsResponse, trafficDevices: Device[], eventBodies: Record<string, EventParsedBody>): TrafficEvent[] {
    let formattedEvents: TrafficEvent[] = [];
    res.Devices.forEach((device: DeviceEventsDevice) => {
      let trafficDevice: Device = trafficDevices.find(oneDevice => oneDevice.Id === device.Id);
      if (trafficDevice !== undefined) {
        let expectedBody: EventParsedBody = eventBodies[trafficDevice.Model.Name];
        device.Events.forEach((event: DeviceEventsEvent) => {
          let eventBody: any = event.Body;
          if (this.eventBodyService.isAProperEvent(event, trafficDevice.Model, eventBodies)) {
            let categories: TrafficCategory[] = [];
            let categoryNames: string[] = this.eventBodyService
            .getNestedValuesArray(eventBody, expectedBody.Mapping['CategoryName']).map(name => name.toString());
            let categoryIcons: string[] = this.eventBodyService
            .getNestedValuesArray(eventBody, expectedBody.Mapping['CategoryIcon']).map(icon => icon.toString());
            let categoryLevels: number[] = this.eventBodyService
            .getNestedValuesArray(eventBody, expectedBody.Mapping['CategoryLevel']).map(level => +level);
            categoryNames.forEach((categoryName: string, index: number) => {
              let category: TrafficCategory = {
                Name: categoryName,
                Icon: categoryIcons[index] ?? "",
                Level: categoryLevels[index] ?? 0
              };
              categories.push(category);
            });

            let trafficEvent: TrafficEvent = {
              Categories: categories,
              Created: event.CreatedTimestamp,
              Device: trafficDevice,
              DomainId: trafficDevice.Domain.Id,
              Id: '',
              Level: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Level'])
            }

            formattedEvents.push(trafficEvent);
          }
        });
      }
    });
    return formattedEvents;
  }

  private calculateTrafficEventsAverages(trafficEvents: TrafficEvent[], trafficDevices: Device[]): TrafficEventAverage[] {
    let averages: TrafficEventAverage[] = [];
    trafficDevices.forEach((trafficDevice: Device) => {
      //let deviceBrandName = trashBinDevice.Model.Brand.Name;
      let deviceEvents: TrafficEvent[] = trafficEvents.filter((event: TrafficEvent) => event.Device.Id === trafficDevice.Id);
      if(deviceEvents.length > 0) {
        let average: TrafficEventAverage = {
          Device: trafficDevice,
          Created: deviceEvents[0].Created,
          Level: 0,
          Categories: []
        };
        let categoriesLevels: { [key: string]: number } = {};

        deviceEvents.forEach((event: TrafficEvent) => {
          average.Level += event.Level;
          event.Categories.forEach(category => {
            if(!Object.keys(categoriesLevels).includes(category.Name)) {
              average.Categories.push({
                Name: category.Name,
                Level: 0,
                Icon: category.Icon
              });
              categoriesLevels[category.Name] = 0;
            }
            categoriesLevels[category.Name] += category.Level;
          });
        });
        average.Level = Math.round(average.Level / deviceEvents.length * 100) / 100;

        average.Categories.forEach((category: TrafficCategory) => {
          category.Level = Math.round(categoriesLevels[category.Name] / deviceEvents.length * 100) / 100;
        });

        averages.push(average);
      }
    });
    return averages;
  }

  private mapToCrosswalkEvents(res: DeviceEventsResponse, crosswalkDevices: Device[], eventBodies: Record<string, EventParsedBody>): CrosswalkEvent[] {
    let formattedEvents: CrosswalkEvent[] = [];

    res.Devices.forEach((device: DeviceEventsDevice) => {
      let crosswalkDevice: Device;
      if ((crosswalkDevice = crosswalkDevices.find(oneDevice => oneDevice.Id === device.Id)) !== undefined) {
        let expectedBody: EventParsedBody = eventBodies[crosswalkDevice.Model.Name];
        device.Events.forEach((event: DeviceEventsEvent) => {
          let eventBody: any = event.Body;
          if (this.eventBodyService.isAProperEvent(event, crosswalkDevice.Model, eventBodies)) {
            let crosswalkEvent: CrosswalkEvent = {
              Created: event.CreatedTimestamp,
              Device: crosswalkDevice,
              DomainId: crosswalkDevice.Domain.Id,
              Total: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Total']),
              Status: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Status']) as (0 | 1 | 2)
            };

            formattedEvents.push(crosswalkEvent);
          }
        });
      }
    });
    return formattedEvents;
  }

  private calculateCrosswalkEventsAverages(crosswalkEvents: CrosswalkEvent[], crosswalkDevices: Device[]): CrosswalkEventAverage[] {
    let averages: CrosswalkEventAverage[] = [];
    crosswalkDevices.forEach((crosswalkDevice: Device) => {
      let deviceEvents: CrosswalkEvent[] = crosswalkEvents.filter((event: CrosswalkEvent) => event.Device.Id === crosswalkDevice.Id);
      if(deviceEvents.length > 0) {
        //let deviceBrandName = crosswalkDevice.Model.Brand.Name;
        let averagesPerStatus: CrosswalkEventAverage[] = []
        let countsPerStatus: number[] = [];
        let statusArray: (0 | 1 | 2 | 3)[] = [0, 1, 2, 3]; // 0 = red, 1 = yellow, 2 = green, 3 = all
        statusArray.forEach((status: 0 | 1 | 2 | 3) => {
          let average: CrosswalkEventAverage = {
            Created: deviceEvents[0].Created,
            Device: crosswalkDevice,
            DomainId: crosswalkDevice.Domain.Id,
            Total: 0,
            Status: status
          };
          averagesPerStatus[status] = average;
          countsPerStatus[status] = 0;
        });
        deviceEvents.forEach((event: CrosswalkEvent) => {
          averagesPerStatus[event.Status].Total += event.Total;
          averagesPerStatus[3].Total += event.Total;
          countsPerStatus[event.Status]++;
        });
        statusArray.forEach((status : 0 | 1 | 2) => {
          if(countsPerStatus[status] !== 0) {
            averagesPerStatus[status].Total = Math.round(averagesPerStatus[status].Total / countsPerStatus[status] * 100) / 100;
          }
        });
        averagesPerStatus[3].Total = Math.round(averagesPerStatus[3].Total / deviceEvents.length * 100) / 100;
        averages.push(...averagesPerStatus);
      }
    });
    return averages;
  }

  public onMapDragEnd(mapViewData: { Latitude: number, Longitude: number, Range: number }) {
    this.mostDangerousEventsRequest.Latitude = mapViewData.Latitude;
    this.mostDangerousEventsRequest.Longitude = mapViewData.Longitude;
    this.mostDangerousEventsRequest.Range = Math.ceil(mapViewData.Range);
    this.getDangerousPlaces();
    this.boxesDivHeight = this.boxesDiv.nativeElement.offsetHeight + 'px';
    this.loadingBoxesData = true;
    this.getDataForBoxes();
  }

  public changeMinRate(event: MatSliderChange): void {
    this.mostDangerousEventsRequest.Rate = event.value;
    this.getDangerousPlaces();
  }

  public changeMaxNumberOfMarkers(event: MatSliderChange): void {
    this.maxNumberOfMarkers = event.value;
    this.mostDangerousPlaces = [];
    for (let i: number = 0; i < this.mostDangerousEvents.length && i < this.maxNumberOfMarkers; i++) {
      this.mostDangerousPlaces.push(
        new GoogleMarker(
          new google.maps.LatLng(this.mostDangerousEvents[i].Latitude, this.mostDangerousEvents[i].Longitude),
          {
            color: 'black',
            text: 'text',
            fontSize: '0px',
            fontWeight: 'normal',
            scaledSize: new google.maps.Size(80, 80),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(20, 0),
            labelOrigin: null
          },
          null,
          {
            animation: google.maps.Animation.DROP,
            icon: {
              url: "/assets/img/icons/pin-danger-accident.png",
              labelOrigin: new google.maps.Point(90, 20)
            }
          },
          this.mostDangerousEvents[i].Rate.toString(),
          []
        )
      );
    }
  }

  public startIntro(): void {
    this.translate.get([
      'INTRO.ROADRISK_WELCOME',
      'INTRO.ROADRISK_MAP',
      'INTRO.ROADRISK_METERS',
      'INTRO.ROADRISK_BOXES'
    ])
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(intros => {
        this.introJS
          .setOptions({
            steps: [
              {
                title: 'Road risk',
                intro: intros['INTRO.ROADRISK_WELCOME']
              },
              {
                element: '#intro-roadrisk-map',
                intro: intros['INTRO.ROADRISK_MAP'],
                position: 'floating'
              },
              {
                element: '#intro-roadrisk-meters',
                intro: intros['INTRO.ROADRISK_METERS'],
                position: 'floating'
              }
            ],
            showProgress: true
          });
        if (this.totalEvents !== null && this.dangerEvents !== null) {
          this.introJS.addStep({
            element: '#intro-roadrisk-boxes',
            intro: intros['INTRO.ROADRISK_BOXES'],
            position: 'bottom'
          });
        }
        this.introJS.start();
      });
  }

  goToMain(): void {
    let currentUrl: string = this.router.url.split('/').pop();
    this.mainService.setNavigationInfoComand({ BackRoute: currentUrl });
    this.mainService.updateAllDataAndGoToMainComand();
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
