import {TransitDashboardComponent} from '../../pages/main/transit-dashboard/transit-dashboard.component';
import {TransitDetailComponent} from '../../pages/main/transit-detail/transit-detail.component';
import {SmartParkingDetailComponent} from '../../pages/main/smart-parking-detail/smart-parking-detail.component';
import {NewsComponent} from '../../pages/main/news/news.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from '../../pages/main/main.component';
import {MainRoutingModule} from './main-routing.module';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {DashboardComponent} from '../../pages/main/dashboard/dashboard.component';
import {ProfileComponent} from '../../pages/main/profile/profile.component';
import {WipComponent} from '../../pages/main/wip/wip.component';
import {StoreModule} from '@ngrx/store';
import * as fromMain from '../../store/main/main.reducer';
import {EffectsModule} from '@ngrx/effects';
import {MainEffects} from '../../store/main/main.effects';
import {DeviceComponent} from '../../pages/main/device/device.component';
import {DevicesTableComponent} from '../../pages/main/devices-table/devices-table.component';
import {HistoryComponent} from '../../pages/main/history/history.component';
import {RemoteControlDetailComponent} from '../../pages/main/remote-control-detail/remote-control-detail.component';
import {WeatherStationDetailComponent} from '../../pages/main/weatherstation-detail/weatherstation-detail.component';
import {WifiModule} from './wifi/wifi.module';
import {UserDetailComponent} from '../../pages/main/user-detail/user-detail.component';
import {RoleListComponent} from '../../pages/main/administration/role-list/role-list.component';
import {DomainListComponent} from '../../pages/main/administration/domain-list/domain-list.component';
import {UserListComponent} from '../../pages/main/administration/user-list/user-list.component';
import {RoleDetailComponent} from '../../pages/main/role-detail/role-detail.component';
import {UserDomainListComponent} from '../../pages/main/user-domain-list/user-domain-list.component';
import {UserDomainDetailComponent} from '../../pages/main/user-domain-detail/user-domain-detail.component';
import {MessageComponent} from '../../pages/main/message/message.component';
import {PlatformStatisticsComponent} from '../../pages/main/platform-statistics/platform-statistics.component';
import {
  PlatformStatisticsBarchartComponent
} from '../../pages/main/platform-statistics-barchart/platform-statistics-barchart.component';
import {LogsStatisticsComponent} from '../../pages/main/logs-statistics/logs-statistics.component';
import {NotificationListComponent} from '../../pages/main/administration/notification-list/notification-list.component';
import {
  NotificationDetailComponent
} from '../../pages/main/administration/notification-detail/notification-detail.component';
import {DomainDetailComponent} from '../../pages/main/administration/domain-detail/domain-detail.component';
import {TranslateComponent} from '../../pages/main/administration/translate/translate.component';
import {
  ClientSettingListComponent
} from '../../pages/main/administration/client-setting-list/client-setting-list.component';
import {
  ResourceListComponent
} from '../../pages/main/administration/resource-list/resource-list.component';
import {DeviceEventsComponent} from '../../pages/main/device-events/device-events.component';
import {DomainDeviceEventsComponent} from '../../pages/main/domain-device-events/domain-device-events.component';
import {HeatmapDevicesComponent} from '../../pages/main/heatmap-devices/heatmap-devices.component';
import {DeviceBrandListComponent} from '../../pages/main/administration/device-brand-list/device-brand-list.component';
import {DeviceTypeListComponent} from '../../pages/main/administration/device-type-list/device-type-list.component';
import {DeviceModelListComponent} from '../../pages/main/administration/device-model-list/device-model-list.component';
import {HeatmapPeopleCounterComponent} from '../../pages/main/heatmap-people-counter/heatmap-people-counter.component';
import {DeviceTypeDetailComponent} from '../../pages/main/administration/device-type-detail/device-type-detail.component';
import {DeviceGroupListComponent} from '../../pages/main/device-group-list/device-group-list.component';
import {DeviceGroupDetailComponent} from '../../pages/main/device-group-detail/device-group-detail.component';
import {AdvertiserComponent} from '../../pages/main/smss/advertiser/advertiser.component';
import {AdvertisersComponent} from '../../pages/main/smss/advertisers/advertisers.component';
import {CampaignComponent} from '../../pages/main/smss/campaign/campaign.component';
import {CampaignsComponent} from '../../pages/main/smss/campaigns/campaigns.component';
import {LocationListComponent} from '../../pages/main/location-list/location-list.component';
import {CalendarComponent} from '../../pages/main/calendar/calendar.component';
import {GatewayComponent} from '../../pages/main/smss/gateway/gateway.component';
import {WptemplateComponent} from '../../pages/main/smss/wptemplate/wptemplate.component';
import {
  SmartParkingDashboardComponent
} from '../../pages/main/smart-parking-dashboard/smart-parking-dashboard.component';
import {CrosswalkDashboardComponent} from '../../pages/main/crosswalk-dashboard/crosswalk-dashboard.component';
import {CrosswalkDetailComponent} from '../../pages/main/crosswalk-detail/crosswalk-detail.component';
import {TrafficDashboardComponent} from '../../pages/main/traffic-dashboard/traffic-dashboard.component';
import {TrafficDetailComponent} from '../../pages/main/traffic-detail/traffic-detail.component';
import {RoadRiskComponent} from '../../pages/main/road-risk/road-risk.component';
import {TaskScheduleComponent} from '../../pages/main/task-schedule/task-schedule.component';
import {TaskScheduleDetailComponent} from '../../pages/main/task-schedule-detail/task-schedule-detail.component';
import {TrashBinDetailComponent} from '../../pages/main/trash-bin-detail/trash-bin-detail.component';
import {TrashBinDashboardComponent} from '../../pages/main/trash-bin-dashboard/trash-bin-dashboard.component';
import {DeviceNotificationsComponent} from '../../pages/main/device-notifications/device-notifications.component';
import {DeviceCommandsComponent} from '../../pages/main/device-commands/device-commands.component';
import {DeviceCommandComponent} from '../../pages/main/device-command/device-command.component';
import {DeviceCommandDetailComponent} from '../../pages/main/device-command-detail/device-command-detail.component';
import {
  DeviceCommandTypeListComponent
} from "../../pages/main/administration/device-command-type-list/device-command-type-list.component";
import {WidgetComponent} from '../../pages/main/administration/widget/widget.component';
import {ServerSettingListComponent} from '../../pages/main/administration/server-setting-list/server-setting-list.component';
import {TemplateListComponent} from '../../pages/main/administration/template-list/template-list.component';
import {TemplateFormatListComponent} from '../../pages/main/administration/template-format-list/template-format-list.component';
import {TemplateTypeListComponent} from '../../pages/main/administration/template-type-list/template-type-list.component';
import {LocationEventsComponent} from "../../pages/main/location-events/location-events.component";
import {LocationEventComponent} from "../../pages/main/location-event/location-event.component";
import {LocationDetailComponent} from "../../pages/main/location-detail/location-detail.component";
import {
  DomainVisibilityItemListComponent
} from "../../pages/main/administration/domain-visibility-item-list/domain-visibility-item-list.component";
import {ThemeListComponent} from "../../pages/main/administration/theme-list/theme-list.component";
import {ThemeDetailComponent} from "../../pages/main/administration/theme-detail/theme-detail.component";
import { BacnetMonitorComponent } from '../../pages/main/bacnet-monitor/bacnet-monitor.component';
import { BacnetDetailComponent } from '../../pages/main/bacnet-detail/bacnet-detail.component';
import {
  BroadcastClientTypesComponent
} from "../../pages/main/content-management/broadcast-client-types/broadcast-client-types.component";
import {ScheduleRulesComponent} from "../../pages/main/content-management/schedule-rules/schedule-rules.component";
import {
  BroadcastClientsComponent
} from "../../pages/main/content-management/broadcast-clients/broadcast-clients.component";
import {
  BroadcastClientComponent
} from "../../pages/main/content-management/broadcast-client/broadcast-client.component";
import {ChannelsComponent} from "../../pages/main/content-management/channels/channels.component";
import {ChannelComponent} from "../../pages/main/content-management/channel/channel.component";
import {BroadcastsComponent} from "../../pages/main/content-management/broadcasts/broadcasts.component";
import {ContentsComponent} from "../../pages/main/content-management/contents/contents.component";
import {ContentComponent} from "../../pages/main/content-management/content/content.component";
import {EntitiesComponent} from "../../pages/main/content-management/entities/entities.component";
import {EntityComponent} from "../../pages/main/content-management/entity/entity.component";
import { PeoplePassageComponent } from '../../pages/main/people-passage/people-passage.component';
import { ServiceListComponent } from 'src/app/pages/main/service-list/service-list.component';
import { ServiceDetailComponent } from 'src/app/pages/main/service-detail/service-detail.component';
import { DashboardManagementComponent } from 'src/app/pages/main/dashboard-management/dashboard-management.component';
import { DashboardVideoComponent } from 'src/app/pages/main/dashboard-video/dashboard-video.component';
import { AdvertisingTypeListComponent } from '../../pages/main/administration/advertising-type-list/advertising-type-list.component';
import { AdvertisingListComponent } from '../../pages/main/advertising-list/advertising-list.component';
import { ConsumerListComponent } from '../../pages/main/administration/consumer-list/consumer-list.component';
import { ConsumerDetailComponent } from '../../pages/main/administration/consumer-detail/consumer-detail.component';
import { AdvertisingEventListComponent } from '../../pages/main/advertising-event-list/advertising-event-list.component';
import { MapComponent } from '../../pages/main/map/map.component';
import { PassageDashboardComponent } from '../../pages/main/passage-dashboard/passage-dashboard.component';
import { PassageDetailComponent } from '../../pages/main/passage-detail/passage-detail.component';
import { DeviceEventsRejectedComponent } from 'src/app/pages/main/device-events-rejected/device-events-rejected.component';
import { DeviceModelDetailComponent } from '../../pages/main/administration/device-model-detail/device-model-detail.component';
import { DeviceBrandDetailComponent } from 'src/app/pages/main/administration/device-brand-detail/device-brand-detail.component';
import { ServiceTypeListComponent } from 'src/app/pages/main/administration/service-type-list/service-type-list.component';
import { DeletedUserListComponent } from 'src/app/pages/main/administration/deleted-user-list/deleted-user-list.component';
import { UpdateMissingTranslationComponent } from 'src/app/pages/main/administration/update-missing-translation/update-missing-translation.component';
import { UserSettingListComponent } from 'src/app/pages/main/user-setting-list/user-setting-list.component';
import { NotesComponent } from 'src/app/pages/main/notes/notes.component';

@NgModule({
  declarations: [
    MainComponent,
    DashboardComponent,
    ProfileComponent,
    DeviceComponent,
    WipComponent,
    HistoryComponent,
    DevicesTableComponent,
    RemoteControlDetailComponent,
    WeatherStationDetailComponent,
    UserListComponent,
    UserDetailComponent,
    RoleListComponent,
    DomainListComponent,
    UserListComponent,
    RoleDetailComponent,
    UserDomainListComponent,
    UserDomainDetailComponent,
    MessageComponent,
    PlatformStatisticsComponent,
    LogsStatisticsComponent,
    PlatformStatisticsBarchartComponent,
    NotificationListComponent,
    NotificationDetailComponent,
    DomainDetailComponent,
    TranslateComponent,
    ClientSettingListComponent,
    ResourceListComponent,
    UpdateMissingTranslationComponent,
    DeviceEventsComponent,
    DomainDeviceEventsComponent,
    HeatmapDevicesComponent,
    DeviceBrandListComponent,
    DeviceBrandDetailComponent,
    DeviceTypeListComponent,
    DeviceModelListComponent,
    HeatmapPeopleCounterComponent,
    DeviceTypeDetailComponent,
    DeviceGroupListComponent,
    DeviceGroupDetailComponent,
    NewsComponent,
    AdvertiserComponent,
    AdvertisersComponent,
    CampaignComponent,
    CampaignsComponent,
    LocationListComponent,
    CalendarComponent,
    GatewayComponent,
    WptemplateComponent,
    SmartParkingDashboardComponent,
    SmartParkingDetailComponent,
    CrosswalkDashboardComponent,
    CrosswalkDetailComponent,
    TrafficDashboardComponent,
    TrafficDetailComponent,
    RoadRiskComponent,
    TaskScheduleComponent,
    TaskScheduleDetailComponent,
    TrashBinDashboardComponent,
    TrashBinDetailComponent,
    TransitDashboardComponent,
    TransitDetailComponent,
    DeviceNotificationsComponent,
    DeviceCommandsComponent,
    DeviceCommandComponent,
    DeviceCommandDetailComponent,
    DeviceCommandTypeListComponent,
    WidgetComponent,
    ServerSettingListComponent,
    TemplateListComponent,
    TemplateFormatListComponent,
    TemplateTypeListComponent,
    LocationEventsComponent,
    LocationEventComponent,
    LocationDetailComponent,
    DomainVisibilityItemListComponent,
    ThemeListComponent,
    ThemeDetailComponent,
    BacnetMonitorComponent,
    BacnetDetailComponent,
    BroadcastClientTypesComponent,
    ScheduleRulesComponent,
    BroadcastClientsComponent,
    BroadcastClientComponent,
    ChannelsComponent,
    ChannelComponent,
    BroadcastsComponent,
    ContentsComponent,
    ContentComponent,
    EntitiesComponent,
    EntityComponent,
    PeoplePassageComponent,
    NotesComponent,
    UserSettingListComponent,
    ServiceTypeListComponent,
    ServiceListComponent,
    ServiceDetailComponent,
    DashboardManagementComponent,
    DashboardVideoComponent,
    AdvertisingTypeListComponent,
    DeletedUserListComponent,
    AdvertisingListComponent,
    ConsumerListComponent,
    ConsumerDetailComponent,
    AdvertisingEventListComponent,
    MapComponent,
    PassageDashboardComponent,
    PassageDetailComponent,
    DeviceEventsRejectedComponent,
    DeviceModelDetailComponent
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    RouterModule,
    SharedModule,
    WifiModule,
    StoreModule.forFeature(fromMain.mainFeatureKey, fromMain.mainReducer),
    EffectsModule.forFeature([MainEffects])
  ],
  providers: [],
})
export class MainModule {
}
