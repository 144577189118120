import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ConfirmationDialogComponent
} from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Role } from '../../../../shared/models/userRoles';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { ApiService } from '../../../../shared/services/api.service';
import { WidgetManagementDialogComponent } from "../../../../shared/dialogs/widget-management-dialog/widget-management-dialog.component";
import { PaginationInstance } from 'ngx-pagination';
import { Widget } from 'src/app/shared/models/widget';
import { WidgetRequest } from 'src/app/shared/models/administrator/widgetRequest';

@Component({
  selector: 'urban-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit, OnDestroy {

  public widgetColumns = ['Name', 'Id', 'Description', 'Actions'];
  public widgets: Widget[] = [];
  public filteredData: Widget[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private roleList: Role[];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private adminApiService: AdministratorApiService,
    private apiService: ApiService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this._initWidgetList();
    this._initRoleList();
  }

  private _initWidgetList(): void {
    this.apiService.getWidgetList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res && res.widgets) {
        this.widgets = res.widgets['Widgets'];
        this.filteredData = this.widgets;
      }
    });
  }

  private _initRoleList(): void {
    this.adminApiService.roleList().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res?.length > 0) {
        this.roleList = res;
      }
    });
  }

  public addWidget(): void {
    const addWidgetDialogRef = this.dialog.open(WidgetManagementDialogComponent, {
      data: {
        roleList: this.roleList
      },
      width: "458px"
    });

    addWidgetDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newWidget => {
      if (newWidget) {
        const addWidgetConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addWidgetConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.adminApiService.addWidget(newWidget).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this._initWidgetList();
            });
          }
        });
      }
    });
  }

  public editWidget(widgetId: string): void {
    if (!widgetId) {
      return;
    }

    const widgetToEdit = this.widgets.find(widget => widget.Id === widgetId);

    const updateDialogRef = this.dialog.open(WidgetManagementDialogComponent, {
      data: {
        widgetToEdit: widgetToEdit,
        roleList: this.roleList
      },
      width: "458px"
    });

    updateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((editedWidget: WidgetRequest) => {
      if (editedWidget) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.adminApiService.updateWidget(editedWidget).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this._initWidgetList()
            });
          }
        });
      }
    });
  }

  public widgetDelete(widget: Widget): void {
    if (!widget || widget === undefined) {
      return;
    }

    const deleteWidgetConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteWidgetConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.adminApiService.deleteWidget(widget.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this._initWidgetList();
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.widgets?.filter((widget: Widget) =>
      [
        widget.Name?.toLowerCase(), 
        widget.Id?.toLowerCase()
      ]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
