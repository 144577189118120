import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { PaginationInstance } from "ngx-pagination";
import { AdministratorApiService } from "../../../../shared/services/administrator-api.service";
import { MatDialog } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { Configuration } from "../../../../shared/models/configuration";
import { PassDataService } from "../../../../shared/services/pass-data/pass-data.service";
import { ThemeRequest } from "../../../../shared/models/administrator/themeRequest";
import { MainSubscriptionsService } from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import { Router } from "@angular/router";

@Component({
  selector: 'urban-theme-list',
  templateUrl: './theme-list.component.html',
  styleUrls: ['./theme-list.component.scss']
})
export class ThemeListComponent implements OnInit, OnDestroy {

  public columns = ['Id', 'Hostname', 'Detail'];
  public themes: ThemeRequest[] = [];
  public filteredData: ThemeRequest[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private appConfig: Configuration;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private administratorApiService: AdministratorApiService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadData();

    this.passDataService.appConfiguration$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(config => {
      this.appConfig = config;
    });
  }

  private loadData(): void {
    this.administratorApiService.themeList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(themes => {
      this.themes = themes;
      this.filteredData = this.themes;
    });
  }

  public add(): void {
    this.mainService.setNavigationInfoComand({BackRoute: 'themes'});
    this.router.navigate(['main/theme-detail']);
  }

  public delete(theme: ThemeRequest): void {
    if (theme) {
      const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
        if (confirmed) {
          this.administratorApiService.themeDelete(theme.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.loadData();
          });
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.themes?.filter((theme: ThemeRequest) =>
      [
        theme?.Hostname?.toLowerCase(), 
        theme?.Id?.toString().toLowerCase()
      ]
        .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public detail(theme: ThemeRequest): void {
    if (!theme) {
      return;
    }

    this.mainService.setNavigationInfoComand({Id: theme.Id.toString(), BackRoute: 'theme-list'});
    this.router.navigate(['main/theme-detail']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
