<div class="container-fluid">
    <div class="d-flex justify-content-between mx-0 pageTop flex-wrap">
        <span class="title2">
            {{ 'GENERAL.DOMAIN' | translate }} : {{currentDomain?.Name}}
        </span>

        <button mat-button class="btn actionButton" (click)="openAddProperty()">
            {{ 'DOMAIN_DETAIL.ADD_PROPERTY' | translate }}
        </button>
    </div>

    <div class="domainInfo panelBG">
        <form [formGroup]="form">
            <div class="d-flex justify-content-between align-items-center mb-4 flex-wrap">
                <span class="title3 secondaryTextColor">{{ 'GENERAL.INFO' | translate }}</span>

                <div>
                    <button type="button" mat-button class="btn actionButton" *ngIf="editMode" (click)="update()" [disabled]="!dataChanged">
                        {{ 'GENERAL.SAVE' | translate }}
                    </button>
    
                    <button type="button" mat-button class="btn actionButton" *ngIf="!editMode" (click)="enableEdit()">
                        {{ 'GENERAL.EDIT' | translate }}
                    </button>
    
                    <button type="button" mat-button class="btn outlineActionButton ml-0 cancelBtn mt-2 mt-sm-0" *ngIf="editMode" (click)="cancelEdit()">
                        {{ 'GENERAL.CANCEL' | translate }}
                    </button>
                </div>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-12 col-md-3 pl-0 logoUploader">
                    <div class="domainImage text-center" [ngClass]="{'pointer' : editMode}" (click)="editMode ? imageInput.click() : ''">
                        <img *ngIf="domainLogo !== ''" src="{{ domainLogo }}" class="img-fluid" alt="Logo">
                        <input style="display: none;" type="file" (change)="logoImageUpload($event)" accept=".png, .jpg, .jpeg" #imageInput>
                    </div>

                    <span class="captionText" *ngIf="editMode">{{ 'DOMAIN_DETAIL.UPDATE_LOGO' | translate }}</span>

                    <mat-error *ngIf="imageError">
                        {{ 'ERROR.' + imageError | translate }}
                    </mat-error>
                </div>

                <div class="col-12 col-md-9 d-flex flex-wrap">
                    <div class="d-flex col-12 col-md-6 flex-column">
                        <mat-form-field appearance="outline" class="customMatFormField mb-3" [ngClass]="{'noUnderline': !editMode}">
                            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
                            <input matInput formControlName="name" autocomplete="off" [required]="editMode" [readonly]="!editMode">
                        </mat-form-field>
            
                        <mat-form-field appearance="outline" class="customMatFormField" [ngClass]="{'noUnderline': !editMode}">
                            <mat-label>{{ 'ADMINISTRATION.DOMAIN_PARENT' | translate }}</mat-label>
                            <mat-select formControlName="parentSelected" hideSingleSelectionIndicator="true">
                                <mat-option *ngFor="let parentDomain of parents" value={{parentDomain.Id}}>{{parentDomain.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
    
                    <div class="col-12 col-md-6">
                        <mat-form-field appearance="outline" class="customMatFormField" [ngClass]="{'noUnderline': !editMode}">
                            <mat-label>{{ 'ADMINISTRATION.DOMAIN_VISIBILITY' | translate }}</mat-label>
                            <mat-select formControlName="visibilitySelected" hideSingleSelectionIndicator="true">
                                <mat-option *ngFor="let visibility of domainVisibilityItems" value={{visibility.Id}}>
                                    {{visibility.Description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="customTable">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <span class="title3">{{ 'DOMAIN_DETAIL.DOMAIN_PROPERTY' | translate }}</span>

            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                       autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear"
                        (click)="applyFilterString(''); actualFilter = ''"
                        matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 user-select-none noTableHover" mat-table [dataSource]="filteredData | paginate: config">

                <ng-container matColumnDef="Key">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.KEY' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{element.Key}} </td>
                </ng-container>

                <ng-container matColumnDef="Value">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.VALUE' | translate }} </th>
                    <td mat-cell *matCellDef="let element"> {{ element.Value.length > 20 ? element.Value.slice(0, 20) +
                        "..." : element.Value }} </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i class="iconInfoButton urbanicon-info-outline"
                               matTooltip="{{'GENERAL.DETAIL' | translate }}"
                               (click)="openDetailProperty(element)">
                            </i>
                            <i class="iconDeleteButton urbanicon-delete" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                               (click)="openDeleteDialog(element.Key)">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                                     [maxSize]="7"
                                     [directionLinks]="true"
                                     [previousLabel]="''"
                                     [nextLabel]="''"
                                     [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                                     [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                                     [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                                     (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase() : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
