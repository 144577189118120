<div class="container-fluid px-0 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'LOGS_STATISTICS.TITLE' | translate }}
        </span>
    </div>

    <urban-searchbar id="intro-logs-statistics-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="customTable" *ngIf="traces?.length > 0">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <span class="title3">{{ 'LOGS_STATISTICS.LOGS' | translate }}</span>

            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 user-select-none" mat-table [dataSource]="filteredData | paginate: config">
                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef> {{'STATISTICS.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{ item.Created*1000 | date : 'short' }} </td>
                </ng-container>
                
                <ng-container matColumnDef="ClientIp">
                    <th mat-header-cell *matHeaderCellDef> {{'STATISTICS.CLIENTIP' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        {{ item.ClientIp }}
                    </td>
                </ng-container>
                
                
                <ng-container matColumnDef="SessionId">
                    <th mat-header-cell *matHeaderCellDef> {{'STATISTICS.SESSIONID' | translate }} </th>
                    <td mat-cell *matCellDef="let item" [matTooltip]="item.SessionId" [matTooltipPosition]="'above'">
                        {{ item.SessionId?.length > 15 ? item.SessionId.slice(0,15) + "..." : item.SessionId }}
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="Url">
                    <th mat-header-cell *matHeaderCellDef> {{'STATISTICS.URL' | translate }} </th>
                    <td mat-cell *matCellDef="let item" [matTooltip]="item.Resource" [matTooltipPosition]="'above'">
                        {{ item.Resource }}
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="Username">
                    <th mat-header-cell *matHeaderCellDef> {{'STATISTICS.USERNAME' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        {{ item.Username }} 
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="Domain">
                    <th mat-header-cell *matHeaderCellDef> {{'STATISTICS.DOMAIN' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> 
                        {{ item.Domain }} 
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="ExecutionTime">
                    <th mat-header-cell *matHeaderCellDef> {{'STATISTICS.MILLIS' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> 
                        {{ item.Millis }} ms 
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1"></th>
                    <td mat-cell *matCellDef="let item" class="text-center pr-1">
                        <div class="pr-2 d-flex justify-content-start">
                            <i *ngIf="item.Error" matTooltip="{{'STATISTICS.CHECK_ERROR' | translate }}"
                                (click)="openDetailDialog(item, 'error')" class="iconDeleteButton urbanicon-alert">
                            </i>
                            <i *ngIf="item.Message" class="iconInfoButton urbanicon-sms-outline"
                                matTooltip="{{'STATISTICS.CHECK_MESSAGE' | translate }}" (click)="openDetailDialog(item, 'message')">
                            </i>
                            <i matTooltip="{{'STATISTICS.DETAIL' | translate }}" (click)="openDetailDialog(item, '')"
                                class="iconInfoButton urbanicon-info-outline">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>

        <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true" (selectionChange)="paginatorOnItemsPerPageChange()">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center noDataIllustration responsiveIllustration" *ngIf="traces?.length === 0">
        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
        <div class="d-flex">
            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                {{ 'GENERAL.NEW_SEARCH' | translate }}
            </button>
        </div>
    </div>
</div>
