import { NewsDialogComponent } from '../../../shared/dialogs/news-dialog/news-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { NewsApiService } from '../../../shared/services/news-api.service';
import { News } from '../../../shared/models/news';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ApiService } from '../../../shared/services/api.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {

  public displayedColumns = ['Date', 'Created', 'Body', 'Source', 'Detail'];
  public newsList: News[] = [];
  public cliccedSeeMore: boolean = false;
  public maxLength: number = 100;
  public filteredData: News[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private maxFileSize: number;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private newsApiService: NewsApiService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadData();
    this.getClientSettings();
  }

  private loadData(): void {
    this.newsApiService.getAllNews().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.newsList = result;
        this.filteredData = this.newsList;
      }
    });
  }

  private getClientSettings(): void {
    this.apiService.getClientSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(clientSettings => {
      if (clientSettings && clientSettings.max_news_image_size) {
        this.maxFileSize = +clientSettings.max_news_image_size;
      }
    });
  }

  public editNews(news: News): void {
    this.newsApiService.getNews(news.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(remoteNews => {
      if (remoteNews) {
        const editNewsDialogRef = this.dialog.open(NewsDialogComponent, {
          data: { currentNews: remoteNews, maxFileSize: this.maxFileSize },
          width: '50%',
          minWidth: 450
        });

        editNewsDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedNews => {
          if (updatedNews) {
            const editNewsConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
              disableClose: false
            });

            editNewsConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
              if (confirmed) {
                this.newsApiService.updateNews(updatedNews).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                  this.loadData();
                });
              }
            });
          }
        });
      }
    });
  }

  public deleteNews(id: string): void {
    const addNewsConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    addNewsConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.newsApiService.deleteNews(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.loadData();
        });
      }
    });
  }

  public addNews(): void {
    const addNewsDialogRef = this.dialog.open(NewsDialogComponent, {
      data: { maxFileSize : this.maxFileSize},
      width: '50%',
      minWidth: 450
    });

    addNewsDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(news => {
      if (news) {
        const addNewsConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addNewsConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.newsApiService.addNews(news).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }

  public seeMore(news: News): void {
    this.cliccedSeeMore = true;

    const seeMoreNewsDialogRef = this.dialog.open(NewsDialogComponent, {
      data: { currentNews: news, seeMore: this.cliccedSeeMore, maxFileSize : this.maxFileSize },
      width: '50%'
    });

    seeMoreNewsDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.cliccedSeeMore = false
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.newsList?.filter((news: News) =>
      [
        news.Date ? new Date(news.Date * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/'): '',
        news.Date ? new Date(news.Created * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/'): '',
        news.Title?.toLowerCase(), 
        news.Source?.toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
