<div class="container-fluid">
    <div
        class="row mb-1 d-flex justify-content-between align-items-baseline bg-light-grey border-radius-15 m-0">
        <span *ngIf="!this.isAdd" class="h4 color-dark mb-2 mt-2 thin">
            {{ 'SMSS.WP_TEMPLATE' | translate }} - {{this.wpTemplate?.id}}
        </span>
        <span *ngIf="this.isAdd" class="h4 color-dark mb-2 mt-2 thin">
            {{ 'SMSS.WP_TEMPLATE' | translate }}
        </span>
    </div>
    <br>
    <form [formGroup]="form">
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.WP_TEMPLATE_CONFIGURATION' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="p-0 mt-1">
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'GENERAL.USE_DEFAULTS' | translate }}</mat-label>
                    <input matInput formControlName="Code"
                        autocomplete="off" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'GENERAL.USE_DEFAULTS' | translate }}</mat-label>
                    <mat-select formControlName="UseDefaults" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.NO' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.YES' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField bodyArea">
                    <mat-label>{{ 'GENERAL.NOTES' | translate }}</mat-label>
                    <textarea  matInput formControlName="Notes" matTextareaAutosize matAutosizeMinRows=15
                        matAutosizeMaxRows=30></textarea>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.PORTAL_TITLE' | translate }}</mat-label>
                    <input matInput formControlName="PortalTitle"
                        autocomplete="off" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.PORTAL_SUB_TITLE' | translate }}</mat-label>
                    <input matInput
                        formControlName="PortalSubTitle" autocomplete="off" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.PORTAL_THEME' | translate }}</mat-label>
                    <mat-select formControlName="PortalTheme" hideSingleSelectionIndicator="true">
                        <mat-option value="0" required>
                            {{ 'SMSS.DEFAULT' | translate }}
                        </mat-option>
                        <mat-option value="10">
                            {{ 'SMSS.LIGHT' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.SHOWS_APPBAR' | translate }}</mat-label>
                    <mat-select formControlName="ShowAppsBar" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.NO' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.YES' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.SHOW_SIDEBAR_BUTTON' | translate }}</mat-label>
                    <mat-select formControlName="ShowSideBarButton" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.NO' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.YES' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.SHOW_APPS_IN_SIDEBAR' | translate }}</mat-label>
                    <mat-select formControlName="ShowAppsInSidebar" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.NO' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.YES' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_WP_CUSTOMIZATION' | translate }}</mat-label>
                    <mat-select formControlName="EnableWPCustomization" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.NO' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.YES' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField bodyArea">
                    <mat-label>{{ 'SMSS.WP_CUSTOM_HELP' | translate }}</mat-label>
                    <textarea  matInput formControlName="WPCustomHelp" matTextareaAutosize matAutosizeMinRows=15
                        matAutosizeMaxRows=30></textarea>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.PORTAL_INTRO_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="PortalIntroType"  hideSingleSelectionIndicator="true" required >
                        <mat-option value="0" required>
                            {{ 'SMSS.NONE' | translate }}
                        </mat-option>
                        <mat-option value="5">
                            {{ 'SMSS.IMAGE_FROM_FILE' | translate }}
                        </mat-option>
                        <mat-option value="10">
                            {{ 'SMSS.STATIC_IMAGE' | translate }}
                        </mat-option>
                        <mat-option value="11">
                            {{ 'SMSS.BANNER_WIFI_4EU' | translate }}
                        </mat-option>
                        <mat-option value="20">
                            {{ 'SMSS.PLACE_IMAGE' | translate }}
                        </mat-option>
                        <mat-option value="40">
                            {{ 'SMSS.FLICKR_PHOTESET_IMAGE' | translate }}
                        </mat-option>
                        <mat-option value="50">
                            {{ 'SMSS.IMAGE_FROM_WEATHER_REPORT' | translate }}
                        </mat-option>
                        <mat-option value="60">
                            {{ 'SMSS.YOUTUBE_VIDEO' | translate }}
                        </mat-option>
                        <mat-option value="70">
                            {{ 'SMSS.EXTERNAL_URL' | translate }}
                        </mat-option>
                        <mat-option value="80">
                            {{ 'SMSS.EXTERNAL_MP4_VIDEO' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-expansion-panel>

        <br>
        <div class="separator">{{ 'GENERAL.IMAGES' | translate }}</div>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.PORTALMAINIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.PortalMainImg.value !== ''"
                        src="{{ this.form.controls.PortalMainImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.PortalMainImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'PortalMainImg')"
                        accept=".png, .jpg, .jpeg" #portalMainImageInput>
                    <button *ngIf="this.form.controls.PortalMainImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="portalMainImageInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.PortalMainImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('PortalMainImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>

        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.PORTALMAINIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.PortalIntroImageFromFileImg.value !== ''"
                        src="{{ this.form.controls.PortalIntroImageFromFileImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.PortalIntroImageFromFileImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file"
                        (change)="imageUpload($event, 'PortalIntroImageFromFileImg')" accept=".png, .jpg, .jpeg"
                        #portalIntroImageFromFileImgInput>
                    <button *ngIf="this.form.controls.PortalIntroImageFromFileImg.value === ''" mat-mini-fab
                        color="accent" class="my-fab mat-elevation-z2"
                        (click)="portalIntroImageFromFileImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.PortalIntroImageFromFileImg.value !== ''" mat-mini-fab
                        color="accent" class="my-fab mat-elevation-z2 ml-1"
                        (click)="removeImage('PortalIntroImageFromFileImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.PORTALINTROVDEOPOSTERIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.PortalIntroVideoPosterImg.value !== ''"
                        src="{{ this.form.controls.PortalIntroVideoPosterImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.PortalIntroVideoPosterImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file"
                        (change)="imageUpload($event, 'PortalIntroVideoPosterImg')" accept=".png, .jpg, .jpeg"
                        #portalIntroVideoPosterImgInput>
                    <button *ngIf="this.form.controls.PortalIntroVideoPosterImg.value === ''" mat-mini-fab
                        color="accent" class="my-fab mat-elevation-z2" (click)="portalIntroVideoPosterImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.PortalIntroVideoPosterImg.value !== ''" mat-mini-fab
                        color="accent" class="my-fab mat-elevation-z2 ml-1"
                        (click)="removeImage('PortalIntroVideoPosterImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.PORTALBACKGROUNDIMAGEFROMFILEIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.PortalBackgroundImageFromFileImg.value !== ''"
                        src="{{ this.form.controls.PortalBackgroundImageFromFileImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.PortalBackgroundImageFromFileImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file"
                        (change)="imageUpload($event, 'PortalBackgroundImageFromFileImg')" accept=".png, .jpg, .jpeg"
                        #PortalBackgroundImageFromFileImgInput>
                    <button *ngIf="this.form.controls.PortalBackgroundImageFromFileImg.value === ''" mat-mini-fab
                        color="accent" class="my-fab mat-elevation-z2"
                        (click)="PortalBackgroundImageFromFileImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.PortalBackgroundImageFromFileImg.value !== ''" mat-mini-fab
                        color="accent" class="my-fab mat-elevation-z2 ml-1"
                        (click)="removeImage('PortalBackgroundImageFromFileImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>

        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.INFOHEADERIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.InfoHeaderImg.value !== ''"
                        src="{{ this.form.controls.InfoHeaderImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.InfoHeaderImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'InfoHeaderImg')"
                        accept=".png, .jpg, .jpeg" #InfoHeaderImgInput>
                    <button *ngIf="this.form.controls.InfoHeaderImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="InfoHeaderImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.InfoHeaderImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('InfoHeaderImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.INFOFOOTERIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.InfoFooterImg.value !== ''"
                        src="{{ this.form.controls.InfoFooterImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.InfoFooterImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'InfoFooterImg')"
                        accept=".png, .jpg, .jpeg" #InfoFooterImgInput>
                    <button *ngIf="this.form.controls.InfoFooterImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="InfoFooterImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.InfoFooterImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('InfoFooterImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.LOGINHEADERIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.LoginHeaderImg.value !== ''"
                        src="{{ this.form.controls.LoginHeaderImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.LoginHeaderImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'LoginHeaderImg')"
                        accept=".png, .jpg, .jpeg" #LoginHeaderImgInput>
                    <button *ngIf="this.form.controls.LoginHeaderImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="LoginHeaderImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.LoginHeaderImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('LoginHeaderImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.APPBARINFOIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.AppBarInfoImg.value !== ''"
                        src="{{ this.form.controls.AppBarInfoImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.AppBarInfoImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'AppBarInfoImg')"
                        accept=".png, .jpg, .jpeg" #AppBarInfoImgInput>
                    <button *ngIf="this.form.controls.AppBarInfoImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="AppBarInfoImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.AppBarInfoImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('AppBarInfoImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.APPBARLOGINIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.AppBarLoginImg.value !== ''"
                        src="{{ this.form.controls.AppBarLoginImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.AppBarLoginImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'AppBarLoginImg')"
                        accept=".png, .jpg, .jpeg" #AppBarLoginImgInput>
                    <button *ngIf="this.form.controls.AppBarLoginImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="AppBarLoginImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.AppBarLoginImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('AppBarLoginImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.APPBARSURFIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.AppBarSurfImg.value !== ''"
                        src="{{ this.form.controls.AppBarSurfImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.AppBarSurfImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'AppBarSurfImg')"
                        accept=".png, .jpg, .jpeg" #AppBarSurfImgInput>
                    <button *ngIf="this.form.controls.AppBarSurfImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="AppBarSurfImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.AppBarSurfImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('AppBarSurfImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.APPBARCONNSTATUSIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.AppBarConnStatusImg.value !== ''"
                        src="{{ this.form.controls.AppBarConnStatusImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.AppBarConnStatusImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'AppBarConnStatusImg')"
                        accept=".png, .jpg, .jpeg" #AppBarConnStatusImgInput>
                    <button *ngIf="this.form.controls.AppBarConnStatusImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="AppBarConnStatusImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.AppBarConnStatusImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('AppBarConnStatusImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.APPBARAROUNDMEIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.AppBarAroundMeImg.value !== ''"
                        src="{{ this.form.controls.AppBarAroundMeImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.AppBarAroundMeImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'AppBarAroundMeImg')"
                        accept=".png, .jpg, .jpeg" #AppBarAroundMeImgInput>
                    <button *ngIf="this.form.controls.AppBarAroundMeImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="AppBarAroundMeImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.AppBarAroundMeImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('AppBarAroundMeImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.APPBARWEATHERIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.AppBarWeatherImg.value !== ''"
                        src="{{ this.form.controls.AppBarWeatherImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.AppBarWeatherImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'AppBarWeatherImg')"
                        accept=".png, .jpg, .jpeg" #AppBarWeatherImgInput>
                    <button *ngIf="this.form.controls.AppBarWeatherImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="AppBarWeatherImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.AppBarWeatherImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('AppBarWeatherImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.APPBARUSERPROFILEIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.AppBarUserProfileImg.value !== ''"
                        src="{{ this.form.controls.AppBarUserProfileImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.AppBarUserProfileImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'AppBarUserProfileImg')"
                        accept=".png, .jpg, .jpeg" #AppBarUserProfileImgInput>
                    <button *ngIf="this.form.controls.AppBarUserProfileImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="AppBarUserProfileImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.AppBarUserProfileImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('AppBarUserProfileImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.APPBARUSERPROFILEIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.AppBarRouteImg.value !== ''"
                        src="{{ this.form.controls.AppBarRouteImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.AppBarRouteImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'AppBarRouteImg')"
                        accept=".png, .jpg, .jpeg" #AppBarRouteImgInput>
                    <button *ngIf="this.form.controls.AppBarRouteImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="AppBarRouteImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.AppBarRouteImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('AppBarRouteImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.APPBARSURVEYIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.AppBarSurveyImg.value !== ''"
                        src="{{ this.form.controls.AppBarSurveyImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.AppBarSurveyImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'AppBarSurveyImg')"
                        accept=".png, .jpg, .jpeg" #AppBarSurveyImgInput>
                    <button *ngIf="this.form.controls.AppBarSurveyImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="AppBarSurveyImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.AppBarSurveyImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('AppBarSurveyImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.APPBARCUSTOMIMG' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <div class="image-container mx-auto">
                    <img *ngIf="this.form.controls.AppBarCustomImg.value !== ''"
                        src="{{ this.form.controls.AppBarCustomImg.value }}" class="img-fluid">
                    <p *ngIf="this.form.controls.AppBarCustomImg.value === ''">{{ 'SMSS.ADD_IMAGE' |
                        translate }}</p>
                    <input style="display: none;" type="file" (change)="imageUpload($event, 'AppBarCustomImg')"
                        accept=".png, .jpg, .jpeg" #AppBarCustomImgInput>
                    <button *ngIf="this.form.controls.AppBarCustomImg.value === ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2" (click)="AppBarCustomImgInput.click()">
                        <i class="urbanicon-edit"></i>
                    </button>
                    <button *ngIf="this.form.controls.AppBarCustomImg.value !== ''" mat-mini-fab color="accent"
                        class="my-fab mat-elevation-z2 ml-1" (click)="removeImage('AppBarCustomImg')">
                        <i class="urbanicon-remove"></i>
                    </button>
                </div>
            </div>
        </mat-expansion-panel>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>

        <div class="mt-2">
            <button mat-button class="btn actionButton ml-2" type="button" id="updateButton"
                (click)="save()">
                {{ 'GENERAL.SAVE' | translate }}
            </button>
        </div>
    </form>
</div>
