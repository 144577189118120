<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span *ngIf="parkingDevice" class="title2 appearingTextLeft">
                {{ this.parkingDevice.Name + ' -'}}
            </span>
            <span class="title2">{{ 'GENERAL.DETAIL' | translate }}</span>
        </div>
    </div>

    <urban-searchbar id="intro-parking-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="" *ngIf="alertPanelInput !== undefined">
        <urban-alert-panel
            [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
            [titleText]="alertPanelInput.TitleText | translate" [buttonLabel]="'DEVICE.VIEW_EVENTS' | translate"
            [descriptionText]="alertPanelInput.DescriptionText + (firstUsefulDate ? (' ' + (firstUsefulDate | date: 'short') ): '')"
            (buttonAction)="goToDeviceEvents()" (close)="alertPanelInput = undefined">
        </urban-alert-panel>
    </div>

    <div>
        <mat-tab-group id="intro-transit-detail-tab" animationDuration="0ms" dynamicHeight="true">
            <mat-tab [label]="'GENERAL.STATUS' | translate">
                <div *ngIf="parkingEvents" class="tabContent">
                    <div *ngIf="averageOccupiedSlots.length > 0 || averageFreeSlots.length > 0">
                        <p *ngIf="startDate && endDate">{{ 'GENERAL.AVERAGE' | translate }}
                            ( {{ startDate*1000 | date: 'short' }} - {{ endDate*1000 | date: 'short' }} )
                        </p>
                        <p
                            *ngIf="startDate === undefined  || startDate === null || endDate === undefined  || endDate === null">
                            {{ 'GENERAL.AVERAGE' | translate }} ( {{ 'GENERAL.LAST_24H' | translate }} )
                        </p>
                        <div id="averageData" class="d-flex flex-row justify-content-between align-items-end">
                            <div class="d-inline-flex">
                                <i *ngFor="let n of averageOccupiedSlots"
                                    class="urbanicon-car mr-3"></i>
                                <i *ngFor="let n of averageFreeSlots" class="urbanicon-car free-slots mr-3"></i>
                            </div>
                            <div class="d-inline-flex">
                                <div class="descriptor primaryColorBG">
                                    {{ 'SMART_PARKING.FREE_SLOTS' | translate }}: {{ averageFreeSlots.length }}
                                </div>
                                <div class="descriptor" [ngStyle]="{'background-color': 'gray'}">
                                    {{ 'SMART_PARKING.OCCUPIED_SLOTS' | translate }}: {{ averageOccupiedSlots.length }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="latestFreeSlots.length > 0 || latestOccupiedSlots.length > 0">
                        <p *ngIf="startDate && endDate">{{ 'GENERAL.LAST_VALUE' | translate }} ( {{ endDate*1000 | date:
                            'short' }} )</p>
                        <p
                            *ngIf="startDate === undefined  || startDate === null || endDate === undefined  || endDate === null">
                            {{ 'GENERAL.LAST_VALUE' | translate }} ( {{ 'GENERAL.LAST_24H' | translate }} )
                        </p>
                        <div id="latestData" class="d-flex flex-row justify-content-between align-items-end">
                            <div *ngIf="occupiedSlots.length > 0 && !incoherenceError" class="d-inline-flex">
                                <div class="d-flex flex-column align-items-center mr-3"
                                    *ngFor="let n of occupiedSlots; let i = index">
                                    <span [ngClass]="{'free-slots': !n}">{{ i+1 }}</span>
                                    <i class="urbanicon-car" [ngClass]="{ 'free-slots': !n }"></i>
                                </div>
                            </div>
                            <div *ngIf="occupiedSlots.length === 0 || incoherenceError" class="d-inline-flex">
                                <i *ngFor="let n of latestOccupiedSlots"
                                    class="urbanicon-car mr-3"></i>
                                <i *ngFor="let n of latestFreeSlots" class="urbanicon-car free-slots mr-3"></i>
                            </div>

                            <div class="d-inline-flex">
                                <div class="descriptor primaryColorBG">
                                    {{ 'SMART_PARKING.FREE_SLOTS' | translate }}: {{ latestFreeSlots.length }}
                                </div>
                                <div class="descriptor" [ngStyle]="{'background-color': 'gray'}">
                                    {{ 'SMART_PARKING.OCCUPIED_SLOTS' | translate }}: {{ latestOccupiedSlots.length }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-row justify-content-center mt-3"
                        *ngIf="averageOccupiedSlots.length > 0 || averageFreeSlots.length > 0 || latestFreeSlots.length > 0 || latestOccupiedSlots.length > 0; else statusNoDataIllustration">
                        <span class="d-inline-flex align-items-center m-3">
                            <i class="urbanicon-car free-slots legend mr-3"></i>
                            <span>{{ 'SMART_PARKING.FREE' | translate }}</span>
                        </span>
                        <span class="d-inline-flex align-items-center m-3">
                            <i class="urbanicon-car legend mr-3"></i>
                            <span>{{ 'SMART_PARKING.OCCUPIED' | translate }}</span>
                        </span>
                    </div>

                    <ng-template #statusNoDataIllustration>
                        <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs">
                            <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                            <div>
                                <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                                <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                                <div class="d-flex noDataActions">
                                    <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                        {{ 'GENERAL.NEW_SEARCH' | translate }}
                                    </button>
                                    <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                        {{ 'GENERAL.SHOW_DATA' | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </mat-tab>
            <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
                <div *ngIf="chartsTab.isActive" class="tabContent">
                    <div *ngIf="readyToShow" class="roundedPanel panelBG chartContainer">
                        <h6 class="pt-3 pl-3">{{ 'SMART_PARKING.HISTORY' | translate }}</h6>
                        <urban-google-linechart [sensor]="mySPSensor" maxValueDescription="SMART_PARKING.TOTAL"
                            valueDescription="SMART_PARKING.FREE_SLOTS" [startDate]="startDate" [endDate]="endDate"
                            [darkThemeActive]="this.isDarkActive">
                        </urban-google-linechart>
                    </div>

                    <div *ngIf="readyToShow" class="roundedPanel panelBG chartContainer">
                        <urban-google-columnchart columnChartTitle="SMART_PARKING.AVERAGE_PER_HOUR"
                            [passedChartData]="columnChartData" [limitValue]="parkingTotalSlots"
                            columnsTitle="SMART_PARKING.DAILY_TIME_BANDS" valuesDescription="SMART_PARKING.AVERAGE_FREE"
                            [darkThemeActive]="this.isDarkActive">
                        </urban-google-columnchart>
                    </div>

                    <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs" *ngIf="readyToShow === false">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <div>
                            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                            <div class="d-flex noDataActions">
                                <button type="button" class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                                </button>
                                <button type="button" class="btn outlineActionButton" (click)="loadLatestData()">
                                    {{ 'GENERAL.SHOW_DATA' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
