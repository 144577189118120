import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { Device } from '../../../../shared/models/device';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { PassDataService } from '../../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-device-model-list',
  templateUrl: './device-model-list.component.html',
  styleUrls: ['./device-model-list.component.scss']
})
export class DeviceModelListComponent implements OnInit, OnDestroy {

  public deviceColumns = ['Id', 'Domain', 'Name', 'Latitude', 'Longitude', 'Type'];
  public devices: Device[] = [];
  public filteredData: Device[] = [];
  private brandId : string;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private administratorService: AdministratorApiService,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    ) { }

  ngOnInit(): void {
    let modelId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        modelId = navInfo.Id;
      }

      if (navInfo?.Type) {
        this.brandId = navInfo.Type;
      }

      if (!modelId || !this.brandId) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }
      
      this.administratorService.devicesByModel(modelId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(devices => {
        if (devices) {
          this.devices = devices;
          this.filteredData = this.devices;
        }
      });
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.devices?.filter((device: Device) =>
      [
        device.Id?.toLowerCase(), 
        device.Domain?.Name?.toLowerCase(), 
        device.Name?.toLowerCase(), 
        device.Latitude?.toString().toLowerCase(), 
        device.Longitude?.toString().toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
