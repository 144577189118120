import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Role } from '../../../shared/models/userRoles';
import { AdministratorApiService } from '../../../shared/services/administrator-api.service';
import { Resource } from '../../../shared/models/resource';
import { Subject } from 'rxjs';
import { AuthorizeRoleRequest } from '../../../shared/models/administrator/authorizeRoleRequest';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { first, takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-role-detail',
  templateUrl: './role-detail.component.html',
  styleUrls: ['./role-detail.component.scss']
})
export class RoleDetailComponent implements OnInit, OnDestroy {

  public currentRole: Role;
  public resources: Resource[] = [];
  public grantedResources: Resource[] = [];
  private authorizeRequest: AuthorizeRoleRequest;
  public displayedColumns = ['Url', 'Title', 'EditState'];
  public filteredData: Resource[] = [];
  public actualFilter: string = '';
  private urlSorted: boolean = true;
  private activeSorted: boolean = false;
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(public dialog: MatDialog,
    private administrationApiService: AdministratorApiService,
    private router: Router,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    ) { }


  ngOnInit(): void {
    let roleId: string;
    let roleName: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        roleId = navInfo.Id;
      }

      if (navInfo?.Type) {
        roleName = navInfo.Type;
      }

      if (!roleId || !roleName) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }

      this.currentRole = { Id: roleId, Name: roleName };

      this._loadResources();
    });
  }

  private _loadResources(): void {
    this.administrationApiService.resourceList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(resources => {
      if (resources) {
        this.resources = resources.sort((a, b) => a.Url.localeCompare(b.Url));
        this.filteredData = this.resources;

        this.administrationApiService.grantList(this.currentRole.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(grants => {
          if (grants) {
            this.grantedResources = grants;
          }
        });
      }
    })
  }

  private deauthorize(resourceId: string): void {
    this.authorizeRequest = {
      Resource : resourceId,
      Role : this.currentRole.Id
    }

    this.administrationApiService.deauthorizeRole(this.authorizeRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this._loadResources();
    });
  }

  private authorize(resourceId: string): void {
    this.authorizeRequest = {
      Resource : resourceId,
      Role : this.currentRole.Id
    }

    this.administrationApiService.authorizeRole(this.authorizeRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this._loadResources();
    });
  }

  public isToggled(resourceId: string): boolean {
    let result = this.grantedResources.find(x => x.Id == resourceId)
    return result !== null && result !== undefined;
  }

  public toggleResource(event: MatSlideToggleChange, resourceId: string): void {
    if (event.checked == true) {
      this.authorize(resourceId);
    } else {
      this.deauthorize(resourceId);
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.resources?.filter((resource: Resource) =>
      [
        resource.Url?.toLowerCase(),
        resource.Title?.toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public sortByUrl() {
    this.filteredData = this.filteredData.sort((a, b) =>
      a.Url.localeCompare(b.Url) * (this.urlSorted ? -1 : 1)
    );

    this.urlSorted = !this.urlSorted;
    this.activeSorted = this.urlSorted ? false : this.activeSorted;
  }

  public sortByActive() {
    this.filteredData = this.filteredData.sort((a, b) => {
      let aIsActive: boolean = this.isToggled(a.Id);
      let bIsActive: boolean = this.isToggled(b.Id);
      return aIsActive !== bIsActive && (
        (!this.activeSorted && aIsActive) ||
        (this.activeSorted && bIsActive)
      ) ? -1 : 0;
    });

    this.activeSorted = !this.activeSorted;
    this.urlSorted = this.activeSorted ? false : this.urlSorted;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
