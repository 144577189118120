<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'USER_MANAGEMENT.DELETED_USERS_ARCHIVE' | translate }}
        </span>
    </div>

    <div class="customTable">
        <div class="customTableRadius d-flex justify-content-between align-items-center flex-wrap px-4 pt-4">
            <span class="title3">{{ 'SIDEBAR.USER$USERS_LIST' | translate }}</span>

            <mat-form-field class="tableSearh" appearance="outline">
                <mat-select (valueChange)="sortBy($event)" placeholder="{{'GENERAL.SORT_BY' | translate }}" hideSingleSelectionIndicator="true">
                    <mat-option value="Deleted">{{'ADMINISTRATION.DELETED' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 user-select-none" mat-table [dataSource]="filteredData | paginate: config">

                <ng-container matColumnDef="Username">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADMINISTRATION.USERNAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span id="usernameShown">{{ element.Username }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Email">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADMINISTRATION.EMAIL' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span id="emailShown">{{ element.Email }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Firstname">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.FIRSTNAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span id="identityShown">{{element.Firstname}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Lastname">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.LASTNAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span id="identityShown">{{element.Lastname}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Deleted">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADMINISTRATION.DELETED' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span id="emailShown">{{ element.Deleted <= 0 ? '' : element.Deleted*1000 | date : 'short' }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef style="width: 5%"></th>
                    <td mat-cell *matCellDef="let element" class="text-end p-0">
                        <i matTooltip="{{'GENERAL.REMOVE' | translate }}" (click)="hardDelete(element.Id)"
                            class="iconDeleteButton urbanicon-delete">
                        </i>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>

        <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>