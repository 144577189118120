<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{advertiser?.CompanyName}}
        </span>
    </div>

    <form [formGroup]="form">
        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.COMPANY_NAME' | translate }}</mat-label>
            <input matInput formControlName="companyName" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.ADDRESS' | translate }}</mat-label>
            <input matInput formControlName="address" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.ZIP_CODE' | translate }}</mat-label>
            <input matInput formControlName="zip" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.CITY' | translate }}</mat-label>
            <input matInput formControlName="city" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.STATE' | translate }}</mat-label>
            <input matInput formControlName="state" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.COUNTRY' | translate }}</mat-label>
            <input matInput formControlName="country" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.VAT' | translate }}</mat-label>
            <input matInput formControlName="vat" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.PHONE' | translate }}</mat-label>
            <input matInput formControlName="phone" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.FAX' | translate }}</mat-label>
            <input matInput formControlName="fax" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.WEB' | translate }}</mat-label>
            <input matInput formControlName="web" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.EMAIL' | translate }}</mat-label>
            <input matInput formControlName="email" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.FIRSTNAME' | translate }}</mat-label>
            <input matInput formControlName="firstname" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.LASTNAME' | translate }}</mat-label>
            <input matInput formControlName="lastname" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'GENERAL.TIMEZONE' | translate }}</mat-label>
            <input matInput formControlName="timezone" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField bodyArea">
            <mat-label>{{ 'GENERAL.CUSTOM_CSS' | translate }}</mat-label>
            <textarea matInput
                formControlName="customCss" matTextareaAutosize matAutosizeMinRows=15 matAutosizeMaxRows=30></textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'SMSS.MAX_ACTIVE_CAMPAIGNS' | translate }}</mat-label>
            <input matInput formControlName="maxCampaigns" type="number" min="0" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'SMSS.PUBLIC_ADV_CAMPAIGNS' | translate }}</mat-label>
            <input matInput formControlName="publicAdvCampaigns" type="number" min="0" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'SMSS.ERP_CODE' | translate }}</mat-label>
            <input matInput formControlName="erpCode" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField bodyArea">
            <mat-label>{{ 'GENERAL.NOTES' | translate }}</mat-label>
            <textarea  matInput
                formControlName="notes" matTextareaAutosize matAutosizeMinRows=15 matAutosizeMaxRows=30></textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField">
            <mat-label>{{ 'SMSS.LOCKED' | translate }}</mat-label>
            <mat-select formControlName="locked" hideSingleSelectionIndicator="true">
                <mat-option value="false">
                    {{ 'GENERAL.FALSE' | translate }}
                </mat-option>
                <mat-option value="true">
                    {{ 'GENERAL.TRUE' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>

        <div>
            <button mat-button class="btn actionButton ml-2" type="button" id="updateButton"
                (click)="update()">
                {{ 'GENERAL.UPDATE' | translate }}
            </button>
            <button mat-button class="btn actionButton ml-2" type="button" id="addModelButton"
                (click)="goToCampaigns()">
                {{ 'SMSS_ADVERTISER.CAMPAIGNS' | translate }}
            </button>
        </div>
    </form>
</div>
