<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'MESSAGE.TITLE' | translate }}
        </span>
    </div>

    <form [formGroup]="form">
        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'MESSAGE.REQUEST' | translate }}</mat-label>
            <textarea   matInput formControlName="message"                        
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5"
                        cdkAutosizeMaxRows="10"
                        autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-error *ngIf="error && !this.form.controls.message.dirty" class="mb-3">
            {{ 'GENERAL.ERROR_EMPTY' | translate }}
        </mat-error>

        <button mat-button class="btn actionButton" type="button" id="updateButton" (click)="send()">
            {{ 'MESSAGE.SEND' | translate }}
        </button>
    </form>
</div>