import { DeviceEventsResponse, DeviceEventsEvent, DeviceEventRequest, DeviceEventsDevice } from './../../../shared/models/deviceEvent';
import { SensorData } from '../../../shared/models/weatherstation/sensorData';
import { ParkingEvent } from '../../../shared/models/parkingEvents';
import { ApiService } from '../../../shared/services/api.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Sensor } from '../../../shared/models/weatherstation/sensor';
import { ChartDataList } from '../../../shared/models/ChartDataList';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { Device } from 'src/app/shared/models/device';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { EventParsedBody } from 'src/app/shared/models/administrator/eventBody';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';

@Component({
  selector: 'urban-smart-parking-detail',
  templateUrl: './smart-parking-detail.component.html',
  styleUrls: ['./smart-parking-detail.component.scss']
})
export class SmartParkingDetailComponent implements OnInit, OnDestroy {

  public parkingEvents: ParkingEvent[];
  public mySPSensor: Sensor;
  public latestFreeSlots: number[] = [];
  public latestOccupiedSlots: number[] = [];
  public latestParkingEvent: ParkingEvent;
  public averageFreeSlots: number[] = [];
  public averageOccupiedSlots: number[] = [];
  public occupiedSlots: boolean[] = [];
  public columnChartData: ChartDataList;
  public parkingDevice: Device;
  public parkingTotalSlots: number;
  public last24hSearch: boolean = true;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public lastCreated: number;
  public setDates: boolean;
  private eventBodies: Record<string, EventParsedBody>;
  public loadingData: boolean;
  public readyToShow: boolean = false;
  public noDataInDate: boolean = false;
  public incoherenceError: boolean = false;
  public currentLanguage: string;
  public isDarkActive: boolean;
  public startDate: number;
  public endDate: number;
  public firstUsefulDate: Date;
  public alertPanelInput: AlertPanelInput;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private passDataService: PassDataService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private mainService: MainSubscriptionsService,
    private router: Router,
    private translate: TranslateService,
    private eventBodyService:EventBodyUtilityService
  ) { }

  ngOnInit(): void {
    let deviceId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;

        this.loadData(deviceId);
      }
      else {
        this.setErrorAndGoToMain();
        return;
      }
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translate
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText]
    };
  }

  public loadData(deviceId: string): void {
    let deviceFeature: number, deviceEventsFeature: number, latestEventsFeature: number;

    const syncContext = this.apiSync.initialize();
    this.apiSync.addFeatures(3, syncContext);

    this.apiSync.waitFeaturesAndThen(
      (checkValues: boolean[], data: any) => {
        if (checkValues[deviceFeature]) {
          this.resetAllData();

          if (checkValues[latestEventsFeature]) {
            let mappedEvents: ParkingEvent[] = this.mapToParkingEvents(data[latestEventsFeature], [this.parkingDevice], this.eventBodies);
            if(mappedEvents.length > 0) {
              this.latestParkingEvent = mappedEvents[0];
              this.parkingTotalSlots = this.latestParkingEvent.Total;
            }
          }

          if (checkValues[deviceEventsFeature]) {
            this.parkingEvents = this.mapToParkingEvents(data[deviceEventsFeature], [this.parkingDevice], this.eventBodies);
          }

          if (this.parkingEvents.length > 0) {
            this.calculateDataFromEvents(this.parkingEvents, checkValues[latestEventsFeature]);
            this.readyToShow = true;
          } else {
            this.readyToShow = false;
            this.noDataInDate = true;
          }
          this.loadingData = false;
        }
      },
      syncContext
    );

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.parkingDevice = device;
        this.eventBodies = this.eventBodyService.initEventBody([this.parkingDevice]);

        deviceFeature = this.apiSync.loadedFeature(syncContext);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(syncContext);
        this.setErrorAndGoToMain();
      }
    });

    let latestEventsRequest: DeviceEventLatestRequest = {
      DeviceId: deviceId
    };

    this.apiService.getDeviceEventLatest(latestEventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
      if(res && res.Devices?.length > 0) {
        latestEventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
      }
      else {
        latestEventsFeature = this.apiSync.failedFeature(syncContext);
      }
    });

    let parkingEventsRequest: DeviceEventRequest = {
      DeviceId: deviceId,
    }

    this.apiService.getDeviceEvents(parkingEventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
      if(res && res.Devices?.length > 0) {
        deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
      }
      else {
        deviceEventsFeature = this.apiSync.failedFeature(syncContext);
      }
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;
    let eventsRequest: DeviceEventLatestRequest = { DeviceId: this.parkingDevice.Id };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if (res && res.Devices?.length > 0) {
        this.parkingEvents = this.mapToParkingEvents(res, [this.parkingDevice], this.eventBodies);
      }

      if (this.parkingEvents.length > 0) {
        this.setLatestDates(res);

        this.calculateDataFromEvents(this.parkingEvents, this.parkingTotalSlots !== null && this.parkingTotalSlots !== undefined);
        this.noDataInDate = false;
        this.readyToShow = true;
      }
      else {
        this.readyToShow = false;
        this.noDataInDate = true;
      }
      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.endDate = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp));
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates: SearchDates): void {
    this.loadingData = true;

    this.startDate = selectedDates.startDate;
    this.endDate = selectedDates.endDate;

    let parkingEventsRequest: DeviceEventRequest = {
      DeviceId: this.parkingDevice.Id,
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(parkingEventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
      this.resetAllData();

      if (res && res.Devices?.length > 0) {
        this.parkingEvents = this.mapToParkingEvents(res, [this.parkingDevice], this.eventBodies);
      }

      if (this.parkingEvents.length > 0) {
        this.calculateDataFromEvents(this.parkingEvents, this.parkingTotalSlots !== null && this.parkingTotalSlots !== undefined);
        this.noDataInDate = false;
        this.readyToShow = true;
      }
      else {
        this.last24hSearch = selectedDates.last24hSearch;
        this.readyToShow = false;
        this.noDataInDate = true;
      }
      this.loadingData = false;
    });
  }

  private resetAllData(): void {
    this.parkingEvents = [];
    this.mySPSensor = null;
    this.latestFreeSlots = [];
    this.latestOccupiedSlots = [];
    this.averageFreeSlots = [];
    this.averageOccupiedSlots = [];
    this.occupiedSlots = [];
    this.columnChartData = {};
    this.firstUsefulDate = null;
    this.alertPanelInput = undefined;
  }

  private calculateDataFromEvents(parkingEvents: ParkingEvent[], check: boolean): void {
    let parkingSensor: Sensor = {
      Name: parkingEvents[0].Device.Name,
      Limit: parkingEvents[0].Total.toString(),
      Unit: '',
      Data: []
    }
    this.latestFreeSlots = Array(parkingEvents[0].Free).fill(0);
    this.latestOccupiedSlots = Array(parkingEvents[0].Total - parkingEvents[0].Free).fill(0);

    let sumPool: number = 0;
    let totalAveragePool: number[] = [];

    parkingEvents.forEach((singleEvent: ParkingEvent) => {
      let parkingData: SensorData = {
        Created: singleEvent.Created,
        Value: singleEvent.Free.toString()
      };
      parkingSensor.Data.push(parkingData);
      sumPool += singleEvent.Free;
      totalAveragePool.push(singleEvent.Free);
    });

    this.mySPSensor = parkingSensor;

    let relativeFractionPool: number;
    if (totalAveragePool.length > 0) {
      relativeFractionPool = sumPool / totalAveragePool.length;
    }
    else {
      relativeFractionPool = 0;
    }

    //Slots Status
    this.averageFreeSlots = Array(Math.floor(relativeFractionPool)).fill(0);
    this.averageOccupiedSlots = Array(parkingEvents[0].Total - this.averageFreeSlots.length).fill(0);

    let startEventIndex: number;
    let startEvent: ParkingEvent = parkingEvents.find((event: ParkingEvent, eventIndex: number) => {
      if (event.Free === 0 || event.Free === event.Total) {
        startEventIndex = eventIndex;
        return true;
      }
      else {
        return false;
      }
    });

    if (startEvent !== undefined) {
      let tempLatestFreeSlots: number[];

      if (startEvent.Free === 0) {
        this.occupiedSlots = Array(startEvent.Total).fill(true);
        tempLatestFreeSlots = [];
      }
      else {
        this.occupiedSlots = Array(startEvent.Total).fill(false);
        tempLatestFreeSlots = Array(startEvent.Total).fill(0);
      }

      for (let i: number = startEventIndex - 1; i >= 0; i--) {
        let position: number = parkingEvents[i].Position;
        let type: number = parkingEvents[i].Type
        if (type === 0) { //A slot gets free
          if (this.occupiedSlots[position] === true && tempLatestFreeSlots.length < startEvent.Total) {
            this.occupiedSlots[position] = false;
            tempLatestFreeSlots.push(0);
          }
          else if (this.occupiedSlots[position] === false || tempLatestFreeSlots.length >= startEvent.Total) {
            //Data incoherence
            this.incoherenceError = true;
          }
        }
        else if (type === 1) { //A slot gets occupied
          if (this.occupiedSlots[position] === true || tempLatestFreeSlots.length === 0) {
            //Data incoherence
            this.incoherenceError = true;
          }
          else if (this.occupiedSlots[position] === false) {
            this.occupiedSlots[position] = true;
            tempLatestFreeSlots.pop();
          }
        }
      }

      if(!this.incoherenceError) {
        this.latestFreeSlots = tempLatestFreeSlots;
        this.latestOccupiedSlots = Array(this.occupiedSlots.length - tempLatestFreeSlots.length).fill(0);
      }
    }
    else {
      //Not calculable
      this.incoherenceError = true;
    }

    //Hourly Averages

    this.columnChartData = {};
    let hourlyEventsCount: Array<number> = Array(24).fill(0);

    for (let i: number = 0; i < 10; i++) {
      this.columnChartData[`0${i}:00`] = 0;
    }
    for (let i: number = 10; i < 24; i++) {
      this.columnChartData[`${i}:00`] = 0;
    }

    parkingEvents.forEach((singleEvent: ParkingEvent) => {
      let eventHour: number = new Date(singleEvent.Created * 1000).getHours();
      let eventHourString: string;
      if (eventHour < 10) {
        eventHourString = `0${eventHour}:00`
      }
      else {
        eventHourString = `${eventHour}:00`
      }
      hourlyEventsCount[eventHour]++;
      this.columnChartData[eventHourString] += singleEvent.Free
    });

    for (let i: number = 0; i < 10; i++) {
      if (hourlyEventsCount[i] !== 0) {
        this.columnChartData[`0${i}:00`] /= hourlyEventsCount[i];
      }
    }
    for (let i: number = 10; i < 24; i++) {
      if (hourlyEventsCount[i] !== 0) {
        this.columnChartData[`${i}:00`] /= hourlyEventsCount[i];
      }
    }

    if (check) {
      this.checkDataCoherence();
    }
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEvents: EventLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT' | 'UNKNOWN_ERROR';

    if (res?.Devices?.length > 0) {
      alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
    }

    if (alertEvents.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
      }

      if (alertEvents.length > 0) {
        //Unknown first error
        alertType = 'error';
        eventType = 'UNKNOWN_ERROR';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEvents = res.Devices[0].Events.filter((event: EventLatest) =>
            ['Info', 'Debug'].includes(event.Level) &&
            !this.eventBodyService.isAProperEvent(event, this.parkingDevice.Model, this.eventBodies));
        }

        if (alertEvents.length > 0) {
          //Wrong body
          alertType = 'error';
          eventType = 'WRONG_BODY_EVENT';
        }
        else {
          if (res?.LatestBadEvents?.Devices?.length > 0) {
            alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }
          else if (res?.Devices?.length > 0) {
            alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }

          if (alertEvents.length > 0) {
            alertType = 'warning';
            eventType = 'WARNING';
          }
        }
      }
    }

    if (alertEvents.length > 0) {
      let errorPhrases: string[];

      if (eventType === 'UNKNOWN_ERROR') {
        let lastInfoEvent: EventLatest = res?.Devices?.[0]?.Events?.find(
          (event: EventLatest) => event.Level === 'Info' &&
          this.eventBodyService.isAProperEvent(event, this.parkingDevice.Model, this.eventBodies));
        if (lastInfoEvent !== undefined) {
          this.firstUsefulDate = new Date(lastInfoEvent.CreatedTimestamp * 1000);
        }

        eventType = 'ERROR';
        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          this.firstUsefulDate ? 'ALERT_PANEL.LAST_CORRECT_INFO_DATE' : ''
        ];
      }
      else {
        this.firstUsefulDate = new Date(alertEvents[0].CreatedTimestamp * 1000);

        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          'ALERT_PANEL.FIRST_' + eventType + '_DATE'
        ];
      }

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEvents.length.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private mapToParkingEvents(res: DeviceEventsResponse, parkingDevices: Device[], eventBodies: Record<string, EventParsedBody>): ParkingEvent[] {
    let formattedEvents: ParkingEvent[] = [];
    res.Devices.forEach((device: DeviceEventsDevice) => {
      let parkingDevice: Device = parkingDevices.find(oneDevice => oneDevice.Id === device.Id);
      if (parkingDevice !== undefined) {
        let expectedBody: EventParsedBody = eventBodies[parkingDevice.Model.Name];
        device.Events.forEach((event: DeviceEventsEvent) => {
          let eventBody = event.Body;
          if (this.eventBodyService.isAProperEvent(event, parkingDevice.Model, eventBodies)) {
            let parkingEvent: ParkingEvent = {
              Created: event.CreatedTimestamp,
              Device: parkingDevice,
              DomainId: parkingDevice.Domain.Id,
              Free: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Free']),
              Latitude: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Latitude']),
              Longitude: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Longitude']),
              Position: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Position']),
              Timezone: this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Timezone']).toString(),
              Total: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Total']),
              Type: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Type'])
            };
            formattedEvents.push(parkingEvent);
          }
        });
      }
    });
    return formattedEvents;
  }

  checkDataCoherence(): void {
    if ((this.occupiedSlots.length > 0 && this.parkingTotalSlots !== this.occupiedSlots.length)
      || ((this.averageFreeSlots.length > 0 || this.averageOccupiedSlots.length > 0) && this.parkingTotalSlots !== this.averageFreeSlots.length + this.averageOccupiedSlots.length)
      || (+this.mySPSensor.Limit > 0 && this.parkingTotalSlots !== +this.mySPSensor.Limit)) {
        this.mainService.setCustomErrorComand('Data incoherence. Try later');
    }
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    if (this.parkingEvents.length === 0) {
      this.readyToShow = false;
      this.noDataInDate = true;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceEvents(): void {
    if (this.parkingDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.parkingDevice.Id, BackRoute: 'smart-parking-detail' });
      this.router.navigate(['main/device-events']);
    }
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
