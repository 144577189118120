<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'USER.TITLE' | translate }}
        </span>

        <button mat-button class="btn actionButton"
            (click)="addUser()">{{ 'ADMINISTRATION.ADD_USER' | translate }}
        </button>
    </div>

    <div class="customTable">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <span class="title3">{{ 'SIDEBAR.USER$USERS_LIST' | translate }}</span>

            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 user-select-none" mat-table [dataSource]="filteredData | paginate: config">

                <ng-container matColumnDef="Username">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADMINISTRATION.USERNAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{ element.Username }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Email">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADMINISTRATION.EMAIL' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{ element.Email }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Firstname">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.FIRSTNAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.Firstname}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Lastname">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.LASTNAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element.Lastname}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Roles">
                    <th mat-header-cell *matHeaderCellDef> {{ 'PROFILE.ROLES' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.Authorizations[0]">
                            <div class="roleContainer" [ngStyle]="{'border-color' : colors[element.Authorizations[0].Role?.Name] ?? colors['other'], 
                                    'color' : colors[element.Authorizations[0].Role?.Name] ?? colors['other']}">
                                <span class="no-wrap-text">{{element.Authorizations[0].Role?.Name ?? ''}}</span>
                            </div>
                            <span *ngIf="element.Authorizations.length > 1" class="captionText secondaryTextColor">
                                {{ 'GENERAL.AND_ANOTHER' | translate }} {{element.Authorizations.length}}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef style="width: 8%"></th>
                    <td mat-cell *matCellDef="let element" class="text-end p-0">
                        <i matTooltip="{{'GENERAL.DETAIL' | translate }} / {{'GENERAL.EDIT' | translate }}" (click)="goToDetail(element.Id)"
                            class="iconInfoButton urbanicon-info">
                        </i>
                        <i matTooltip="{{'GENERAL.REMOVE' | translate }}" *ngIf="canDelete(element)" (click)="deleteUser(element.Id)"
                            class="iconDeleteButton urbanicon-delete">
                        </i>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
