import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ServiceDialogComponent } from 'src/app/shared/dialogs/service-dialog/service-dialog.component';
import { AddServiceRequest, GetServiceResponse, GetServiceTypeResponse, Service, ServiceType } from 'src/app/shared/models/service/service';
import { ApiService } from 'src/app/shared/services/api.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';

@Component({
  selector: 'urban-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss']
})
export class ServiceListComponent implements OnInit, OnDestroy {

  public columns = ['Name', 'Description', 'Type', 'Actions'];
  public services: Service[] = [];
  public serviceTypes: ServiceType[] = [];
  public filteredData: Service[] = [];
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public actualFilter: string = '';
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.apiService.getServiceTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: GetServiceTypeResponse) => {
      if (res?.Items) {
        this.serviceTypes = res.Items;
      }
    });

    this.loadData();
  }

  private loadData(): void {
    this.apiService.getServices().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: GetServiceResponse) => {
      if (res?.Items) {
        this.services = res.Items;
        this.filteredData = this.services;
      }
    });
  }

  public serviceAdd(): void {
    const addTypeDialogRef = this.dialog.open(ServiceDialogComponent, {
      data: {
        types: this.serviceTypes
      }
    });

    addTypeDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((newService: AddServiceRequest) => {
      if (newService) {
        const addTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.apiService.addService(newService).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
              next: () => {
                this.loadData();
              },
              error: () => {}
            });
          }
        });
      }
    });
  }

  public serviceDelete(service: Service): void {
    const addTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    addTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.apiService.deleteService(service.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.loadData();
          },
          error: () => {}
        })
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.services.filter((type: Service) =>
      [
        type.Id?.toString().toLowerCase(),
        type.Name?.toLowerCase(),
        type.Description?.toLowerCase(),
        type.ServiceType?.Name?.toLowerCase(),
      ].some((x) => x?.includes(filterValue.toLowerCase()))
    );

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage
  }

  public goToDetail(element: Service): void {
    this.mainService.setNavigationInfoComand({ Id: element.Id, BackRoute: 'service-list' });
    this.router.navigate(['main/service-detail']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
