import { EventLatest, EventsDeviceLatest } from './../../../shared/models/deviceEventLatest';
import { TrafficCategory } from './../../../shared/models/smartTraffic';
import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { LoaderService } from './../../../shared/services/loader/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartMultiDataList, ChartDataDescription } from './../../../shared/models/ChartDataList';
import { Component, HostBinding, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { Device } from '../../../shared/models/device';
import { TrafficEvent, TrafficEventAverage } from '../../../shared/models/smartTraffic';
import { ApiService } from '../../../shared/services/api.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { DeviceEventLatestRequest, DeviceEventLatestResponse } from 'src/app/shared/models/deviceEventLatest';
import { DeviceEventRequest, DeviceEventsDevice, DeviceEventsEvent, DeviceEventsResponse } from 'src/app/shared/models/deviceEvent';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { EventParsedBody } from 'src/app/shared/models/administrator/eventBody';
import { DeviceModel } from 'src/app/shared/models/deviceModel';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';
import introJs from 'intro.js';

@Component({
  selector: 'urban-traffic-dashboard',
  templateUrl: './traffic-dashboard.component.html',
  styleUrls: ['./traffic-dashboard.component.scss']
})
export class TrafficDashboardComponent implements OnInit, OnDestroy {
  @HostBinding('style.--traffic-average-panel-height') private trafficAveragePanelHeight: string;

  @ViewChild('trafficAveragePanel') private trafficAveragePanel: ElementRef;

  public trafficDevices: Device[] = [];
  private trafficEvents: TrafficEvent[];
  private trafficAverages: TrafficEventAverage[]
  public trafficDataSource: MatTableDataSource<TrafficEvent>;
  public dataDescriptions: ChartDataDescription[] = [];
  public translationsReady: boolean = false;
  public averageTrafficVehiclesPercentages: ChartMultiDataList;
  public displayedColumns: string[] = ['Name', 'TrafficLevel', 'TrafficDetail'];
  public chartReady: boolean;
  public noDataInDate: boolean = false;
  public loadingEventsData: boolean;
  public loadingLatestData: boolean;
  public mapReady: boolean = false;
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  private eventBodies: Record<string, EventParsedBody>;
  private alertEventsDevicesInvolved: string;
  public alertPanelInput: AlertPanelInput;
  public isDomainAdmin: boolean = false;
  private translateSubscription: Subscription = new Subscription();
  public isDarkActive: boolean;
  private introJS = introJs();
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private translate: TranslateService,
    private loader: LoaderService,
    private eventBodyService: EventBodyUtilityService
  ) {}

  ngOnInit(): void {
    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.trafficAveragePanelHeight = '200px';

    this.loader.disable();

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.loadData();

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDomainAdmin = (res && res.some(x => x.Name === 'Administrators' || x.Name == 'Domain admin'));
    });
  }

  private setDataDescriptions(phrases: string[], afterComplete: () => void = () => {}): void {
    this.translateSubscription.unsubscribe();
    let prefix: string = 'SMART_TRAFFIC.';

    this.setDynamicTranslations(
      phrases.map((phrase: string) => prefix + phrase.toUpperCase()),
      (res: any) => this.afterCategoriesTranslations(res, phrases, prefix),
      afterComplete
    );
  }

  private setDynamicTranslations(
    phrases: string[],
    afterTranslated: (phrasesTranslated: any) => void = () => {},
    afterFirstComplete: () => void = () => {}
  ): void {
    this.getTranslations(phrases, (res: any) => {
      afterTranslated(res);
      afterFirstComplete();
    });
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterCategoriesTranslations(res: any, phrases: any, prefix: string): void {
    this.dataDescriptions = [];
    phrases.forEach((phrase: string) => {
      let translation: string = res[prefix + phrase.toUpperCase()];
      let dataDescription: ChartDataDescription = {
        Original: phrase,
        Translated: translation !== (prefix + phrase.toUpperCase()) ? translation : phrase
      }
      this.dataDescriptions.push(dataDescription);
    });
    this.translationsReady = true;
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText] +
        ' ' + this.alertEventsDevicesInvolved
    };
  }

  public loadData(): void {
    let devicesByTypeFeatureLatest: number;
    let latestEventsFeature: number;
    const latestContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, latestContext);

    let devicesByTypeFeatureEvents: number;
    let deviceEventsFeature: number;
    const eventsContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, eventsContext);

    this.apiSync.waitFeaturesAndThen(
      (checkValues: boolean[], data: any) => {
        let latestTrafficEvents: TrafficEvent[] = [];

        if(checkValues[devicesByTypeFeatureLatest]) {
          if(checkValues[latestEventsFeature]) {
            latestTrafficEvents = this.mapToTrafficEvents(data[latestEventsFeature], this.trafficDevices, this.eventBodies);
          }

          if (latestTrafficEvents.length > 0) {
            this.trafficDataSource = new MatTableDataSource<TrafficEvent>(latestTrafficEvents);
          }
        }
        if (!checkValues[devicesByTypeFeatureLatest] || latestTrafficEvents.length === 0) {
          this.trafficDataSource = new MatTableDataSource<TrafficEvent>();
        }

        this.loadingLatestData = false;
      },
      latestContext
    );

    this.apiSync.waitFeaturesAndThen(
      (checkValues: boolean[], data: any) => {
        if (checkValues[devicesByTypeFeatureEvents]) {
          if (checkValues[deviceEventsFeature]) {
            this.trafficEvents = this.mapToTrafficEvents(data[deviceEventsFeature], this.trafficDevices, this.eventBodies);
          }

          if (this.trafficEvents.length > 0) {
            this.setAllData();
          }
        }
        if (!checkValues[devicesByTypeFeatureEvents] || this.trafficEvents.length === 0) {
          this.noDataInDate = true;
          this.chartReady = true;
        }

        this.loadingEventsData = false;
      },
      eventsContext
    );

    this.apiService.getDevicesByType('traffic-monitor').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: Device[]) => {
      if(res && res.length > 0) {
        this.trafficDevices = res;
        this.eventBodies = this.eventBodyService.initEventBody(this.trafficDevices);

        devicesByTypeFeatureLatest = this.apiSync.loadedFeature(latestContext);
        devicesByTypeFeatureEvents = this.apiSync.loadedFeature(eventsContext);
      }
      else {
        devicesByTypeFeatureLatest = this.apiSync.failedFeature(latestContext);
        devicesByTypeFeatureEvents = this.apiSync.failedFeature(eventsContext);
      }

      this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
        this.mapReady = mapLoading;
      });
    });

    let latestEventsRequest: DeviceEventLatestRequest = { DeviceType: 'traffic-monitor' };

    this.apiService.getDeviceEventLatest(latestEventsRequest)
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe( (res: DeviceEventLatestResponse) => {
      if(res && res.Devices?.length > 0) {
        latestEventsFeature = this.apiSync.loadedFeatureWithData(res, latestContext);
      }
      else {
        latestEventsFeature = this.apiSync.failedFeature(latestContext);
      }
    });

    let eventsRequest: DeviceEventRequest = {
      DeviceType: 'traffic-monitor'
    };

    this.apiService.getDeviceEvents(eventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
        this.resetAllData();

        if (res && res.Devices?.length > 0) {
          deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, eventsContext);
        }
        else {
          deviceEventsFeature = this.apiSync.failedFeature(eventsContext);
        }
      });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;

    this.trafficAveragePanelHeight = `${this.trafficAveragePanel.nativeElement.offsetHeight}px`;
    this.loadingEventsData = true;
    this.chartReady = false;
    this.noDataInDate = false;

    let eventsRequest: DeviceEventLatestRequest = { DeviceType: 'traffic-monitor' };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription))
      .subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if (res && res.Devices?.length > 0) {
        this.trafficEvents = this.mapToTrafficEvents(res, this.trafficDevices, this.eventBodies);
      }

      if (this.trafficEvents.length > 0) {
        this.setLatestDates(res);
        this.setAllData();
      }
      else {
        this.noDataInDate = true;
        this.chartReady = true;
      }

      this.loadingEventsData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.lastCreated = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp)) * 1000;
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates: SearchDates): void {
    this.trafficAveragePanelHeight = `${this.trafficAveragePanel.nativeElement.offsetHeight}px`;

    this.loadingEventsData = true;
    this.chartReady = false;
    this.noDataInDate = false;

    let eventsRequest: DeviceEventRequest = {
      DeviceType: 'traffic-monitor',
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(eventsRequest)
    .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
      this.resetAllData();

      if (res && res.Devices?.length > 0) {
        this.trafficEvents = this.mapToTrafficEvents(res, this.trafficDevices, this.eventBodies);
      }

      if (this.trafficEvents.length > 0) {
        this.setAllData();
      }
      else {
        this.last24hSearch = selectedDates.last24hSearch;
        this.noDataInDate = true;
        this.chartReady = true;
      }
      this.loadingEventsData = false;
    });
  }

  private resetAllData(): void {
    this.trafficEvents = [];
    this.trafficAverages = [];
    this.averageTrafficVehiclesPercentages = {};
    this.alertPanelInput = undefined;
    this.alertEventsDevicesInvolved = null;
  }

  private setAllData(): void {
    let trafficCategories: string[] = this.getVehiclesCategories(this.trafficEvents);
    this.setDataDescriptions(trafficCategories, () => {
      this.trafficAverages = this.calculateTrafficEventsAverages(this.trafficEvents, this.trafficDevices);
      this.averageTrafficVehiclesPercentages = this.getAverageVehiclesPercentages(this.trafficAverages);
    });
  }


  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEventsDevices: EventsDeviceLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT';

    if (res?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.Devices,
        (event: EventLatest) => event.Level === 'Error');
    }

    if (res?.LatestBadEvents?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
        (event: EventLatest) => event.Level === 'Error', alertEventsDevices);
    }

    if (alertEventsDevices.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(res.Devices,
          (event: EventLatest) => ['Info', 'Debug'].includes(event.Level) &&
          !this.eventBodyService.isAProperEvent(event, this.getModelByEventId(res.Devices, event.Id), this.eventBodies));
      }

      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
          (event: EventLatest) => ['Info', 'Debug'].includes(event.Level) &&
          !this.eventBodyService.isAProperEvent(event, this.getModelByEventId(res.Devices, event.Id), this.eventBodies),
          alertEventsDevices);
      }

      if (alertEventsDevices.length > 0) {
        //Wrong body
        alertType = 'error';
        eventType = 'WRONG_BODY_EVENT';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.Devices,
            (event: EventLatest) => event.Level === 'Warning');
        }

        if (res?.LatestBadEvents?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
            (event: EventLatest) => event.Level === 'Warning', alertEventsDevices);
        }

        if (alertEventsDevices.length > 0) {
          alertType = 'warning';
          eventType = 'WARNING';
        }
      }
    }

    if (alertEventsDevices.length > 0) {
      let alertEventsNumber: number = alertEventsDevices.reduce((sum, device) => sum + device.Events.length, 0);
      let errorPhrases: string[] = [
        'ALERT_PANEL.' + eventType + (alertEventsNumber > 1 ? 'S' : '') + '_DETECTED',
        'ALERT_PANEL.DEVICE' + (alertEventsDevices.length > 1 ? 'S' : '') + '_INVOLVED'
      ];

      this.alertEventsDevicesInvolved = alertEventsDevices
        .map(device => device.Name).join(', ');

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEventsNumber.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private getCertainEventsDevices(
    devices: EventsDeviceLatest[],
    eventCheck: (event: EventLatest) => boolean,
    initialArray: EventsDeviceLatest[] = []): EventsDeviceLatest[] {
    return devices.reduce<EventsDeviceLatest[]>((accumulator, currentDevice) => {
      let alertEvents: EventLatest[] = currentDevice.Events.filter((event: EventLatest) => eventCheck(event));
      let deviceIndex: number = accumulator.findIndex(device => device.Id === currentDevice.Id);
      if (deviceIndex !== -1) {
        accumulator[deviceIndex].Events.push(...alertEvents.filter(eventToAdd =>
          accumulator[deviceIndex].Events.find(event => event.Id === eventToAdd.Id) === undefined
        ));
        return accumulator;
      }
      else {
        return alertEvents.length > 0 ? [...accumulator, { ...currentDevice, Events: alertEvents } ] : accumulator;
      }
    }, initialArray);
  }

  private mapToTrafficEvents(res: DeviceEventsResponse, trafficDevices: Device[], eventBodies: Record<string, EventParsedBody>): TrafficEvent[] {
    let formattedEvents: TrafficEvent[] = [];
    res.Devices.forEach((device: DeviceEventsDevice) => {
      let trafficDevice: Device = trafficDevices.find(oneDevice => oneDevice.Id === device.Id);
      if (trafficDevice !== undefined) {
        let expectedBody: EventParsedBody = eventBodies[trafficDevice.Model.Name];
        device.Events.forEach((event: DeviceEventsEvent) => {
          let eventBody: any = event.Body;
          if (this.eventBodyService.isAProperEvent(event, trafficDevice.Model, eventBodies)) {
            let categories: TrafficCategory[] = [];
            let categoryNames: string[] = this.eventBodyService
            .getNestedValuesArray(eventBody, expectedBody.Mapping['CategoryName']).map(name => name.toString());
            let categoryIcons: string[] = this.eventBodyService
            .getNestedValuesArray(eventBody, expectedBody.Mapping['CategoryIcon']).map(icon => icon.toString());
            let categoryLevels: number[] = this.eventBodyService
            .getNestedValuesArray(eventBody, expectedBody.Mapping['CategoryLevel']).map(level => +level);
            categoryNames.forEach((categoryName: string, index: number) => {
              let category: TrafficCategory = {
                Name: categoryName,
                Icon: categoryIcons[index] ?? "",
                Level: categoryLevels[index] ?? 0
              };
              categories.push(category);
            });

            let trafficEvent: TrafficEvent = {
              Categories: categories,
              Created: event.CreatedTimestamp,
              Device: trafficDevice,
              DomainId: trafficDevice.Domain.Id,
              Id: '',
              Level: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Level'])
            }

            formattedEvents.push(trafficEvent);
          }
        });
      }
    });
    return formattedEvents;
  }

  private getVehiclesCategories(trafficEvents: TrafficEvent[]): string[] {
    let categories: string[] = [];

    trafficEvents.forEach((trafficEvent: TrafficEvent) => {
      trafficEvent.Categories.forEach((category: TrafficCategory) => {
        if (!categories.includes(category.Name)) {
          categories.push(category.Name);
        }
      });
    });

    return categories;
  }

  private calculateTrafficEventsAverages(trafficEvents: TrafficEvent[], trafficDevices: Device[]): TrafficEventAverage[] {
    let averages: TrafficEventAverage[] = [];
    trafficDevices.forEach((trafficDevice: Device) => {
      let deviceEvents: TrafficEvent[] = trafficEvents.filter((event: TrafficEvent) => event.Device.Id === trafficDevice.Id);
      if(deviceEvents.length > 0) {
        let average: TrafficEventAverage = {
          Device: trafficDevice,
          Created: deviceEvents[0].Created,
          Level: 0,
          Categories: []
        };
        let categoriesLevels: { [key: string]: number } = {};

        deviceEvents.forEach((event: TrafficEvent) => {
          average.Level += event.Level;
          event.Categories.forEach(category => {
            if(!Object.keys(categoriesLevels).includes(category.Name)) {
              average.Categories.push({
                Name: category.Name,
                Level: 0,
                Icon: category.Icon
              });
              categoriesLevels[category.Name] = 0;
            }
            categoriesLevels[category.Name] += category.Level;
          });
        });
        average.Level = Math.round(average.Level / deviceEvents.length * 100) / 100;

        average.Categories.forEach((category: TrafficCategory) => {
          category.Level = Math.round(categoriesLevels[category.Name] / deviceEvents.length * 100) / 100;
        });

        averages.push(average);
      }
    });
    return averages;
  }

  private getAverageVehiclesPercentages(averages: TrafficEventAverage[]): ChartMultiDataList {
    let averageVehiclesPercentages: ChartMultiDataList = {};

    averages.forEach((average: TrafficEventAverage) => {
      averageVehiclesPercentages[average.Device.Name] = {};
      average.Categories.forEach((category: TrafficCategory) => {
        averageVehiclesPercentages[average.Device.Name][category.Name] = Math.round(category.Level * average.Level) / 100;
      });
    });

    return averageVehiclesPercentages;
  }

  public startIntro(): void {
    this.translate.get([
      'INTRO.TRAFFIC_WELCOME',
      'INTRO.TRAFFIC_TAB',
      'INTRO.TRAFFIC_TABLE',
      'INTRO.SEARCH'
    ])
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(intros => {
        this.introJS
          .setOptions({
            steps: [
              {
                title: 'Welcome',
                intro: intros['INTRO.TRAFFIC_WELCOME']
              },
              {
                title: 'Tab bar',
                element: '#intro-traffic-tab',
                intro: intros['INTRO.TRAFFIC_TAB'],
                position: 'bottom'
              },
              {
                title: 'Table',
                element: '#intro-traffic-table',
                intro: intros['INTRO.TRAFFIC_TABLE'],
                position: 'bottom'
              },
              {
                title: 'Search bar',
                element: '#intro-traffic-search',
                intro: intros['INTRO.SEARCH'],
                position: 'right'
              }
            ],
            showProgress: true
          })
          .start();
      });
  }

  public getModelByEventId(eventsDevices: EventsDeviceLatest[], eventId: string): DeviceModel {
    let deviceId: string, deviceModel: DeviceModel;

    deviceId = eventsDevices.find(device => device.Events.find(deviceEvent => deviceEvent.Id === eventId))?.Id;
    if (deviceId !== undefined) {
      deviceModel = this.trafficDevices.find(device => device.Id === deviceId)?.Model;
    }

    return deviceModel;
  }

  public objectKeysNumber(object: any): number {
    return Object.keys(object).length;
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingEventsData = false;
    this.loadingLatestData = false;

    if (this.trafficEvents.length === 0) {
      this.chartReady = true;
      this.noDataInDate = true;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceDetail(device: Device): void {
    this.mainService.setNavigationInfoComand({ Id: device.Id, BackRoute: 'smart-traffic' });
    this.router.navigate(['main/smart-traffic-detail']);
  }

  public goToRoadRisk(): void {
    this.mainService.setNavigationInfoComand({ BackRoute: 'smart-traffic' });
    this.router.navigate(['main/road-risk']);
  }

  public goToDomainEvents(): void {
    if (this.isDomainAdmin) {
      this.mainService.setNavigationInfoComand({ BackRoute: 'smart-traffic' });
      this.router.navigate(['main/domain-events']);
    }
  }

  ngOnDestroy(): void {
    this.loader.enable();
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
