import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from './../../../shared/models/deviceEventLatest';
import { ParkingEventAverage } from './../../../shared/models/parkingEvents';
import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { TranslateService } from '@ngx-translate/core';
import { ParkingEvent } from '../../../shared/models/parkingEvents';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../../../shared/services/api.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Device } from '../../../shared/models/device';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { Router } from '@angular/router';
import { ChartDataList } from '../../../shared/models/ChartDataList';
import { DeviceEventRequest, DeviceEventsDevice, DeviceEventsEvent, DeviceEventsResponse } from 'src/app/shared/models/deviceEvent';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { DeviceModel } from 'src/app/shared/models/deviceModel';
import { EventParsedBody } from 'src/app/shared/models/administrator/eventBody';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';
import introJs from 'intro.js';

@Component({
  selector: 'urban-smart-parking-dashboard',
  templateUrl: './smart-parking-dashboard.component.html',
  styleUrls: ['./smart-parking-dashboard.component.scss']
})
export class SmartParkingDashboardComponent implements OnInit, OnDestroy {

  public parkingDevices: Device[] = [];
  public parkingEvents: ParkingEvent[];
  public totalSlotsMax: number = 0;
  public parkingDataSource: MatTableDataSource<ParkingEvent>;
  public displayedColumns: string[] = ['Created', 'Name', 'Free', 'Total'];
  public latestParkingEvents: ParkingEvent[];
  public averageFreeSlotsList: ChartDataList;
  public barchartReadyToShow: boolean = false;
  public mapReady: boolean = false;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  private eventBodies: Record<string, EventParsedBody>;
  public loadingData: boolean;
  public currentLanguage: string;
  private alertEventsDevicesInvolved: string;
  public alertPanelInput: AlertPanelInput;
  public isDomainAdmin: boolean = false;
  public isDarkActive: boolean;
  private introJS = introJs();
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private passDataService: PassDataService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private mainService: MainSubscriptionsService,
    private router: Router,
    private translate: TranslateService,
    private eventBodyService: EventBodyUtilityService
  ) { }

  ngOnInit(): void {
    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.loadData();

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDomainAdmin = (res && res.some(x => x.Name === 'Administrators' || x.Name == 'Domain admin'));
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText] +
        ' ' + this.alertEventsDevicesInvolved
    };
  }

  public loadData(): void {
    let getDevicesFeature: number;
    let deviceEventsFeature: number;
    let latestEventsFeature: number;

    const syncContext = this.apiSync.initialize();
    this.apiSync.addFeatures(3, syncContext);

    this.apiSync.waitFeaturesAndThen(
      (checkValues: Boolean[], data: any) => {
        this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
          this.mapReady = mapLoading;
        });

        if(checkValues[getDevicesFeature]) {
          this.resetAllData();

          if(checkValues[latestEventsFeature]) {
            this.latestParkingEvents = this.mapToParkingEvents(data[latestEventsFeature], this.parkingDevices, this.eventBodies);
            if(this.latestParkingEvents.length > 0) {
              this.parkingDataSource = new MatTableDataSource(this.latestParkingEvents);
            }
          }

          if(checkValues[deviceEventsFeature]) {
            this.parkingEvents = this.mapToParkingEvents(data[deviceEventsFeature], this.parkingDevices, this.eventBodies);
            if(this.parkingEvents.length > 0) {
              let parkingEventsAverages: ParkingEventAverage[] = this.calculateParkingEventsAverages(this.parkingEvents);
              parkingEventsAverages.forEach((singleEvent: ParkingEventAverage) => {
                this.averageFreeSlotsList[singleEvent.Device.Name] = singleEvent.Free;
                if (singleEvent.Total > this.totalSlotsMax) {
                  this.totalSlotsMax = singleEvent.Total;
                }
              });
              this.barchartReadyToShow = true;
            }
          }
        }

        if (!checkValues[getDevicesFeature] || this.latestParkingEvents.length === 0) {
          this.parkingDataSource = new MatTableDataSource();
        }
      },
      syncContext
    );

    this.apiService.getDevicesByType('parking-monitor').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: Device[]) => {
      if(res && res.length > 0) {
        this.parkingDevices = res
        this.eventBodies = this.eventBodyService.initEventBody(this.parkingDevices);

        getDevicesFeature = this.apiSync.loadedFeature(syncContext);
      }
      else {
        getDevicesFeature = this.apiSync.failedFeature(syncContext);
      }
    });

    let latestEventsRequest: DeviceEventLatestRequest = {
      DeviceType: "parking-monitor"
    };

    this.apiService.getDeviceEventLatest(latestEventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
      if(res && res.Devices?.length > 0) {
        latestEventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
      }
      else {
        latestEventsFeature = this.apiSync.failedFeature(syncContext);
      }
    });

    let eventsRequest: DeviceEventRequest = {
      DeviceType: 'parking-monitor'
    };

    if (this.loadingData !== undefined) {
      this.loadingData = true;
    }

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if(res && res.Devices?.length > 0) {
        deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
      }
      else {
        deviceEventsFeature = this.apiSync.failedFeature(syncContext);
      }

      this.loadingData = false;
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;

    if (this.loadingData !== undefined) {
      this.loadingData = true;
    }

    let eventsRequest: DeviceEventLatestRequest = { DeviceType: 'parking-monitor' };
      this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if(res && res.Devices?.length > 0) {
        this.parkingEvents = this.mapToParkingEvents(res, this.parkingDevices, this.eventBodies);
      }

      if(this.parkingEvents.length > 0) {
        this.setLatestDates(res);

        let parkingEventsAverages: ParkingEventAverage[] = this.calculateParkingEventsAverages(this.parkingEvents);
        parkingEventsAverages.forEach((singleEvent: ParkingEventAverage) => {
          this.averageFreeSlotsList[singleEvent.Device.Name] = singleEvent.Free;
          if (singleEvent.Total > this.totalSlotsMax) {
            this.totalSlotsMax = singleEvent.Total;
          }
        });

        this.barchartReadyToShow = true;
      } else {
        this.barchartReadyToShow = false;
      }

      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.lastCreated = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp)) * 1000;
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates: SearchDates): void {
    if (this.loadingData !== undefined) {
      this.loadingData = true;
    }

    let eventsRequest: DeviceEventRequest = {
      DeviceType: 'parking-monitor',
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      this.resetAllData();

      if(res && res.Devices?.length > 0) {
        this.parkingEvents = this.mapToParkingEvents(res, this.parkingDevices, this.eventBodies);
      }

      if(this.parkingEvents.length > 0) {
        let parkingEventsAverages: ParkingEventAverage[] = this.calculateParkingEventsAverages(this.parkingEvents);
        parkingEventsAverages.forEach((singleEvent: ParkingEventAverage) => {
          this.averageFreeSlotsList[singleEvent.Device.Name] = singleEvent.Free;
          if (singleEvent.Total > this.totalSlotsMax) {
            this.totalSlotsMax = singleEvent.Total;
          }
        });
        this.barchartReadyToShow = true;
      } else {
        this.last24hSearch = selectedDates.last24hSearch;
        this.barchartReadyToShow = false;
      }

      this.loadingData = false;
    });
  }

  private resetAllData(): void {
    this.parkingEvents = [];
    this.averageFreeSlotsList = {};
    this.alertPanelInput = undefined;
    this.alertEventsDevicesInvolved = null;
  }


  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEventsDevices: EventsDeviceLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT';

    if (res?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.Devices,
        (event: EventLatest) => event.Level === 'Error');
    }

    if (res?.LatestBadEvents?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
        (event: EventLatest) => event.Level === 'Error', alertEventsDevices);
    }

    if (alertEventsDevices.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(res.Devices,
          (event: EventLatest) => ['Info', 'Debug'].includes(event.Level) &&
          !this.eventBodyService.isAProperEvent(event, this.getModelByEventId(res.Devices, event.Id), this.eventBodies));
      }

      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
          (event: EventLatest) => ['Info', 'Debug'].includes(event.Level) &&
          !this.eventBodyService.isAProperEvent(event, this.getModelByEventId(res.Devices, event.Id), this.eventBodies),
          alertEventsDevices);
      }

      if (alertEventsDevices.length > 0) {
        //Wrong body
        alertType = 'error';
        eventType = 'WRONG_BODY_EVENT';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.Devices,
            (event: EventLatest) => event.Level === 'Warning');
        }

        if (res?.LatestBadEvents?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
            (event: EventLatest) => event.Level === 'Warning', alertEventsDevices);
        }

        if (alertEventsDevices.length > 0) {
          alertType = 'warning';
          eventType = 'WARNING';
        }
      }
    }

    if (alertEventsDevices.length > 0) {
      let alertEventsNumber: number = alertEventsDevices.reduce((sum, device) => sum + device.Events.length, 0);
      let errorPhrases: string[] = [
        'ALERT_PANEL.' + eventType + (alertEventsNumber > 1 ? 'S' : '') + '_DETECTED',
        'ALERT_PANEL.DEVICE' + (alertEventsDevices.length > 1 ? 'S' : '') + '_INVOLVED'
      ];

      this.alertEventsDevicesInvolved = alertEventsDevices
        .map(device => device.Name).join(', ');

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEventsNumber.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private getCertainEventsDevices(
    devices: EventsDeviceLatest[],
    eventCheck: (event: EventLatest) => boolean,
    initialArray: EventsDeviceLatest[] = []): EventsDeviceLatest[] {
    return devices.reduce<EventsDeviceLatest[]>((accumulator, currentDevice) => {
      let alertEvents: EventLatest[] = currentDevice.Events.filter((event: EventLatest) => eventCheck(event));
      let deviceIndex: number = accumulator.findIndex(device => device.Id === currentDevice.Id);
      if (deviceIndex !== -1) {
        accumulator[deviceIndex].Events.push(...alertEvents.filter(eventToAdd =>
          accumulator[deviceIndex].Events.find(event => event.Id === eventToAdd.Id) === undefined
        ));
        return accumulator;
      }
      else {
        return alertEvents.length > 0 ? [...accumulator, { ...currentDevice, Events: alertEvents } ] : accumulator;
      }
    }, initialArray);
  }

  private mapToParkingEvents(res: DeviceEventsResponse, parkingDevices: Device[], eventBodies: Record<string, EventParsedBody>): ParkingEvent[] {
    let formattedEvents: ParkingEvent[] = [];

    res.Devices.forEach((device: DeviceEventsDevice) => {
      let parkingDevice: Device = parkingDevices.find(oneDevice => oneDevice.Id === device.Id);
      if (parkingDevice !== undefined) {
        let expectedBody: EventParsedBody = eventBodies[parkingDevice.Model.Name];
        device.Events.forEach((event: DeviceEventsEvent) => {
          let eventBody: any = event.Body;
          if (this.eventBodyService.isAProperEvent(event, parkingDevice.Model, eventBodies)) {
            let parkingEvent: ParkingEvent = {
              Created: event.CreatedTimestamp,
              Device: parkingDevice,
              DomainId: parkingDevice.Domain.Id,
              Free: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Free']),
              Latitude: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Latitude']),
              Longitude: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Longitude']),
              Position: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Position']),
              Timezone: this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Timezone']).toString(),
              Total: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Total']),
              Type: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Type'])
            };
            formattedEvents.push(parkingEvent);
          }
        });
      }
    });
    return formattedEvents;
  }

  private calculateParkingEventsAverages(events: ParkingEvent[]): ParkingEventAverage[] {
    let averages: ParkingEventAverage[] = [];
    this.parkingDevices.forEach((parkingDevice: Device) => {
      let deviceEvents: ParkingEvent[] = events.filter((event: ParkingEvent) => event.Device.Id === parkingDevice.Id);
      if(deviceEvents.length > 0) {
        let latestEvent = deviceEvents[0];
        let average: ParkingEventAverage = {
          Created: latestEvent.Created,
          Device: parkingDevice,
          DomainId: parkingDevice.Domain.Id,
          Free: 0,
          Latitude: parkingDevice.Latitude,
          Longitude: parkingDevice.Longitude,
          Timezone: latestEvent.Timezone,
          Total: latestEvent.Total
        }
        deviceEvents.forEach((event: ParkingEvent) => {
          average.Free += event.Free;
        });
        average.Free /= deviceEvents.length;

        averages.push(average);
      }
    });
    return averages;
  }

  public startIntro(): void {
    this.translate.get([
      'INTRO.PARKING_WELCOME',
      'INTRO.PARKING_TAB',
      'INTRO.PARKING_TABLE',
      'INTRO.SEARCH'
    ])
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(intros => {
        this.introJS
          .setOptions({
            steps: [
              {
                title: 'Welcome',
                intro: intros['INTRO.PARKING_WELCOME']
              },
              {
                title: 'Tab bar',
                element: '#intro-parking-tab',
                intro: intros['INTRO.PARKING_TAB'],
                position: 'bottom'
              },
              {
                title: 'Table',
                element: '#intro-parking-table',
                intro: intros['INTRO.PARKING_TABLE'],
                position: 'bottom'
              },
              {
                title: 'Search bar',
                element: '#intro-parking-search',
                intro: intros['INTRO.SEARCH'],
                position: 'right'
              }
            ],
            showProgress: true
          })
          .start();
      });
  }

  public getModelByEventId(eventsDevices: EventsDeviceLatest[], eventId: string): DeviceModel {
    let deviceId: string, deviceModel: DeviceModel;

    deviceId = eventsDevices.find(device => device.Events.find(deviceEvent => deviceEvent.Id === eventId))?.Id;
    if (deviceId !== undefined) {
      deviceModel = this.parkingDevices.find(device => device.Id === deviceId)?.Model;
    }

    return deviceModel;
  }

  public goToDeviceDetails(device: Device): void {
    this.mainService.setNavigationInfoComand({ Id: device.Id, BackRoute: 'smart-parking' });
    this.router.navigate(['main/smart-parking-detail']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    if (this.parkingEvents.length === 0) {
      this.barchartReadyToShow = false;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDomainEvents(): void {
    if (this.isDomainAdmin) {
      this.mainService.setNavigationInfoComand({ BackRoute: 'smart-parking' });
      this.router.navigate(['main/domain-events']);
    }
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
