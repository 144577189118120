import {BacnetDetailComponent} from './../../pages/main/bacnet-detail/bacnet-detail.component';
import {BacnetMonitorComponent} from './../../pages/main/bacnet-monitor/bacnet-monitor.component';
import {TrafficDetailComponent} from '../../pages/main/traffic-detail/traffic-detail.component';
import {TransitDashboardComponent} from '../../pages/main/transit-dashboard/transit-dashboard.component';
import {TransitDetailComponent} from '../../pages/main/transit-detail/transit-detail.component';
import {NewsComponent} from '../../pages/main/news/news.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WipComponent} from '../../pages/main/wip/wip.component';
import {DashboardComponent} from '../../pages/main/dashboard/dashboard.component';
import {DeviceComponent} from '../../pages/main/device/device.component';
import {DevicesTableComponent} from '../../pages/main/devices-table/devices-table.component';
import {HistoryComponent} from '../../pages/main/history/history.component';
import {RemoteControlDetailComponent} from '../../pages/main/remote-control-detail/remote-control-detail.component';
import {WeatherStationDetailComponent} from '../../pages/main/weatherstation-detail/weatherstation-detail.component';
import {MainComponent} from '../../pages/main/main.component';
import {InvisibleChildComponent} from '../../shared/components/invisible-child/invisible-child.component';
import {UserDetailComponent} from '../../pages/main/user-detail/user-detail.component';
import {RoleListComponent} from '../../pages/main/administration/role-list/role-list.component';
import {DomainListComponent} from '../../pages/main/administration/domain-list/domain-list.component';
import {UserListComponent} from '../../pages/main/administration/user-list/user-list.component';
import {AdminGuard} from '../../shared/services/guards/admin.guard';
import {RoleDetailComponent} from '../../pages/main/role-detail/role-detail.component';
import {UserDomainListComponent} from '../../pages/main/user-domain-list/user-domain-list.component';
import {DomainAdminGuard} from '../../shared/services/guards/domainadmin.guard';
import {UserDomainDetailComponent} from '../../pages/main/user-domain-detail/user-domain-detail.component';
import {MessageComponent} from '../../pages/main/message/message.component';
import {PlatformStatisticsComponent} from '../../pages/main/platform-statistics/platform-statistics.component';
import {LogsStatisticsComponent} from '../../pages/main/logs-statistics/logs-statistics.component';
import {NotificationListComponent} from '../../pages/main/administration/notification-list/notification-list.component';
import {
  NotificationDetailComponent
} from '../../pages/main/administration/notification-detail/notification-detail.component';
import {DomainDetailComponent} from '../../pages/main/administration/domain-detail/domain-detail.component';
import {TranslateComponent} from '../../pages/main/administration/translate/translate.component';
import {
  ClientSettingListComponent
} from '../../pages/main/administration/client-setting-list/client-setting-list.component';
import {
  ResourceListComponent
} from '../../pages/main/administration/resource-list/resource-list.component';
import {DeviceEventsComponent} from '../../pages/main/device-events/device-events.component';
import {DomainDeviceEventsComponent} from '../../pages/main/domain-device-events/domain-device-events.component';
import {HeatmapDevicesComponent} from '../../pages/main/heatmap-devices/heatmap-devices.component';
import {DeviceBrandListComponent} from '../../pages/main/administration/device-brand-list/device-brand-list.component';
import {DeviceBrandDetailComponent} from '../../pages/main/administration/device-brand-detail/device-brand-detail.component';
import {DeviceTypeListComponent} from '../../pages/main/administration/device-type-list/device-type-list.component';
import {DeviceModelListComponent} from '../../pages/main/administration/device-model-list/device-model-list.component';
import {HeatmapPeopleCounterComponent} from '../../pages/main/heatmap-people-counter/heatmap-people-counter.component';
import {DeviceTypeDetailComponent} from '../../pages/main/administration/device-type-detail/device-type-detail.component';
import {DeviceGroupListComponent} from '../../pages/main/device-group-list/device-group-list.component';
import {DeviceGroupDetailComponent} from '../../pages/main/device-group-detail/device-group-detail.component';
import {AdvertisersComponent} from '../../pages/main/smss/advertisers/advertisers.component';
import {CampaignsComponent} from '../../pages/main/smss/campaigns/campaigns.component';
import {AdvertiserComponent} from '../../pages/main/smss/advertiser/advertiser.component';
import {CampaignComponent} from '../../pages/main/smss/campaign/campaign.component';
import {LocationListComponent} from '../../pages/main/location-list/location-list.component';
import {CalendarComponent} from '../../pages/main/calendar/calendar.component';
import {GatewayComponent} from '../../pages/main/smss/gateway/gateway.component';
import {WptemplateComponent} from '../../pages/main/smss/wptemplate/wptemplate.component';
import {
  SmartParkingDashboardComponent
} from '../../pages/main/smart-parking-dashboard/smart-parking-dashboard.component';
import {SmartParkingDetailComponent} from 'src/app/pages/main/smart-parking-detail/smart-parking-detail.component';
import {CrosswalkDashboardComponent} from '../../pages/main/crosswalk-dashboard/crosswalk-dashboard.component';
import {CrosswalkDetailComponent} from 'src/app/pages/main/crosswalk-detail/crosswalk-detail.component';
import {TrafficDashboardComponent} from '../../pages/main/traffic-dashboard/traffic-dashboard.component';
import {RoadRiskComponent} from '../../pages/main/road-risk/road-risk.component';
import {TaskScheduleComponent} from '../../pages/main/task-schedule/task-schedule.component';
import {TaskScheduleDetailComponent} from '../../pages/main/task-schedule-detail/task-schedule-detail.component';
import {TrashBinDashboardComponent} from '../../pages/main/trash-bin-dashboard/trash-bin-dashboard.component';
import {TrashBinDetailComponent} from 'src/app/pages/main/trash-bin-detail/trash-bin-detail.component';
import {DeviceNotificationsComponent} from '../../pages/main/device-notifications/device-notifications.component';
import {DeviceCommandComponent} from '../../pages/main/device-command/device-command.component';
import {DeviceCommandsComponent} from '../../pages/main/device-commands/device-commands.component';
import {DeviceCommandDetailComponent} from '../../pages/main/device-command-detail/device-command-detail.component';
import {
  DeviceCommandTypeListComponent
} from '../../pages/main/administration/device-command-type-list/device-command-type-list.component';
import {WidgetComponent} from '../../pages/main/administration/widget/widget.component';
import {ServerSettingListComponent} from "../../pages/main/administration/server-setting-list/server-setting-list.component";
import {TemplateListComponent} from "../../pages/main/administration/template-list/template-list.component";
import {TemplateFormatListComponent} from "../../pages/main/administration/template-format-list/template-format-list.component";
import {TemplateTypeListComponent} from "../../pages/main/administration/template-type-list/template-type-list.component";
import {LocationEventsComponent} from "../../pages/main/location-events/location-events.component";
import {LocationEventComponent} from "../../pages/main/location-event/location-event.component";
import {LocationDetailComponent} from "../../pages/main/location-detail/location-detail.component";
import {
  DomainVisibilityItemListComponent
} from "../../pages/main/administration/domain-visibility-item-list/domain-visibility-item-list.component";
import {ThemeListComponent} from "../../pages/main/administration/theme-list/theme-list.component";
import {ThemeDetailComponent} from "../../pages/main/administration/theme-detail/theme-detail.component";
import {
  BroadcastClientTypesComponent
} from "../../pages/main/content-management/broadcast-client-types/broadcast-client-types.component";
import {ScheduleRulesComponent} from "../../pages/main/content-management/schedule-rules/schedule-rules.component";
import {
  BroadcastClientsComponent
} from "../../pages/main/content-management/broadcast-clients/broadcast-clients.component";
import {
  BroadcastClientComponent
} from "../../pages/main/content-management/broadcast-client/broadcast-client.component";
import {ChannelsComponent} from "../../pages/main/content-management/channels/channels.component";
import {ChannelComponent} from "../../pages/main/content-management/channel/channel.component";
import {BroadcastsComponent} from "../../pages/main/content-management/broadcasts/broadcasts.component";
import {ContentsComponent} from "../../pages/main/content-management/contents/contents.component";
import {ContentComponent} from "../../pages/main/content-management/content/content.component";
import {EntitiesComponent} from "../../pages/main/content-management/entities/entities.component";
import {EntityComponent} from "../../pages/main/content-management/entity/entity.component";
import { PeoplePassageComponent } from 'src/app/pages/main/people-passage/people-passage.component';
import { NotesComponent } from 'src/app/pages/main/notes/notes.component';
import { ServiceListComponent } from 'src/app/pages/main/service-list/service-list.component';
import { ServiceDetailComponent } from 'src/app/pages/main/service-detail/service-detail.component';
import { DashboardVideoComponent } from 'src/app/pages/main/dashboard-video/dashboard-video.component';
import { ConsumerListComponent } from 'src/app/pages/main/administration/consumer-list/consumer-list.component';
import { ConsumerDetailComponent } from 'src/app/pages/main/administration/consumer-detail/consumer-detail.component';
import { AdvertisingListComponent } from 'src/app/pages/main/advertising-list/advertising-list.component';
import { AdvertisingEventListComponent } from 'src/app/pages/main/advertising-event-list/advertising-event-list.component';
import { AdvertisingTypeListComponent } from 'src/app/pages/main/administration/advertising-type-list/advertising-type-list.component';
import { MapComponent } from 'src/app/pages/main/map/map.component';
import { PassageDashboardComponent } from 'src/app/pages/main/passage-dashboard/passage-dashboard.component';
import { PassageDetailComponent } from 'src/app/pages/main/passage-detail/passage-detail.component';
import { DeviceEventsRejectedComponent } from 'src/app/pages/main/device-events-rejected/device-events-rejected.component';
import { DeviceModelDetailComponent } from 'src/app/pages/main/administration/device-model-detail/device-model-detail.component';
import { ServiceTypeListComponent } from 'src/app/pages/main/administration/service-type-list/service-type-list.component';
import { DeletedUserListComponent } from 'src/app/pages/main/administration/deleted-user-list/deleted-user-list.component';
import { UpdateMissingTranslationComponent } from 'src/app/pages/main/administration/update-missing-translation/update-missing-translation.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'wip', component: WipComponent},
      {path: 'wip-smart-pole', component: WipComponent},
      {path: 'wip-environment-monitoring', component: WipComponent},
      {path: 'wip-citizens-services', component: WipComponent},
      {path: 'wip-diagnostics', component: WipComponent},
      {path: 'wip-social-network', component: WipComponent},
      {path: 'wip-flow', component: WipComponent},
      {path: 'wip-sm-gas', component: WipComponent},
      {path: 'wip-sm-energy', component: WipComponent},
      {path: 'wip-green-areas', component: WipComponent},
      {path: 'wip-facial-recognition', component: WipComponent},
      {path: 'wip-lte-4g', component: WipComponent},
      {path: 'wip-transactions', component: WipComponent},
      {path: 'wip-traffic-data', component: WipComponent},
      {path: 'wip-air-quality', component: WipComponent},
      {path: 'wip-sm-water', component: WipComponent},
      {path: 'wip-recycling', component: WipComponent},
      {path: 'wip-delivered', component: WipComponent},
      {path: 'wip-open-data', component: WipComponent},
      {path: 'wip-gateway-lora', component: WipComponent},
      {path: 'wip-sensors', component: WipComponent},
      {path: 'wip-sm-heat', component: WipComponent},
      {path: 'wip-proximity-services', component: WipComponent},
      {path: 'wip-activity-index', component: WipComponent},
      {path: 'wip-public-transportation', component: WipComponent},
      {path: 'wip-backoffice', component: WipComponent},
      {path: 'wip-social-distancing', component: WipComponent},
      {path: 'wip-diagnostics', component: WipComponent},
      {path: 'wip-withlicense-transportation', component: WipComponent},
      {path: 'wip-withoutlicense-transportation', component: WipComponent},
      {path: 'wip-maintenance', component: WipComponent},
      {path: 'wip-3dmodels', component: WipComponent},
      {path: 'wip-vehicle-recognition', component: WipComponent},
      {path: 'wip-plates-recognition', component: WipComponent},
      {path: 'wip-object-detection', component: WipComponent},
      {path: 'wip-access-control', component: WipComponent},
      {path: 'wip-smartgrid', component: WipComponent},
      {path: 'wip-predictive-models', component: WipComponent},
      {path: 'wip-transitions', component: WipComponent},
      {path: 'wip-viability', component: WipComponent},
      {path: 'wip-climate-data', component: WipComponent},
      {path: 'wip-infrastructures-diagnostics', component: WipComponent},
      {path: 'electric-charge', component: WipComponent},
      {path: 'device', component: DeviceComponent},
      {path: 'devices-table', component: DevicesTableComponent},
      {path: 'device-history', component: HistoryComponent},
      {path: 'rc-detail', component: RemoteControlDetailComponent},
      {path: 'ws-detail', component: WeatherStationDetailComponent},
      {path: 'invisible-child', component: InvisibleChildComponent},
      {path: 'user-list', canActivate: [AdminGuard], component: UserListComponent},
      {path: 'user-detail', canActivate: [AdminGuard], component: UserDetailComponent},
      {path: 'role-list', canActivate: [AdminGuard], component: RoleListComponent},
      {path: 'domain-list', canActivate: [AdminGuard], component: DomainListComponent},
      {path: 'role-detail', canActivate: [AdminGuard], component: RoleDetailComponent},
      {path: 'user-domain-list', canActivate: [DomainAdminGuard], component: UserDomainListComponent},
      {path: 'user-domain-detail', canActivate: [DomainAdminGuard], component: UserDomainDetailComponent},
      {path: 'platform-statistics', canActivate: [DomainAdminGuard], component: PlatformStatisticsComponent},
      {path: 'logs-statistics', canActivate: [DomainAdminGuard], component: LogsStatisticsComponent},
      {path: 'message', component: MessageComponent},
      {path: 'notification-list', canActivate: [AdminGuard], component: NotificationListComponent},
      {path: 'notification-detail', canActivate: [AdminGuard], component: NotificationDetailComponent},
      {path: 'domain-detail', canActivate: [AdminGuard], component: DomainDetailComponent},
      {path: 'translate', canActivate: [AdminGuard], component: TranslateComponent},
      {path: 'update-missing-translation', canActivate: [AdminGuard], component: UpdateMissingTranslationComponent},
      {path: 'client-setting-list', canActivate: [AdminGuard], component: ClientSettingListComponent},
      {path: 'resource-list', canActivate: [AdminGuard], component: ResourceListComponent},
      {path: 'device-events', component: DeviceEventsComponent},
      {path: 'domain-events', canActivate: [DomainAdminGuard], component: DomainDeviceEventsComponent},
      {path: 'device-brand-list', canActivate: [AdminGuard], component: DeviceBrandListComponent},
      {path: 'device-brand-detail', canActivate: [AdminGuard], component: DeviceBrandDetailComponent},
      {path: 'device-model-detail', canActivate: [AdminGuard], component: DeviceModelDetailComponent},
      {path: 'device-type-list', canActivate: [AdminGuard], component: DeviceTypeListComponent},
      {path: 'device-type-detail', canActivate: [AdminGuard], component: DeviceTypeDetailComponent},
      {path: 'device-model-list', canActivate: [AdminGuard], component: DeviceModelListComponent},
      {path: 'heatmap', component: HeatmapDevicesComponent},
      {path: 'people-counter', component: HeatmapPeopleCounterComponent},
      {path: 'device-group-list', canActivate: [DomainAdminGuard], component: DeviceGroupListComponent},
      {path: 'device-group-detail', canActivate: [DomainAdminGuard], component: DeviceGroupDetailComponent},
      {path: 'news', canActivate: [DomainAdminGuard], component: NewsComponent},
      {path: 'advertisers', component: AdvertisersComponent},
      {path: 'campaigns', component: CampaignsComponent},
      {path: 'advertiser', component: AdvertiserComponent},
      {path: 'campaign', component: CampaignComponent},
      {path: 'location-list', canActivate: [DomainAdminGuard], component: LocationListComponent},
      {path: 'calendar', component: CalendarComponent},
      {path: 'gateway', component: GatewayComponent},
      {path: 'wptemplate', component: WptemplateComponent},
      {path: 'smart-parking', component: SmartParkingDashboardComponent},
      {path: 'smart-parking-detail', component: SmartParkingDetailComponent},
      {path: 'smart-crosswalk', component: CrosswalkDashboardComponent},
      {path: 'smart-crosswalk-detail', component: CrosswalkDetailComponent},
      {path: 'smart-traffic', component: TrafficDashboardComponent},
      {path: 'smart-traffic-detail', component: TrafficDetailComponent},
      {path: 'road-risk', component: RoadRiskComponent},
      {path: 'task-schedule', canActivate: [DomainAdminGuard], component: TaskScheduleComponent},
      {path: 'task-schedule-detail', canActivate: [DomainAdminGuard], component: TaskScheduleDetailComponent},
      {path: 'trash-bin', component: TrashBinDashboardComponent},
      {path: 'trash-bin-detail', component: TrashBinDetailComponent},
      {path: 'transit-dashboard', component: TransitDashboardComponent},
      {path: 'transit-detail', component: TransitDetailComponent},
      {path: 'device-notifications', canActivate: [DomainAdminGuard], component: DeviceNotificationsComponent},
      {path: 'device-command', canActivate: [DomainAdminGuard], component: DeviceCommandComponent},
      {path: 'device-commands', canActivate: [DomainAdminGuard], component: DeviceCommandsComponent},
      {path: 'device-command-detail', canActivate: [DomainAdminGuard], component: DeviceCommandDetailComponent},
      {path: 'device-command-type-list', canActivate: [AdminGuard], component: DeviceCommandTypeListComponent},
      {path: 'widget', canActivate: [AdminGuard], component: WidgetComponent},
      {path: 'server-setting-list', canActivate: [AdminGuard], component: ServerSettingListComponent},
      {path: 'template-list', canActivate: [AdminGuard], component: TemplateListComponent},
      {path: 'template-format-list', canActivate: [AdminGuard], component: TemplateFormatListComponent},
      {path: 'template-type-list', canActivate: [AdminGuard], component: TemplateTypeListComponent},
      {path: 'location-events', component: LocationEventsComponent},
      {path: 'location-event', component: LocationEventComponent},
      {path: 'location-detail', component: LocationDetailComponent},
      {path: 'domain-visibility-item-list', canActivate: [AdminGuard], component: DomainVisibilityItemListComponent},
      {path: 'theme-list', canActivate: [AdminGuard], component: ThemeListComponent},
      {path: 'theme-detail', canActivate: [AdminGuard], component: ThemeDetailComponent},
      {path: 'bacnet-monitor', component: BacnetMonitorComponent},
      {path: 'bacnet-detail', component: BacnetDetailComponent},
      {path: 'content-management/broadcast-client-types', component: BroadcastClientTypesComponent},
      {path: 'content-management/schedule-rules', component: ScheduleRulesComponent},
      {path: 'content-management/broadcast-clients', component: BroadcastClientsComponent},
      {path: 'content-management/broadcast-client', component: BroadcastClientComponent},
      {path: 'content-management/channels', component: ChannelsComponent},
      {path: 'content-management/channel', component: ChannelComponent},
      {path: 'content-management/broadcasts', component: BroadcastsComponent},
      {path: 'content-management/contents', component: ContentsComponent},
      {path: 'content-management/content', component: ContentComponent},
      {path: 'content-management/entities', component: EntitiesComponent},
      {path: 'content-management/entity', component: EntityComponent},
      {path: 'people-passage', component: PeoplePassageComponent},
      {path: 'notes', component: NotesComponent},
      {path: 'service-type-list', canActivate: [AdminGuard], component: ServiceTypeListComponent},
      {path: 'service-list', canActivate: [DomainAdminGuard], component: ServiceListComponent},
      {path: 'service-detail', canActivate: [DomainAdminGuard], component: ServiceDetailComponent},
      {path: 'dashboard-video', component: DashboardVideoComponent},
      {path: 'advertising-type-list', component: AdvertisingTypeListComponent},
      {path: 'deleted-user-list', canActivate: [AdminGuard], component: DeletedUserListComponent},
      {path: 'advertising-list', component: AdvertisingListComponent},
      {path: 'consumer-list', component: ConsumerListComponent},
      {path: 'consumer-detail', component: ConsumerDetailComponent},
      {path: 'advertising-event-list', component: AdvertisingEventListComponent},
      {path: 'map', component: MapComponent},
      {path: 'passage-dashboard', component: PassageDashboardComponent},
      {path: 'passage-detail', component: PassageDetailComponent},
      {path: 'device-events-rejected', component: DeviceEventsRejectedComponent},
      {
        path: 'net-wifi',
        //component: WifiComponent,
        loadChildren: () => import('./wifi/wifi.module').then(m => m.WifiModule)
      },
      {path: '**', redirectTo: 'dashboard'},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
