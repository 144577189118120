import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PaginationInstance } from 'ngx-pagination';
import { Phrase } from 'src/app/shared/models/administrator/phrase';
import { ApiService } from 'src/app/shared/services/api.service';
import { AdministratorApiService } from 'src/app/shared/services/administrator-api.service';

@Component({
  selector: 'urban-update-missing-translation',
  templateUrl: './update-missing-translation.component.html',
  styleUrls: ['./update-missing-translation.component.scss']
})
export class UpdateMissingTranslationComponent implements OnInit, OnDestroy {

  public passedLanguage: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public languageDiffPhrases: Phrase[] = [];
  displayedColumns = ['Source', 'Target'];
  public translatedPhrases: string[] = [];
  public filteredData: Phrase[] = [];
  public isUpdatingPhrase: boolean = false;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private apiService: ApiService,
    private administratorApiService: AdministratorApiService,
    private router: Router,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    ) { }

  ngOnInit(): void {
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        this.passedLanguage = navInfo.Id;
      } else {
          this.mainService.setNavigationInfoComand();
          this.router.navigate(['main/dashboard']);
          return;
      }

      this.checkDifferences('en');
    });
  }

  public checkDifferences(sourceLang: string): void {
    if (this.passedLanguage !== sourceLang) {
      this.apiService.checkLanguageDifferences({ source: sourceLang, target: this.passedLanguage})
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(diff => {
        if (diff?.length > 0) {
          this.languageDiffPhrases = diff;
          this.filteredData = this.languageDiffPhrases;
        } else {
          this.mainService.setNavigationInfoComand();
          this.router.navigate(['main/translate']);
        }
      });
    }
  }

  public updateSinglePhrase(event: Event, index: number): void {
    if((event.target as HTMLInputElement).value?.length > 0) {
      this.isUpdatingPhrase = true;

      let newPhrase: Phrase = {
        Language: this.passedLanguage,
        Page: this.languageDiffPhrases[index].Page,
        Key: this.languageDiffPhrases[index].Key,
        Value: this.translatedPhrases[index]
      };

      this.translatedPhrases.splice(index, 1);

      this.administratorApiService.upsertPhrase(newPhrase).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if (res) {
          this.isUpdatingPhrase = false;
          this.checkDifferences('en');
        }
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.languageDiffPhrases?.filter((phrase: Phrase) =>
      [
        phrase.Key?.toLowerCase(),
        phrase.Value?.toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
