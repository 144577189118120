import { PaginationInstance } from 'ngx-pagination';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeviceBrandDialogComponent } from '../../../../shared/dialogs/device-brand-dialog/device-brand-dialog.component';
import { DeviceBrand } from '../../../../shared/models/deviceBrand';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { ApiService } from '../../../../shared/services/api.service';

@Component({
  selector: 'urban-device-brand-list',
  templateUrl: './device-brand-list.component.html',
  styleUrls: ['./device-brand-list.component.scss']
})
export class DeviceBrandListComponent implements OnInit, OnDestroy {

  public brandColumns = ['Name', 'Detail'];
  public brands: DeviceBrand[] = [];
  public filteredData: DeviceBrand[] = [];
  public isAdmin: boolean = false;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private administratorService: AdministratorApiService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    ) { }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.apiService.getDevicesBrands().pipe(takeUntil(this.ngUnsubscribe)).subscribe(brands => {
      if (brands) {
        this.brands = brands;
        this.filteredData = this.brands;
      }
    });
  }

  public addBrand(): void {
    const addBrandDialogRef = this.dialog.open(DeviceBrandDialogComponent);

    addBrandDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newBrand => {
      if (newBrand) {
        const addBrandConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addBrandConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.administratorService.addDeviceBrand(newBrand.Name).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }

  public brandDelete(brand: DeviceBrand): void {
    if (!brand || brand === undefined) {
      return;
    }

    const deleteBrandConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteBrandConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.administratorService.deleteDeviceBrand(brand.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.loadData();
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.brands?.filter((setting: DeviceBrand) =>
      [setting.Name?.toLowerCase()].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public brandDetail(brand: DeviceBrand): void {
    if (!brand || brand === undefined) {
      return;
    }

    this.mainService.setNavigationInfoComand({ Id: brand.Id, BackRoute: 'device-brand-list' });
    this.router.navigate(['main/device-brand-detail']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
