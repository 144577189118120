<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'CROSSWALK.TITLE' | translate }}
        </span>
        <span class="btn material-icons-outlined helpButton" (click)="startIntro()">
            live_help
        </span>
    </div>

    <div class="d-flex flex-row justify-content-start mb-4">
        <button class="btn actionButton" type="button" (click)="goToRoadRisk()">
            {{ 'GENERAL.RISK_ANALYSIS' | translate }}
        </button>
    </div>

    <urban-searchbar id="intro-crosswalk-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="" *ngIf="alertPanelInput !== undefined">
        <urban-alert-panel
            [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
            [titleText]="alertPanelInput.TitleText | translate" [descriptionText]="alertPanelInput.DescriptionText"
            [buttonLabel]="isDomainAdmin ? ('DEVICE.VIEW_EVENTS' | translate) : null"
            (buttonAction)="isDomainAdmin ? goToDomainEvents() : null" (close)="alertPanelInput = undefined">
        </urban-alert-panel>
    </div>

    <mat-tab-group id="intro-crosswalk-tab" animationDuration="0ms" dynamicHeight="true" class="mb-3">
        <mat-tab [label]="'GENERAL.STATUS' | translate">
            <div class="tabContent">
                <div class="customTable">
                    <table *ngIf="crosswalkDataSource?.data.length > 0" id="intro-crosswalk-table" class="w-100 h-100" mat-table [dataSource]="crosswalkDataSource">
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef style="width: 20%">
                                {{ 'GENERAL.NAME' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"> {{item.Device.Name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Average crosswalks">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">
                                {{ 'CROSSWALK.AVERAGE' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item" style="word-break: keep-all;">
                                {{item.Average}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Total crosswalks">
                            <th mat-header-cell *matHeaderCellDef style="width: 10%;">
                                {{ (chosenDatesSearch ? 'CROSSWALK.TOTAL' : 'CROSSWALK.LAST_24H_TOTAL') | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item" style="word-break: keep-all;">
                                {{item.Total}}
                            </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="Graphic average violations">
                            <th mat-header-cell *matHeaderCellDef style="width: 20%;"></th>
                            <td mat-cell *matCellDef="let item">
                                <mat-progress-bar mode="determinate"
                                    [value]="item.Red * 100 / item.Total">
                                </mat-progress-bar>
                            </td>
                        </ng-container> -->

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                            (click)="goToDeviceDetails(row.Device.Id)">
                        </tr>
                    </table>
                </div>

                <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs" *ngIf="crosswalkDataSource?.data.length === 0">
                    <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                    <div>
                        <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                        <div class="d-flex noDataActions">
                            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                {{ 'GENERAL.NEW_SEARCH' | translate }}
                            </button>
                            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                {{ 'GENERAL.SHOW_DATA' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab [label]="'GENERAL.MAP' | translate" #mapTab>
            <div class="tabContent">
                <urban-traffic-maps-widget *ngIf="mapTab.isActive && mapReady" class="mapPanel"
                    [passedHeight]="650" [trafficList]="crosswalkDevices" [initialZoom]="10"
                    [darkModeStatus]="isDarkActive"></urban-traffic-maps-widget>
            </div>
        </mat-tab>

        <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
            <div class="tabContent" *ngIf="chartsTab.isActive">
                <div [ngClass]="loadingData !== undefined ? (loadingData ? 'disappearing-charts' : 'appearing-charts') : 'initial-charts'">
                    <div *ngIf="crosswalkEvents">
                        <div *ngIf="readyToShow" class="roundedPanel panelBG chartContainer">
                            <urban-google-columnchart [columnChartTitle]="columnChartTitle" columnsTitle="REPORT.crosswalk-monitor"
                                valuesDescription="CROSSWALK.CROSSWALKS" [passedChartData]="columnChartData"
                                [darkThemeActive]="isDarkActive">
                            </urban-google-columnchart>
                        </div>

                        <div *ngIf="readyToShow && translationsReady" class="roundedPanel panelBG chartContainer">
                            <urban-google-piechart [passedPieChartTitle]="pieChartTitle"
                                [pieDataPassed]="averageDataToPass" [passedElementsDescription]="dataDescriptions" [pieHole]="false"
                                [darkThemeActive]="this.isDarkActive">
                            </urban-google-piechart>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs" *ngIf="noDataInDate">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <div>
                            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                            <div class="d-flex noDataActions">
                                <button type="button" class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                                </button>
                                <button type="button" class="btn outlineActionButton" (click)="loadLatestData()">
                                    {{ 'GENERAL.SHOW_DATA' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
