import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { EMPTY, of } from 'rxjs';
import { map, exhaustMap, catchError, switchMap, tap, concatMap } from 'rxjs/operators';
import { LoaderService } from '../../shared/services/loader/loader.service';
import { ApiService } from '../../shared/services/api.service';
import * as MainActions from './main.actions';
import { SiralabApiService } from '../../shared/services/siralab-api.service';
import { WeatherStationApiService } from '../../shared/services/weatherstation-api.service';
import { StyleModel } from '../../shared/models/styleModel';
import { Store } from '@ngrx/store';
import { AuthState } from '../auth/auth.reducer';
import * as AuthSelectors from '../../store/auth/auth.selectors';

const wait = (ms) => {
  var start = new Date().getTime();
  var end = start;
  while (end < start + ms) {
    end = new Date().getTime();
  }
}

@Injectable()
export class MainEffects {

  constructor(
    private actions$: Actions,
    private authStore: Store<AuthState>,
    private readonly apiService: ApiService,
    private readonly router: Router,
    private readonly loaderService: LoaderService,
    private readonly siralabApiService: SiralabApiService,
    private readonly weatherstationApiService: WeatherStationApiService
  ) { }

  readonly initDomainStyle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.initDomainStyle),
      exhaustMap(() =>
        this.apiService.getDomainStyle().pipe(
          switchMap((res: StyleModel[]) => [
            MainActions.setDomainStyle({ styles: res }),
            MainActions.checkTranslatorRole()
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      )
    ),
    { dispatch: true }
  );

  readonly reInitDomainStyle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.reInitDomainStyle),
      exhaustMap(() =>
        this.apiService.getDomainStyle().pipe(
          map((res: StyleModel[]) =>
            MainActions.setDomainStyle({ styles: res })
          ),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      )
    ),
    { dispatch: true }
  );

  readonly checkTranslatorRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.checkTranslatorRole),
      concatLatestFrom(() => this.authStore.select(AuthSelectors.getUserRoles)),
      map(([, data]) => {
        if (data.every(x => x.Name === 'Translator')) {
          return MainActions.initResourcesForTranslator();
        } else {
          return MainActions.initResources();
        }
      }),
    ),
    { dispatch: true }
  );

  readonly initResourcesForTranslator$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.initResourcesForTranslator),
      exhaustMap(() =>
        this.apiService.getUserResources().pipe(
          switchMap((res: any) => [
            MainActions.setResources({ resources: res.resources.Resources }),
            MainActions.goToMain()
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          }),
        )
      )
    ),
    { dispatch: true }
  );

  readonly initResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.initResources),
      exhaustMap(() =>
        this.apiService.getUserResources().pipe(
          switchMap((res: any) => [
            MainActions.setResources({ resources: res.resources.Resources }),
            MainActions.initMapsKey(),
            MainActions.initDashboards()
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          }),
        )
      )
    ),
    { dispatch: true }
  );

  readonly initMapsKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.initMapsKey),
      exhaustMap(() =>
        this.apiService.getGoogleMapsKey().pipe(
          map((res: any) =>
            MainActions.setMapsKey({ googleMapsKey: res })
          ),
          catchError(err => {
            this.loaderService.hide();
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }));
          })
        )
      )
    )
  );

  readonly initDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.initDashboards),
      exhaustMap(() =>
        this.apiService.getDashboardList().pipe(
          switchMap(res => {
            const selectedDashboard = res.dashboards.find(dashboard => dashboard.IsSelected) || res.dashboards[0];
            return [
              MainActions.setDashboards(res),
              MainActions.setDashboardToShow(selectedDashboard),
              MainActions.initWidgets()
            ];
          }),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      )
    ),
    { dispatch: true }
  );

  readonly initWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.initWidgets),
      exhaustMap(() =>
        this.apiService.getWidgetList().pipe(
          switchMap((res: any) => [
            MainActions.setWidgets({ widgets: res.widgets.Widgets }),
            MainActions.initLocations()
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      )
    ),
    { dispatch: true }
  );

  readonly initDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.initDevices),
      exhaustMap(res =>
        this.apiService.getDevices().pipe(
          switchMap(devices => [
            MainActions.setDevices({ devices }),
            MainActions.initBrands()
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          }),
        )
      )
    ),
    { dispatch: true }
  );

  readonly initBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.initBrands),
      exhaustMap(res =>
        this.apiService.getDevicesBrands().pipe(
          switchMap(brands => [
            MainActions.setBrands({ brands }),
            MainActions.initModels()
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          }),
        )
      )
    ),
    { dispatch: true }
  );

  readonly initModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.initModels),
      exhaustMap(res =>
        this.apiService.getDevicesModels().pipe(
          switchMap(models => [
            MainActions.setModels({ models }),
            MainActions.goToMain()
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      )
    ),
    { dispatch: true }
  );

  readonly goToMain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.goToMain),
      tap({
        next: () => {
          this.router.navigate(['invisible'], { skipLocationChange: true }).then(() => {
            this.router.navigate(['main']);
          });
        }
      }),
      tap(() => this.loaderService.hide())
    ),
    { dispatch: false }
  )

  //------------------------------------------------------------------------------------------------------------------------------------------------------------
  
  readonly reInitDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.reInitDevices),
      exhaustMap(path =>
        this.apiService.getDevices().pipe(
          switchMap(devices => [
            MainActions.setDevices({ devices }),
            MainActions.reInitBrands(path)
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          }),
        )
      )
    ),
    { dispatch: true }
  );

  readonly reInitBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.reInitBrands),
      exhaustMap(path =>
        this.apiService.getDevicesBrands().pipe(
          switchMap(brands => [
            MainActions.setBrands({ brands }),
            MainActions.reInitModels(path)
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          }),
        )
      )
    ),
    { dispatch: true }
  );

  readonly reInitModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.reInitModels),
      exhaustMap(path =>
        this.apiService.getDevicesModels().pipe(
          switchMap(models => [
            MainActions.setModels({ models }),
            MainActions.goToPath(path)
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      )
    ),
    { dispatch: true }
  );

  // readonly goToPath$ = createEffect(() =>
  //     this.actions$.pipe(
  //         ofType(MainActions.goToPath),
  //         tap(route => {
  //             //sarebbe il caso di spostare questo controllo alla switchMap di reInitModels, per non far dispatchare goToPath in caso negativo
  //             if (route?.route && route?.route !== null && route?.route !== undefined) {
  //                 const { id, back, type } = route.route
  //                 this.router.navigate(['main/' + route.route.path, { id: id, back: back, type: type }])
  //             }
  //         }),
  //         tap(() => this.loaderService.hide())
  //     ),
  //     { dispatch: false }
  // )

  readonly goToPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.goToPath),
      tap(path => {
        //sarebbe il caso di spostare questo controllo alla switchMap di reInitModels, per non far dispatchare goToPath in caso negativo
        if (path?.path) {
          this.router.navigate(['main/invisible-child'], { skipLocationChange: true }).then(() => {
            this.router.navigate(['main/' + path.path]);
          });
        }
      }),
      tap(() => this.loaderService.hide())
    ),
    { dispatch: false }
  )

  //------------------------------------------------------------------------------------------------------------------------------------------------------------

  readonly createDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.createDashboard),
      exhaustMap(res =>
        this.apiService.createNewDashboard(res.dashboard).pipe(
          map(() => MainActions.initDashboards()),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly updateAndReInitDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.updateAndReInitDashboard),
      exhaustMap(res =>
        this.apiService.updateDashboard(res.dashboard).pipe(
          map(() => MainActions.initDashboards()),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly updateDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.updateDashboard),
      exhaustMap(res =>
        this.apiService.updateDashboard(res.dashboard).pipe(
          exhaustMap(() => EMPTY),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly reInitOnlyDashboards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.reInitOnlyDashboards),
      exhaustMap(() =>
        this.apiService.getDashboardList().pipe(
          switchMap(res => {
            const selectedDashboard = res.dashboards.find(dashboard => dashboard.IsSelected) || res.dashboards[0];
            return [
              MainActions.setDashboards(res),
              MainActions.setDashboardToShow(selectedDashboard),
            ];
          }),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          })
        )
      )
    ),
    { dispatch: true }
  )

  readonly changeSelectedDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.changeSelectedDashboard),
      exhaustMap((action) => {
        const updateOldDashboard$ = action.dashboardsToEdit.Old
          ? this.apiService.updateDashboard(action.dashboardsToEdit.Old)
          : of(null);
  
        return this.apiService.updateDashboard(action.dashboardsToEdit.New).pipe(
          concatMap(() => updateOldDashboard$),
          concatMap(() => this.apiService.getDashboardList()),
          switchMap(dashboardList => [
            MainActions.setDashboards(dashboardList),
            MainActions.setDashboardToShow(dashboardList.dashboards.find(dashboard =>
              dashboard.Id === action.dashboardsToEdit.New.Id
            ))
          ]),
          catchError(err => {
            return of(MainActions.setError({ error: err?.error?.Message ?? 'customError' }));
          })
        );
      })
    ),
    { dispatch: true }
  );

  readonly deleteDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.deleteDashboard),
      exhaustMap(action =>
        this.apiService.deleteDashboard(action.dashboardId).pipe(
          map(() => MainActions.reInitOnlyDashboards()),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly addWidgetsToDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.addWidgetsToDashboard),
      exhaustMap(res =>
        this.apiService.addWidgets(res.widgets).pipe(
          map(() => MainActions.reInitOnlyDashboards())
        )
      )
    ),
    { dispatch: true }
  );

  readonly removeWidgetToDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.removeWidgetToDashboard),
      exhaustMap(res =>
        this.apiService.removeWidget(res.widget).pipe(
          map(() => MainActions.reInitOnlyDashboards())
        )
      )
    ),
    { dispatch: true }
  );

  readonly updateWidgetPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.updateWidgetPosition),
      exhaustMap(res =>
        this.apiService.updateWidgetPosition(res).pipe(
          map(() => MainActions.reInitOnlyDashboards()),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly addDeviceToMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.addDeviceToMap),
      exhaustMap(res =>
        this.apiService.addDevice(res.device).pipe(
          map(() => MainActions.reInitDevices({})),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly deleteDeviceFromMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.deleteDeviceFromMap),
      exhaustMap(res =>
        this.apiService.deleteDevice(res.device).pipe(
          map(() => MainActions.initDevices()),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly updateDeviceMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.updateDeviceMap),
      exhaustMap(res =>
        this.apiService.updateDevice(res.device).pipe(
          map(() => MainActions.initDevices()),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );


  readonly changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.changePassword),
      exhaustMap(res =>
        this.apiService.changePassword(res.changePasswordRequest).pipe(
          exhaustMap(() => EMPTY),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly initClientSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.initClientSettings),
      exhaustMap(res =>
        this.apiService.getClientSettings().pipe(
          switchMap(clientSettings => [
            MainActions.setClientSettings({ clientSettings })
          ]),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))),
        )
      )
    ),
    { dispatch: true }
  );

  readonly initDeviceReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.getDeviceReport),
      exhaustMap(res =>
        //convertiamo la string in number
        this.siralabApiService.getDeviceReport(+res.deviceId, res.delayMilliseconds).pipe(
          map(result => MainActions.setRCServices({ remoteControlServices: result.Services })),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );


  readonly setEntityState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.setEntityState),
      exhaustMap(res =>
        this.siralabApiService.setEntityState(res.request).pipe(
          map(() =>
            MainActions.getDeviceReport({ deviceId: res.request.id.toString(), delayMilliseconds : 3000 })
          ),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly getWSSensors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.getWSSensors),
      exhaustMap(res =>
        this.weatherstationApiService.getWeatherStationsData(res.request).pipe(
          map(result => MainActions.setWSSensors({ weatherStationSensors: result.Sensors })),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly addLocationToMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.addLocationToMap),
      exhaustMap(res =>
        this.apiService.locationAdd(res.location).pipe(
          map(() => MainActions.reInitOnlyLocations()),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly updateLocationOnMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.updateLocationOnMap),
      exhaustMap(res =>
        this.apiService.locationUpdate(res.location).pipe(
          map(() => MainActions.reInitOnlyLocations()),
          catchError(err => of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' })))
        )
      )
    ),
    { dispatch: true }
  );

  readonly initLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.initLocations),
      exhaustMap(() =>
        this.apiService.locations().pipe(
          switchMap(locations => [
            MainActions.setLocations({ locations }),
            MainActions.initDevices()
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          }),
        )
      )
    ),
    { dispatch: true }
  );

  readonly reInitOnlyLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.reInitOnlyLocations),
      exhaustMap(() =>
        this.apiService.locations().pipe(
          switchMap(locations => [
            MainActions.setLocations({ locations }),
          ]),
          catchError(err => {
            this.loaderService.hide()
            return of(MainActions.setError({ error: err?.error?.Message ? err.error.Message : 'customError' }))
          }),
        )
      )
    ),
    { dispatch: true }
  );

  readonly logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MainActions.logout),
      exhaustMap(res => {
        this.router.navigate(['/']);
        return EMPTY
      })
    ),
    { dispatch: false }
  );
}
