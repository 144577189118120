import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { DeviceEventsDevice, DeviceEventsResponse, DeviceEventsEvent } from './../../../shared/models/deviceEvent';
import { ApiService } from './../../../shared/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { Device } from '../../../shared/models/device';
import { Sensor, SensorContent } from '../../../shared/models/weatherstation/sensor';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { DeviceEventRequest } from '../../../shared/models/deviceEvent';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { SingleEvent, WeatherElementInfo, WeatherStationEvent } from '../../../shared/models/weatherstation/weatherStationEvent';
import { DomainProperty } from 'src/app/shared/models/domainProperty';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { EventBodyField, EventParsedBody } from 'src/app/shared/models/administrator/eventBody';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';
import introJs from 'intro.js';

@Component({
  selector: 'urban-weatherstation-detail',
  templateUrl: './weatherstation-detail.component.html',
  styleUrls: ['./weatherstation-detail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class WeatherStationDetailComponent implements OnInit, OnDestroy {
  public expandedElement: Sensor | null
  public displayedColumns: string[] = ['Name', 'Unit', 'Limit'];
  public currentDevice: Device;
  public myWSSensors: Sensor[] = [];
  public sensorContent: SensorContent[] = [];
  public readyToShow: boolean = false;
  public noData: boolean = false;
  private weatherStationEvents: WeatherStationEvent[] = [];
  private weatherStationInfo: WeatherElementInfo[] = [];
  public chartLayoutGrid: boolean = true;
  public chartRedrawTrigger: number = 0;
  public isLittleScreen: boolean;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public startDate: number;
  public endDate: number;
  public currentLanguage: string;
  public isDarkActive: boolean;
  public loadingData: boolean;
  private introJS = introJs();
  public firstUsefulDate: Date;
  private eventBodies: Record<string, EventParsedBody>;
  public alertPanelInput: AlertPanelInput;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  public formDate: UntypedFormGroup = this.FormBuilder.group({
    startDate: [{ value: null, disabled: false }],
    endDate: [{ value: null, disabled: false }]
  });

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 800) {
      this.isLittleScreen = false;
    } else {
      this.isLittleScreen = true;
    }
  }

  constructor(
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private router: Router,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private FormBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private eventBodyService: EventBodyUtilityService
  ) { }

  ngOnInit(): void {
    let deviceId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;
      } else {
        this.setErrorAndGoToMain();
        return;
      }

      this.passDataService.currentDomainProperties$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if (res?.length > 0) {
          let wsInfo: DomainProperty;
          if (wsInfo = res.find((property: DomainProperty) => property.Key === 'WeatherStationLimits')) {
            if (wsInfo && wsInfo?.Value) {
              let parsedWsInfo = JSON.parse(wsInfo.Value);
              if (parsedWsInfo?.Limits?.length > 0) {
                parsedWsInfo?.Limits.forEach((elementInfo) => {
                  if (['Key', 'Unit', 'Value', 'Type'].every((key: string) => Object.keys(elementInfo).includes(key))) {
                    let formattedKey: string = elementInfo.Key.toUpperCase().replace('.', '');
                    let singleInfo: WeatherElementInfo = {
                      ...elementInfo,
                      Key: formattedKey
                    }
                    this.weatherStationInfo.push(singleInfo);
                  }
                });
              }
            }
          }
        }

        this.loadData(deviceId);
      });
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translate
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        }
        else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText]
    };
  }

  public loadData(deviceId: string): void {
    let deviceFeature: number, deviceEventsFeature: number;
    const syncContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, syncContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        this.resetAllData();

        if(checkValues[deviceEventsFeature]) {
          let device: DeviceEventsDevice = data[deviceEventsFeature]?.Devices[0];
          if (device) {
            this.weatherStationEvents = this.mapToWeatherStationEvents(device, this.weatherStationInfo, this.eventBodies);
          }
        }

        if (this.weatherStationEvents.length > 0) {
          this.setWsSensor();
          this.readyToShow = true;
        }
        else {
          this.noData = true;
        }
        this.loadingData = false;
      }
    }, syncContext);

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;
        this.eventBodies = this.eventBodyService.initEventBody([this.currentDevice]);

        deviceFeature = this.apiSync.loadedFeature(syncContext);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(syncContext);
        this.setErrorAndGoToMain();
      }
    });

    let deviceEventsRequest: DeviceEventRequest = { DeviceId: deviceId }

    this.apiService.getDeviceEvents(deviceEventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
        if (res && res.Devices?.length > 0) {
          deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
        }
        else {
          deviceEventsFeature = this.apiSync.failedFeature(syncContext);
        }
      });
  }

  public newSearch(selectedDates: SearchDates): void {
    this.loadingData = true;
    this.readyToShow = false;
    this.noData = false;

    this.startDate = selectedDates.startDate;
    this.endDate = selectedDates.endDate;

    let deviceEventsRequest: DeviceEventRequest = {
      DeviceId: this.currentDevice.Id,
      Start: this.startDate,
      End: this.endDate
    }

    this.apiService.getDeviceEvents(deviceEventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
        this.resetAllData();

        if (res && res.Devices?.length > 0) {
          let device: DeviceEventsDevice = res.Devices[0];
          this.weatherStationEvents = this.mapToWeatherStationEvents(device, this.weatherStationInfo, this.eventBodies);
        }

        if (this.weatherStationEvents.length > 0) {
          this.setWsSensor();

          this.readyToShow = true;
        }
        else {
          if (selectedDates !== null && selectedDates !== undefined) {
            this.last24hSearch = selectedDates.last24hSearch;
          }

          this.noData = true;
        }
        this.loadingData = false;
      });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;
    this.readyToShow = false;
    this.noData = false;

    let eventsRequest: DeviceEventLatestRequest = { DeviceId: this.currentDevice.Id };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest)
    .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription))
    .subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if (res && res.Devices?.length > 0) {
        let device: DeviceEventsDevice = res.Devices[0];
        this.weatherStationEvents = this.mapToWeatherStationEvents(device, this.weatherStationInfo, this.eventBodies);
      }

      if (this.weatherStationEvents.length > 0) {
        this.setLatestDates(res);

        this.setWsSensor();
        this.readyToShow = true;
      }
      else {
        this.noData = true;
      }
      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.endDate = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp));
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  private resetAllData(): void {
    this.weatherStationEvents = [];
    this.myWSSensors = [];
    this.firstUsefulDate = null;
    this.alertPanelInput = undefined;
  }

  private setWsSensor(): void {
    this.weatherStationEvents.forEach(event => {
      event.Events.forEach(eventData => {
        let sensor: Sensor = this.myWSSensors.find(x => x.Name == event.Name);

        if (sensor && sensor != undefined) {
          sensor.Data.push({ Value: eventData.Value.toString(), Created: eventData.Created });
          let index = this.myWSSensors.indexOf(sensor);
          this.myWSSensors[index] = sensor;
        }
        else {
          sensor = {
            Name: '',
            Unit: '',
            Limit: '',
            Data: []
          };

          sensor.Name = event.Name;
          sensor.Limit = event.Limit;
          sensor.Unit = event.Unit;
          sensor.Data.push({ Value: eventData.Value.toString(), Created: eventData.Created });

          this.myWSSensors.push(sensor);
        }
      });
    });
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEvents: EventLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT' | 'UNKNOWN_ERROR';

    if (res?.Devices?.length > 0) {
      alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
    }

    if (alertEvents.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
      }

      if (alertEvents.length > 0) {
        //Unknown first error
        alertType = 'error';
        eventType = 'UNKNOWN_ERROR';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEvents = res.Devices[0].Events.filter(
            (event: EventLatest) => ['Info', 'Debug'].includes(event.Level) &&
              !this.eventBodyService.isAProperEvent(event, this.currentDevice.Model, this.eventBodies)
          );
        }

        if (alertEvents.length > 0) {
          //Wrong body
          alertType = 'error';
          eventType = 'WRONG_BODY_EVENT';
        }
        else {
          if (res?.LatestBadEvents?.Devices?.length > 0) {
            alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }
          else if (res?.Devices?.length > 0) {
            alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }

          if (alertEvents.length > 0) {
            alertType = 'warning';
            eventType = 'WARNING';
          }
        }
      }
    }

    if (alertEvents.length > 0) {
      let errorPhrases: string[];

      if (eventType === 'UNKNOWN_ERROR') {
        let lastInfoEvent: EventLatest = res?.Devices?.[0]?.Events?.find(
          (event: EventLatest) => event.Level === 'Info' &&
            this.eventBodyService.isAProperEvent(event, this.currentDevice.Model, this.eventBodies)
        );
        if (lastInfoEvent !== undefined) {
          this.firstUsefulDate = new Date(lastInfoEvent.CreatedTimestamp * 1000);
        }

        eventType = 'ERROR';
        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          this.firstUsefulDate ? 'ALERT_PANEL.LAST_CORRECT_INFO_DATE' : ''
        ];
      }
      else {
        this.firstUsefulDate = new Date(alertEvents[0].CreatedTimestamp * 1000);

        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          'ALERT_PANEL.FIRST_' + eventType + '_DATE'
        ];
      }

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEvents.length.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private mapToWeatherStationEvents(device: DeviceEventsDevice, weatherStationInfo: WeatherElementInfo[], eventBodies: Record<string, EventParsedBody>): WeatherStationEvent[] {
    let wsEvents: WeatherStationEvent[] = [];
    let expectedBody: EventParsedBody = eventBodies[device.Model.Name];

    weatherStationInfo.forEach((wsElementInfo: WeatherElementInfo) => {
      let wsElementEvents: SingleEvent[] = [];

      device.Events.forEach((event: DeviceEventsEvent) => {
        if (this.eventBodyService.isAProperEvent(event, device.Model, eventBodies)) {
          let eventBody: any = event.Body;
          let wsElementField: EventBodyField = expectedBody.MandatorySingleFields.find((field: EventBodyField) =>
            field.Name.toUpperCase().replace('.', '') === wsElementInfo.Key);

          if (wsElementField !== undefined) {
            let value: number | string = this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping[wsElementField.MapTo]);
            if (typeof value === 'number') {
              let wsSingleEvent: SingleEvent = {
                Created: event.CreatedTimestamp,
                Value: +value
              };
              wsElementEvents.push(wsSingleEvent);
            }
          }
        }
      });

      let wsEvent: WeatherStationEvent = {
        Name: wsElementInfo.Key,
        Unit: wsElementInfo ? wsElementInfo.Unit : '',
        Limit: wsElementInfo ? wsElementInfo.Value : '',
        Events: wsElementEvents
      };

      wsEvents.push(wsEvent);
    });

    return wsEvents;
  }

  changeBoxLayoutAndRedraw(): void {
    this.readyToShow = false
    this.chartLayoutGrid = !this.chartLayoutGrid;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      this.readyToShow = true;
    }, 500);
  }

  public startIntro(): void {
    this.translate.get([
      'INTRO.WSSENSORS_WELCOME',
      'INTRO.SEARCH_DATE',
      'INTRO.WSSENSORS_LAYOUT'
    ])
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(intros => {
        this.introJS
          .setOptions({
            steps: [
              {
                title: 'Welcome',
                intro: intros['INTRO.WSSENSORS_WELCOME']
              },
              {
                title: 'Search bar',
                element: '#intro-ws-sensors-search',
                intro: intros['INTRO.SEARCH_DATE'],
                position: 'right'
              }
            ],
            showProgress: true
          });
        if (!this.isLittleScreen) {
          this.introJS.addStep({
            title: 'Layout',
            element: '#intro-ws-sensors-layout',
            intro: intros['INTRO.WSSENSORS_LAYOUT'],
            position: 'right'
          })
        }
        this.introJS.start();
      });
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    if (this.weatherStationEvents.length === 0) {
      this.noData = true;
      this.readyToShow = false;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceEvents(): void {
    if (this.currentDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.currentDevice.Id, BackRoute: 'ws-detail' });
      this.router.navigate(['main/device-events']);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
