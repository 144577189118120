import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdvertisingTypeDialogComponent } from 'src/app/shared/dialogs/advertising-type-dialog/advertising-type-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AdvertisingType } from 'src/app/shared/models/advertising/advertising';
import { AddAdvertisingTypeRequest, UpdateAdvertisingTypeRequest } from 'src/app/shared/models/advertising/advertisingRequest';
import { GetAdvertisingTypesResponse } from 'src/app/shared/models/advertising/advertisingResponse';
import { UserRoles } from 'src/app/shared/models/userRoles';
import { ApiService } from 'src/app/shared/services/api.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-advertising-type-list',
  templateUrl: './advertising-type-list.component.html',
  styleUrls: ['./advertising-type-list.component.scss']
})
export class AdvertisingTypeListComponent implements OnInit,OnDestroy {
  typeColumns = ['Name', 'Detail'];
  public types: AdvertisingType[] = [];
  public filteredData: AdvertisingType[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public userRoles: UserRoles['Roles'];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private router: Router,
    private apiService: ApiService,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }
      this.apiService.getAdvertisingTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if (res && res.Items?.length > 0) {
          this.types = res.Items;
          this.filteredData = this.types;
        }
      });
    });
  }

  public addType(): void {
    const addTypeDialogRef = this.dialog.open(AdvertisingTypeDialogComponent);

    addTypeDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newType => {
      if (newType) {
        const addTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            let addAdvertisingTypeRequest: AddAdvertisingTypeRequest = {
              Name: newType.Name,
              AttachmentType: newType.AttachmentType
            };

            this.apiService.addAdvertisingType(addAdvertisingTypeRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.apiService.getAdvertisingTypes().pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res: GetAdvertisingTypesResponse) => {
                  if (res?.Items?.length > 0) {
                    this.types = res.Items;
                    this.filteredData = this.types;
                  }
                });
            });
          }
        });
      }
    });
  }

  public typeUpdate(type: AdvertisingType): void {
    if (!type || type === undefined) {
      return;
    }

    const updateTypeDialogRef = this.dialog.open(AdvertisingTypeDialogComponent, {
      data: { ...type }
    });

    updateTypeDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newType => {
      if (newType) {
        const updateTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        updateTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            let updateAdvertisingTypeRequest: UpdateAdvertisingTypeRequest = { ...newType };

            this.apiService.updateAdvertisingType(updateAdvertisingTypeRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.apiService.getAdvertisingTypes().pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res: GetAdvertisingTypesResponse) => {
                  if (res?.Items?.length > 0) {
                    this.types = res.Items;
                    this.filteredData = this.types;
                  }
                });
            });
          }
        });
      }
    });
  }

  public typeDelete(type: AdvertisingType): void {
    if (!type || type === undefined) {
      return;
    }

    const deleteTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.deleteAdvertisingType(type.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.apiService.getAdvertisingTypes().pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res: GetAdvertisingTypesResponse) => {
              if (res?.Items?.length > 0) {
                this.types = res.Items;
                this.filteredData = this.types;
              }
            });
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.types?.filter((type: AdvertisingType) =>
      type.Name?.toLowerCase().includes(filterValue.toLowerCase())
    );

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
