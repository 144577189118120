import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { Device } from 'src/app/shared/models/device';
import { DeviceEventRequest, DeviceEventsEvent, DeviceEventsResponse } from 'src/app/shared/models/deviceEvent';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { Passage, PassageData } from 'src/app/shared/models/passage';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { ChartDataList } from 'src/app/shared/models/ChartDataList';
import { EventParsedBody } from 'src/app/shared/models/administrator/eventBody';
import { DeviceModel } from 'src/app/shared/models/deviceModel';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';

@Component({
  selector: 'urban-passage-dashboard',
  templateUrl: './passage-dashboard.component.html',
  styleUrls: ['./passage-dashboard.component.scss']
})
export class PassageDashboardComponent implements OnInit {
  public passageDevices: Device[] = [];
  public passages: Passage[];
  public startDate: number;
  public endDate: number;
  public passageDataSource: MatTableDataSource<PassageData>;
  public hourlyAveragePassagesChartData: ChartDataList;
  public displayedColumns: string[] = ['Device name', 'Last hour total', 'Last 24h total'];

  public loadingData: boolean;
  public mapReady: boolean = false;
  public chartReady: boolean;

  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  private eventBodies: Record<string, EventParsedBody>;
  private alertEventsDevicesInvolved: string;
  public alertPanelInput: AlertPanelInput;
  public isDomainAdmin: boolean = false;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private router: Router,
    private loader: LoaderService,
    private translate: TranslateService,
    private eventBodyService: EventBodyUtilityService
  ) { }

  ngOnInit(): void {
    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.loader.disable();

    this.loadData();

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDomainAdmin = (res && res.some(x => x.Name === 'Administrators' || x.Name == 'Domain admin'));
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText] +
        ' ' + this.alertEventsDevicesInvolved
    };
  }

  public loadData(): void {
    let deviceEventsFeature: number;

    this.apiSync.initialize();
    this.apiSync.addFeatures(2);

    this.apiSync.waitFeaturesAndThen((checkValues: Boolean[], data: any) => {
      this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
        this.mapReady = mapLoading;
      });
      if (checkValues.every(value => value)) {
        this.passages = this.mapToPassages(data[deviceEventsFeature], this.passageDevices, this.eventBodies);
      }

      if (this.passages.length > 0) {
        this.setAllData();
      }
      else {
        this.passageDataSource = new MatTableDataSource<PassageData>();
        this.chartReady = true;
      }
      this.loadingData = false;
    });

    this.apiService.getDevicesByType('camera-geovision').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: Device[]) => {
      if (res && res.length > 0) {
        this.passageDevices = res
        this.eventBodies = this.eventBodyService.initEventBody(this.passageDevices);

        this.apiSync.loadedFeature();
      }
      else {
        this.apiSync.failedFeature();
      }
    });

    let eventsRequest: DeviceEventRequest = { DeviceType: 'camera-geovision' };

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
      this.resetAllData();

      if (res && res.Devices?.length > 0) {
        deviceEventsFeature = this.apiSync.loadedFeatureWithData(res);
      }
      else {
        this.apiSync.failedFeature();
      }
    });
  }

  public newSearch(selectedDates: SearchDates): void {
    this.chartReady = false;
    this.loadingData = true;

    this.startDate = selectedDates.startDate;
    this.endDate = selectedDates.endDate;

    let eventsRequest: DeviceEventRequest = {
      DeviceType: 'camera-geovision',
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
      this.resetAllData();

      if (res && res.Devices?.length > 0) {
        this.passages = this.mapToPassages(res, this.passageDevices, this.eventBodies);
      }

      if (this.passages.length > 0) {
        this.setChartsData();
      }
      else {
        this.last24hSearch = selectedDates.last24hSearch;
        this.chartReady = true;
      }
      this.loadingData = false;
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.chartReady = false;
    this.loadingData = true;

    let eventsRequest: DeviceEventLatestRequest = { DeviceType: 'camera-geovision' };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription))
      .subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if(res && res.Devices?.length > 0) {
        this.passages = this.mapToPassages(res, this.passageDevices, this.eventBodies);
      }

      if(this.passages.length > 0) {
        this.setLatestDates(res);
        this.setChartsData();
      }
      else {
        this.chartReady = true;
      }
      this.loadingData = false;
    });
  }

  private setLatestDates(res: DeviceEventLatestResponse): void {
    this.lastCreated = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp)) * 1000;
    this.setDates = !this.setDates;
  }

  private resetAllData(): void {
    this.passages = [];
    this.alertPanelInput = undefined;
    this.alertEventsDevicesInvolved = null;
    this.hourlyAveragePassagesChartData = {};
  }

  private setAllData(): void {
    this.setStatusTableData();
    this.setChartsData();
  }

  private setStatusTableData(): void {
    let passageData: PassageData[] = [];
    const oneHourMilliseconds = 60 * 60 * 1000;

    this.passageDevices.forEach(device => {
      let devicePassages: Passage[] = this.passages.filter(passage => passage.Device.Id === device.Id);
      let lastHourTotal: number = this.calculateLastPassagesCount(devicePassages, oneHourMilliseconds);
      let last24hTotal: number = this.calculateLastPassagesCount(devicePassages, 24 * oneHourMilliseconds);
      let newData: PassageData = {
        Device: device,
        LastHourTotal: lastHourTotal,
        Last24hTotal: last24hTotal
      };

      passageData.push(newData);
    });

    this.passageDataSource = new MatTableDataSource<PassageData>(passageData);
  }

  private setChartsData(): void {
    this.setHourlyAveragesChartData();
  }

  private setHourlyAveragesChartData(): void {
    this.hourlyAveragePassagesChartData = this.getHourlyAveragesChartData(this.passages);
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEventsDevices: EventsDeviceLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT';

    if (res?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.Devices,
        (event: EventLatest) => event.Level === 'Error');
    }

    if (res?.LatestBadEvents?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
        (event: EventLatest) => event.Level === 'Error', alertEventsDevices);
    }

    if (alertEventsDevices.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(res.Devices, (event: EventLatest) =>
          ['Info', 'Debug'].includes(event.Level) &&
          !this.eventBodyService.isAProperEvent(event, this.getModelByEventId(res.Devices, event.Id), this.eventBodies)
        );
      }

      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(
          res.LatestBadEvents.Devices,
          (event: EventLatest) =>
            ['Info', 'Debug'].includes(event.Level) &&
            !this.eventBodyService.isAProperEvent(event, this.getModelByEventId(res.Devices, event.Id), this.eventBodies),
          alertEventsDevices
        );
      }

      if (alertEventsDevices.length > 0) {
        //Wrong body
        alertType = 'error';
        eventType = 'WRONG_BODY_EVENT';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.Devices,
            (event: EventLatest) => event.Level === 'Warning');
        }

        if (res?.LatestBadEvents?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
            (event: EventLatest) => event.Level === 'Warning', alertEventsDevices);
        }

        if (alertEventsDevices.length > 0) {
          alertType = 'warning';
          eventType = 'WARNING';
        }
      }
    }

    if (alertEventsDevices.length > 0) {
      let alertEventsNumber: number = alertEventsDevices.reduce((sum, device) => sum + device.Events.length, 0);
      let errorPhrases: string[] = [
        'ALERT_PANEL.' + eventType + (alertEventsNumber > 1 ? 'S' : '') + '_DETECTED',
        'ALERT_PANEL.DEVICE' + (alertEventsDevices.length > 1 ? 'S' : '') + '_INVOLVED'
      ];

      this.alertEventsDevicesInvolved = alertEventsDevices
        .map(device => device.Name).join(', ');

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEventsNumber.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private getCertainEventsDevices(
    devices: EventsDeviceLatest[],
    eventCheck: (event: EventLatest) => boolean,
    initialArray: EventsDeviceLatest[] = []): EventsDeviceLatest[] {
    return devices.reduce<EventsDeviceLatest[]>((accumulator, currentDevice) => {
      let alertEvents: EventLatest[] = currentDevice.Events.filter((event: EventLatest) => eventCheck(event));
      let deviceIndex: number = accumulator.findIndex(device => device.Id === currentDevice.Id);
      if (deviceIndex !== -1) {
        accumulator[deviceIndex].Events.push(...alertEvents.filter(eventToAdd =>
          accumulator[deviceIndex].Events.find(event => event.Id === eventToAdd.Id) === undefined
        ));
        return accumulator;
      }
      else {
        return alertEvents.length > 0 ? [...accumulator, { ...currentDevice, Events: alertEvents } ] : accumulator;
      }
    }, initialArray);
  }

  private mapToPassages(res: DeviceEventsResponse, passageDevices: Device[], eventBodies: Record<string, EventParsedBody>): Passage[] {
    let passages: Passage[] = [];

    res.Devices.forEach(device => {
      let passageDevice: Device = passageDevices.find(oneDevice => oneDevice.Id === device.Id);
      if(passageDevice !== undefined) {
        let expectedBody: EventParsedBody = eventBodies[passageDevice.Model.Name];
        device.Events.forEach((event: DeviceEventsEvent) => {
          if (this.eventBodyService.isAProperEvent(event, passageDevice.Model, eventBodies)) {
            let eventBody: any = event.Body;
            let x1: number = +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['x1']);
            let x2: number = +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['x2']);
            let y1: number = +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['y1']);
            let y2: number = +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['y2']);

            let newPassage: Passage = {
              Device: device,
              TimedPosition: {
                Created: event.CreatedTimestamp * 1000, //in milliseconds
                X: (x1 + x2) / 2,
                Y: (y1 + y2) / 2
              },
              DetectionRegion: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['DetectionRegion']),
              ResolutionX: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['ResolutionX']),
              ResolutionY: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['ResolutionY']),
            };

            passages.push(newPassage);
          }
        });
      }
    });

    return passages;
  }

  private calculateLastPassagesCount(passages: Passage[], periodSeconds: number): number {
    let nowTimestamp: number = (new Date()).getTime();
    let startTimestamp: number = nowTimestamp - periodSeconds;
    return passages.filter((passage: Passage) => passage.TimedPosition.Created < nowTimestamp && passage.TimedPosition.Created > startTimestamp).length;
  }

  private getHourlyAveragesChartData(passages: Passage[]): ChartDataList {
    let chartDataList: ChartDataList = {};
    const oneHourMilliseconds = 60 * 60 * 1000;

    passages.forEach((passage: Passage) => {
      let deviceName: string = passage.Device.Name;

      if (chartDataList[deviceName] === undefined) {
        chartDataList[deviceName] = 1;
      }
      else {
        chartDataList[deviceName]++;
      }
    });

    Object.keys(chartDataList).forEach(deviceName => {
      let devicePassages: Passage[] = passages.filter(passage => passage.Device.Name == deviceName);
      if (devicePassages.length > 1) {
        let devicePassagesPeriodMilliseconds: number = Math.abs(devicePassages[0].TimedPosition.Created - devicePassages[devicePassages.length - 1].TimedPosition.Created);
        if (devicePassagesPeriodMilliseconds > oneHourMilliseconds) {
          chartDataList[deviceName] = Math.round(chartDataList[deviceName] * oneHourMilliseconds / devicePassagesPeriodMilliseconds);
        }
      }
    });

    return chartDataList;
  }

  public getModelByEventId(eventsDevices: EventsDeviceLatest[], eventId: string): DeviceModel {
    let deviceId: string, deviceModel: DeviceModel;

    deviceId = eventsDevices.find(device => device.Events.find(deviceEvent => deviceEvent.Id === eventId))?.Id;
    if (deviceId !== undefined) {
      deviceModel = this.passageDevices.find(device => device.Id === deviceId)?.Model;
    }

    return deviceModel;
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    if (this.passages.length === 0) {
      this.chartReady = true;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceDetails(deviceId: string): void {
    this.mainService.setNavigationInfoComand({ Id: deviceId, BackRoute: 'passage-dashboard' });
    this.router.navigate(['main/passage-detail']);
  }

  public goToDomainEvents(): void {
    if (this.isDomainAdmin) {
      this.mainService.setNavigationInfoComand({ BackRoute: 'passage-dashboard' });
      this.router.navigate(['main/domain-events']);
    }
  }

  ngOnDestroy(): void {
      this.apiSync.abort();
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
      this.loader.enable();
  }
}
