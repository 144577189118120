<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'SMART_TRAFFIC.TITLE' | translate }}
        </span>
        <span class="btn material-icons-outlined helpButton" (click)="startIntro()">
            live_help
        </span>
    </div>

    <button class="btn actionButton mb-4" type="button" (click)="goToRoadRisk()">
        {{ 'GENERAL.RISK_ANALYSIS' | translate }}
    </button>

    <mat-tab-group id="intro-traffic-tab" animationDuration="0ms" dynamicHeight="true" class="my-3 position-relative">
        <mat-tab [label]="'GENERAL.LAST_DATA' | translate">
            <div class="tabContent position-relative">
                <div [ngClass]="trafficDataSource === undefined ? 'initialHeight' : 'finalHeight'" class="customTable">
                    <table *ngIf="trafficDataSource" id="intro-traffic-table"
                        class="w-100 tableSimpleHover h-100 appearing" mat-table [dataSource]="trafficDataSource">

                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef style="width: auto; min-width: 150px;">
                                {{'GENERAL.NAME' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"> {{item.Device.Name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="TrafficLevel">
                            <th mat-header-cell *matHeaderCellDef style="width: 20%; min-width: 100px;">
                                {{'SMART_TRAFFIC.LEVEL' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item"> {{item.Level}}% </td>
                        </ng-container>

                        <ng-container matColumnDef="TrafficDetail">
                            <th mat-header-cell *matHeaderCellDef style="width: auto">
                                {{'SMART_TRAFFIC.DETAIL' | translate }}
                            </th>
                            <td mat-cell *matCellDef="let item" style="white-space: nowrap;">
                                <span *ngFor="let category of item.Categories"
                                    class="d-inline-flex align-items-end justify-content-start mr-2">
                                    <i class="mr-1 {{ category.Icon }}" style="width: initial !important;"></i>
                                    <span matTooltip="{{ 'SMART_TRAFFIC.' + category.Name.toUpperCase() | translate }}"
                                        style="word-break: keep-all">{{ category.Level }}%</span>
                                </span>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDeviceDetail(row.Device)">
                        </tr>
                    </table>
                    <div *ngIf="trafficDataSource?.data.length === 0" class="text-center my-5">
                        <h6 class="secondaryTextColor">{{ 'GENERAL.NO_DATA' | translate }}</h6>
                    </div>
                </div>
                <urban-loader [localized]="true" [disappearCondition]="loadingLatestData === false">
                </urban-loader>
            </div>
        </mat-tab>
        <mat-tab [label]="'GENERAL.MAP' | translate" #mapTab>
            <div class="tabContent position-relative" [ngClass]="mapReady ? 'finalHeight' : 'initialHeight'">
                <urban-traffic-maps-widget class="appearing mapPanel" *ngIf="mapTab.isActive && mapReady"
                    [passedHeight]="650" [trafficList]="trafficDevices" [initialZoom]="10"
                    [darkModeStatus]="isDarkActive"></urban-traffic-maps-widget>

                <urban-loader [localized]="true" [disappearCondition]="mapReady"></urban-loader>
            </div>
        </mat-tab>
        <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
            <div class="tabContent position-relative">
                <urban-searchbar id="intro-traffic-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated"
                    [loadingEvents]="loadingEventsData !== false"
                    [loadingEvents]="loadingEventsData !== false || loadingLatestData !== false"
                    (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()"
                    [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe"
                    [clearDateEvent]="clearDate" [setLatestDates]="setDates">
                </urban-searchbar>

                <div class="" *ngIf="alertPanelInput !== undefined">
                    <urban-alert-panel
                        [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
                        [titleText]="alertPanelInput.TitleText | translate" [descriptionText]="alertPanelInput.DescriptionText"
                        [buttonLabel]="isDomainAdmin ? ('DEVICE.VIEW_EVENTS' | translate) : null"
                        (buttonAction)="isDomainAdmin ? goToDomainEvents() : null" (close)="alertPanelInput = undefined">
                    </urban-alert-panel>
                </div>

                <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs pt-2"
                    *ngIf="noDataInDate">
                    <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'" />
                    <div>
                        <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}
                        </h2>
                        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                        <div class="d-flex noDataActions">
                            <button type="button" mat-button class="btn outlineActionButton"
                                (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                {{ 'GENERAL.NEW_SEARCH' | translate }}
                            </button>
                            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                {{ 'GENERAL.SHOW_DATA' | translate }}
                            </button>
                        </div>
                    </div>
                </div>

                <div #trafficAveragePanel class="roundedPanel panelBG chartContainer position-relative"
                    *ngIf="chartsTab.isActive"
                    [ngClass]="{'initialHeight': loadingEventsData === undefined || chartReady === undefined, 'finalHeight': loadingEventsData === false && chartReady}">

                    <urban-loader [localized]="true"
                        [disappearCondition]="loadingEventsData === false && chartReady"></urban-loader>

                    <div *ngIf="averageTrafficVehiclesPercentages !== undefined">
                        <urban-google-columnchart
                            *ngIf="objectKeysNumber(averageTrafficVehiclesPercentages) > 0 && translationsReady"
                            id="trafficAverage-content" class="appearing"
                            [columnChartTitle]="chosenDatesSearch ? 'SMART_TRAFFIC.AVERAGE' : 'SMART_TRAFFIC.LAST_24H_AVERAGE'"
                            columnsTitle="REPORT.traffic-monitor" valuesDescription="SMART_TRAFFIC.DISTRIBUTION"
                            [multiValuesDescription]="dataDescriptions"
                            [passedChartMultiData]="averageTrafficVehiclesPercentages" [limitValue]="100" valuesUnit="%"
                            [darkThemeActive]="isDarkActive" (chartDrawn)="chartReady = true">
                        </urban-google-columnchart>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    </div>
