<div class="container-fluid">
    <div
        class="row mb-1 d-flex justify-content-between align-items-baseline plr-15 bg-light-grey border-radius-15 pb-0 pt-0 m-0">
        <span *ngIf="!this.isAdd" class="h4 color-dark mb-2 mt-2 thin">
            {{ 'SMSS.GATEWAY' | translate }} - {{this.gateway?.GatewayName}}
        </span>
        <span *ngIf="this.isAdd" class="h4 color-dark mb-2 mt-2 thin">
            {{ 'SMSS.GATEWAY_ADD' | translate }}
        </span>
    </div>
    <br>

    <form [formGroup]="form">
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.GATEWAY_GENERAL' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
                    <input matInput formControlName="GatewayName" autocomplete="off" required>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.GATEWAY_MAC_ADDRESS' | translate }}</mat-label>
                    <input matInput formControlName="MacAddress" autocomplete="off">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField bodyArea">
                    <mat-label>{{ 'SMSS.MULTIPLE_LOGIN_TITLE' | translate }}</mat-label>
                    <textarea  matInput formControlName="MultipleLoginTitle" matTextareaAutosize
                        matAutosizeMinRows=15 matAutosizeMaxRows=30></textarea>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'GENERAL.ADDRESS' | translate }}</mat-label>
                    <input matInput formControlName="Address" autocomplete="off">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'GENERAL.ZIP' | translate }}</mat-label>
                    <input matInput formControlName="Zip" autocomplete="off">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'GENERAL.CITY' | translate }}</mat-label>
                    <input matInput formControlName="City" autocomplete="off">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'GENERAL.COUNTRY' | translate }}</mat-label>
                    <input matInput formControlName="Country" autocomplete="off">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.GATEWAY_PHONE' | translate }}</mat-label>
                    <input matInput formControlName="Phone" autocomplete="off">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.GATEWAY_MOBILE_PHONE' | translate }}</mat-label>
                    <input matInput formControlName="MobilePhone" autocomplete="off">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.GATEWAY_LOG' | translate }}</mat-label>
                    <mat-select formControlName="Log" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="2">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField" *ngIf="this.form.controls.Log.value === '2'">
                    <mat-label>{{ 'SMSS.GATEWAY_IP_ADDRESS' | translate }}</mat-label>
                    <input matInput formControlName="IPAddress" autocomplete="off" required>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField" *ngIf="this.form.controls.Log.value === '0'">
                    <mat-label>{{ 'SMSS.GATEWAY_IP_ADDRESS' | translate }}</mat-label>
                    <input matInput formControlName="IPAddress" autocomplete="off" />
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.GATEWAY_MANUFACTURER_WEBSITE' | translate }}</mat-label>
                    <input matInput formControlName="ManifacturerWebAdminAddress" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.HARDWARE_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="HardwareType" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let hardware of getKeys(this.hardwareTypes)"
                            value={{this.hardwareTypes[hardware]}}>
                            {{hardware}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.HARDWARE_VERSION' | translate }}</mat-label>
                    <input matInput formControlName="HardwareVersion" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.UPTIME' | translate }}</mat-label>
                    <input matInput formControlName="Uptime" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.MAX_USERS' | translate }}</mat-label>
                    <input matInput formControlName="MaxUsers"
                        autocomplete="off" type="number" min="0" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.WP_TEMPLATE' | translate }}</mat-label>
                    <mat-select formControlName="TemplateID" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let template of wpTemplates" value={{template.id}}>
                            {{template.Code}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-expansion-panel>

        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.GATEWAY_GEOINFORMATION' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="p-0 mt-1">
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.TEMPLATE_ID' | translate }}</mat-label>
                    <input matInput formControlName="TemplateID"
                        autocomplete="off" type="number" min="0" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.USE_TEMPLATE_COLOR' | translate }}</mat-label>
                    <input matInput formControlName="UseTemplateColor" autocomplete="off" type="number" min="0" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ADVERTISING' | translate }}</mat-label>
                    <mat-select formControlName="Advertising" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.ENABLE' | translate }}
                        </mat-option>
                        <mat-option value="10">
                            {{ 'GENERAL.ONLY_ON_THIS_DOMAIN' | translate }}
                        </mat-option>
                        <mat-option value="20">
                            {{ 'GENERAL.DISABLE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.SURVEY' | translate }}</mat-label>
                    <mat-select formControlName="Survey" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.DISABLE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.ENABLE_ONE_BY_ONE' | translate }}
                        </mat-option>
                        <mat-option value="2">
                            {{ 'GENERAL.ENABLE_ALL' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'GENERAL.LATITUDE' | translate }}</mat-label>
                    <input matInput formControlName="Latitude" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'GENERAL.LONGITUDE' | translate }}</mat-label>
                    <input matInput formControlName="Longitude"
                        autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.GPSTRACKINGENABLE' | translate }}</mat-label>
                    <mat-select formControlName="GPSTrackingEnabled" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.GPSTRACKINGKEEPLOGSFOR' | translate }}</mat-label>
                    <input matInput formControlName="GPSTrackingKeepLogsFor" autocomplete="off" type="number" min="0">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'GENERAL.TIMEZONE' | translate }}</mat-label>
                    <input matInput formControlName="Timezone"
                        autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.TIMETABLES' | translate }}</mat-label>
                    <input matInput formControlName="Timetables"
                        autocomplete="off" type="number" min="0">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.DAYSOFWEEKTIMETABLES' | translate }}</mat-label>
                    <input matInput formControlName="DaysOfWeekTimetable" autocomplete="off" type="number" min="0">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.MONTHSTIMETABLES' | translate }}</mat-label>
                    <input matInput formControlName="MonthsTimetable" autocomplete="off" type="number" min="0">
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.CONTENT_INJECTION' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.INJECTION' | translate }}</mat-label>
                    <mat-select formControlName="Injection" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.DOMAIN_DEFINITION' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.DISABLE' | translate }}
                        </mat-option>
                        <mat-option value="2">
                            {{ 'GENERAL.ENABLE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.INJECTION_START_SECONDS' | translate }}</mat-label>
                    <input matInput formControlName="InjectionStartSeconds" autocomplete="off" type="number" min="0">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.INJECTION_FREQUENCY_SECONDS' | translate }}</mat-label>
                    <input matInput formControlName="InjectionFrequencySeconds" autocomplete="off" type="number" min="0">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.INJECTION_SHOW_TOOL_BAR' | translate }}</mat-label>
                    <mat-select formControlName="Injection" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.INJECTION_IN_WELCOME_PORTAL' | translate }}</mat-label>
                    <mat-select formControlName="InjectionInWelcomePortal" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.COIN_GATEWAY_GUID' | translate }}</mat-label>
                    <input matInput formControlName="COINGatewayGUID" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.COINWOL' | translate }}</mat-label>

                    <input matInput formControlName="COINWOL" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.COIN_DNS' | translate }}</mat-label>
                    <input matInput formControlName="COINDns" autocomplete="off" />
                </mat-form-field>
            </div>
        </mat-expansion-panel>

        <br>
        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.SOCIAL_NETWORK' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <mat-form-field appearance="outline" class="customMatFormField bodyArea">
                    <mat-label>{{ 'SMSS.CUSTOM_APPS' | translate }}</mat-label>
                    <textarea  matInput
                        formControlName="CustomApps" matTextareaAutosize matAutosizeMinRows=15
                        matAutosizeMaxRows=30></textarea>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.FB_FAN_PAGE_URL' | translate }}</mat-label>

                    <input matInput formControlName="FacebookFanPageURL" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.FB_FAN_PAGE_URL' | translate }}</mat-label>
                    <input matInput formControlName="FacebookFanPageURL" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.FACEBOOK_PUBLISH_UPON_CONNECTION' | translate }}</mat-label>
                    <mat-select formControlName="FacebookPublishUponConnection" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'SMSS.DOMAIN_DEFINITION' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.YES' | translate }}
                        </mat-option>
                        <mat-option value="2">
                            {{ 'GENERAL.NEVER' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.TW_FOLLOW_USER' | translate }}</mat-label>
                    <input matInput formControlName="TwitterFollowerUser" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.DISABLE_APPLE_CAPTIVE_PORTAL' | translate }}</mat-label>
                    <mat-select formControlName="DisableAppleCaptivePortal" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.DISABLE_ANDROID_CAPTIVE_PORTAL' | translate }}</mat-label>
                    <mat-select formControlName="DisableAndroidCaptivePortal" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField bodyArea">
                    <mat-label>{{ 'GENERAL.NOTES' | translate }}</mat-label>
                    <textarea  matInput
                        formControlName="Notes" matTextareaAutosize matAutosizeMinRows=15
                        matAutosizeMaxRows=30></textarea>
                </mat-form-field>
            </div>
        </mat-expansion-panel>
        <br>

        <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header class="pl-0">
                <mat-panel-title class="pl-1">
                    {{ 'SMSS.GATEWAY_CONFIGURATION' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="p-0 mt-1">
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_NOTIFICATIONS' | translate }}</mat-label>
                    <mat-select formControlName="EnableNotifications" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.WALLED_GARDENS_OWN_FOREACH_GATEWAY' | translate }}</mat-label>
                    <mat-select formControlName="WalledGardensOwnForEachGateway" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.LOCKED' | translate }}</mat-label>
                    <mat-select formControlName="Locked" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.AUTH_USER_BY_MAC_LOGIN' | translate }}</mat-label>
                    <mat-select formControlName="AuthUsersByMACLogin">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_WIRELESS' | translate }}</mat-label>
                    <mat-select formControlName="EnableWireless">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.SSID' | translate }}</mat-label>
                    <input matInput formControlName="SSID" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.WAN_CONFIG_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="WANConfigType" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'SMSS.WITH_PARAMETERS' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'SMSS.MANUAL' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.HOTSPOT_IN_LAN' | translate }}</mat-label>
                    <mat-select formControlName="HotspotInLAN" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.NETWORK_ADDRESSING_MODE' | translate }}</mat-label>
                    <mat-select formControlName="NetworkAddressingMode" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'SMSS.STATIC_DHCP' | translate }}
                        </mat-option>
                        <mat-option value="2">
                            {{ 'SMSS.USB_MODEM' | translate }}
                        </mat-option>
                        <mat-option value="3">
                            {{ 'SMSS.LTE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.WAN_INTERFACE' | translate }}</mat-label>
                    <mat-select formControlName="WanInterface" hideSingleSelectionIndicator="true">
                        <mat-option value="ether1-12">
                            {{ 'SMSS.ETHER1_12' | translate }}
                        </mat-option>
                        <mat-option value="wlan2">
                            {{ 'SMSS.WLAN2' | translate }}
                        </mat-option>
                        <mat-option value="sfp1-12">
                            {{ 'SMSS.SFP1_12' | translate }}
                        </mat-option>
                        <mat-option value="sfp+1-12">
                            {{ 'SMSS.SFP+1_12' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.USERVLAN' | translate }}</mat-label>
                    <mat-select formControlName="UseVLAN" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.VLANID' | translate }}</mat-label>
                    <input matInput formControlName="VLANID" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.WAN_USE_DHCP' | translate }}</mat-label>
                    <mat-select formControlName="WANUseDHCP" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.WANIPADDRESS' | translate }}</mat-label>
                    <input matInput formControlName="WANIPAddress" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.WANNETMASK' | translate }}</mat-label>
                    <input matInput formControlName="WANNetMask" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.WANGATEWAYIP' | translate }}</mat-label>
                    <input matInput formControlName="WANGatewayIP" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.USBMODEMAPN' | translate }}</mat-label>
                    <input matInput formControlName="USBModemAPN" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.LTEAUTHENTICATION' | translate }}</mat-label>
                    <input matInput formControlName="LTEAuthentication" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.USBMODEMUSERNAME' | translate }}</mat-label>
                    <input matInput formControlName="USBModemUserName" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.USBMODEMPASSWORD' | translate }}</mat-label>
                    <input matInput formControlName="USBModemPassword" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField" *ngIf="this.form.controls.NetworkAddressingMode.value === '2'">
                    <mat-label>{{ 'SMSS.USBMODEMPASSWORD' | translate }}</mat-label>
                    <input matInput formControlName="USBModemInitATCommand" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField" *ngIf="this.form.controls.NetworkAddressingMode.value === '2'">
                    <mat-label>{{ 'SMSS.USBMODEMDIALATCOMMAND' | translate }}</mat-label>
                    <input matInput formControlName="USBModemDialATCommand" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField" *ngIf="this.form.controls.NetworkAddressingMode.value === '2'">
                    <mat-label>{{ 'SMSS.USBMODEMUSBPORT' | translate }}</mat-label>
                    <mat-select formControlName="USBModemUSBPort" hideSingleSelectionIndicator="true">
                        <mat-option value="1">
                            {{ 'SMSS.USB_1' | translate }}
                        </mat-option>
                        <mat-option value="2">
                            {{ 'SMSS.USB_2' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField" *ngIf="this.form.controls.NetworkAddressingMode.value === '2'">
                    <mat-label>{{ 'SMSS.USBMODEMPHONENUMBER' | translate }}</mat-label>
                    <input matInput formControlName="USBModemPhoneNumber" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.CERTIFICATE_ID' | translate }}</mat-label>
                    <input matInput formControlName="CertificateID" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.CERTIFICATE_DOMAIN' | translate }}</mat-label>
                    <input matInput formControlName="CertificateDomain" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH1' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth1" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH2' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth2" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH3' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth3" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH4' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth4" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH5' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth5" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH6' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth6" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH7' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth7" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH8' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth8" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH9' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth9" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH10' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth10" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH11' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth11" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ENABLE_HOTSPOT_BRIDGE_ON_ETH12' | translate }}</mat-label>
                    <mat-select formControlName="EnableHotspotBridgeOnEth12" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.KEEPALIVETIMEOUT' | translate }}</mat-label>
                    <input matInput formControlName="KeepAliveTimeout" autocomplete="off" type="number" min="0" max="259200">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.HOTSPOT_IPADDRESS' | translate }}</mat-label>
                    <input matInput formControlName="HotspotIPAddress" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.HOTSPOT_NETMASK' | translate }}</mat-label>
                    <input matInput formControlName="HotspotNetMask" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.DNS_IP_ADDRESS' | translate }}</mat-label>
                    <input matInput formControlName="DNSIPAddress"
                        autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.USER_POOL_FROM_IP' | translate }}</mat-label>
                    <input matInput formControlName="UsersPoolFromIP" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.USER_POOL_TO_IP' | translate }}</mat-label>
                    <input matInput formControlName="UsersPoolToIP" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.USER_POOL_LEASE_TIME_HOURS' | translate }}</mat-label>
                    <input matInput formControlName="UsersPoolLeaseTimeHours" autocomplete="off" type="number" min="0" max="72">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.HOTSPOT_USE_VLAN' | translate }}</mat-label>
                    <mat-select formControlName="HotspotUseVLAN" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.HOTSPOT_VLAN_ID' | translate }}</mat-label>
                    <input matInput formControlName="HotspotVLANID" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.PPTP_ENABLED' | translate }}</mat-label>
                    <mat-select formControlName="PPTPEnabled" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.PPTP_SERVER' | translate }}</mat-label>
                    <input matInput formControlName="PPTPServer" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.PPTP_USERNAME' | translate }}</mat-label>
                    <input matInput formControlName="PPTPUserName" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.PPTP_PASSWORD' | translate }}</mat-label>
                    <input matInput formControlName="PPTPPassword" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.GPS_ENABLE' | translate }}</mat-label>
                    <mat-select formControlName="GPSEnable" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.GPS_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="GPSType" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'SMSS.USB' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'SMSS.INTEGRATED' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.GPS_USB_PORT' | translate }}</mat-label>
                    <mat-select formControlName="GPSUSBPort" hideSingleSelectionIndicator="true">
                        <mat-option value="1">
                            {{ 'SMSS.USB_1' | translate }}
                        </mat-option>
                        <mat-option value="2">
                            {{ 'SMSS.USB_2' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.WALLED_GARDEN_UPDATE_MINS' | translate }}</mat-label>
                    <input matInput formControlName="WalledGardenUpdateMins" autocomplete="off" type="number" min="5">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.WALLED_GARDEN_UPDATE_MINS' | translate }}</mat-label>
                    <input matInput formControlName="UpdateGatewayStatusMins" autocomplete="off" type="number" min="0">
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.ADMIN_PASSWORD' | translate }}</mat-label>
                    <input matInput formControlName="AdminPassword" autocomplete="off" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField bodyArea">
                    <mat-label>{{ 'GENERAL.CUSTOM_COMMANDS' | translate }}</mat-label>
                    <textarea  matInput formControlName="CustomCommands" matTextareaAutosize matAutosizeMinRows=15
                        matAutosizeMaxRows=30></textarea>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.UPDATABLE_CONFIG' | translate }}</mat-label>
                    <mat-select formControlName="UpdatableConfig" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="customMatFormField">
                    <mat-label>{{ 'SMSS.IMPORT_UM_USERS' | translate }}</mat-label>
                    <mat-select formControlName="ImportUMUsers" hideSingleSelectionIndicator="true">
                        <mat-option value="0">
                            {{ 'GENERAL.FALSE' | translate }}
                        </mat-option>
                        <mat-option value="1">
                            {{ 'GENERAL.TRUE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </mat-expansion-panel>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>

        <div class="mt-2">
            <button mat-button class="btn actionButton ml-2" type="button" id="updateButton"
                (click)="save()">
                {{ 'GENERAL.SAVE' | translate }}
            </button>
            <button mat-button class="btn actionButton ml-2" (click)="addWalledGarden()">{{
                'SMSS.ADD_WALLED_GARDEN' | translate }}</button>
            <button mat-button class="btn actionButton ml-2" (click)="addWpTemplate()">{{
                'SMSS.ADD_WP_TEMPLATE' | translate }}</button>
        </div>
    </form>

    <ng-template [ngIf]="walledGardens !== null && walledGardens !== undefined && walledGardens.length > 0">
        <br>
        <div
            class="row mb-1 d-flex justify-content-between align-items-baseline plr-15 bg-light-grey border-radius-15 pb-0 pt-0 m-0">
            <span class="h5 color-dark mb-1 mt-1 thin">
                {{ 'SMSS.WALLED_GARDENS' | translate }}
            </span>
        </div>
        <br>

        <div class="domains-table mat-elevation-z4">
            <table class="w-100 tableSimpleHover" mat-table [dataSource]="wpWalledGardensDataSource">

                <!-- Id Column -->
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.id }}
                    </td>
                </ng-container>

                <!-- DestHost Column -->
                <ng-container matColumnDef="DestHost">
                    <th mat-header-cell *matHeaderCellDef> {{ 'SMSS.WALLED_GARDEN_DESTHOST' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.DestHost }}
                    </td>
                </ng-container>

                <!-- DestHostPort Column -->
                <ng-container matColumnDef="DestHostPort">
                    <th mat-header-cell *matHeaderCellDef> {{ 'SMSS.WALLED_GARDEN_DESTHOSTPORT' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.DestHostPort }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 15%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i class="mr-2 urbanicon-info-outline" matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                (click)="walledGardenDetail(element)">
                            </i>
                            <i class="mr-2 urbanicon-delete" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                (click)="walledGardenDelete(element)">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedWalledGardensColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedWalledGardensColumns;"></tr>
            </table>
        </div>
    </ng-template>

    <ng-template [ngIf]="wpTemplates !== null && wpTemplates !== undefined && wpTemplates.length > 0">
        <br>
        <div
            class="row mb-1 d-flex justify-content-between align-items-baseline plr-15 bg-light-grey border-radius-15 pb-0 pt-0 m-0">
            <span class="h5 color-dark mb-1 mt-1 thin">
                {{ 'SMSS.WP_TEMPLATES' | translate }}
            </span>
        </div>
        <br>

        <div class="devices-table mat-elevation-z4">
            <table class="w-100 tableSimpleHover" mat-table [dataSource]="wpTemplatesDataSource">

                <!-- Id Column -->
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.ID' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.id }}
                    </td>
                </ng-container>

                <!-- Code Column -->
                <ng-container matColumnDef="Code">
                    <th mat-header-cell *matHeaderCellDef> {{ 'SMSS.WP_CODE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Code }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 15%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i class="mr-2 urbanicon-info-outline" matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                (click)="wpTemplateDetail(element)">
                            </i>
                            <i class="mr-2 urbanicon-delete" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                (click)="wpTemplateDelete(element)">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedWpTemplateColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedWpTemplateColumns;"></tr>
            </table>
        </div>
    </ng-template>
</div>
