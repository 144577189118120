import { LocationProperty } from '../../../shared/models/location';
import {Component, OnDestroy, OnInit } from '@angular/core';
import {Location} from "../../../shared/models/location";
import {Subject} from "rxjs";
import {UserRoles} from "../../../shared/models/userRoles";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AdministratorApiService} from "../../../shared/services/administrator-api.service";
import {ApiService} from "../../../shared/services/api.service";
import {MatDialog} from "@angular/material/dialog";
import {PassDataService} from "../../../shared/services/pass-data/pass-data.service";
import {MainSubscriptionsService} from "../../../shared/services/main-subscriptions/main-subscriptions.service";
import {first, takeUntil} from "rxjs/operators";
import {ConfirmationDialogComponent} from "../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import {PropertyDialogComponent} from "../../../shared/dialogs/property-dialog/property-dialog.component";
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss']
})
export class LocationDetailComponent implements OnInit, OnDestroy {

  private locationId: string;
  public location: Location;
  public editMode: 'info' | 'position' | null = null;
  public mapReady: boolean = false;
  public isDarkModeActive: boolean = false;
  public dataInfoChanged: boolean;
  public dataLocationChanged: boolean;
  public properties: LocationProperty[] = [];
  public filteredData: LocationProperty[] = [];
  public propertiesColumns: string[] = ['Key', 'Value', 'Actions'];
  public userRoles: UserRoles['Roles'];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  public form: UntypedFormGroup = this.formBuilder.group({
    address: [{value: '', disabled: true}, Validators.required],
    zip: [{value: '', disabled: true}, Validators.required],
    city: [{value: '', disabled: true}, Validators.required],
    state: [{value: '', disabled: true}, Validators.required],
    country: [{value: '', disabled: true}, Validators.required],
    name: [{value: '', disabled: true}, Validators.required],
    description: [{value: '', disabled: true}],
  });

  public positionForm: UntypedFormGroup = this.formBuilder.group({
    latitude: [{value: 0, disabled: true}, Validators.required],
    longitude: [{value: 0, disabled: true}, Validators.required]
  });

  constructor(
    private router: Router,
    private administratorApiService: AdministratorApiService,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
  ) {
  }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;
      if (!this.userRoles.some(x => x.Name === 'Administrators')) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
      }

      this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
        if (navInfo?.Id) {
          this.locationId = navInfo.Id;
        } else {
          this.mainService.setNavigationInfoComand();
          this.router.navigate(['main/dashboard']);
          return;
        }

        this._initialize();
      });

      this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
        this.mapReady = mapLoading;
      });

      this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.isDarkModeActive = res == true;
      });

      this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((values: any) => {
        const locationInfoValues = {
          address: this.location.Address,
          zip: this.location.Zip,
          city: this.location.City,
          state: this.location.State,
          country: this.location.Country,
          name: this.location.Name,
          description: this.location.Description
        }

        this.dataInfoChanged = false;

        for (let key in locationInfoValues) {
          if (locationInfoValues[key] !== values[key]) {
            this.dataInfoChanged = true;
            break;
          }
        }
      });

      this.positionForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((values: any) => {
        const locationValues = {
          latitude: this.location.Latitude,
          longitude: this.location.Longitude,
        }

        this.dataLocationChanged = false;

        for (let key in locationValues) {
          if (locationValues[key] !== values[key]) {
            this.dataLocationChanged = true;
            break;
          }
        }
      });
    });
  }

  private _initialize(): void {
    this.location = null;

    this.apiService.locationGet(this.locationId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.location = result;

        this.form.setValue({
          address: this.location.Address,
          zip: this.location.Zip,  
          city: this.location.City,
          state: this.location.State,  
          country: this.location.Country,
          name: this.location.Name,   
          description: this.location.Description,
        });

        this.positionForm.setValue({
          latitude: this.location.Latitude,
          longitude: this.location.Longitude
        });

        this.properties = this.getPropertiesTableData(this.location.Properties);

        this.filteredData = this.properties;
        this.dataInfoChanged = false;
        this.dataLocationChanged = false;
        this.editMode = null;
        this.form.disable();
        this.positionForm.disable();
      }
      else {
        this.mainService.setNavigationInfoComand({ Back: true });
        this.router.navigate(['main/location-list']);
      }
    });
  }

  private getPropertiesTableData(properties: Record<string, string>): LocationProperty[] {
    let locationProperties: LocationProperty[] = [];

    Object.keys(properties).forEach(key => {
      locationProperties.push({
        Key: key,
        Value: properties[key]
      });
    });

    return locationProperties;
  }

  private update(): void {
    const updateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    updateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {

        this.location = {
          Id: this.locationId,
          Address: this.form.controls.address.value,
          City: this.form.controls.city.value,
          Zip: this.form.controls.zip.value,
          State: this.form.controls.state.value,
          Country: this.form.controls.country.value,
          Name: this.form.controls.name.value,
          Latitude: +this.positionForm.controls.latitude.value,
          Longitude: +this.positionForm.controls.longitude.value,
          Description: this.form.controls.description.value
        }

        this.apiService.locationUpdate(this.location).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            if (this.dataLocationChanged) {
              this.mainService.updateLocationComand(this.location);
            }

            this._initialize();
          }
        });
      }
    });
  }

  public sendUpdate(editMode: string): void {
    if ((editMode === 'info' && this.form.valid) || (editMode === 'position' && this.positionForm.valid)) {
      this.update();
    }
  }

  public updateLocationMarkerPosition(location: Location): void {
    if (location && location.Latitude && location.Longitude) {
      this.positionForm.controls.latitude.setValue(location.Latitude);
      this.positionForm.controls.longitude.setValue(location.Longitude);
    }
  }

  public addProperty(): void {
    const addPropertyDialogRef = this.dialog.open(PropertyDialogComponent);

    addPropertyDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newProperty => {
      if (newProperty) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.setLocationProperty(this.locationId, newProperty.key, newProperty.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public openPropertyDetail(key: string, value: string): void {
    const openPropertyDialogRef = this.dialog.open(PropertyDialogComponent, {
      data: {key: key, value: value}
    });

    openPropertyDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(updatedProperty => {
      if (updatedProperty) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.administratorApiService.setLocationProperty(this.locationId, updatedProperty.key, updatedProperty.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public events(): void {
    this.mainService.setNavigationInfoComand({Id: this.location.Id, BackRoute: 'location-detail' });
    this.router.navigate(['main/location-events']);
  }

  public deleteProperty(key: string): void {
    if (!key) {
      return;
    }

    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.administratorApiService.setLocationProperty(this.locationId, key, '').pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this._initialize();
          }
        });
      }
    });
  }

  public enableEdit(target: 'info' | 'position'): void {
    target === "info" ? this.form.enable() : this.positionForm.enable();
    this.editMode = target;
  }

  public cancelInfoEdit(): void {
    this.form.setValue({
      address: this.location.Address,
      zip: this.location.Zip,  
      city: this.location.City,
      state: this.location.State,  
      country: this.location.Country,
      name: this.location.Name,   
      description: this.location.Description,
    });

    this.dataInfoChanged = false;
    this.editMode = null;
    this.form.disable();
  }

  public cancelPositionEdit(): void {
    this.positionForm.setValue({
      latitude: this.location.Latitude,
      longitude: this.location.Longitude
    });

    this.dataLocationChanged = false;
    this.editMode = null;
    this.positionForm.disable();
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.properties.filter((property: LocationProperty) =>
      [property.Key?.toLowerCase(), property.Value?.toLowerCase()]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
