import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { AdministratorApiService } from "../../../../shared/services/administrator-api.service";
import { MatDialog } from "@angular/material/dialog";
import { MainSubscriptionsService } from "../../../../shared/services/main-subscriptions/main-subscriptions.service";
import { takeUntil } from "rxjs/operators";
import { ConfirmationDialogComponent } from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { TemplateItem } from "../../../../shared/models/administrator/templateItem";
import { TemplateDialogComponent } from "../../../../shared/dialogs/template-dialog/template-dialog.component";
import { Language } from "../../../../shared/models/language";
import { NameEntity } from "../../../../shared/models/nameEntity";
import { ApiService } from "../../../../shared/services/api.service";
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit, OnDestroy {

  public templatesColumns = ['Name', 'Language', 'Type', 'Format', 'Detail'];
  public templates: TemplateItem[] = [];
  public filteredData: TemplateItem[] = [];
  public languages: Language[] = [];
  public templateTypes : NameEntity<number>[] = [];
  public templateFormats : NameEntity<number>[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private administratorService: AdministratorApiService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) {}

  ngOnInit(): void {
    this.administratorService.listTemplateFormat().pipe(takeUntil(this.ngUnsubscribe)).subscribe(formats => {
      if(formats){
        this.templateFormats = formats;
      }
    });

    this.administratorService.listTemplateType().pipe(takeUntil(this.ngUnsubscribe)).subscribe(types => {
      if(types){
        this.templateTypes = types;
      }
    });

    this.apiService.getLanguages().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      this.languages = x.List;
    });

    this.loadData();
  }

  private loadData(): void {
    this.administratorService.listTemplate().pipe(takeUntil(this.ngUnsubscribe)).subscribe(templates => {
      if (templates) {
        this.templates = templates;
        this.filteredData = this.templates;
      }
    });
  }

  public add(): void {
    const templateDialogRef = this.dialog.open(TemplateDialogComponent, {
      data: {
        languages: this.languages,
        templateTypes: this.templateTypes,
        templateFormats: this.templateFormats
      },
      width: '80%',
      maxWidth: 1000
    });

    templateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newTemplate => {
      if (newTemplate) {
        const templateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        templateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.administratorService.addTemplate(newTemplate).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }



  public detail(template: TemplateItem): void {
    if (!template || template === undefined) {
      return;
    }

    const updateDialogRef = this.dialog.open(TemplateDialogComponent, {
      data: {
        templateItem: template,
        languages: this.languages,
        templateTypes: this.templateTypes,
        templateFormats: this.templateFormats
      },
      width: '80%',
      maxWidth: 1000
    });

    updateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.administratorService.updateTemplate(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this.loadData();
              }
            });
          }
        });
      }
    });
  }

  public delete(template: TemplateItem): void {
    if (!template || template === undefined) {
      return;
    }

    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.administratorService.deleteTemplate(template.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.loadData();
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.templates?.filter((template: TemplateItem) =>
      [
        template.Name?.toLowerCase(), 
        template.Type?.Name?.toLowerCase(), 
        template.Language?.Name?.toLowerCase(), 
        template.Format?.Name?.toLowerCase()
      ]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public formats(): void {
    this.mainService.setNavigationInfoComand({BackRoute: 'template-list'});
    this.router.navigate(['main/template-format-list']);
  }

  public types(): void {
    this.mainService.setNavigationInfoComand({BackRoute: 'template-list'});
    this.router.navigate(['main/template-type-list']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
