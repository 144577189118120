import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DeviceCommandType } from '../../../../shared/models/device-command/deviceCommandType';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import {
  ConfirmationDialogComponent
} from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  DeviceCommandTypeDialogComponent
} from '../../../../shared/dialogs/device-command-type-dialog/device-command-type-dialog.component';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-device-command-type-list',
  templateUrl: './device-command-type-list.component.html',
  styleUrls: ['./device-command-type-list.component.scss']
})
export class DeviceCommandTypeListComponent implements OnInit, OnDestroy {

  public deviceCommandTypeColumns = ['Name', 'Detail'];
  public deviceCommandTypes: DeviceCommandType[] = [];
  public filteredData: DeviceCommandType[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private administratorService: AdministratorApiService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.administratorService.listDeviceCommandType().pipe(takeUntil(this.ngUnsubscribe)).subscribe(types => {
      if (types) {
        this.deviceCommandTypes = types;
        this.filteredData = this.deviceCommandTypes;
      }
    });
  }

  public addDeviceCommandType(): void {
    const addDeviceCommandTypeDialogRef = this.dialog.open(DeviceCommandTypeDialogComponent);

    addDeviceCommandTypeDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newDeviceCommandType => {
      if (newDeviceCommandType) {
        const addDeviceCommandTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addDeviceCommandTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.administratorService.addDeviceCommandType(newDeviceCommandType).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }

  public deviceCommandTypeDetail(deviceCommandType: DeviceCommandType): void {
    if (!deviceCommandType || deviceCommandType === undefined) {
      return;
    }

    const updateDialogRef = this.dialog.open(DeviceCommandTypeDialogComponent, {
      data: {
        deviceCommandType: deviceCommandType
      },
      width: '50%',
      maxWidth: 500
    });

    updateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.administratorService.updateDeviceCommandType(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }

  public deviceCommandTypeDelete(deviceCommandType: DeviceCommandType): void {
    if (!deviceCommandType || deviceCommandType === undefined) {
      return;
    }

    const deleteDeviceCommandTypeDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteDeviceCommandTypeDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.administratorService.deleteDeviceCommandType(deviceCommandType.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.loadData();
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.deviceCommandTypes?.filter((key: DeviceCommandType) =>
      [key.Name?.toLowerCase()].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
