import { DeviceEventsDevice, DeviceEventsEvent, DeviceEventsResponse } from './../../../shared/models/deviceEvent';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subject } from 'rxjs';
import { takeUntil, takeWhile, first } from 'rxjs/operators';
import { Device } from '../../../shared/models/device';
import { HeatmapElement } from '../../../shared/models/heatmapElement';
import { ApiService } from '../../../shared/services/api.service';
import { DeviceEventRequest } from '../../../shared/models/deviceEvent';
import { Sensor } from '../../../shared/models/weatherstation/sensor';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { PeopleCount, PeopleCountEvent } from 'src/app/shared/models/peopleCount';
import { EventParsedBody } from 'src/app/shared/models/administrator/eventBody';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';
import introJs from 'intro.js';

const secondsToRefresh = 90; //ogni quanto bisogna refreshare ---- prenderlo da BE
@Component({
  selector: 'urban-heatmap-people-counter',
  templateUrl: './heatmap-people-counter.component.html',
  styleUrls: ['./heatmap-people-counter.component.scss']
})
export class HeatmapPeopleCounterComponent implements OnInit {
  public peopleCounterDevices: Device[] = [];
  public pcHeatmapElements: HeatmapElement[] = [];
  public peopleCounterMaxValue: number;
  private peopleCounts: PeopleCount[] = [];
  public devicePCEvents: DeviceEventsEvent[] = [];
  public myPCSensor: Sensor = {
    Name: '',
    Unit: '',
    Limit: '',
    Data: []
  };
  public isASingleDevice: boolean;
  public singlePeopleCounterDevice: Device;
  public buttonToggleSelected: number = 24;
  public isChecked = true;
  public readyToShow: boolean = false;
  public chartReadyToShow: boolean = false;
  public noDataIn24h: boolean = false;
  public noDataInDate: boolean = false;
  public lastCreated: number;
  public lastUpdate: number = null;
  public last24hSearch: boolean = true;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  private eventBodies: Record<string, EventParsedBody>;
  public currentLanguage: string;
  public isDarkActive: boolean = false;
  public startDate: number;
  public endDate: number;
  public loadingData: boolean;
  public firstUsefulDate: Date;
  public alertPanelInput: AlertPanelInput;
  private introJS = introJs();
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private mainService: MainSubscriptionsService,
    private passDataService: PassDataService,
    private translate: TranslateService,
    private eventBodyService: EventBodyUtilityService
  ) { }

  ngOnInit(): void {
    let peopleCounterId : string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        peopleCounterId = navInfo.Id;
        this.isASingleDevice = true;
        this.loadSingleDeviceData(peopleCounterId);
      } else {
        this.isASingleDevice = false;
        this.loadDevicesData();
      }
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.translate
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText]
    };
  }

  private showMap(): void {
    this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
      this.readyToShow = mapLoading;
    });
  }

  private loadSingleDeviceData(peopleCounterId: string): void {
    let deviceFeature: number, deviceEventsFeature: number;

    const syncContext = this.apiSync.initialize();
    this.apiSync.addFeatures(2, syncContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if(checkValues[deviceFeature]) {
        this.resetAllData();

        if (checkValues[deviceEventsFeature]) {
          this.peopleCounts = this.mapToPeopleCounts(data[deviceEventsFeature], [this.singlePeopleCounterDevice], this.eventBodies);
        }

        if (this.peopleCounts.length > 0) {
          this.setDatesRange();
          this.setAllData();

          this.showMap();
          this.chartReadyToShow = true;
          this.noDataIn24h = false;
        }
        else {
          this.noDataIn24h = true;
        }

        this.autoRefreshDataSingleDevice();
      }
      else {
        this.setErrorAndGoToMain();
      }
    }, syncContext);

    this.apiService.getDevice(peopleCounterId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.singlePeopleCounterDevice = device;
        this.eventBodies = this.eventBodyService.initEventBody([this.singlePeopleCounterDevice]);

        deviceFeature = this.apiSync.loadedFeature(syncContext);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(syncContext);
      }
    });

    let deviceEventRequest: DeviceEventRequest = { DeviceId: peopleCounterId };
    this.apiService.getDeviceEvents(deviceEventRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.pcHeatmapElements = [];

      if (res && res.Devices?.length > 0) {
        deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
      }
      else {
        deviceEventsFeature = this.apiSync.failedFeature(syncContext);
      }

    });
  }
  private loadDevicesData(): void  {
    this.peopleCounterMaxValue = 1;
    let deviceFeature: number, deviceEventsFeature: number;

    const syncContext = this.apiSync.initialize();
    this.apiSync.addFeatures(2, syncContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if(checkValues.every(value => value)) {
        this.peopleCounts = this.mapToPeopleCounts(data[deviceEventsFeature], this.peopleCounterDevices, this.eventBodies);
        this.setHeatmapData();

        this.showMap();
        this.lastUpdate = Math.round(Date.now() / 1000);
        this.noDataIn24h = false;
      }
      else {
        this.noDataIn24h = true;
      }

      interval(secondsToRefresh * 1000).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.refreshDevicesData();
      });
    }, syncContext);

    this.apiService.getDevicesByType('people-counter').pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if(res && res.length > 0) {
        this.peopleCounterDevices = res;
        this.eventBodies = this.eventBodyService.initEventBody(this.peopleCounterDevices);

        deviceFeature = this.apiSync.loadedFeature(syncContext);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(syncContext);
      }
    });

    let request: DeviceEventRequest = { DeviceType: 'people-counter' };

    this.apiService.getDeviceEvents(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if(res && res.Devices?.length > 0) {
        deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
      }
      else {
        deviceEventsFeature = this.apiSync.failedFeature(syncContext);
      }
    });
  }

  private refreshDevicesData(): void {
    let request: DeviceEventRequest = { DeviceType: 'people-counter' };
    this.apiService.getDeviceEvents(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if(res && res.Devices?.length > 0) {
        this.peopleCounts = this.mapToPeopleCounts(res, this.peopleCounterDevices, this.eventBodies);
        this.lastUpdate = Math.round(Date.now() / 1000);
        this.setHeatmapData();

        this.showMap();
        this.noDataIn24h = false;
      }
      else {
        this.noDataIn24h = true;
      }
    });
  }

  private autoRefreshDataSingleDevice(): void {
    if (this.isChecked == true) {
      interval(secondsToRefresh * 1000).pipe(takeUntil(this.ngUnsubscribe), takeWhile(() => this.isChecked)).subscribe(() => {
        let end: Date = new Date();
        let start: Date = new Date(end);
        start.setHours(start.getHours() - 1);

        let deviceEventRequest: DeviceEventRequest = {
          DeviceId: this.singlePeopleCounterDevice.Id,
          Start: Math.round(start.getTime() / 1000),
          End: Math.round(end.getTime() / 1000)
        };

        this.apiService.getDeviceEvents(deviceEventRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
          this.resetAllData();

          if (res && res.Devices?.length > 0) {
            this.peopleCounts = this.mapToPeopleCounts(res, [this.singlePeopleCounterDevice], this.eventBodies);
          }

          if (this.peopleCounts.length > 0) {
            this.setDatesRange();
            this.setAllData();

            this.showMap();
            this.chartReadyToShow = true;
            this.noDataIn24h = false;
          }
        });

        this.buttonToggleSelected = null;
      });
    }
  }

  public slideOnToggle() {
    if(this.isChecked == true) {
      this.autoRefreshDataSingleDevice();
    }
  }

  public newSearch (selectedDates?: SearchDates): void {
    this.loadingData = true;
    this.readyToShow = false;
    this.noDataInDate = false;
    this.chartReadyToShow = false;
    this.isChecked = false;

    this.startDate = selectedDates?.startDate ? selectedDates.startDate : undefined;
    this.endDate = selectedDates?.endDate ? selectedDates.endDate : undefined;

    let deviceEventsRequest: DeviceEventRequest = {
      DeviceId: this.singlePeopleCounterDevice.Id,
      Start: selectedDates?.startDate ? selectedDates.startDate : undefined,
      End: selectedDates?.endDate ? selectedDates.endDate : undefined
    };

    this.apiService.getDeviceEvents(deviceEventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      this.pcHeatmapElements = [];
      this.resetAllData();

      if (res && res.Devices?.length > 0) {
        this.peopleCounts = this.mapToPeopleCounts(res, [this.singlePeopleCounterDevice], this.eventBodies);
      }

      if(this.peopleCounts.length > 0) {
        this.setAllData();
        this.showMap();
        this.chartReadyToShow = true;
      }
      else {
        if (selectedDates !== null && selectedDates !== undefined) {
          this.last24hSearch = selectedDates.last24hSearch;
        }

        this.noDataInDate = true;
      }
      this.loadingData = false;
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;
    this.noDataInDate = false;
    this.noDataIn24h = false;
    this.chartReadyToShow = false;
    this.readyToShow = false;
    this.isChecked = false;

    let eventsRequest: DeviceEventLatestRequest = { DeviceId: this.singlePeopleCounterDevice.Id };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest)
    .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription))
    .subscribe((res: DeviceEventLatestResponse) => {
      this.pcHeatmapElements = [];
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if (res && res.Devices?.length > 0) {
        this.peopleCounts = this.mapToPeopleCounts(res, [this.singlePeopleCounterDevice], this.eventBodies);
      }

      if(this.peopleCounts.length > 0) {
        this.setLatestDates(res);
        this.setAllData();
        this.showMap();

        this.chartReadyToShow = true;
      }
      else {
        this.noDataInDate = true;
      }
      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.endDate = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp));
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  public setDatesRange(): void {
    this.endDate = this.peopleCounts[0].Values[0].Created;
    let start: Date = new Date(this.endDate * 1000);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
  }

  private resetAllData() {
    this.peopleCounts = [];
    this.devicePCEvents = [];
    this.firstUsefulDate = null;
    this.alertPanelInput = undefined;
  }

  private setAllData(): void {
    this.peopleCounterMaxValue = +this.peopleCounts[0].Limit;
    this.lastUpdate = this.peopleCounts[0].Values[0].Created;

    this.setHeatmapData();
    this.setHistoryData();
  }

  private setHeatmapData(): void {
    let peopleCounterElements: HeatmapElement[] = [];

    this.peopleCounts.forEach((peopleCount: PeopleCount) => {
      let lastValue: number = peopleCount.Values[0].Count;
      let newPeopleCounterElement: HeatmapElement = {
        Latitude: peopleCount.Latitude,
        Longitude: peopleCount.Longitude,
        Value: (!this.isASingleDevice && !isNaN(+peopleCount.Limit)) ? (lastValue / +peopleCount.Limit) : lastValue
      };

      peopleCounterElements.push(newPeopleCounterElement);
    });

    this.pcHeatmapElements = peopleCounterElements;
  }

  private setHistoryData(): void {
    let updatedPCSensor: Sensor = {
      Name: '',
      Unit: '',
      Limit: '',
      Data: []
    };

    updatedPCSensor.Name = this.peopleCounts[0].PeopleCounterName;
    updatedPCSensor.Unit = this.peopleCounts[0].Unit;
    updatedPCSensor.Limit = this.peopleCounts[0].Limit;
    this.peopleCounts.forEach(count => {
      count.Values.forEach(value => {
        updatedPCSensor.Data.push({ Value: value.Count.toString(), Created: this.reformatCreated(value.Created.toString()) });
      });
    });

    this.myPCSensor = updatedPCSensor;
  }

  public startIntro(): void {
    this.translate.get([
      'INTRO.PEOPLECOUNTER_WELCOME',
      'INTRO.SEARCH_DATE'
    ])
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(intros => {
      this.introJS
      .setOptions({
        steps: [
          {
            title: 'Welcome',
            intro: intros['INTRO.PEOPLECOUNTER_WELCOME']
          },
          {
            title: 'Search bar',
            element: '#intro-people-counter-search',
            intro: intros['INTRO.SEARCH_DATE'],
            position: 'right'
          }
        ],
        showProgress: true
      })
      .start();
    });
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;

    if (this.pcHeatmapElements.length === 0 && this.devicePCEvents.length === 0) {
      this.noDataInDate = false;
      this.chartReadyToShow = false;
      this.readyToShow = false;
      this.isChecked = false;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEvents: EventLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT' | 'UNKNOWN_ERROR';

    if (res?.Devices?.length > 0) {
      alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
    }

    if (alertEvents.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
      }

      if (alertEvents.length > 0) {
        //Unknown first error
        alertType = 'error';
        eventType = 'UNKNOWN_ERROR';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEvents = res.Devices[0].Events.filter((event: EventLatest) =>
            ['Info', 'Debug'].includes(event.Level) &&
            !this.eventBodyService.isAProperEvent(event, this.singlePeopleCounterDevice.Model, this.eventBodies)
          );
        }

        if (alertEvents.length > 0) {
          //Wrong body
          alertType = 'error';
          eventType = 'WRONG_BODY_EVENT';
        }
        else {
          if (res?.LatestBadEvents?.Devices?.length > 0) {
            alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }
          else if (res?.Devices?.length > 0) {
            alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }

          if (alertEvents.length > 0) {
            alertType = 'warning';
            eventType = 'WARNING';
          }
        }
      }
    }

    if (alertEvents.length > 0) {
      let errorPhrases: string[];

      if (eventType === 'UNKNOWN_ERROR') {
        let lastInfoEvent: EventLatest = res?.Devices?.[0]?.Events?.find(
          (event: EventLatest) => event.Level === 'Info' &&
          this.eventBodyService.isAProperEvent(event, this.singlePeopleCounterDevice.Model, this.eventBodies)
        );
        if (lastInfoEvent !== undefined) {
          this.firstUsefulDate = new Date(lastInfoEvent.CreatedTimestamp * 1000);
        }

        eventType = 'ERROR';
        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          this.firstUsefulDate ? 'ALERT_PANEL.LAST_CORRECT_INFO_DATE' : ''
        ];
      }
      else {
        this.firstUsefulDate = new Date(alertEvents[0].CreatedTimestamp * 1000);

        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          'ALERT_PANEL.FIRST_' + eventType + '_DATE'
        ];
      }

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEvents.length.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private mapToPeopleCounts(res: DeviceEventsResponse, peopleCounterDevices: Device[], eventBodies: Record<string, EventParsedBody>):  PeopleCount[] {
    let peopleCounts: PeopleCount[] = [];

    res.Devices.forEach((device: DeviceEventsDevice) => {
      let peopleCounterDevice: Device = peopleCounterDevices.find(oneDevice => oneDevice.Id === device.Id);

      let deviceEvent: DeviceEventsEvent = device.Events
      .find(event => this.eventBodyService.isAProperEvent(event, peopleCounterDevice.Model, eventBodies));
      if (peopleCounterDevice !== undefined && deviceEvent !== undefined) {
        let expectedBody: EventParsedBody = eventBodies[peopleCounterDevice.Model.Name];

        let eventBody = device.Events[0].Body;
        let newPeopleCounterElement: PeopleCount = {
          Device: peopleCounterDevice,
          PeopleCounterName: this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping["Name"]).toString(),
          Latitude: device.Latitude,
          Longitude: device.Longitude,
          Unit: "",
          Limit: this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping["Limit"]).toString(),
          Values: []
        };

        device.Events.forEach(event => {
          if (this.eventBodyService.isAProperEvent(event, peopleCounterDevice.Model, eventBodies)) {
            let eventBody = event.Body;
            let newPeopleCountEvent: PeopleCountEvent = {
              Count: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping["Values"]),
              Created: event.CreatedTimestamp
            };
            newPeopleCounterElement.Values.push(newPeopleCountEvent);
          }
        });

        peopleCounts.push(newPeopleCounterElement);
      }
    });

    return peopleCounts;
  }

  public reformatCreated(createdToFormat: string): number {
    let currentDate = new Date(+createdToFormat * 1000);
    return Math.ceil(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds()).getTime() / 1000 + 1);
  }

  public goToDeviceEvents(): void {
    if (this.singlePeopleCounterDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.singlePeopleCounterDevice.Id, BackRoute: 'people-counter' });
      this.router.navigate(['main/device-events']);
    }
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public goToMain(): void {
    let currentUrl: string = this.router.url.split('/').pop();
    this.mainService.setNavigationInfoComand({ BackRoute: currentUrl });
    this.mainService.updateAllDataAndGoToMainComand();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
