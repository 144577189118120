import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { SerializationHelper } from '../../../serializationHelper';
import { SmartDialogComponent } from '../../../shared/dialogs/smart-dialog/smart-dialog.component';
import { DeviceGroupCapability } from '../../../shared/models/deviceGroupCapability';
import { DeviceTypeCapability } from '../../../shared/models/deviceTypeCapability';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeviceGroupSetDialogComponent } from '../../../shared/dialogs/device-group-set-dialog/device-group-set-dialog.component';
import { Device } from '../../../shared/models/device';
import { DeviceGroup } from '../../../shared/models/deviceGroup';
import { AdministratorApiService } from '../../../shared/services/administrator-api.service';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-device-group-detail',
  templateUrl: './device-group-detail.component.html',
  styleUrls: ['./device-group-detail.component.scss']
})
export class DeviceGroupDetailComponent implements OnInit, OnDestroy {

  public deviceColumns = ['Icon', 'Name', 'Latitude', 'Longitude', 'Detail'];
  public deviceGroup: DeviceGroup = { Devices: [], Name: '', Created: 0, Id: '', Creator: '', Domain: null, Deleted: 0, Color: '' };
  public listDevices: Device[] = [];
  public filteredData: Device[] = [];
  public dataChanged: boolean;
  public editMode: boolean = false;
  public isAdmin: boolean = false;
  public mapReady: boolean = false;
  public isDarkModeActive: boolean;
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private deviceGroupId: string;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  public form: UntypedFormGroup = this.formBuilder.group({
    name: [{ value: '', disabled: false }, Validators.required],
    color: [{ value: '', disabled: true }, Validators.required]
  });

  constructor(
    private administratorApiService: AdministratorApiService,
    public dialog: MatDialog,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private mainService: MainSubscriptionsService,
    private passDataService: PassDataService
  ) { }

  ngOnInit(): void {
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (!navInfo?.Id) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }

      this.deviceGroupId = navInfo.Id;
    });

    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((values: any) => {
      const serviceValues = {
        name: this.deviceGroup.Name,
        color: this.deviceGroup.Color,
      }

      this.dataChanged = false;

      for (let key in serviceValues) {
        if (serviceValues[key] !== values[key]) {
          this.dataChanged = true;
          break;
        }
      }
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkModeActive = res == true;
    });

    this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
      this.mapReady = mapLoading;
    });

    this.loadData();
  }

  private loadData(): void {
    this.administratorApiService.deviceGroupGet(this.deviceGroupId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.deviceGroup = result;
        this.form.controls.name.setValue(this.deviceGroup.Name);
        this.form.controls.color.setValue(this.deviceGroup.Color);

        if (this.deviceGroup.Devices && this.deviceGroup.Devices !== undefined && this.deviceGroup.Devices.length > 0) {
          this.filteredData = this.deviceGroup.Devices;
        }

        if (this.deviceGroup.Capabilities && this.deviceGroup.Capabilities !== undefined && this.deviceGroup.Capabilities.length > 0) {
          this.deviceGroup.Capabilities = this.deviceGroup.Capabilities.filter(x => x.Action.substr(0, 1) === '{')
        }

        this._initDevices();
      }
      else {
        this.mainService.setNavigationInfoComand();
        this.mainService.setCustomErrorComand('It was not possible to find a Device group with the given ID');
        this.router.navigate(['main/dashboard']);
      }
    });
  }

  private _initDevices(): void {
    this.passDataService.devices$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(devices => {
      if (devices) {
        this.listDevices = [];
        this._loadDevices(devices);
        this.deviceGroup?.Devices?.forEach(element => {
          this.listDevices = this.listDevices.filter(device => device.Id != element.Id);
        });
      }
    });
  }

  private _loadDevices(devicesToIterate: Device[]): void {
    if (devicesToIterate && devicesToIterate.length > 0) {
      for (let device of devicesToIterate) {
        if (device.Childs?.length > 0) {
          this.listDevices.push(device);
          for (let child of device.Childs) {
            this.listDevices.push(child);
            if (child.Childs?.length > 0) {
              this._loadDevices(child.Childs)
            }
          }
        }
        else {
          this.listDevices.push(device);
        }
      }

      this.listDevices.sort((a, b) => a.Name.localeCompare(b.Name))
    }
  }

  public removeDevice(device: Device): void {
    if (device) {
      const deleteDeviceFromGroupConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteDeviceFromGroupConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
        if (confirmed) {
          this.administratorApiService.deviceGroupRemove(device.Id, this.deviceGroup.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.loadData();
          });
        }
      });
    }
  }

  public addDevice(): void {
    const addDeviceGroupDialogRef = this.dialog.open(DeviceGroupSetDialogComponent, {
      data: {
        groups: [this.deviceGroup],
        devices: this.listDevices,
        fromGroup: true
      },
      width: '50%',
      maxWidth: 500
    });

    addDeviceGroupDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const addSettingConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addSettingConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.administratorApiService.deviceGroupSet(data.deviceId, data.groupId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }

  public executeAction(capability: DeviceTypeCapability): void {
    if (capability === undefined || capability === null) {
      return;
    }

    let deviceGroupCapability: DeviceGroupCapability = SerializationHelper.toInstance(capability.Action);

    if (deviceGroupCapability === undefined) {
      return;
    }

    const smartDialog = this.dialog.open(SmartDialogComponent, {
      disableClose: false,
      data: {
        body: deviceGroupCapability.Body,
        parameters: deviceGroupCapability.Parameters,
        action: deviceGroupCapability.Path
      },
      minWidth: 250,
      width: '30%'
    })

    smartDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {

        const executeActionConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        executeActionConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            if (data.parameters != '') {
              deviceGroupCapability.Parameters = data.parameters;
            }

            if (deviceGroupCapability.Parameters !== '' && deviceGroupCapability.Parameters !== undefined) {
              deviceGroupCapability.Path = deviceGroupCapability.Path + '?' + deviceGroupCapability.Parameters;
            }

            if (data.body !== undefined && data.body !== '') {
              deviceGroupCapability.Body = data.body;
            }

            if (deviceGroupCapability.Body !== '') {
              this.administratorApiService.executePost(deviceGroupCapability.Path, deviceGroupCapability.Body).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                if (res) {
                  this.mainService.setSuccessMessageComand(res.Message);
                }
              });
            }
            else {
              this.administratorApiService.executeGet(deviceGroupCapability.Path).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                if (res) {
                  this.mainService.setSuccessMessageComand(res.Message);
                }
              });
            }
          }
        });
      }
    });
  }

  public enableEdit(): void {
    this.form.controls['color'].enable();
    this.editMode = true;
  }

  public saveInfo(): void {
    if (this.form.valid) {
      this.administratorApiService.deviceGroupUpdate(this.form.controls.name.value, this.form.controls.color.value, this.deviceGroup.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
        if (x) {
          this.dataChanged = false;
          this.editMode = false;
          this.loadData();
        }
      });
    }
  }

  public cancelEdit(): void {
    this.form.setValue({
      name: this.deviceGroup.Name,
      color: this.deviceGroup.Color,
    });

    this.form.controls['color'].disable();
    this.dataChanged = false;
    this.editMode = false;
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.deviceGroup?.Devices?.filter((device: Device) =>
      [
        device.Name?.toLowerCase(), 
        device.Latitude?.toString(), 
        device.Longitude?.toString()
      ]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public openDeviceDetail(deviceId: string): void {
    this.mainService.setNavigationInfoComand({ Id: deviceId, BackRoute: 'device-group-detail' });
    this.router.navigate(['main/device']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
