import {MainState} from "../../store/main/main.reducer";
import {LoaderService} from "./loader/loader.service";
import { HttpClient } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import * as MainActions from "../../store/main/main.actions";
import {DeviceNotificationListRequest} from "../models/device-command/deviceNotificationListRequest";
import {DeviceNotificationListResponse} from "../models/device-command/deviceNotificationListResponse";
import { DeviceNotification } from "../models/device-command/deviceNotification";

@Injectable({
  providedIn: 'root'
})

export class DeviceNotificationService{
  constructor(private http: HttpClient, private store: Store<MainState>, private readonly loaderService: LoaderService) {}

  public list(request: DeviceNotificationListRequest): Observable<DeviceNotificationListResponse> {
    return this.http.post<DeviceNotificationListRequest>(
      `uv.device.notification.list.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public polling(request: DeviceNotificationListRequest): Observable<DeviceNotificationListResponse> {
    this.loaderService.hide();
    return this.http.post<DeviceNotificationListRequest>(
      `uv.device.notification.list.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public getLatest24Hours(deviceId: string, commandId: string): Observable<DeviceNotification[]> {
    return this.http.get<DeviceNotificationListResponse>(
      `uv.device.notification.last24hours.api?device=${deviceId}&device-command=${commandId}`
    ).pipe(
      map((res: any) => res.Items),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }
}
