import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { AdministratorApiService } from "../../../../shared/services/administrator-api.service";
import { MatDialog } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators"; import {
  ConfirmationDialogComponent
} from "../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { NameEntity } from "../../../../shared/models/nameEntity";
import { NameEntityDialogComponent } from "../../../../shared/dialogs/name-entity-dialog/name-entity-dialog.component";
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-template-format-list',
  templateUrl: './template-format-list.component.html',
  styleUrls: ['./template-format-list.component.scss']
})
export class TemplateFormatListComponent implements OnInit , OnDestroy {

  public formatsColumns = ['Name', 'Detail'];
  public formats: NameEntity<number>[] = [];
  public filteredData: NameEntity<number>[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private administratorService: AdministratorApiService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.administratorService.listTemplateFormat().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.formats = x;
        this.filteredData = this.formats;
      }
    });
  }

  public add(): void {
    const addDialogRef = this.dialog.open(NameEntityDialogComponent);

    addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newFormat => {
      if (newFormat) {
        const addConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.administratorService.addTemplateFormat(newFormat).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }

  public detail(format: NameEntity<number>): void {
    if (!format || format === undefined) {
      return;
    }
    const updateDialogRef = this.dialog.open(NameEntityDialogComponent, {
      data: {
        nameEntity: format
      },
      width: '50%',
      maxWidth: 500
    });

    updateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.administratorService.updateTemplateFormat(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }

  public delete(format: NameEntity<number>): void {
    if (!format || format === undefined) {
      return;
    }

    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.administratorService.deleteTemplateFormat(format.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.loadData();
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.formats?.filter((key: NameEntity<number>) =>
      [key.Name?.toLowerCase()].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
