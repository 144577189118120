import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { BubbleChartColorLegend, BubbleChartDescriptions, BubblesData, Point } from 'src/app/shared/models/ChartDataList';
import { EventParsedBody } from 'src/app/shared/models/administrator/eventBody';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { Device } from 'src/app/shared/models/device';
import { DeviceEventRequest, DeviceEventsEvent, DeviceEventsResponse } from 'src/app/shared/models/deviceEvent';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { Passage, PassageDetailData } from 'src/app/shared/models/passage';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { TimedPosition } from 'src/app/shared/models/timedPosition';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-passage-detail',
  templateUrl: './passage-detail.component.html',
  styleUrls: ['./passage-detail.component.scss']
})
export class PassageDetailComponent implements OnInit {

  public passageDevice: Device;
  public passages: Passage[];
  public passageDataSource: MatTableDataSource<PassageDetailData>;
  public displayedColumns: string[] = ['Area', 'Percentage', 'Total'];
  public passagesBubbleChartData: BubblesData;
  public bubbleChartLegend: BubbleChartColorLegend = {
    MinAmount: 'PASSAGE_DETAIL.LESS_BUSY_AREA',
    MaxAmount: 'PASSAGE_DETAIL.BUSIER_AREA'
  }
  public bubbleChartDescriptions: BubbleChartDescriptions = {
    hAxis: 'X',
    vAxis: 'Y',
    colorAxis: 'PASSAGE_DETAIL.PASSAGES_PERCENTAGE',
    sizeAxis: 'PASSAGE_DETAIL.PASSAGES_AMOUNT'
  }
  private minAreaLimit: Point = { X: 0, Y: 0 };
  private maxAreaLimit: Point;
  public spatialPrecision: number = 10;
  public startDate: number;
  public endDate: number;
  public isDarkActive: boolean;
  public firstCall: boolean = undefined;
  public firstCallPathsChart: boolean = undefined;
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean
  public setDates: boolean;
  public loadingData: boolean;
  public firstUsefulDate: Date;
  private eventBody: Record<string, EventParsedBody>;
  public alertPanelInput: AlertPanelInput;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private passDataService: PassDataService,
    private router: Router,
    private translate: TranslateService,
    private loader: LoaderService,
    private eventBodyService: EventBodyUtilityService
  ) {}

  ngOnInit(): void {
    let deviceId: string;

    this.loader.disable();

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;

        this.loadData(deviceId);
      }
      else {
        this.setErrorAndGoToMain();
        return;
      }
    });

    this.passDataService.currentDarkModeStatus$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText]
    };
  }

  private loadData(deviceId: string): void {
    let deviceFeature: number, deviceEventsFeature: number;

    const syncContext = this.apiSync.initialize();
    this.apiSync.addFeatures(2, syncContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        this.resetAllData();

        if (checkValues[deviceEventsFeature]) {
          this.passages = this.mapToPassages(data[deviceEventsFeature], [this.passageDevice], this.eventBody);
        }

        if (this.passages.length > 0) {
          this.setAllData();
        }
      }
      this.loadingData = false;
    });

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.passageDevice = device;
        this.eventBody = this.eventBodyService.initEventBody([this.passageDevice]);

        deviceFeature = this.apiSync.loadedFeature(syncContext);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(syncContext);
        this.setErrorAndGoToMain();
      }
    });

    let eventsRequest: DeviceEventRequest = { DeviceId: deviceId };

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
      if (res && res.Devices?.length > 0) {
        deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
      }
      else {
        deviceEventsFeature = this.apiSync.failedFeature(syncContext);
      }
    });
  }

  public newSearch(selectedDates: SearchDates): void {
    this.loadingData = true;
    this.startDate = selectedDates.startDate;
    this.endDate = selectedDates.endDate;

    let eventsRequest: DeviceEventRequest = {
      DeviceId: this.passageDevice.Id,
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(eventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
      this.resetAllData();
      if (res && res.Devices?.length > 0) {
        this.passages = this.mapToPassages(res, [this.passageDevice], this.eventBody);
      }

      if(this.passages.length > 0) {
        this.setAllData();
      }

      this.loadingData = false;
      this.last24hSearch = selectedDates.last24hSearch;
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;

    let eventsRequest: DeviceEventLatestRequest = {
      DeviceId: this.passageDevice.Id
    }

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest)
    .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription))
    .subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if (res && res.Devices?.length > 0) {
        this.passages = this.mapToPassages(res, [this.passageDevice], this.eventBody);
      }

      if (this.passages.length > 0) {
        this.setLatestDates(res);
        this.setAllData();
      }
      this.loadingData = false;
    });
  }

  private setLatestDates(res: DeviceEventLatestResponse): void {
    this.endDate = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp));
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  private resetAllData(): void {
    this.passages = [];
    this.passagesBubbleChartData = {};
    this.passageDataSource = new MatTableDataSource<PassageDetailData>();
    this.firstUsefulDate = null;
    this.alertPanelInput = undefined;
  }

  private setAllData(): void {
    this.maxAreaLimit = {
      X: this.passages[0].ResolutionX,
      Y: this.passages[0].ResolutionY
    };

    this.setStatusTableData();
    this.setChartsData();
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEvents: EventLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT' | 'UNKNOWN_ERROR';

    if (res?.Devices?.length > 0) {
      alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
    }

    if (alertEvents.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
      }

      if (alertEvents.length > 0) {
        //Unknown first error
        alertType = 'error';
        eventType = 'UNKNOWN_ERROR';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEvents = res.Devices[0].Events.filter((event: EventLatest) =>
            ['Info', 'Debug'].includes(event.Level) &&
            !this.eventBodyService.isAProperEvent(event, this.passageDevice.Model, this.eventBody)
          );
        }

        if (alertEvents.length > 0) {
          //Wrong body
          alertType = 'error';
          eventType = 'WRONG_BODY_EVENT';
        }
        else {
          if (res?.LatestBadEvents?.Devices?.length > 0) {
            alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }
          else if (res?.Devices?.length > 0) {
            alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }

          if (alertEvents.length > 0) {
            alertType = 'warning';
            eventType = 'WARNING';
          }
        }
      }
    }

    if (alertEvents.length > 0) {
      let errorPhrases: string[];

      if (eventType === 'UNKNOWN_ERROR') {
        let lastInfoEvent: EventLatest = res?.Devices?.[0]?.Events?.find(
          (event: EventLatest) => event.Level === 'Info' &&
            this.eventBodyService.isAProperEvent(event, this.passageDevice.Model, this.eventBody)
        );
        if (lastInfoEvent !== undefined) {
          this.firstUsefulDate = new Date(lastInfoEvent.CreatedTimestamp * 1000);
        }

        eventType = 'ERROR';
        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          this.firstUsefulDate ? 'ALERT_PANEL.LAST_CORRECT_INFO_DATE' : ''
        ];
      }
      else {
        this.firstUsefulDate = new Date(alertEvents[0].CreatedTimestamp * 1000);

        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          'ALERT_PANEL.FIRST_' + eventType + '_DATE'
        ];
      }

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEvents.length.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private setChartsData(): void {
    this.setPassagesAmountChartData();
  }

  private setStatusTableData(): void {
    let passageDetailData: PassageDetailData[] = [
      { Position: 'NW', Percentage: 0, Total: 0 },
      { Position: 'NE', Percentage: 0, Total: 0 },
      { Position: 'SE', Percentage: 0, Total: 0 },
      { Position: 'SW', Percentage: 0, Total: 0 }
    ];

    let regionWidth: number = this.maxAreaLimit.X - this.minAreaLimit.X;
    let regionHeight: number = this.maxAreaLimit.Y - this.minAreaLimit.Y;

    passageDetailData[0].Total = this.passages.filter((passage: Passage) => passage.TimedPosition.X < regionWidth / 2 && passage.TimedPosition.Y < regionHeight / 2).length;
    passageDetailData[1].Total = this.passages.filter((passage: Passage) => passage.TimedPosition.X > regionWidth / 2 && passage.TimedPosition.Y < regionHeight / 2).length;
    passageDetailData[2].Total = this.passages.filter((passage: Passage) => passage.TimedPosition.X > regionWidth / 2 && passage.TimedPosition.Y > regionHeight / 2).length;
    passageDetailData[3].Total = this.passages.filter((passage: Passage) => passage.TimedPosition.X < regionWidth / 2 && passage.TimedPosition.Y > regionHeight / 2).length;
    passageDetailData.forEach((passageDetail: PassageDetailData) => {
      passageDetail.Percentage = Math.round((passageDetail.Total / this.passages.length) * 100 * 10) / 10;
    });

    this.passageDataSource = new MatTableDataSource<PassageDetailData>(passageDetailData);
  }

  private setPassagesAmountChartData(): void {
    this.passagesBubbleChartData = this.getBubbleChartData(this.passages, this.spatialPrecision, this.maxAreaLimit, this.minAreaLimit);
  }

  private getBubbleChartData(passages: Passage[], positionsAmount: number, areaMax: Point, areaMin: Point): BubblesData {
    let bubblesData: BubblesData = {};

    passages.forEach((passage: Passage) => {
      let point: Point = this.translatePositionToRangedPoint(passage.TimedPosition, positionsAmount, areaMax, areaMin, false);
      if (bubblesData[point.X] === undefined) {
        bubblesData[point.X] = {};
      }

      if (bubblesData[point.X][point.Y] === undefined) {
        bubblesData[point.X][point.Y] = {
          Value: 1,
          Description: ''
        };
      }
      else {
        bubblesData[point.X][point.Y].Value++;
      }
    });

    for (let x in bubblesData) {
      for (let y in bubblesData[x]) {
        bubblesData[x][y].Description = bubblesData[x][y].Value.toString();
      }
    }

    return bubblesData;
  }

  private translatePositionToRangedPoint(position: TimedPosition, totalPoints: number, maxLimit: Point, minLimit: Point, percent: boolean): Point {
    let x: number = position.X;
    let y: number = position.Y;
    let rangeX: number = maxLimit.X - minLimit.X;
    let rangeY: number = maxLimit.Y - minLimit.Y;
    //minLimit is an offset, so that the new minimum value is 0
    x -= minLimit.X;
    y -= minLimit.Y;
    //scaled point position to give it a sort of a range
    x = Math.floor(x * totalPoints / rangeX);
    y = Math.floor(y * totalPoints / rangeY);
    //translated point to start of the centering process:
    //gave it a distance (half range unit) from top/left border of the range
    x += 0.5;
    y += 0.5;

    if (percent) {
      //range for percentage
      rangeX = 100;
      rangeY = 100;
    }

    //made range wider to complete the centering process
    //(and optionally converted to percentage range)
    let point: Point = {
      X: x * rangeX / (totalPoints + 1),
      Y: y * rangeY / (totalPoints + 1)
    };

    return point;
  }

  private mapToPassages(res: DeviceEventsResponse, passageDevices: Device[], eventBodies: Record<string, EventParsedBody>): Passage[] {
    let passages: Passage[] = [];

    res.Devices.forEach(device => {
      let passageDevice: Device = passageDevices.find(oneDevice => oneDevice.Id === device.Id);
      if(passageDevice !== undefined) {
        let expectedBody: EventParsedBody = eventBodies[passageDevice.Model.Name];
        device.Events.forEach((event: DeviceEventsEvent) => {
          if (this.eventBodyService.isAProperEvent(event, passageDevice.Model, eventBodies)) {
            let eventBody: any = event.Body;
            let x1: number = +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['x1']);
            let x2: number = +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['x2']);
            let y1: number = +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['y1']);
            let y2: number = +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['y2']);

            let newPassage: Passage = {
              Device: device,
              TimedPosition: {
                Created: event.CreatedTimestamp * 1000, //in milliseconds
                X: (x1 + x2) / 2,
                Y: (y1 + y2) / 2
              },
              DetectionRegion: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['DetectionRegion']),
              ResolutionX: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['ResolutionX']),
              ResolutionY: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['ResolutionY']),
            };

            passages.push(newPassage);
          }
        });
      }
    });

    return passages;
  }

  public changeSpatialPrecision(): void {
    this.setPassagesAmountChartData();
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceEvents(): void {
    if (this.passageDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.passageDevice.Id, BackRoute: 'passage-detail' });
      this.router.navigate(['main/device-events']);
    }
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.loader.enable();
  }

}
