import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { Component, OnInit } from '@angular/core';
import { Subject } from "rxjs";
import { Device } from "../../../shared/models/device";
import { ApiService } from "../../../shared/services/api.service";
import { PassDataService } from "../../../shared/services/pass-data/pass-data.service";
import { Router } from "@angular/router";
import { MainSubscriptionsService } from "../../../shared/services/main-subscriptions/main-subscriptions.service";
import { first, takeUntil } from "rxjs/operators";
import { DeviceCommandService } from "../../../shared/services/device-command.service";
import { DeviceCommandListRequest } from "../../../shared/models/device-command/deviceCommandListRequest";
import { DeviceCommand } from "../../../shared/models/device-command/deviceCommand";
import { ConfirmationDialogComponent } from "../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { SearchDates } from 'src/app/shared/models/searchDates';
import { PaginationInstance } from 'ngx-pagination';
import { NavigationInfo } from 'src/app/shared/models/navigationExtra';

@Component({
  selector: 'urban-device-commands',
  templateUrl: './device-commands.component.html',
  styleUrls: ['./device-commands.component.scss']
})
export class DeviceCommandsComponent implements OnInit {

  public deviceCommands: DeviceCommand[] = [];
  public filteredData: DeviceCommand[] = [];
  public displayedColumns: string[] = ['Id', 'Created', 'Device', 'DeviceCommandType', 'Command', 'Detail'];
  public isDarkActive: boolean;
  public startDate: number;
  public endDate: number;
  public currentDevice: Device;
  public actualFilter: string = '';
  public last24hSearch: boolean = true;
  public lastAvailabletDataSearch: boolean;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public loadingData: boolean;
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  }
  private subscription: Subject<void> = new Subject<void>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private deviceCommandApiService: DeviceCommandService,
    private passDataService: PassDataService,
    public dialog: MatDialog,
    private router: Router,
    private mainService: MainSubscriptionsService,
  ) {
  }

  ngOnInit(): void {
    this.passDataService.navigationInfo$.pipe(first()).subscribe((navInfo: NavigationInfo) => {
      if (!navInfo?.Id) {
        this.setErrorAndGoToMain();
        return;
      }

      this.loadData(navInfo.Id);
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });
  }

  private loadData(deviceId: string): void {
    let deviceFeature: number, commandsFeature: number;
    const context: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, context);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        if (checkValues[commandsFeature]) {
          this.deviceCommands = data[commandsFeature].DeviceCommands;
          this.filteredData = this.deviceCommands;
          this.config.totalItems = data[commandsFeature].Total;

          for (const deviceCommand of this.deviceCommands) {
            deviceCommand.DeviceCommandJson = JSON.parse(deviceCommand.Json);
          }
        }
      }
      this.loadingData = false;
    }, context);

    let firstRequest: DeviceCommandListRequest = {
      Device: deviceId,
      Page: 0,
      Limit: this.config.itemsPerPage
    };

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;
        deviceFeature = this.apiSync.loadedFeature(context);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(context);
        this.setErrorAndGoToMain();
      }
    });

    this.deviceCommandApiService.list(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        commandsFeature = this.apiSync.loadedFeatureWithData(res, context);
      } else {
        commandsFeature = this.apiSync.failedFeature(context);
      }
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;
    this.lastAvailabletDataSearch = true;

    this.deviceCommandApiService.getLatest24Hours(this.currentDevice.Id).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceCommand[]) => {
      if (res?.length > 0) {
        this.setLatestDates(res);
  
        this.config.totalItems = res.length;
        this.deviceCommands = res;
        this.filteredData = this.deviceCommands;

        for (const deviceCommand of this.deviceCommands) {
          deviceCommand.DeviceCommandJson = JSON.parse(deviceCommand.Json);
        }
      }

      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceCommand[]): void {
    this.endDate = res[0].Created;
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates?: SearchDates): void {
    this.loadingData = true;
    this.lastAvailabletDataSearch = false;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
      this.config.currentPage = 1;
    }

    let serverRequest: DeviceCommandListRequest = {
      Device: this.currentDevice.Id,
      Page: this.config.currentPage - 1,
      Limit: this.config.itemsPerPage,
      Start: this.startDate,
      End: this.endDate
    }

    this.deviceCommandApiService.list(serverRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      if (res) {
        this.deviceCommands = res.DeviceCommands;
        this.filteredData = this.deviceCommands;
        this.config.totalItems = res.Total;

        for (const deviceCommand of this.deviceCommands) {
          deviceCommand.DeviceCommandJson = JSON.parse(deviceCommand.Json);
        }
      }

      if (selectedDates !== null && selectedDates !== undefined) {
        this.last24hSearch = selectedDates.last24hSearch;
      }

      this.loadingData = false;
    });
  }

  public commandDelete(deviceCommand: DeviceCommand) {
    if (!deviceCommand) {
      return;
    }

    const deleteCommandConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteCommandConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.deviceCommandApiService.delete(deviceCommand.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
          if (res) {
            this.newSearch();
          }
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.deviceCommands?.filter((command: DeviceCommand) =>
      [
        command.Id.toLowerCase(),
        command.Created ? new Date(command.Created * 1000).toLocaleString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        }) : '',
        command.Device?.Name.toLowerCase(),
        command.DeviceCommandType?.Name.toLowerCase(),
        command.DeviceCommandJson?.Body.toLowerCase(),
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
    
    if (!this.lastAvailabletDataSearch) {
      this.newSearch();
    }
  }

  public paginatorOnItemsPerPageChange(): void {
    this.config.currentPage = 1;
    this.newSearch();
  }

  public paginatorGetMaxPage(): number {
    return Math.ceil(this.config.totalItems / this.config.itemsPerPage);
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDetail(deviceCommand: DeviceCommand): void {
    this.mainService.setNavigationInfoComand({Id: deviceCommand.Id, BackRoute: 'device-commands'});
    this.router.navigate(['main/device-command-detail']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
