<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{currentDevice?.Name}}
        </span>
        <div class="d-flex flex-row">
            <button mat-button class="btn outlineActionButton"
                *ngIf="this.isAdmin || this.isDomainAdmin"
                (click)="deviceEvents()">
                {{ 'DEVICE.VIEW_EVENTS' | translate }}
            </button>
            <ng-container
                *ngIf="currentDevice?.Model.Type.Capabilities !== null && currentDevice?.Model.Type.Capabilities !== undefined">
                <ng-container *ngFor="let capability of currentDevice?.Model.Type.Capabilities">
                    <button *ngIf="!cameraCapabilities.includes(capability.Label)" mat-button
                        id="{{capability.Id}}" class="btn ml-2"
                        [ngClass]="actionCapabilitiesLabelsList.includes(capability.Label) ? 'actionButton' : 'outlineActionButton'"
                        (click)="this.executeAction(capability.Id)" type="button">
                        {{ 'DEVICE.' + capability.Label | translate }}
                    </button>
                </ng-container>
            </ng-container>
            <button mat-button class="btn warnButton ml-2"
                *ngIf="(childs === null || childs === undefined) && (this.isAdmin || this.isDomainAdmin)" id="deleteButton" type="button"
                (click)="delete()">
                {{ 'DEVICE.DELETE' | translate }}
            </button>
        </div>
    </div>

    <form [formGroup]="form">
        <div class="panelBG roundedPanel infoPanel px-3 pt-3">
            <div class="row d-flex justify-content-between align-items-baseline mx-0 px-3 pt-3">
                <span class="title3">{{ 'GENERAL.INFO' | translate }}</span>
                <div>
                    <button mat-button class="btn actionButton" *ngIf="editMode === 'info' && (this.isAdmin || this.isDomainAdmin)"
                        type="button" id="updateButton" (click)="updateInfo()">
                        {{ 'GENERAL.SAVE' | translate }}
                    </button>
                    <div *ngIf="editMode !== 'info' && (this.isAdmin || this.isDomainAdmin)"
                        [matTooltip]="editMode === 'location' ? ('DEVICE.ONGOING_CHANGES_MESSAGE' | translate) : ''">
                        <button [disabled]="editMode === 'location'" mat-button class="btn actionButton"
                            type="button" id="editButton" (click)="editInfo()">
                            {{ 'GENERAL.EDIT' | translate }}
                        </button>
                    </div>
                    <button mat-button class="btn outlineActionButton ml-2" *ngIf="editMode === 'info'"
                        type="button" id="cancelButton" (click)="cancelInfoEdit()">
                        {{ 'GENERAL.CANCEL' | translate }}
                    </button>
                </div>
            </div>
            <div class="row mx-0 pt-4">
                <div class="col-6 infoColumn">
                    <div *ngFor="let formField of formFields; let i=index" >
                        <ng-container *ngIf="i < (formFields.length / 2)" [ngTemplateOutlet]="infoForm"
                        [ngTemplateOutletContext]="{ $implicit: formField, formGroup: form }">
                        </ng-container>
                    </div>
                </div>
                <div  class="col-6 infoColumn">
                    <div *ngFor="let formField of formFields; let i=index" >
                        <ng-container *ngIf="i >= (formFields.length / 2)" [ngTemplateOutlet]="infoForm"
                        [ngTemplateOutletContext]="{ $implicit: formField, formGroup: form }">
                        </ng-container>
                    </div>
                </div>
            </div>
            <mat-error class="infoError" *ngIf="showFormError && !this.form.valid">
                {{ 'DEVICE.FORM_ERROR' | translate }}
            </mat-error>
        </div>

    </form>

    <ng-template #infoForm let-formField let-formGroup="formGroup">
        <ng-container [formGroup]="formGroup">
            <div *ngIf="formField?.formControl === 'id'" class="d-flex justify-content-between mb-3">
                <mat-form-field appearance="outline" class="customMatFormField mr-3" *ngIf="formField.formControl === 'id'" [ngClass]="{'noUnderline': editMode !== 'info'}">
                    <mat-label>{{ 'DEVICE.ID' | translate }}</mat-label>
                    <input matInput formControlName="id" autocomplete="off">
                </mat-form-field>
    
                <button id="copyButton" mat-button type="submit" class="btn outlineActionButton no-wrap-text"
                    (click)="copyToClipboard()">
                    <i class="urbanicon-copy"></i>
                    <span class="mr-2">{{ 'GENERAL.COPY'| translate }}</span>
                </button>
                <button id="smallCopyButton" mat-button type="submit" class="btn smallOutlineActionButton no-wrap-text d-none"
                    (click)="copyToClipboard()">
                    <i class="urbanicon-copy"></i>
                </button>
            </div>

            <mat-form-field appearance="outline" class="customMatFormField mb-3" *ngIf="formField.formControl !== undefined && formField.formControl !== 'id' && formField.formControl !== 'parentSelected'"
                [ngClass]="{'noUnderline': editMode !== 'info'}">
                <mat-label>{{ formField.formLabel | translate }}</mat-label>
                <input matInput [formControlName]="formField.formControl" autocomplete="off" [readonly]="formField.readonly || editMode !== 'info'" [required]="formField.required">
            </mat-form-field>

            <mat-form-field appearance="outline" class="customMatFormField mb-3" *ngIf="formField.propertyKey !== undefined" [ngClass]="{'noUnderline': editMode !== 'info', 'user-selected-none' : formField.disabled}">
                <mat-label *ngIf="formField.propertyKey === 'Retention'">{{formField.translation}} ({{ 'DEVICE.RETENTION_ZERO' |
                    translate }})</mat-label>
                <mat-label *ngIf="formField.propertyKey !== 'Retention'">{{formField.translation}}</mat-label>
                <div class="d-flex">
                    <input *ngIf="formField.propertyKey !== 'Password' && formField.propertyKey !== 'Retention' && formField.propertyKey !== 'Id'"
                        matInput [formControlName]="'property' + formField.propertyKey" id="{{formField.propertyKey}}" type="text" autocomplete="off"
                        [readonly]="editMode !== 'info'">
                    <input *ngIf="formField.propertyKey === 'Password'" matInput [formControlName]="'property' + formField.propertyKey"
                        id="{{formField.propertyKey}}" type="password" autocomplete="off" [readonly]="editMode !== 'info'">
                    <input #decHex *ngIf="formField.propertyKey === 'Id'" matInput [formControlName]="'property' + formField.propertyKey"
                        id="{{formField.propertyKey}}" type="text" autocomplete="off" [readonly]="editMode !== 'info'">

                    <!-- <mat-slide-toggle *ngIf="property.Key === 'Id'" [checked]="this.isHexadecimal"
                    (toggleChange)="setHexadecimal()" matTooltip="{{ 'DEVICE.HEXADECIMAL' | translate }}">
                </mat-slide-toggle> -->

                    <mat-radio-group color="primary" *ngIf="formField.propertyKey === 'Id'" (change)="setHexadecimal()"
                        class="d-flex flex-row" [readonly]="editMode !== 'info'">
                        <mat-radio-button value="DEC" [checked]="!this.isHexadecimal" class="ml-2 mr-2"
                            matTooltip="{{ 'DEVICE.DECIMAL' | translate }}">DEC</mat-radio-button>
                        <mat-radio-button value="HEX" [checked]="this.isHexadecimal" class="ml-2 mr-2"
                            matTooltip="{{ 'DEVICE.HEXADECIMAL' | translate }}">HEX</mat-radio-button>
                    </mat-radio-group>

                    <input *ngIf="formField.propertyKey === 'Retention'" matInput [formControlName]="'property' + formField.propertyKey"
                        id="{{formField.propertyKey}}" type="number" (change)="changeCosts($event)" min="1" autocomplete="off" [readonly]="editMode !== 'info'">
                </div>
            </mat-form-field>

            <div *ngIf="formField.formControl === 'parentSelected'" class="d-flex justify-content-between mb-3">
                <mat-form-field appearance="outline" class="customMatFormField flex-grow-1" 
                                [ngClass]="{'noUnderline': editMode !== 'info', 'mr-3': this.currentDevice?.ParentId && this.currentDevice.ParentId !== '00000000-0000-0000-0000-000000000000'}">
                    <mat-label>{{ 'DEVICE.PARENT' | translate }}</mat-label>
                    <mat-select formControlName="parentSelected" hideSingleSelectionIndicator="true">
                        <mat-option value="">{{ 'DEVICE.NO_PARENT' | translate }}</mat-option>
                        <mat-option *ngFor="let parentDevice of parents" value={{parentDevice.Id}}>
                            <span class="urbanicon-{{ parentDevice.Model.Type?.Icon }}"></span>
                            {{parentDevice.Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="mb-4" *ngIf="this.currentDevice?.ParentId !== undefined && this.currentDevice?.ParentId != '00000000-0000-0000-0000-000000000000'">
                    <button mat-button class="btn outlineActionButton no-wrap-text" (click)="parentDetail()">
                        {{ 'DEVICE.GO_TO_PARENT' | translate }}
                    </button>
                </div>
            </div>

            <div *ngIf="formField.formButton === 'changeDomain'" class="d-flex justify-content-between mb-3">
                <mat-form-field appearance="outline" class="customMatFormField flex-grow-1 mr-3" [ngClass]="{'noUnderline': editMode !== 'info'}">
                    <mat-label>{{ 'GENERAL.DOMAIN' | translate }}</mat-label>
                    <mat-select value="opt" disabled hideSingleSelectionIndicator="true">
                        <mat-option value="opt">{{ currentDevice.Domain.Name }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <button mat-button class="btn actionButton no-wrap-text" type="button" id="changeDeviceDomainButton" (click)="changeDomain()">
                    {{ 'DEVICE.CHANGE_DOMAIN' | translate }}
                </button>
            </div>
        </ng-container>
    </ng-template>


    <div class="row mx-0 justify-content-between align-items-stretch">
        <div class="px-0" [ngClass]="this.currentDevice?.Model?.Type?.Name === 'camera' ? 'col-6 pr-12 halfColumn' : 'col-12'">
            <form [formGroup]="locationForm" class="h-100">
                <div class="panelBG roundedPanel h-100 d-flex flex-column">
                    <div class="row mx-0 d-flex justify-content-between align-items-baseline">
                        <span class="title3 mb-4">{{ 'DEVICE.POSITION' | translate }}</span>
                        <div>
                            <button mat-button class="btn actionButton" *ngIf="editMode === 'location' && (this.isAdmin || this.isDomainAdmin)"
                                type="button" id="updateButton" (click)="updateLocation()">
                                {{ 'GENERAL.SAVE' | translate }}
                            </button>
                            <div *ngIf="editMode !== 'location' && this.isAdmin"
                                [matTooltip]="this.form.controls.parentSelected.value !== '' ? ('DEVICE.PARENT_LOCATION' | translate) :
                                (editMode === 'info' ? ('DEVICE.ONGOING_CHANGES_MESSAGE' | translate) : '')">
                                <button [disabled]="editMode === 'info' || this.form.controls.parentSelected.value !== ''"
                                    mat-button class="btn actionButton" type="button" id="editButton" (click)="editLocation()">
                                    {{ 'GENERAL.EDIT' | translate }}
                                </button>
                            </div>
                            <button mat-button class="btn outlineActionButton ml-2" *ngIf="editMode === 'location'"
                                type="button" id="cancelButton" (click)="cancelLocationEdit()">
                                {{ 'GENERAL.CANCEL' | translate }}
                            </button>
                        </div>
                    </div>

                    <div class="h-100 flex-grow-1 d-flex flex-row justify-content-between pt-2">
                        <urban-maps-widget *ngIf="mapReady && currentDeviceLocalized" [showInfo]="false"
                            [editMode]="editMode === 'location' && (this.isAdmin || this.isDomainAdmin) ? 'drag' : ''"
                            [devicesList]="[currentDeviceLocalized]" [initialZoom]="16"
                            [darkModeStatus]="isDarkModeActive" class="flex-grow-1" [passedHeight]="currentDevice?.Model?.Type?.Name === 'camera' ? 0 : 330"
                            [showInfo]="false" [outputChangePosition]="true" (changeDevicePosition)="updateDeviceMarkerPosition($event)" class="col-10">
                        </urban-maps-widget>

                        <div class="col-2">
                            <mat-form-field appearance="outline" class="customMatFormField mb-3" [ngClass]="{'noUnderline': editMode !== 'location'}">
                                <mat-label>{{ 'DEVICE.LATITUDE' | translate }}</mat-label>
                                <input matInput formControlName="latitude" type="number" autocomplete="off" required
                                    [readonly]="editMode !== 'location'">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="customMatFormField" [ngClass]="{'noUnderline': editMode !== 'location'}">
                                <mat-label>{{ 'DEVICE.LONGITUDE' | translate }}</mat-label>
                                <input matInput formControlName="longitude" type="number" autocomplete="off" required
                                    [readonly]="editMode !== 'location'">
                            </mat-form-field>
                        </div>

                        <mat-error *ngIf="showLocationFormError && !this.locationForm.valid">
                            {{ ((locationForm.controls.latitude.value === '' || locationForm.controls.latitude.value === '') ?
                            'DEVICE.FORM_ERROR' : 'DEVICE.FORM_NUMBER_ERROR') | translate }}
                        </mat-error>
                    </div>
                </div>
            </form>
        </div>

        <div *ngIf="currentDevice?.Model?.Type?.Name === 'camera'" class="col-6 pr-0 pl-12 halfColumn">
            <div class="panelBG roundedPanel h-100 d-flex flex-column">
                <div class="row mx-0 d-flex justify-content-between align-items-baseline">
                    <span class="title3">{{ 'DEVICE.SHOT' | translate }}</span>
                    <div *ngIf="currentDevice.Model.Type.Capabilities">
                        <ng-container *ngFor="let capability of currentDevice.Model.Type.Capabilities">
                            <button *ngIf="capability.Label === cameraCapabilities[0] || capability.Label === cameraCapabilities[1]"
                                [ngClass]="actionCapabilitiesLabelsList.includes(capability.Label) ? 'actionButton' : 'outlineActionButton'"
                                mat-button type="button" id="{{capability.Id}}" class="btn ml-2" (click)="this.executeAction(capability.Id)">
                                {{ 'DEVICE.' + capability.Label | translate }}
                            </button>
                        </ng-container>
                    </div>
                </div>
                <div class="w-100 position-relative marginTop-32">
                    <img [src]="'./../../../../assets/img/streaming-default0.png'" alt="Preview streaming camera" width="100%">
                    <ng-container *ngFor="let capability of currentDevice.Model.Type.Capabilities">
                        <button *ngIf="capability.Label === cameraCapabilities[2]" mat-button type="button" id="{{capability.Id}}"
                            class="btn actionButton playButton" (click)="this.executeAction(capability.Id)">
                            {{ 'DEVICE.' + capability.Label | translate }}
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="customTable marginTop-32">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <span class="title3">{{ 'DEVICE.ALIASES' | translate }}</span>

            <button mat-button class="btn actionButton" *ngIf="this.isAdmin || this.isDomainAdmin"
                type="button" id="addAliasButton" (click)="addAlias()">
                {{ 'DEVICE.ADD_ALIAS' | translate }}
            </button>
        </div>

        <div class="justify-content-between align-items-start px-4 pt-4 flex-wrap" [ngClass]="aliasesDataSource?.data.length === 0 ? 'd-none' : 'd-flex'">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input class="aliasesData" matInput (keyup)="applyFilter($event, 'aliasesData')"
                    autocomplete="off" [(ngModel)]="aliasesActualFilter">
                <button *ngIf="aliasesActualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString('', 'aliasesData'); aliasesActualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight" *ngIf="aliasesDataSource?.data.length > 0">
            <table [dataSource]="filteredAliasesData | paginate: aliasesConfig"
                mat-table class="w-100 user-select-none noTableHover">
             <ng-container matColumnDef="Alias">
                 <th mat-header-cell *matHeaderCellDef style="width: 95%;"> {{ 'DEVICE.ALIAS' | translate }} </th>
                 <td mat-cell *matCellDef="let alias">{{ alias.Alias }}</td>
             </ng-container>

             <ng-container matColumnDef="Action">
                 <th mat-header-cell *matHeaderCellDef style="width: 5%;"></th>
                 <td mat-cell *matCellDef="let alias">
                     <i matTooltip="{{'GENERAL.DELETE' | translate }}" class="iconDeleteButton urbanicon-delete"
                           (click)="deleteAlias(alias)">
                     </i>
                 </td>
             </ng-container>

             <tr mat-header-row *matHeaderRowDef="aliasesColumns; sticky: true"></tr>
             <tr mat-row *matRowDef="let row; columns: aliasesColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius justify-content-between align-items-center px-4 pt-4 flex-wrap" [ngClass]="aliasesDataSource?.data.length === 0 ? 'd-none' : 'd-flex'">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [id]="'aliasesData'"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event, 'aliasesData')">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{aliasesConfig.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredAliasesData?.length / aliasesConfig.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage('aliasesData')}}
                    {{(filteredAliasesData?.length / aliasesConfig.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="aliasesConfig.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="m-3 py-3" *ngIf="aliasesDataSource?.data.length === 0">
            {{ 'DEVICE.NO_ALIASES_MESSAGE' | translate}}
        </div>
    </div>

    <div class="customTable marginTop-32">
        <div class="customTableRadius d-flex justify-content-between align-items-baseline px-4 pt-4">
            <span class="title3">
                {{ 'DEVICE.CHILDS' | translate }}
            </span>

            <button mat-button class="btn actionButton" *ngIf="this.isAdmin || this.isDomainAdmin" type="button"
                id="addChildButton" (click)="addChild()">
                {{ 'DEVICE.ADD_CHILD' | translate }}
            </button>
        </div>

        <div class="justify-content-between align-items-start px-4 pt-4 flex-wrap" [ngClass]="childsDataSource?.data.length === 0 ? 'd-none' : 'd-flex'">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input class="childsData" matInput (keyup)="applyFilter($event, 'childsData')"
                    autocomplete="off" [(ngModel)]="childsActualFilter">
                <button *ngIf="childsActualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString('', 'childsData'); childsActualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight" *ngIf="childsDataSource?.data.length > 0">
            <table [dataSource]="filteredChildsData | paginate: childsConfig"
                mat-table class="w-100 user-select-none noTableHover">
                <ng-container matColumnDef="Icon">
                    <th mat-header-cell *matHeaderCellDef style="width: 5%;"></th>
                    <td mat-cell *matCellDef="let device">
                        <span id="iconToClick" class="urbanicon-{{ device.Model?.Type?.Icon }}"
                            (click)="openChildStream(device)"></span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef style="width: 90%;"> {{ 'GENERAL.NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let device">
                        <a href="javascript:void(0);" (click)="childDetail(device)">{{ device.Name }}</a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef style="width: 5%;"></th>
                    <td mat-cell *matCellDef="let device">
                        <i matTooltip="{{'GENERAL.DELETE' | translate }}" class="iconDeleteButton urbanicon-delete"
                                (click)="removeFromParent(device)">
                        </i>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="childsColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: childsColumns;"></tr>
            </table>
        </div>

        <div [ngClass]="childsDataSource?.data.length === 0 ? 'd-none' : 'd-flex'" class="customTableRadius justify-content-between align-items-center px-4 pt-4 flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [id]="'childsData'"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event,'childsData')">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{childsConfig.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredChildsData?.length / childsConfig.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage('childsData')}}
                    {{(filteredChildsData?.length / childsConfig.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="childsConfig.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="m-3 py-3" *ngIf="childsDataSource?.data.length === 0">
            {{ 'DEVICE.NO_CHILDS_MESSAGE' | translate}}
        </div>
    </div>

    <div class="customTable marginTop-32">
        <div class="customTableRadius d-flex justify-content-between align-items-baseline px-4 pt-4">
            <span class="title3">
                {{ 'DEVICE.GROUPS' | translate }}
            </span>

            <button mat-button class="btn actionButton" *ngIf="this.isAdmin || this.isDomainAdmin" type="button"
                    id="addDeviceToGroupButton" (click)="addDeviceToGroup()">
                {{ 'DEVICE.ADD_TO_GROUP' | translate }}
            </button>
        </div>

        <div class="justify-content-between align-items-start px-4 pt-4 flex-wrap" [ngClass]="groupsDataSource?.data.length === 0 ? 'd-none' : 'd-flex'">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input class="groupsData" matInput (keyup)="applyFilter($event, 'groupsData')"
                    autocomplete="off" [(ngModel)]="groupsActualFilter">
                <button *ngIf="groupsActualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString('', 'groupsData'); groupsActualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div *ngIf="groupsDataSource?.data.length > 0" class="tableHeight">
            <table [dataSource]="filteredGroupsData | paginate: groupsConfig"
                mat-table class="w-100 user-select-none noTableHover">
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef style="width: 95%;"> {{ 'GENERAL.NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let group">{{ group.Name }}</td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef style="width: 5%;"></th>
                    <td mat-cell *matCellDef="let group">
                        <i matTooltip="{{'GENERAL.DELETE' | translate }}" class="iconDeleteButton urbanicon-delete"
                                (click)="removeFromGroup(group)">
                        </i>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="groupsColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: groupsColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius justify-content-between align-items-center px-4 pt-4 flex-wrap" [ngClass]="groupsDataSource?.data.length === 0 ? 'd-none' : 'd-flex'">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [id]="'groupsData'"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event, 'groupsData')">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{groupsConfig.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredGroupsData?.length / groupsConfig.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage('groupsData')}}
                    {{(filteredGroupsData?.length / groupsConfig.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="groupsConfig.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="m-3 py-3" *ngIf="groupsDataSource?.data.length === 0">
            {{ 'DEVICE.NO_GROUPS_MESSAGE' | translate}}
        </div>
    </div>
</div>
