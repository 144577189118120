<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'SMART_PARKING.TITLE' | translate }}
        </span>
        <span class="btn material-icons-outlined helpButton" (click)="startIntro()">
            live_help
        </span>
    </div>

    <div>
        <mat-tab-group id="intro-parking-tab" animationDuration="0ms" dynamicHeight="true">
            <mat-tab [label]="'GENERAL.STATUS' | translate">
                <div class="tabContent">
                    <div *ngIf="parkingDataSource" class="customTable">
                        <table id="intro-parking-table" class="w-100 h-100" mat-table
                        [dataSource]="parkingDataSource">

                            <ng-container matColumnDef="Created">
                                <th mat-header-cell *matHeaderCellDef style="width: 30%">
                                    {{'HEATMAP.LAST_UPDATE' | translate }}
                                </th>
                                <!-- <td mat-cell *matCellDef="let item"> {{item.Created | date: "short" : "" : "it"}} </td> -->
                                <!-- <td mat-cell *matCellDef="let item"> {{item.Created | date: 'dd:MMM:yyyy hh-mm-ss z'}} </td> -->
                                <td mat-cell *matCellDef="let item"> {{item.Created*1000 | date: 'short'}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef style="width: 20%">
                                    {{'GENERAL.NAME' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item"> {{item.Device.Name}} </td>
                            </ng-container>

                            <ng-container matColumnDef="Free">
                                <th mat-header-cell *matHeaderCellDef style="width: 10%;">
                                    {{'SMART_PARKING.FREE_SLOTS' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item" style="word-break: keep-all;">
                                    {{item.Free + "/" + item.Total}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Total">
                                <th mat-header-cell *matHeaderCellDef style="width: 40%;"></th>
                                <td mat-cell *matCellDef="let item">
                                    <mat-progress-bar mode="determinate" [value]="item.Free * 100 / item.Total">
                                    </mat-progress-bar>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                (click)="goToDeviceDetails(row.Device)">
                            </tr>
                        </table>

                        <div *ngIf="parkingDataSource?.data.length === 0" class="text-center my-5">
                            <h6 class="secondaryTextColor">{{ 'GENERAL.NO_DATA' | translate }}</h6>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab [label]="'GENERAL.MAP' | translate" #mapTab>
                <div class="tabContent">
                    <urban-parking-maps-widget *ngIf="mapTab.isActive && mapReady" class="mapPanel"
                        [latestEvents]="latestParkingEvents" [devicesList]="parkingDevices"
                        [darkThemeActive]="isDarkActive" [passedHeight]="650" [initialZoom]="10"></urban-parking-maps-widget>
                </div>
            </mat-tab>

            <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
                <div class="tabContent">
                    <urban-searchbar id="intro-parking-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
                        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
                    </urban-searchbar>

                    <div class="" *ngIf="alertPanelInput !== undefined">
                        <urban-alert-panel
                            [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
                            [titleText]="alertPanelInput.TitleText | translate" [descriptionText]="alertPanelInput.DescriptionText"
                            [buttonLabel]="isDomainAdmin ? ('DEVICE.VIEW_EVENTS' | translate) : null"
                            (buttonAction)="isDomainAdmin ? goToDomainEvents() : null" (close)="alertPanelInput = undefined">
                        </urban-alert-panel>
                    </div>

                    <div class="roundedPanel panelBG chartContainer" *ngIf="chartsTab.isActive"
                        [ngClass]="loadingData !== undefined ? (loadingData ? 'disappearing-charts' : 'appearing-charts') : 'initial-charts'">
                        <div *ngIf="barchartReadyToShow">
                            <urban-platform-statistics-barchart [barDataPassed]="averageFreeSlotsList" [limitValue]="totalSlotsMax"
                                barChartTitle='SMART_PARKING.AVERAGE_FREE' valuesDescription="SMART_PARKING.SLOTS"
                                barChartError='STATISTICS.NO_CHART_DATA' [darkThemeActive]="this.isDarkActive">
                            </urban-platform-statistics-barchart>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs pt-2" *ngIf="barchartReadyToShow === false">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <div>
                            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                            <div class="d-flex noDataActions">
                                <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                                </button>
                                <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                    {{ 'GENERAL.SHOW_DATA' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
