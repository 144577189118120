import { AdministratorApiService } from 'src/app/shared/services/administrator-api.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RemoveWidgetDialogComponent } from '../../../shared/dialogs/remove-widget-dialog/remove-widget-dialog.component';
import { Device } from '../../../shared/models/device';
import { PositionUpdater } from '../../../shared/models/positionUpdater';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Note } from '../../../shared/models/Note';
import { Location } from '../../../shared/models/location';
import { TranslateService } from '@ngx-translate/core';
import { DeviceType } from 'src/app/shared/models/deviceType';
import { ApiService } from 'src/app/shared/services/api.service';
import { ServiceEventItem, ServiceEventResponse } from 'src/app/shared/models/service/serviceEventResponse';
import { AdvertisingEventsCount, AdvertisingType } from 'src/app/shared/models/advertising/advertising';
import { DashboardManagementDialogComponent } from 'src/app/shared/dialogs/dashboard-management-dialog/dashboard-management-dialog.component';
import { AdvertisingEventsCountResponseItem, AdvertisingsEventsCountsResponse } from 'src/app/shared/models/advertising/advertisingResponse';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { DeviceGroup } from 'src/app/shared/models/deviceGroup';
import { Widget, WidgetToHandle } from 'src/app/shared/models/widget';
import { Dashboard, DashboardRequest } from 'src/app/shared/models/dashboard';
import { AddWidgetToDashboardDialogComponent } from 'src/app/shared/dialogs/add-widget-to-dashboard-dialog/add-widget-to-dashboard-dialog.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { Domain } from 'src/app/shared/models/domain';
import introJs from 'intro.js';

@Component({
  selector: 'urban-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  public draggableWidgetColZero: Widget[] = [];
  public draggableWidgetColOne: Widget[] = [];
  public draggableWidgetColTwo: Widget[] = [];
  public draggableWidgetColThree: Widget[] = [];
  public myWidgetList: Widget[];
  private atLeastDomainAdmin: boolean = false;
  private onlyAdminsWidgetsNames: string[] = ['Device', 'Alert events'];
  public addableWidgets: Widget[];
  public dashboard: Dashboard = null;
  public locations: Location[] = [];
  public currentDomain: Domain;
  public myDevices: Device[];
  public myDevicesTypes: DeviceType[] = [];
  public deviceGroups: DeviceGroup[] = [];
  public myServiceEvents: Record<string, ServiceEventItem[]> = {};
  public advertisingsEventsCounts: AdvertisingEventsCount[] = [];
  public wifiDevices: Device[];
  public mapInDOM: boolean = true;
  public myProfileNotes: Note[];
  public isDarkActive: boolean;
  public dragVisible: boolean = false;
  public dragEnabled: boolean = false;
  public introJS = introJs();
  private updateWidgetPositions: PositionUpdater = {
    DashboardId: '',
    Positions: []
  };
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private AdministratorApiService: AdministratorApiService
  ) {
    this.passDataService.devices$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.myDevices = response;
      this.wifiDevices = this.myDevices?.filter((device: Device) => device.Model.Type.Name === 'wifi');
    });
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.atLeastDomainAdmin = res?.length > 0 && res.some(role => ['Administrators', 'Domain admin'].includes(role.Name));

      if (this.atLeastDomainAdmin) {
        this.AdministratorApiService.deviceGroupList().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
          if (res?.length > 0) {
            this.deviceGroups = res;
          }
        });
      }
    });
    this.passDataService.widgets$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.myWidgetList = response;
    });
    this.passDataService.currentUserNotes$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.myProfileNotes = response;
    });
    this.passDataService.locations$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.locations = response;
    });
    this.passDataService.currentDomain$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domain => {
      if (domain) {
        this.currentDomain = domain;
      }
    });
    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });
  }

  ngOnInit(): void {
    this.passDataService.dashboardToShow$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      const dashSelected = {...res};
      let formattedWidgetList: Widget[] = [];

      if (dashSelected.hasOwnProperty('type')) delete dashSelected.type;
      // questo primo controllo viene messo per evitare che faccia associazioni se (ad esempio dopo una delete) non c'è nessuna dashtoShow
      if (dashSelected) {
        this.draggableWidgetColZero = [];
        this.draggableWidgetColOne = [];
        this.draggableWidgetColTwo = [];
        this.draggableWidgetColThree = [];
        let allDevices = this.getAllDeviceTypes(this.myDevices);

        if (dashSelected?.Widgets?.length > 1) {
          this.dashboard = {
            ...dashSelected,
            Widgets: [...dashSelected.Widgets]
          };
        } else {
          this.dashboard = dashSelected;
        }
        this.dashboard.Widgets ? formattedWidgetList = [...this.dashboard.Widgets] : null

        if (this.myWidgetList) {
          this.addableWidgets = this.myWidgetList.filter(widget =>
            formattedWidgetList?.every(x => x.Id !== widget.Id) &&
            !(!this.atLeastDomainAdmin && this.onlyAdminsWidgetsNames.includes(widget.Name))
          ).sort((a, b) => a.Name.localeCompare(b.Name));
        }

        if (formattedWidgetList?.length > 0) {
          this.updateWidgetPositions.DashboardId = this.dashboard.Id;
          this.groupWidgetByColumn(formattedWidgetList);
          this.loadServiceEvents(formattedWidgetList, allDevices);
        }
      }
      this.mapControl();
    });
  }

  private getAllDeviceTypes(devices: Device[]): Device[] {
    let allLevelsDevices: Device[] = [];

    devices.forEach((singleDevice: Device) => {
      allLevelsDevices.push(singleDevice);
      if(singleDevice.Childs?.length > 0) {
        allLevelsDevices.push(...this.getAllDeviceTypes(singleDevice.Childs));
      }

      if(!this.myDevicesTypes.some(x => x.Id === singleDevice.Model.Type.Id)) {
        this.myDevicesTypes.push(singleDevice.Model.Type);
      }
    });

    return allLevelsDevices;
  }

  public loadServiceEvents(formattedWidgetList: Widget[], allDevices: Device[]): void {
    let requiredTypes: string[] = [];

    let serviceWidgets: Widget[] = formattedWidgetList.filter(w => w.Name.includes('-service'));
    requiredTypes = serviceWidgets.map(widget => {
      const name = widget.Name.split('-service')[0]
      return name.toLowerCase();
    });

    if (requiredTypes.length > 0) {
      this.apiService.getDeviceEventLatest24hoursByServicesTypeName(requiredTypes).subscribe((res: ServiceEventResponse) => {
        if (res?.Items) {
          this.myServiceEvents = this.groupServiceEventData(res.Items, allDevices);
        }
      });
    }

    this.loadMarketingData();
  }

  private loadMarketingData(): void {
    let advTypesApi: number, advCountsApi: number;
    let marketingContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, marketingContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues.every(value => value)) {
        this.advertisingsEventsCounts = data[advCountsApi].map((item: AdvertisingEventsCountResponseItem) => {
          return {
            AdvertisingId: item.AdvertisingId,
            AdvertisingType: data[advTypesApi].find((type: AdvertisingType) => type.Id === item.Type)?.Name,
            Count: item.Count
          };
        });
      }
    }, marketingContext);

    this.apiService.getAdvertisingTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res && res.Items?.length > 0) {
        advTypesApi = this.apiSync.loadedFeatureWithData(res.Items, marketingContext);
      }
      else {
        advTypesApi = this.apiSync.failedFeature(marketingContext);
      }
    });

    this.apiService.getAdvertisingsEventsCounts().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: AdvertisingsEventsCountsResponse) => {
        if (res?.Items?.length > 0) {
          advCountsApi = this.apiSync.loadedFeatureWithData(res.Items, marketingContext);
        }
        else {
          advCountsApi = this.apiSync.failedFeature(marketingContext);
        }
      });
  }

  private groupServiceEventData(events: ServiceEventItem[], allDevices: Device[]): Record<string, ServiceEventItem[]> {
    const eventsWithDevice = events.filter(event => allDevices.find(device => device.Id === event.Id));
    let formattedEvents: Record<string, ServiceEventItem[]> = {};

    eventsWithDevice.forEach((event) => {
      const device = allDevices.find(device => device.Id === event.Id);
      const type = device.Model.Type.Name;

      if (!formattedEvents[type]) {
        formattedEvents[type] = [];
      }

      formattedEvents[type].push(event);
    });

    return formattedEvents;
  }

  private groupWidgetByColumn(formattedWidgetList: Widget[]): void {
    const COL_ZERO = 0;
    const COL_ONE = 1;
    const COL_TWO = 2;
    const COL_THREE = 3;
    let widgetPositionChange: Record<string, number> = {};

    const [colZeroList, colOneList, colTwoList, colThreeList] = formattedWidgetList.reduce(
      (acc, widget) => {
        if ((widget.Name === 'Device' || widget.Name === 'Imx monitor') && this.myDevicesTypes.length <= 4 && widget?.Column === 0) {
          let shortestColArray: Widget[] = this.getShortestCol();
          let shortestCol: number = shortestColArray === this.draggableWidgetColOne ? COL_ONE : shortestColArray === this.draggableWidgetColTwo ? COL_TWO : COL_THREE;

          acc[shortestCol].push(widget);

          widgetPositionChange[widget.Id] = shortestCol;
        } else if ((widget.Name === 'Device' || widget.Name === 'Imx monitor') && this.myDevicesTypes.length > 4 && widget?.Column !== 0) {
          acc[COL_ZERO].push(widget);

          widgetPositionChange[widget.Id] = COL_ZERO;
        } else {
          if (widget?.Column === COL_ZERO) acc[COL_ZERO].push(widget);
          else if (widget?.Column === null) acc[COL_ONE].push(widget);
          else if (widget?.Column === COL_ONE) acc[COL_ONE].push(widget);
          else if (widget?.Column === COL_TWO) acc[COL_TWO].push(widget);
          else if (widget?.Column === COL_THREE) acc[COL_THREE].push(widget);
        }

        return acc;
      },
      [[], [], [], []]
    );

    [colZeroList, colOneList, colTwoList, colThreeList].forEach(list => {
      list.sort((a, b) => a.Position - b.Position);
    });

    this.draggableWidgetColZero = colZeroList;
    this.draggableWidgetColOne = colOneList;
    this.draggableWidgetColTwo = colTwoList;
    this.draggableWidgetColThree = colThreeList;

    if (Object.keys(widgetPositionChange)?.length > 0) {
      this.updateExpandableWidgetPosition(widgetPositionChange);
    }
  }

  private updateExpandableWidgetPosition(widgetPositionChange: Record<string, number>): void {
    let columnsArray = [
      this.draggableWidgetColZero,
      this.draggableWidgetColOne,
      this.draggableWidgetColTwo,
      this.draggableWidgetColThree
    ];

    Object.entries(widgetPositionChange).forEach(([widgetId, newCol]) => {
      const maxPositionWidget = columnsArray[newCol].find(widget => widget.Id !== widgetId && widget?.Position === Math.max(widget?.Position));
      const updatedPositions = {
        WidgetId: widgetId,
        Position: maxPositionWidget?.Position ? maxPositionWidget.Position + 1 : 1,
        Column: newCol
      };

      this.updateWidgetPositions.Positions.push(updatedPositions);
    });

    this.mainService.updateWidgetPositionComand(this.updateWidgetPositions);
    this.updateWidgetPositions.Positions = [];
  }

  public updateAllData(): void {
    this.mainService.updateAllDataAndGoToMainComand();
  }

  public createDashboard(): void {
    this.passDataService.currentDomain$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        const dialogRef = this.dialog.open(DashboardManagementDialogComponent, {
          data: {
            currentDomain: res.Name,
            widgetList: this.myWidgetList
          },
          width: '527px'
        });

        dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.mainService.createDashboardComand(result);
          }
        });
      }
    })
  }

  public addWidgets(widgets: Widget[]): void {
    let widgetsToAdd: WidgetToHandle[] = [];
    let assignedColumn: number = 1;
    let backupColumn: number = null;
    let widgetPositions: { [key: number]: number } = {};

    if (widgets.length > 1) { // case multiple widgets added
      widgets.forEach((widget) => {
        if (widget.Name === "Device" || widget.Name === "Imx Monitor") {
          if (this.myDevicesTypes.length <= 4 && widget.Name === "Device") {
            assignedColumn = assignedColumn % 3 + 1;
          } else {
            backupColumn = assignedColumn;
            assignedColumn = 0;
          }
        }

        let widgetPositionInColumn = (widgetPositions[assignedColumn] || 0) + 1;
        widgetPositions[assignedColumn] = widgetPositionInColumn;

        let widgetToAdd: WidgetToHandle = {
          dashboardId: this.dashboard.Id,
          widgetId: widget.Id,
          widgetPosition: widgetPositionInColumn,
          widgetColumn: assignedColumn,
          widgetConfig: widget.Configuration ?? ''
        };

        widgetsToAdd.push(widgetToAdd);

        if (backupColumn) {
          assignedColumn = backupColumn;
          backupColumn = null;
        } else {
          assignedColumn = assignedColumn % 3 + 1;
        }
      });
    } else if (widgets.length === 1) { // case single widget added
      let shortestCol = this.getShortestCol();

      if(widgets[0].Name === "Device" || widgets[0].Name === "Imx Monitor") {
        if (this.myDevicesTypes.length <= 4 && widgets[0].Name === "Device") {
          assignedColumn = shortestCol === this.draggableWidgetColOne ? 1 : shortestCol === this.draggableWidgetColTwo ? 2 : 3;
        } else {
          shortestCol = this.draggableWidgetColZero;
          assignedColumn = 0;
        }
      } else {
        assignedColumn = shortestCol === this.draggableWidgetColOne ? 1 : shortestCol === this.draggableWidgetColTwo ? 2 : 3;
      }

      widgetsToAdd[0] = {
        dashboardId: this.dashboard.Id,
        widgetId: widgets[0].Id,
        widgetPosition: shortestCol[shortestCol.length - 1]?.Position ? shortestCol[shortestCol.length - 1].Position + 1 : 1,
        widgetColumn: assignedColumn,
        widgetConfig: widgets[0].Configuration ?? ''
      }
    }

    this.mainService.addWidgetsComand(widgetsToAdd);
  }

  private getShortestCol(): Widget[] {
    let shortestCol: Widget[];

    let minLength = Math.min(
      this.draggableWidgetColOne.length,
      this.draggableWidgetColTwo.length,
      this.draggableWidgetColThree.length
    );

    if (this.draggableWidgetColOne.length === minLength) {
      shortestCol = this.draggableWidgetColOne;
    } else if (this.draggableWidgetColTwo.length === minLength) {
      shortestCol = this.draggableWidgetColTwo;
    } else {
      shortestCol = this.draggableWidgetColThree;
    }

    return shortestCol;
  }

  public addWidgetToDashboardDialog() {
    const addWidgetDialogRef = this.dialog.open(AddWidgetToDashboardDialogComponent, {
      data: {
        addableWidgets: this.addableWidgets
      },
      width: '388px'
    });

    addWidgetDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(selectedWidget => {
      if (selectedWidget) {
        this.addWidgets(selectedWidget);
      }
    });
  }

  public removeWidgetDialog(widget: string): void {
    const dialogRef = this.dialog.open(RemoveWidgetDialogComponent, {
      data: {widgetToRemove: widget}
    });

    dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        const widgetSelected: Widget = this.myWidgetList.find(widget => widget.Id === result);

        if (widgetSelected) {
          let widgetToDelete = {
            dashboardId: this.dashboard.Id,
            widgetId: widgetSelected.Id,
            widgetPosition: widgetSelected.Position,
            widgetColumn: widgetSelected.Column,
            widgetConfig: ''
          }

          this.mainService.removeWidgetComand(widgetToDelete);

          if (this.dashboard?.Widgets.length === 1 && widgetToDelete.widgetId === this.dashboard?.Widgets[0].Id) {
            this.updateAllData();
          }
        }
      }
    });
  }

  public onMouseDown(): void {
    this.dragEnabled = true;
  }

  public drop(event: CdkDragDrop<Widget[]>): void {
    if (this.dragEnabled) {
      let movedWidget: Widget = event.previousContainer.data[event.previousIndex];
      let cols = [this.draggableWidgetColOne, this.draggableWidgetColTwo, this.draggableWidgetColThree];
      let sendUpdate: boolean = false;

      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        if (event.previousIndex !== event.currentIndex) {
          sendUpdate = true;
        }
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );

        sendUpdate = true;
      }

      this.dragEnabled = false;

      if (sendUpdate) {
        let targetCol = cols.find(array => array.find(widget => widget.Id === movedWidget.Id));

        if (targetCol) {
          const index = cols.indexOf(targetCol) + 1;
          this.updateWidgetPosition(movedWidget, index);
        }
      }
    }
  }

  private updateWidgetPosition(movedWidget: Widget, newCol: number): void {
    const draggableWidgetColumns = [
      this.draggableWidgetColZero,
      this.draggableWidgetColOne,
      this.draggableWidgetColTwo,
      this.draggableWidgetColThree
    ];

    Object.keys(draggableWidgetColumns).forEach(col => {
      const updatedPositions = draggableWidgetColumns[col].map((widget, index) => ({
        WidgetId: widget.Id,
        Position: index + 1,
        Column: widget.Id === movedWidget.Id ? newCol : widget.Column
      }));

      this.updateWidgetPositions.Positions.push(...updatedPositions);
    });

    this.mainService.updateWidgetPositionComand(this.updateWidgetPositions);
    this.updateWidgetPositions.Positions = [];
  }

  public toggleWidgetPositioning(): void {
    this.dragVisible = !this.dragVisible;
  }

  public editCurrentDashboard(): void {
    const dialogRef = this.dialog.open(DashboardManagementDialogComponent, {
      data: {
        dashboard: this.dashboard,
        DevicesTypes: this.myDevicesTypes,
        currentDomain: this.currentDomain.Name ?? '',
        widgetList: this.myWidgetList
      },
      width: '527px'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((request: DashboardRequest) => {
      if (request) {
        this.mainService.updateAndReInitDashboardComand(request);
      }
    });
  }

  public mapControl(): void {
    this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
      this.mapInDOM = mapLoading;
    });
  }

  public startIntro(): void {
    this.translate.get([
      'INTRO.DASHBOARD_WELCOME',
      'INTRO.DASHBOARD_SIDEBAR',
      'INTRO.HEADER_TOGGLER',
      'INTRO.DASHBOARD_DASHBOARD',
      'INTRO.DASHBOARD_WIDGETS',
      'INTRO.HEADER_DASHBOARD',
      'INTRO.HEADER_PROFILE',
      'INTRO.HEADER_LANGUAGE',
      'INTRO.HEADER_SETTINGS'
    ])
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(intros => {
      this.introJS
        .setOptions({
          steps: [
            {
              title: 'Welcome',
              intro: intros['INTRO.DASHBOARD_WELCOME']
            },
            {
              title: 'Sidebar',
              element: '#intro-sidenav',
              intro: intros['INTRO.DASHBOARD_SIDEBAR'],
              position: 'right'
            },
            {
              title: 'Sidebar',
              element: '#intro-header-toggler',
              intro: intros['INTRO.HEADER_TOGGLER'],
              position: 'right'
            },
            {
              title: 'Dashboard',
              //intro: "Questa che hai creato è una dashboard. Puoi personalizzarla come vuoi sfruttando i widget",
              intro: intros['INTRO.DASHBOARD_DASHBOARD'],
              position: 'right'
            },
            {
              title: 'Widgets',
              element: '#intro-settings',
              intro: intros['INTRO.DASHBOARD_WIDGETS'],
              //intro: "Da qui, nei settaggi della dashboard, puoi accedere alla lista di widget. Grazie a essi puoi tenere traccia rapidamente delle informazioni che preferisci",
              position: 'bottom'
            },
            {
              title: 'Header',
              element: '#intro-header-dashboard',
              intro: intros['INTRO.HEADER_DASHBOARD'],
              //intro: "Sfoglia le tue dashboard",
              position: 'bottom'
            },
            {
              title: 'Header',
              element: '#intro-header-settings',
              intro: intros['INTRO.HEADER_SETTINGS'],
              position: 'bottom'
            },
            {
              title: 'Header',
              element: '#intro-header-profile',
              intro: intros['INTRO.HEADER_PROFILE'],
              //intro: "Accedi alle impostazioni del tuo account",
              position: 'bottom'
            }
          ],
          showProgress: true
        })
        .start();
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
