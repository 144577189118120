<div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center mx-0 pageTop flex-wrap">
        <span class="title2">
            {{ 'GENERAL.USER' | translate }} - {{currentUser?.Firstname}} {{currentUser?.Lastname}}
        </span>

        <div>
            <button mat-button class="btn warnButton mr-3 mb-2 mb-sm-0" id="iconDeleteButton" type="button" (click)="delete()">
                {{ 'ADMINISTRATION.DELETE' | translate }}
            </button>
        
            <button mat-button class="btn warnButton" id="iconDeleteButton" type="button" (click)="hardDelete()">
                {{ 'ADMINISTRATION.HARDDELETE' | translate }}
            </button>
        </div>
    </div>

    <div class="userInfo panelBG">
        <form [formGroup]="form">
            <div class="d-flex justify-content-between align-items-start infoCol">
                <div class="col-4 customCol pl-0 userPropic d-flex flex-column">
                    <span class="subheadText">{{ 'GENERAL.PROFILE_PICTURE' | translate }}</span>

                    <div class="d-flex justify-content-center pr-4 align-items-center">
                        <img *ngIf="profileImage" [src]="profileImage" alt="Profile Image">
                        <img *ngIf="!profileImage" src="assets/img/default-profile.png" alt="Profile Image">
                    </div>
                </div>

                <div class="col-8 customColTwo d-flex flex-column">
                    <div class="d-flex justify-content-between align-items-center mb-4 flex-wrap">
                        <span class="title3 secondaryTextColor">{{ 'GENERAL.INFO' | translate }}</span>

                        <div>
                            <button type="button" mat-button class="btn actionButton" *ngIf="editMode" (click)="saveInfo()" [disabled]="!dataChanged">
                                {{ 'GENERAL.SAVE' | translate }}
                            </button>

                            <button type="button" mat-button class="btn actionButton" *ngIf="!editMode" (click)="enableEdit()">
                                {{ 'GENERAL.EDIT' | translate }}
                            </button>

                            <button  type="button" mat-button class="btn outlineActionButton ml-0 cancelBtn mt-2 mt-sm-0" *ngIf="editMode" (click)="cancelEdit()">
                                {{ 'GENERAL.CANCEL' | translate }}
                            </button>
                        </div>
                    </div>

                    <div class="d-flex">
                        <div class="col-6 pl-0 d-flex flex-column">
                            <mat-form-field appearance="outline" class="customMatFormField mb-3" [ngClass]="{'noUnderline': !editMode}">
                                <mat-label class="secondaryTextColor paragraphText">{{ 'ADMINISTRATION.USERNAME' | translate }}</mat-label>
                                <input matInput formControlName="username" autocomplete="off" [readonly]="!editMode" [required]="editMode" class="paragraphText">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="customMatFormField mb-3" [ngClass]="{'noUnderline': !editMode}">
                                <mat-label class="secondaryTextColor paragraphText">{{ 'ADMINISTRATION.FIRSTNAME' | translate }}</mat-label>
                                <input matInput formControlName="firstname" autocomplete="off" [readonly]="!editMode" [required]="editMode" class="paragraphText">
                            </mat-form-field>

                            <div>
                                <mat-form-field appearance="outline" class="customMatFormField mb-3" [ngClass]="{'noUnderline': !editMode}" *ngIf="!editMode">
                                    <mat-label>{{ 'PROFILE.PHONE' | translate }}</mat-label>
                                    <input matInput [readonly]="!editMode"  class="paragraphText" [value]="currentUser?.Properties['PhoneNumber'] ?? ''">
                                </mat-form-field>

                                <div *ngIf="editMode" class="d-flex flex-wrap">
                                    <mat-form-field appearance="outline" class="customMatFormField col-6 p-0 pr-1 phonePrefix">
                                        <mat-label>{{ 'PROFILE.PREFIX' | translate }}</mat-label>
                                        <mat-select formControlName="prefix" [required]="editMode" hideSingleSelectionIndicator="true">
                                            <mat-option *ngFor="let prefix of myPrefixesList" [value]="prefix.Code">
                                                <span class="flag-icon flag-icon-{{ prefix.Flag }}"></span>
                                                {{ prefix.Country }}
                                                {{ "+" + prefix.Code }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" class="customMatFormField col-6 p-0 pl-1 phone">
                                        <mat-label>{{ 'PROFILE.PHONE' | translate }}</mat-label>
                                        <input type="number" matInput formControlName="telephone" [readonly]="!editMode" [required]="editMode" class="paragraphText" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 p-0 d-flex flex-column">
                            <mat-form-field appearance="outline" class="customMatFormField mb-3" [ngClass]="{'noUnderline': !editMode}">
                                <mat-label class="secondaryTextColor paragraphText">{{ 'ADMINISTRATION.EMAIL' | translate }}</mat-label>
                                <input matInput formControlName="email" autocomplete="off" [readonly]="!editMode" [required]="editMode" class="paragraphText">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="customMatFormField mb-3" [ngClass]="{'noUnderline': !editMode}">
                                <mat-label class="secondaryTextColor paragraphText">{{ 'ADMINISTRATION.LASTNAME' | translate }}</mat-label>
                                <input matInput formControlName="lastname" autocomplete="off" [readonly]="!editMode" [required]="editMode" class="paragraphText">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="customTableRadius panelAccentBG w-100 mt-5">
        <div class="d-flex customTableRadius justify-content-between align-items-baseline px-4 pt-4 w-100 flex-wrap">
            <span class="h5 mb-1 mt-1">
                {{ 'ADMINISTRATION.AUTHORIZATIONS' | translate }}
            </span>

            <button mat-button class="btn actionButton" type="button"
                id="addChildButton" (click)="addAuthorization()">
                {{ 'ADMINISTRATION.ADD_AUTHORIZATION' | translate }}
            </button>
        </div>

        <div class="justify-content-between align-items-start px-4 pt-4 flex-wrap" [ngClass]="authorizations?.length === 0 ? 'd-none' : 'd-flex'">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight" *ngIf="authorizations?.length > 0">
            <table class="w-100 noTableHover panelAccentBG" mat-table [dataSource]="filteredData | paginate: config">
                <ng-container matColumnDef="Domain">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADMINISTRATION.DOMAIN' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Domain.Name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Role">
                    <th mat-header-cell *matHeaderCellDef> {{ 'ADMINISTRATION.ROLE' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.Role.Name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 8%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i matTooltip="{{'GENERAL.REMOVE' | translate }}" (click)="deauthorize(element.Domain.Id, element.Role.Id)"
                                    class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedUserDetailColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedUserDetailColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius text-center py-5" *ngIf="authorizations?.length === 0">
            {{ 'USER.NO_AUTHORIZATIONS_MESSAGE' | translate}}
        </div>

        <div class="customTableRadius justify-content-between align-items-center px-4 pt-4 flex-wrap" [ngClass]="authorizations?.length === 0 ? 'd-none' : 'd-flex'">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField panelAccentBG" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>