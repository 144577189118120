<div class="container-fluid px-1 px-md-3" [ngClass]="{'h-100' : (noDataIn24h && !isASingleDevice) || noDataInDate}">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span class="title2">{{'HEATMAP_PEOPLE_COUNTER.TITLE' | translate }}</span>
            <span *ngIf="singlePeopleCounterDevice" class="title2 appearingTextRight">
                {{' - ' + singlePeopleCounterDevice.Name}}
            </span>
        </div>

        <span *ngIf="isASingleDevice" class="btn material-icons-outlined helpButton" (click)="startIntro()">
            live_help
        </span>
    </div>

    <div *ngIf="isASingleDevice">
        <urban-searchbar id="intro-people-counter-search" [passedMaxDaysRange]="6" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
            (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="buttonToggleSelected" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
        </urban-searchbar>

        <div class="" *ngIf="alertPanelInput !== undefined">
            <urban-alert-panel
                [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
                [titleText]="alertPanelInput.TitleText | translate" [buttonLabel]="'DEVICE.VIEW_EVENTS' | translate"
                [descriptionText]="alertPanelInput.DescriptionText + (firstUsefulDate ? (' ' + (firstUsefulDate | date: 'short') ): '')"
                (buttonAction)="goToDeviceEvents()" (close)="alertPanelInput = undefined">
            </urban-alert-panel>
        </div>

        <div class="ml-0 ml-xl-2">
            <mat-slide-toggle color="primary" (change)="slideOnToggle()" [(ngModel)]="isChecked">{{ 'HEATMAP_PEOPLE_COUNTER.AUTO.REFRESH' | translate }}</mat-slide-toggle>
        </div>

        <div *ngIf="chartReadyToShow" class="roundedPanel panelBG chartContainer">
            <urban-google-linechart [sensor]="myPCSensor" maxValueDescription="LINECHART.ACCEPTABLE_LIMIT" valueDescription="LINECHART.SENSOR_VALUE"
                [startDate]="startDate" [endDate]="endDate"
                [darkThemeActive]="this.isDarkActive"></urban-google-linechart>
        </div>

        <div class="d-flex flex-column align-items-center justify-content-center noDataIllustration responsiveIllustration" *ngIf="noDataInDate || noDataIn24h">
            <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
            <h2>{{ (last24hSearch ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
            <div class="d-flex">
                <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                </button>
                <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                    {{ 'GENERAL.SHOW_DATA' | translate }}
                </button>
            </div>
        </div>
    </div>

    <br>

    <div *ngIf="readyToShow">
        <urban-heatmap-people-maps [heatmapElements]='pcHeatmapElements' [maxValueHeatmap]='peopleCounterMaxValue'
            [passedLastUpdate]='lastUpdate' [darkModeStatus]="isDarkActive"></urban-heatmap-people-maps>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center noDataIllustration responsiveIllustration" *ngIf="noDataIn24h && isASingleDevice === false">
        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <h2>{{ 'HEATMAP_WEATHER.NO_DATA' | translate }}</h2>
        <div>
            <button type="button" class="btn outlineActionButton" (click)="goToMain()">{{ 'GENERAL.RETURN_DASHBOARD' | translate }}</button>
        </div>
    </div>
</div>
