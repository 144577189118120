<div class="container-fluid h-100" [ngClass]="{'h-100' : (dashboard | json) == '{}' || draggableWidgetColOne.length === 0}">
    <div class="row d-flex justify-content-between mx-0 dashboardInfo"
        *ngIf="(dashboard | json) !== '{}'">
        <span class="title2 text-break">
            {{dashboard?.Name}}
        </span>

        <div class="d-flex">
            <div>
                <button type="button" mat-button class="btn outlineActionButton customizeMenu mr-2" 
                        [matMenuTriggerFor]="dashboardActions" #menuTrigger="matMenuTrigger" *ngIf="!dragVisible"
                        [ngClass]="{'active': menuTrigger.menuOpen}">
                    <div class="d-flex align-items-center justify-content-between">
                        <span class="mr-3">
                            <i class="urbanicon-customize"></i>
                            <span class="linkText">{{ 'DASHBOARD.CUSTOMIZE' | translate }}</span>
                        </span>

                        <i class="pb-1" [ngClass]="menuTrigger.menuOpen ? 'urbanicon-expand-less' : 'urbanicon-expand-more'"></i>
                    </div>
                </button>
                
                <mat-menu #dashboardActions="matMenu">
                    <button mat-menu-item (click)="toggleWidgetPositioning()" [disabled]="!dashboard.Widgets.length">
                        <span class="linkText">{{ 'DASHBOARD.MOVE_WIDGET' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="editCurrentDashboard()">
                        <span class="linkText">{{ 'DASHBOARD.EDIT' | translate }}</span>
                    </button>
                </mat-menu>

                <button type="button" class="btn actionButton mr-2" (click)="toggleWidgetPositioning()" *ngIf="dragVisible">{{ 'DASHBOARD.COMPLETE_EDITS' | translate }}</button>
            </div>

            <button type="button" class="btn outlineActionButton tutorialBtn" (click)="startIntro()">
                <i class="urbanicon-manual"></i>
            </button>
        </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center noDashboard" *ngIf="(dashboard | json) == '{}'">
        <img [src]="isDarkActive ? '/assets/img/noDashboardDark.png' : '/assets/img/noDashboardLight.png'" alt="no dashboard"/>
        <h4>{{ 'DASHBOARD.NO_DASHBOARD' | translate }}</h4>
        <p>{{ 'DASHBOARD.DASHBOARD_ADD' | translate }}</p>
        <button type="button" class="btn outlineActionButton" (click)="createDashboard()">{{ 'DASHBOARD.ADD_DASHBOARD_BUTTON' | translate }}</button>
    </div>

    <div class="row" cdkDropListGroup *ngIf="dashboard && (draggableWidgetColOne?.length > 0 || draggableWidgetColTwo?.length > 0 || draggableWidgetColZero?.length > 0)">
        <div class="col-12" *ngFor="let widget of draggableWidgetColZero">
            <ng-container [ngTemplateOutlet]="allWidgets" [ngTemplateOutletContext]="{ $implicit: widget }"></ng-container>
        </div>

        <div class="col-4 expandableColumn">
            <div cdkDropList [cdkDropListData]="draggableWidgetColOne" class="h-100"
                (cdkDropListDropped)="drop($event)">
                <div *ngFor="let widget of draggableWidgetColOne" cdkDrag [cdkDragDisabled]="!dragEnabled">
                    <ng-container [ngTemplateOutlet]="allWidgets" [ngTemplateOutletContext]="{ $implicit: widget }"></ng-container>
                </div>
            </div>
        </div>

        <div class="col-4 expandableColumn">
            <div cdkDropList [cdkDropListData]="draggableWidgetColTwo" class="h-100"
                (cdkDropListDropped)="drop($event)">
                <div *ngFor="let widget of draggableWidgetColTwo" cdkDrag [cdkDragDisabled]="!dragEnabled">
                    <ng-container [ngTemplateOutlet]="allWidgets" [ngTemplateOutletContext]="{ $implicit: widget }"></ng-container>
                </div>
            </div>
        </div>

        <div class="col-4 expandableColumn">
            <div cdkDropList [cdkDropListData]="draggableWidgetColThree" class="h-100"
                (cdkDropListDropped)="drop($event)">
                <div *ngFor="let widget of draggableWidgetColThree" cdkDrag [cdkDragDisabled]="!dragEnabled">
                    <ng-container [ngTemplateOutlet]="allWidgets" [ngTemplateOutletContext]="{ $implicit: widget }"></ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column justify-content-center align-items-center emptyDashboard responsiveIllustration"
        *ngIf="dashboard?.Id && (draggableWidgetColZero?.length === 0) && (draggableWidgetColOne?.length === 0) && (draggableWidgetColTwo?.length === 0) && (draggableWidgetColThree?.length === 0)">
        <img [src]="isDarkActive ? '/assets/img/dashboardEmptyDark.png' : '/assets/img/dashboardEmptyLight.png'" alt="Dashboard empty"/>
        <h4>{{ 'DASHBOARD.NO_WIDGET' | translate }}</h4>
        <p>{{ 'DASHBOARD.ADD_WIDGET_TEXT' | translate }}</p>
        <button type="button" mat-button class="btn outlineActionButton" (click)="addWidgetToDashboardDialog()">{{ 'DASHBOARD.ADD_WIDGET' | translate }}</button>
    </div>
</div>

<!-- Widgets -->
<ng-template #allWidgets let-widget>
    <ng-container [ngTemplateOutlet]="deviceWidget" *ngIf="widget.Name === 'Device'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="imxMonitorWidget" *ngIf="widget.Name === 'Imx monitor'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="alertWidget" *ngIf="widget.Name === 'Alert events'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="mapsWidget" *ngIf="widget.Name === 'Maps'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="notesWidget" *ngIf="widget.Name === 'Notes'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="geolocationWidget" *ngIf="widget.Name === 'Geolocation'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="streamingWidget" *ngIf="widget.Name === 'Streaming'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="trafficWidget" *ngIf="widget.Name === 'Traffic-service'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="weatherWidget" *ngIf="widget.Name === 'Weather-service'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="crosswalkWidget" *ngIf="widget.Name === 'Crosswalk-service'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="marketingWidget" *ngIf="widget.Name === 'Marketing'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="trashBinWidget" *ngIf="widget.Name === 'Trash-bin-service'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="wifiWidget" *ngIf="widget.Name === 'Wifi'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="EventsRejectedWidget" *ngIf="widget.Name === 'Events rejected'"
        [ngTemplateOutletContext]="{ $implicit: widget }">
    </ng-container>
</ng-template>

<ng-template #alertWidget let-widget>
    <mat-card class="dashboardWidgetCard" *ngIf="widget.Name === 'Alert events'">
        <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
            <i class="urbanicon-drag"></i>
        </div>
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{widget.Name}}</h3>
        <div>
            <urban-alert-events-widget></urban-alert-events-widget>
        </div>
    </mat-card>
</ng-template>

<ng-template #mapsWidget let-widget>
    <mat-card class="mat-elevation-z3 dashboardWidgetCard d-flex flex-row align-items-stretch" *ngIf="widget.Name == 'Maps'">
        <div id="squareExtender"></div>
        <div class="w-100 d-flex flex-column">
            <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
                <i class="urbanicon-drag"></i>
            </div>
            <div class="remover">
                <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
            </div>
            <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{widget.Name}}</h3>
            <div *ngIf="mapInDOM" class="flex-grow-1">
                <urban-maps-widget [devicesList]="myDevices" [darkModeStatus]="isDarkActive" class="mapPanel" [initialZoom]="10" [deviceGroupList]="deviceGroups"
                    [passedHeight]="0" [asWidget]="true">
                </urban-maps-widget>
            </div>
        </div>
    </mat-card>
</ng-template>

<ng-template #notesWidget let-widget>
    <mat-card class="mat-elevation-z0 dashboardWidgetCard" *ngIf="widget.Name == 'Notes'">
        <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
            <i class="urbanicon-drag"></i>
        </div>
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{widget.Name}}</h3>
        <div>
            <urban-note-widget [notesList]='myProfileNotes'></urban-note-widget>
        </div>
    </mat-card>
</ng-template>

<ng-template #deviceWidget let-widget>
    <mat-card class="mat-elevation-z3 dashboardWidgetCard" *ngIf="widget.Name == 'Device'" id="device">
        <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="widget.Column !== 0 && dragVisible">
            <i class="urbanicon-drag"></i>
        </div>
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 class="title3 position-absolute" [ngClass]="{
            'cardTitle': widget.Column !== 0 && dragVisible,
            'cardTitleNoDrag': widget.Column !== 0 && !dragVisible,
            'mt-2 marginFix': widget.Column === 0}">{{widget.Name}}</h3>
        <div>
            <urban-search-device-widget [devicesList]="myDevices" [widgetInColumn]="widget.Column !== 0"></urban-search-device-widget>
        </div>
    </mat-card>
</ng-template>

<ng-template #geolocationWidget let-widget>
    <mat-card class="mat-elevation-z3 dashboardWidgetCard" *ngIf="widget.Name == 'Geolocation'">
        <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
            <i class="urbanicon-drag"></i>
        </div>
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{widget.Name}}</h3>
        <div>
            <urban-around-me-widget [darkModeStatus]="isDarkActive"></urban-around-me-widget>
        </div>
    </mat-card>
</ng-template>

<ng-template #streamingWidget let-widget>
    <mat-card class="mat-elevation-z3 dashboardWidgetCard" *ngIf="widget.Name == 'Streaming'">
        <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
            <i class="urbanicon-drag"></i>
        </div>
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{widget.Name}}</h3>
        <div>
            <urban-streaming-widget [devicesList]="myDevices"></urban-streaming-widget>
        </div>
    </mat-card>
</ng-template>

<ng-template #imxMonitorWidget let-widget>
    <mat-card class="mat-elevation-z0 dashboardWidgetCard" *ngIf="widget.Name === 'Imx monitor'">
        <!-- <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
            <i class="urbanicon-drag"></i>
        </div> -->
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 class="title3 position-absolute ml-3">{{ 'IMX_CAMERA_WIDGET.TITLE' | translate }}</h3>
        <span class="CustomMatCardSubTitle subheadText position-absolute">
            {{ 'IMX_CAMERA_WIDGET.IMX_MONITOR' | translate }}
        </span>
        <div>
            <urban-imx-camera-widget></urban-imx-camera-widget>
        </div>
    </mat-card>
</ng-template>

<ng-template #trafficWidget let-widget>
    <mat-card class="dashboardWidgetCard" *ngIf="widget.Name === 'Traffic-service'">
        <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
            <i class="urbanicon-drag"></i>
        </div>
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{ widget.Name?.split('-')[0] }}</h3>
        <div>
            <urban-traffic-widget [darkModeStatus]="isDarkActive" [trafficEvents]="myServiceEvents['traffic-monitor']"></urban-traffic-widget>
        </div>
    </mat-card>
</ng-template>

<ng-template #weatherWidget let-widget>
    <mat-card class="dashboardWidgetCard" *ngIf="widget.Name === 'Weather-service'">
        <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
            <i class="urbanicon-drag"></i>
        </div>
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{ widget.Name?.split('-')[0] }}</h3>
        <div>
            <urban-weather-widget [weatherEvents]="myServiceEvents['weatherstation']"></urban-weather-widget>
        </div>
    </mat-card>
</ng-template>

<ng-template #crosswalkWidget let-widget>
    <mat-card class="dashboardWidgetCard" *ngIf="widget.Name === 'Crosswalk-service'">
        <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
            <i class="urbanicon-drag"></i>
        </div>
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{ widget.Name?.split('-')[0] }}</h3>
        <div>
            <urban-crosswalk-widget [darkModeStatus]="isDarkActive" [crosswalkEvents]="myServiceEvents['crosswalk-monitor']"></urban-crosswalk-widget>
        </div>
    </mat-card>
</ng-template>

<ng-template #marketingWidget let-widget>
    <mat-card class="dashboardWidgetCard" *ngIf="widget.Name === 'Marketing'">
        <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
            <i class="urbanicon-drag"></i>
        </div>
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{ widget.Name?.split('-')[0] }}</h3>
        <div>
            <urban-marketing-widget [darkModeStatus]="isDarkActive" [advertisingsEventsCounts]="advertisingsEventsCounts"></urban-marketing-widget>
        </div>
    </mat-card>
</ng-template>

<ng-template #trashBinWidget let-widget>
    <mat-card class="dashboardWidgetCard" *ngIf="widget.Name === 'Trash-bin-service'">
        <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
            <i class="urbanicon-drag"></i>
        </div>
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{ widget.Name | slice:0:9 }}</h3>
        <div>
            <urban-trash-bin-widget [darkModeStatus]="isDarkActive" [trashBinEvents]="myServiceEvents['trash-bin']"></urban-trash-bin-widget>
        </div>
    </mat-card>
</ng-template>

<ng-template #wifiWidget let-widget>
    <mat-card class="mat-elevation-z3 dashboardWidgetCard d-flex flex-row align-items-stretch" *ngIf="widget.Name == 'Wifi'">
        <div id="squareExtender"></div>
        <div class="w-100 d-flex flex-column">
            <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
                <i class="urbanicon-drag"></i>
            </div>
            <div class="remover">
                <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
            </div>
            <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{widget.Name}}</h3>
            <div *ngIf="mapInDOM" class="flex-grow-1">
                <urban-wifi-widget [devicesList]="wifiDevices" [darkModeStatus]="isDarkActive" class="mapPanel"
                    [passedHeight]="0" [showToggler]="true" [asWidget]="true" [initialZoom]="10">
                </urban-wifi-widget>
            </div>
        </div>
    </mat-card>
</ng-template>

<ng-template #EventsRejectedWidget let-widget>
    <mat-card class="dashboardWidgetCard" *ngIf="widget.Name === 'Events rejected'">
        <div class="handler" cdkDragHandle (mousedown)="onMouseDown()" *ngIf="dragVisible">
            <i class="urbanicon-drag"></i>
        </div>
        <div class="remover">
            <i class="urbanicon-remove" (click)="removeWidgetDialog(this.widget)"></i>
        </div>
        <h3 [ngClass]="{'cardTitleNoDrag' : !dragVisible}" class="title3 position-absolute cardTitle">{{ widget.Name?.split('-')[0] }}</h3>
        <div>
            <urban-device-events-rejected-widget></urban-device-events-rejected-widget>
        </div>
    </mat-card>
</ng-template>