<urban-header
    class="toolbarShadow navTheme"
    (toggleSidebar)="sidebarToggler()"
    (toggleUserSidebar)="userSidebarToggler($event)"
    [darkModeStatus]="subscribedDarkModeStatus"
    [myProfileImage]="subscribedProfileImage"
    [domainLogo]="subscribedDomainLogo"
    [sidebarOpen]="sidebarOpen"
    [title]="subscribedThemeTitle"
></urban-header>

<mat-sidenav-container>
    <mat-sidenav id="intro-sidenav" class="sidenav navTheme" [ngStyle]="{'width': sidebarOpen ? '298px' : lockSidebar ? '80px' : '0px'}" #sidenav [mode]="lockSidebar ? 'side' : 'over'" (closedStart)="this.sidebarOpen=false" [opened]="true">
        <urban-sidebar
            (closeSidebar)="sidebarCloserOnLittleScreen()"
            (sidebarToggler)="sidebarToggler()"
            [sidebarOpened]="sidebarOpen"
            [myResourcesList]="sidebarResources"
            [routeChangedSignal]="activatedRouteSignal"
            class="sidebar"
        ></urban-sidebar>

        <p class="paragraphText appVersion">v. {{appVersion}}</p>
    </mat-sidenav>

    <mat-sidenav-content class="sidenavContent"
        [ngClass]="lockSidebar ? (sidebarOpen ? 'contentWhenSidebarOpen' : 'contentWhenSidebarClosed') : 'contentWhenSmallScreen' ">

        <mat-drawer-container class="contentContainer">
            <mat-drawer #userSidebar mode="over" position="end" class="userSidebars">
                <urban-user-setting-list *ngIf="activeRightbar === 'settings'"
                                    [myCurrentDomain]="subscribedCurrentDomain"
                                    [darkModeStatus]="subscribedDarkModeStatus"
                                    [availableLanguages]="availableLanguages"
                                    (toggleDarkMode)="darkModeToggler()"
                                    (closeRightbar)="userSidebarToggler($event)">
                </urban-user-setting-list>

                <urban-profile *ngIf="activeRightbar === 'profile'"
                                (closeRightbar)="userSidebarToggler($event)"
                                [myCurrentDomainName]="subscribedCurrentDomain?.Name"
                                (logout)="logout()">
                </urban-profile>

                <urban-dashboard-management *ngIf="activeRightbar === 'dashboard'"
                                (closeRightbar)="userSidebarToggler($event)"
                                [myDashboardList]="subscribedDashboards"
                                [myCurrentDomainName]="subscribedCurrentDomain?.Name"
                                [myDevices]="subscribedDevices">
                </urban-dashboard-management>
            </mat-drawer>

            <div class="routerContent">
                <router-outlet (activate)="signalRouteActivation($event)"></router-outlet>
            </div>
        </mat-drawer-container>
    </mat-sidenav-content>
</mat-sidenav-container>
