import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../../shared/models/loginResponse';
import { TaskSchedule } from '../../../shared/models/taskScheduleElements';
import { ApiService } from '../../../shared/services/api.service';
import { Device } from '../../../shared/models/device';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { TaskScheduleApiService } from '../../../shared/services/task-schedule-api.service';
import { Router } from '@angular/router';
import { AddTaskScheduleDialogComponent } from '../../../shared/dialogs/add-task-schedule-dialog/add-task-schedule-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-task-schedule',
  templateUrl: './task-schedule.component.html',
  styleUrls: ['./task-schedule.component.scss']
})
export class TaskScheduleComponent implements OnInit, OnDestroy {

  public displayedColumns: string[] = ['Device', 'Days', 'Creation', 'Username', 'Detail'];
  public allLevelsDevices: Device[] = [];
  public usersList: User[];
  public domainTaskSchedules: TaskSchedule[];
  public filteredData: TaskSchedule[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private taskScheduleApiService: TaskScheduleApiService,
    private passDataService: PassDataService,
    private router: Router,
    private dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) { }

  ngOnInit(): void {
    this.passDataService.devices$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.loadDevices(result);

        this.apiService.listUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
          if (response && response !== undefined) {
            this.usersList = response;
            this.loadTaskScheduleList();
          }
        });
      }
    });
  }

  private loadTaskScheduleList(): void {
    this.taskScheduleApiService.getTaskScheduleList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        res.forEach(task => {
          let creator = this.usersList.find(user => user.Id === task.UserId).Username ? this.usersList.find(user => user.Id === task.UserId).Username : 'unknown';
          task.UserUsername = creator;
        });

        this.domainTaskSchedules = res;
        this.filteredData = this.domainTaskSchedules;
      }
    });
  }

  private loadDevices(devicesToIterate: Device[]): void {
    if (devicesToIterate && devicesToIterate.length > 0) {
      for (let device of devicesToIterate) {
        if (device.Childs?.length > 0) {
          this.allLevelsDevices.push(device);
          for (let child of device.Childs) {
            this.allLevelsDevices.push(child);
            if (child.Childs?.length > 0) {
              this.loadDevices(child.Childs)
            }
          }
        }
        else {
          this.allLevelsDevices.push(device);
        }
      }

      this.allLevelsDevices.sort((a, b) => a.Name.localeCompare(b.Name))
    }
  }

  public deleteTaskSchedule(task: TaskSchedule): void {
    this.taskScheduleApiService.deleteDeviceTaskSchedule(task.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loadTaskScheduleList();
    });
  }

  public addTaskSchedule(): void {
    const addTaskDialogRef = this.dialog.open(AddTaskScheduleDialogComponent, {
      data: { allDevices: this.allLevelsDevices },
      width: '50%'
    });

    addTaskDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newAddRequest => {
      if (newAddRequest) {
        const addTaskConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addTaskConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.taskScheduleApiService.addTaskSchedule(newAddRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadTaskScheduleList();
            });
          }
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.domainTaskSchedules?.filter((task: TaskSchedule) =>
      [
        task.Device?.Name?.toLowerCase(), 
        task.Created ? new Date(task.Created * 1000).toLocaleString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        }) : '',
        task.UserUsername?.toLowerCase(), 
        task.Date?.toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public taskScheduleDetail(task: TaskSchedule): void {
    this.mainService.setNavigationInfoComand({ Id: task.Id, BackRoute: 'task-schedule' });
    this.router.navigate(['main/task-schedule-detail']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
