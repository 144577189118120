<div class="container-fluid px-1 px-md-3" [ngClass]="{'h-100' : events.length === 0}">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'DEVICE_EVENTS.TITLE' | translate }} - {{this.currentDomain?.Name}}
        </span>
    </div>

    <urban-searchbar id="intro-domain-device-events-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="customTable" *ngIf="events?.length > 0">
        <div class="customTableRadius d-flex flex-wrap justify-content-between align-items-baseline m-4">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
 
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>

                <input matInput (keyup)="applyFilter($event)" autocomplete="off" [(ngModel)]="actualFilter">

                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear"
                    (click)="applyFilterString(''); actualFilter = ''; setFilters(); filterForm.reset();" matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
            
            <form [formGroup]="filterForm" class="d-flex align-items-center flex-wrap">
                <mat-form-field *ngIf="deviceTypes?.length > 0" class="customMatFormField mr-2" appearance="outline">
                    <mat-label> {{ 'DEVICE_EVENTS.DEVICE_TYPE' | translate }} </mat-label>
                    <mat-select formControlName="deviceType" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let deviceType of deviceTypes" [value]="deviceType">
                            {{ ('DEVICE_EVENTS.' + deviceType) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="eventTypes?.length > 0" class="customMatFormField mr-2" appearance="outline">
                    <mat-label>{{ 'DEVICE_EVENTS.TYPE' | translate }}</mat-label>
                    <mat-select formControlName="eventType" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let eventType of eventTypes" [value]="eventType">
                            {{ eventType }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field *ngIf="eventLevels?.length > 0" class="customMatFormField mr-2" appearance="outline">
                    <mat-label>{{ 'DEVICE_EVENTS.LEVEL' | translate }}</mat-label>
                    <mat-select formControlName="eventLevel" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let eventLevel of eventLevels" [value]="eventLevel">
                            {{ eventLevel }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>

        <div class="tableHeight">
            <table class="w-100 h-100" mat-table [dataSource]="filteredData | paginate: config">
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.ID' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item" class="text-truncate"> {{ item.Id }} </td>
                </ng-container>
    
                <ng-container matColumnDef="Device">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.DEVICE_NAME' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item" class="text-truncate">
                        <i class="urbanicon-{{ item.Icon }}"></i>
                        <span class="link" (click)="openDeviceDetail(item)" matTooltip="{{'GENERAL.GO_TO_DEVICE' | translate }}">
                            {{ item.DeviceName }}
                        </span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let item" style="word-break: keep-all"> {{ item.Created*1000 | date: 'short' }} </td>
                </ng-container>
    
                <ng-container matColumnDef="Type">
                    <th mat-header-cell *matHeaderCellDef> {{'DEVICE_EVENTS.TYPE' | translate }} </th>
                    <td mat-cell *matCellDef="let item" style="word-break: keep-all">
                        <span>{{ item.Type }}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="Level">
                    <th mat-header-cell *matHeaderCellDef> {{'DEVICE_EVENTS.LEVEL' | translate }} </th>
                    <td class="eventLevel" mat-cell *matCellDef="let item" style="word-break: keep-all">
                        <span [ngStyle]="item.Level === 'Error' && {'color':eventLevelColors[0], 'border-color':eventLevelColors[0], 'background-color':eventLevelColors[0] + '1A'} ||
                         item.Level === 'Warning' && {'color':eventLevelColors[3], 'border-color':eventLevelColors[3], 'background-color':eventLevelColors[3] + '0D'} ||
                         item.Level === 'Info' && {'color':eventLevelColors[1], 'border-color':eventLevelColors[1], 'background-color':eventLevelColors[1] + '1A'} ||
                         item.Level === 'Debug' && {'color':eventLevelColors[2], 'border-color':eventLevelColors[2], 'background-color':eventLevelColors[2] + '1A'}">
                         {{ item.Level }}
                        </span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1"></th>
                    <td mat-cell *matCellDef="let item" class="text-center pr-1">
                        <div class="pr-2">
                            <i class="mr-2" matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                (click)="openDetailDialog(item)" class="iconInfoButton urbanicon-info-outline">
                            </i>
                            <i *ngIf="isAdmin"
                                matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                (click)="deleteEvent(item.Id)"
                                class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{ config.currentPage }} {{'GENERAL.OF' | translate}}
                    {{ (config.totalItems / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{ (config.totalItems / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true" (selectionChange)="paginatorOnItemsPerPageChange()">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center noDataIllustration responsiveIllustration" *ngIf="events?.length === 0">
        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <h2>{{ (last24hSearch ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
        <div class="d-flex">
            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                {{ 'GENERAL.NEW_SEARCH' | translate }}
            </button>
            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                {{ 'GENERAL.SHOW_DATA' | translate }}
            </button>
        </div>
    </div>
</div>
