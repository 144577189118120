<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'CONTENT_MANAGEMENT.BROADCAST_CLIENTS' | translate }}
        </span>

        <button mat-button class="btn actionButton" (click)="add()">
            {{ 'GENERAL.ADD' | translate }}
        </button>
    </div>

    <div class="customTable">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                       autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <table class="w-100" mat-table [dataSource]="filteredData | paginate: config">

            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.NAME' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.Name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Type">
                <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.TYPE' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.BroadcastClientType.Name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="Detail">
                <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 10%"></th>
                <td mat-cell *matCellDef="let element" class="text-center pr-1">
                    <div class="pr-2">
                        <i matTooltip="{{'GENERAL.DETAIL' | translate }}"
                           (click)="detail(element)" class="iconInfoButton urbanicon-info-outline">
                        </i>
                        <i matTooltip="{{'GENERAL.REMOVE' | translate }}"
                           (click)="delete(element)" class="iconDeleteButton urbanicon-delete">
                        </i>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="typesColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: typesColumns;"></tr>
        </table>

        <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                                     [maxSize]="7"
                                     [directionLinks]="true"
                                     [previousLabel]="''"
                                     [nextLabel]="''"
                                     [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                                     [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                                     [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                                     (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
