import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from './../../../shared/models/deviceEventLatest';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { DeviceEventTrashBin } from '../../../shared/models/deviceEventTrashBin';
import { ChartDataList } from '../../../shared/models/ChartDataList';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Device } from 'src/app/shared/models/device';
import { DeviceEventRequest, DeviceEventsDevice, DeviceEventsEvent, DeviceEventsResponse } from 'src/app/shared/models/deviceEvent';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { EventParsedBody } from 'src/app/shared/models/administrator/eventBody';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';

@Component({
  selector: 'urban-trash-bin-detail',
  templateUrl: './trash-bin-detail.component.html',
  styleUrls: ['./trash-bin-detail.component.scss']
})
export class TrashBinDetailComponent implements OnInit, OnDestroy {

  public trashBinDevice: Device = null;
  public trashBinEvents: DeviceEventTrashBin[];
  public historyDataToPass: ChartDataList[];
  public fillMaxValue : number = 100;
  public trashBinDataSource: MatTableDataSource<DeviceEventTrashBin>;
  public displayedColumns: string[] = ['Read', 'Percentage'];
  public loadingEventsData: boolean;
  public chartReady: boolean;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public currentLanguage: string;
  public firstUsefulDate: Date;
  private eventBody: Record<string, EventParsedBody>;
  public alertPanelInput: AlertPanelInput;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private translate: TranslateService,
    private eventBodyService: EventBodyUtilityService
  ) { }

  ngOnInit(): void {
    let deviceId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;

        this.loadData(deviceId);
      }
      else {
        this.setErrorAndGoToMain();
        return;
      }
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText]
    };
  }

  private loadData(deviceId: string) {
    let deviceFeature: number, deviceEventsFeature: number;
    const syncContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, syncContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        this.resetAllData();

        if(checkValues[deviceEventsFeature]) {
          this.trashBinEvents = this.mapToTrashBinEvents(data[deviceEventsFeature], [this.trashBinDevice], this.eventBody);
        }

        if (this.trashBinEvents.length > 0) {
          this.setAllData();
        }

        this.chartReady = true;
        this.loadingEventsData = false;
      }
    }, syncContext);

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.trashBinDevice = device;
        this.eventBody = this.eventBodyService.initEventBody([this.trashBinDevice]);

        deviceFeature = this.apiSync.loadedFeature(syncContext);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(syncContext);
        this.setErrorAndGoToMain();
      }
    });

    let eventsRequest: DeviceEventRequest = { DeviceId: deviceId };

    this.apiService.getDeviceEvents(eventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
        if(res && res.Devices?.length > 0) {
          deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
        }
        else {
          deviceEventsFeature = this.apiSync.failedFeature(syncContext);
        }
      });
  }

  public newSearch(selectedDates: SearchDates): void {
    this.loadingEventsData = true;
    this.chartReady = false;

    let eventsRequest: DeviceEventRequest = {
      DeviceId: this.trashBinDevice.Id,
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(eventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
        this.resetAllData();

        if (res && res.Devices?.length > 0) {
          this.trashBinEvents = this.mapToTrashBinEvents(res, [this.trashBinDevice], this.eventBody);
        }

        if (this.trashBinEvents.length > 0) {
          this.setAllData();
        } else {
          this.last24hSearch = selectedDates.last24hSearch;
        }

        this.chartReady = true;
        this.loadingEventsData = false;
      });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingEventsData = true;
    this.chartReady = false;

    let eventsRequest: DeviceEventLatestRequest = {
      DeviceId: this.trashBinDevice.Id
    };

    // let trashBinBrandName = this.trashBinDevice.Model.Brand.Name;
    // let properEventCheck: (event: EventLatest) => boolean = trashBinBrandName === 'Moba' ?
    //   this.isAProperMobaEvent :
    //   (trashBinBrandName === 'ADGenera' ? this.isAProperADGeneraEvent : undefined);

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest)
    .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventLatestResponse) => {
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if (res && res.Devices?.length > 0) {
        this.trashBinEvents = this.mapToTrashBinEvents(res, [this.trashBinDevice], this.eventBody);
      }

      if (this.trashBinEvents.length > 0) {
        this.setLatestDates(res);
        this.setAllData();
      }

      this.chartReady = true;
      this.loadingEventsData = false;
    });
  }

  private setLatestDates(res: DeviceEventLatestResponse): void {
    this.lastCreated = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp)) * 1000;
    this.setDates = !this.setDates;
  }

  private resetAllData(): void {
    this.trashBinEvents = [];
    this.historyDataToPass = [];
    this.trashBinDataSource = new MatTableDataSource<DeviceEventTrashBin>();
    this.firstUsefulDate = null;
    this.alertPanelInput = undefined;
  }

  private setAllData(): void {
    this.setStatusTableData();
    this.setHistoryChartData();
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEvents: EventLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT' | 'UNKNOWN_ERROR';

    if (res?.Devices?.length > 0) {
      alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
    }

    if (alertEvents.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Error');
      }

      if (alertEvents.length > 0) {
        //Unknown first error
        alertType = 'error';
        eventType = 'UNKNOWN_ERROR';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEvents = res.Devices[0].Events.filter((event: EventLatest) =>
            ['Info', 'Debug'].includes(event.Level) &&
            !this.eventBodyService.isAProperEvent(event, this.trashBinDevice.Model, this.eventBody)
          );
        }

        if (alertEvents.length > 0) {
          //Wrong body
          alertType = 'error';
          eventType = 'WRONG_BODY_EVENT';
        }
        else {
          if (res?.LatestBadEvents?.Devices?.length > 0) {
            alertEvents = res.LatestBadEvents.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }
          else if (res?.Devices?.length > 0) {
            alertEvents = res.Devices[0].Events.filter((event: EventLatest) => event.Level === 'Warning');
          }

          if (alertEvents.length > 0) {
            alertType = 'warning';
            eventType = 'WARNING';
          }
        }
      }
    }

    if (alertEvents.length > 0) {
      let errorPhrases: string[];

      if (eventType === 'UNKNOWN_ERROR') {
        let lastInfoEvent: EventLatest = res?.Devices?.[0]?.Events?.find(
          (event: EventLatest) => event.Level === 'Info' &&
          !this.eventBodyService.isAProperEvent(event, this.trashBinDevice.Model, this.eventBody)
        );
        if (lastInfoEvent !== undefined) {
          this.firstUsefulDate = new Date(lastInfoEvent.CreatedTimestamp * 1000);
        }

        eventType = 'ERROR';
        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          this.firstUsefulDate ? 'ALERT_PANEL.LAST_CORRECT_INFO_DATE' : ''
        ];
      }
      else {
        this.firstUsefulDate = new Date(alertEvents[0].CreatedTimestamp * 1000);

        errorPhrases = [
          'ALERT_PANEL.' + eventType + (alertEvents.length > 1 ? 'S' : '') + '_DETECTED',
          'ALERT_PANEL.FIRST_' + eventType + '_DATE'
        ];
      }

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEvents.length.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private setStatusTableData(): void {
    let timedTrashBinEvents: DeviceEventTrashBin[] = this.getTimedTrashBinEvents(this.trashBinEvents);
    this.trashBinDataSource = new MatTableDataSource<DeviceEventTrashBin>(timedTrashBinEvents);
  }

  private setHistoryChartData(): void {
    this.historyDataToPass = this.getHistoryChartData(this.trashBinEvents);
  }

  private getTimedTrashBinEvents(trashBinEvents: DeviceEventTrashBin[]): DeviceEventTrashBin[] {
    let timedEvents: DeviceEventTrashBin[] = [];

    if(trashBinEvents?.length > 0) {
      timedEvents.push(trashBinEvents[0]);

      let previousTime: number = timedEvents[0].Read - 60 * 60 * 8; //8 hours before
      trashBinEvents.slice(2).forEach((event: DeviceEventTrashBin, index: number) => {
        if(event.Read <= previousTime) {
          let eventToAdd: DeviceEventTrashBin
          if(event.Read < previousTime) {
            if(previousTime - event.Read < trashBinEvents[index - 1].Read - previousTime) {
              eventToAdd = event;
            }
            else {
              eventToAdd = trashBinEvents[index - 1];
            }
          }
          else {
            eventToAdd = event;
          }

          timedEvents.push(eventToAdd);
          previousTime -= 60 * 60 * 8;
        }
      });
    }

    return timedEvents.reverse();
  }

  private getHistoryChartData(trashBinEvents: DeviceEventTrashBin[]): ChartDataList[] {
    let historyData: ChartDataList[] = [];

    trashBinEvents.forEach((event: DeviceEventTrashBin) => {
      const readTime: string = this.convertTime(event.Read);
      historyData[readTime] = {};
      historyData[readTime][event.Device.Name] = event.Percentage;
    });

    return historyData;
  }

  private mapToTrashBinEvents(res: DeviceEventsResponse, trashBinDevices: Device[], eventBodies: Record<string, EventParsedBody>): DeviceEventTrashBin[] {
    let formattedEvents: DeviceEventTrashBin[] = [];
    res.Devices.forEach((device: DeviceEventsDevice) => {
      let trashBinDevice: Device;
      if ((trashBinDevice = trashBinDevices.find(oneDevice => oneDevice.Id === device.Id)) !== undefined) {
        let expectedBody: EventParsedBody = eventBodies[trashBinDevice.Model.Name];
        device.Events.forEach((event: DeviceEventsEvent) => {
          let eventBody: any = event.Body;
          if (this.eventBodyService.isAProperEvent(event, trashBinDevice.Model, eventBodies)) {
            let read: number;
            if (Object.keys(expectedBody.Mapping).includes('ReadPlus1Char')) {
              let readString = this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['ReadPlus1Char']).toString();
              read = (new Date(readString.substring(0, readString.length - 1))).getTime() / 1000;
            }
            else if (['ReadDate', 'ReadHour'].every((key: string) => Object.keys(expectedBody.Mapping).includes(key))) {
              let date: string = this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['ReadDate']).toString();
              let hour: string = this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['ReadHour']).toString();
              read = (new Date(`${date}T${hour}`)).getTime() / 1000;
            }

            let trashBinEvent: DeviceEventTrashBin = {
              Created: event.CreatedTimestamp,
              Device: trashBinDevice,
              Read: read,
              Percentage: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Percentage'])
            }
            formattedEvents.push(trashBinEvent);
          }
        });
      }
    });
    return formattedEvents;
  }

  convertTime(seconds: number): string {
    return new Date(seconds * 1000).toString();
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingEventsData = false;

    if (this.trashBinEvents.length === 0) {
      this.chartReady = false;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceEvents(): void {
    if (this.trashBinDevice) {
      this.mainService.setNavigationInfoComand({ Id: this.trashBinDevice.Id, BackRoute: 'trash-bin-detail' });
      this.router.navigate(['main/device-events']);
    }
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
