<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'TASK_SCHEDULE_DETAIL.TITLE' | translate }}
        </span>

        <div class="d-flex flex-row ml-2">
            <button mat-button type="button" id="iconDeleteButton"
                class="btn outlineWarnButton" (click)="deleteTaskSchedule()">
                {{ 'GENERAL.DELETE' | translate }}
            </button>

            <button mat-button type="button" id="updateButton"
                class="btn actionButton ml-2" (click)="updateTaskSchedule()">
                {{ 'GENERAL.UPDATE' | translate }}
            </button>
        </div>
    </div>

    <div *ngIf="passedTask" class="panelBG roundedPanel infoSection mb-5">
        <div class="d-flex flex-row flex-wrap infoGap">
            <div class="innerInfo d-flex flex-row">
                <div class="iconCircle" [ngStyle]="{'background-color':'#'+ passedTask.Device.Model.Type?.Color}">
                    <i class="text-center mt-2 iconToShow" [class]="'urbanicon-'+ passedTask.Device.Model.Type?.Icon"></i>
                </div>
                <div class="d-flex flex-column ml-4">
                    <span class="title3 mb-2">{{ passedTask.Device.Name }}</span>
                    <span class="paragraphText secondaryTextColor">{{ passedTask.Device.Name }}</span>
                </div>
            </div>

            <div class="innerInfo">
                <div class="d-flex flex-row justify-content-between" style="max-width: 400px">
                    <div class="infoField">
                        <span>{{ 'TASK_SCHEDULE_DETAIL.CREATOR' | translate }}</span>
                        <div class="d-flex flex-row">
                            <img *ngIf="passedTask.User?.Properties?.ProfileImage !== undefined; else defaultImage" class="roundedImage img-fluid" [src]="passedTask.User.Properties.ProfileImage" alt="User Image">
                            <ng-template #defaultImage>
                                <div class="icon-container iconBG">
                                    <span class="urbanicon-profile"></span>
                                </div>
                            </ng-template>

                            <span>{{ passedTask.User?.Firstname }} {{ passedTask.User?.Lastname }}</span>
                        </div>
                    </div>

                    <div class="infoField">
                        <span>{{ 'TASK_SCHEDULE_DETAIL.CREATED' | translate }}</span>
                        <span>{{ passedTask.Created*1000 | date : 'dd/mm/yyyy' }}</span>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div class="d-flex flex-row flex-wrap infoGap">
            <div class="innerInfo d-flex flex-column infoGap">
                <div class="infoField">
                    <span>{{ 'GENERAL.ID' | translate }}</span>
                    <span>{{ passedTask.Id }}</span>
                </div>

                <div class="d-flex flex-row">
                    <div class="infoField shortInfo mr-3">
                        <span>{{ 'GENERAL.DAYS' | translate }}</span>
                        <div *ngIf="passedTask.Date.split('-').length < 7; else everydayText" class="d-flex flex-row">
                            <span *ngFor="let day of passedTask.Date.split('-'); let i = index">
                                {{ i !== 0 ? '-' : '' }}{{(('GENERAL.' + weekDays[+day - 1]) | translate).substring(0, 3).toLowerCase()}}
                            </span>
                        </div>
                        <ng-template #everydayText>
                            <span>{{ 'TASK_SCHEDULE_DETAIL.EVERYDAY' | translate }}</span>
                        </ng-template>
                    </div>

                    <div class="infoField shortInfo">
                        <span>{{ 'GENERAL.TIME' | translate }}</span>
                        <span>{{ passedTask.Hour }}</span>
                    </div>
                </div>
            </div>

            <div class="infoField innerInfo">
                <span>{{ 'TASK_SCHEDULE_DETAIL.JSON' | translate }}</span>
                <span>{{ passedTask.Json }}</span>
            </div>
        </div>
    </div>

    <p class="title3">
        {{ 'TASK_SCHEDULE.EXECUTIONS_LIST' | translate }}
    </p>

    <urban-searchbar id="intro-task-schedule-detail-search" [passedMaxDaysRange]="3" (newSearch)="newSearch($event)" [loadingEvents]="loadingData !== false"
        [buttonToggleSelected]="24" (stopPreviousSearch)="subscriptionsUnsubscribe()" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate">
    </urban-searchbar>

    <div *ngIf="this.readyToShow && executionsList?.length > 0" class="customTable w-100 mt-5">
        <div class="customTableRadius justify-content-between align-items-start px-4 pt-4 flex-wrap" [ngClass]="executionsList?.length === 0 ? 'd-none' : 'd-flex'">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight" *ngIf="executionsList?.length > 0">
            <table class="w-100 noTableHover" mat-table [dataSource]="filteredData | paginate: config">

                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Created*1000 | date : 'short' }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="End">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.END' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.End*1000 | date : 'short' }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedExecutionsColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedExecutionsColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius justify-content-between align-items-center px-4 pt-4 flex-wrap" [ngClass]="executionsList?.length === 0 ? 'd-none' : 'd-flex'">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="d-flex align-items-center justify-content-center noDataIllustrationInTabs" *ngIf="executionsList?.length === 0">
        <img [src]="isDarkModeActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <div>
            <h2>{{ (last24hSearch ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
            <div class="d-flex noDataActions">
                <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
