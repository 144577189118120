import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { UserRoles } from "../../models/userRoles";
import { AuthState } from '../../../store/auth/auth.reducer';
import * as AuthSelectors from '../../../store/auth/auth.selectors';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DomainAdminGuard  {
    public userRoles: UserRoles['Roles'];
    public isDomainAdmin: boolean;

    constructor(private store: Store<AuthState>, public router: Router) {
        this.store.select(AuthSelectors.getUserRoles).subscribe(
            res => this.userRoles = res
        )
        if (this.userRoles.some(x => x.Name === 'Domain admin') || this.userRoles.some(x => x.Name === 'Administrators')) {
            this.isDomainAdmin = true;
        }
    }

    public canActivate(): boolean {
        if (this.isDomainAdmin) return true;
        else {
            this.router.navigate(['main']);
            return false;
        }
    }

}