<div class="container-fluid">
    <div class="row d-flex mx-0 pageTop">
        <span class="title2">
            {{deviceGroup?.Name}}
        </span>
    </div>

    <div class="groupInfo panelBG">
        <form [formGroup]="form">
            <div class="d-flex justify-content-between align-items-center mb-4 flex-wrap">
                <span class="title3 secondaryTextColor">{{ 'GENERAL.INFO' | translate }}</span>

                <div>
                    <button type="button" mat-button class="btn actionButton" *ngIf="editMode" (click)="saveInfo()" [disabled]="!dataChanged">
                        {{ 'GENERAL.SAVE' | translate }}
                    </button>

                    <button type="button" mat-button class="btn actionButton" *ngIf="!editMode" (click)="enableEdit()">
                        {{ 'GENERAL.EDIT' | translate }}
                    </button>

                    <button type="button" mat-button class="btn outlineActionButton ml-0 cancelBtn mt-2 mt-sm-0" *ngIf="editMode" (click)="cancelEdit()">
                        {{ 'GENERAL.CANCEL' | translate }}
                    </button>
                </div>
            </div>

            <div class="d-flex infoCol">
                <div class="col-6 customCol d-flex flex-column pl-0">
                    <mat-form-field appearance="outline" class="customMatFormField w-50 mb-3" [ngClass]="{'noUnderline': !editMode}">
                        <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
                        <input matInput [readonly]="!editMode" class="paragraphText" formControlName="name" autocomplete="off" required>
                    </mat-form-field>

                    <div class="d-flex flex-row align-items-center justify-content-start">
                        <mat-form-field appearance="outline" class="customMatFormField noUnderline">
                            <mat-label>{{ 'GENERAL.COLOR' | translate }}</mat-label>
                            <input matInput readonly class="paragraphText d-none" formControlName="color" autocomplete="off" required>
                            <div *ngIf="deviceGroup?.Color" class="circle" [style.background]="colorPicker.value">
                                <input #colorPicker formControlName="color" readonly type="color" [value]="deviceGroup?.Color" colorpick-eyedropper-active="false">
                            </div>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-6 customColTwo d-flex flex-column">
                    <div class="d-flex flex-wrap" *ngIf="deviceGroup?.Capabilities !== null && deviceGroup?.Capabilities !== undefined && deviceGroup?.Capabilities.length > 0">
                        <button mat-button class="btn outlineActionButton mr-2 mb-1"
                        *ngFor="let capability of deviceGroup?.Capabilities" id="{{capability.Id}}"
                        (click)="this.executeAction(capability)" type="button">
                        {{ 'DEVICE.' + capability.Label | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="d-flex infoCol">
        <div class="col-6 pl-0 customCol">
            <div class="customTable" *ngIf="deviceGroup?.Devices !== null && deviceGroup?.Devices !== undefined">
                <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4 flex-wrap">
                    <span class="title3">{{ 'GENERAL.DEVICES' | translate }}</span>

                    <div class="d-flex align-items-center flex-wrap">
                        <mat-form-field class="tableSearch mr-3 mt-2" appearance="outline">
                            <div matPrefix class="prefix">
                                <i class="urbanicon-search"></i>
                            </div>
                            <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                            <input matInput (keyup)="applyFilter($event)"
                                autocomplete="off" [(ngModel)]="actualFilter">
                            <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                                <i class="urbanicon-close"></i>
                            </button>
                        </mat-form-field>

                        <button mat-button class="btn actionButton mt-1" type="button" id="addDeviceButton" (click)="addDevice()">
                            {{ 'GENERAL.ADD_DEVICE' | translate }}
                        </button>
                    </div>
                </div>

                <div class="tableHeight">
                    <table class="w-100" mat-table [dataSource]="filteredData | paginate: config">

                        <ng-container matColumnDef="Icon">
                            <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 5%"></th>
                            <td mat-cell *matCellDef="let element" class="text-center pr-1">
                                <div class="pr-2">
                                    <span class="mb-2" class="urbanicon-{{ element.Model.Type?.Icon }}"></span>
                                </div>
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.NAME' | translate }} </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.Name }}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="Latitude">
                            <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.LATITUDE' | translate }} </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.Latitude }}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="Longitude">
                            <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.LONGITUDE' | translate }} </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.Longitude }}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="Detail">
                            <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 15%"></th>
                            <td mat-cell *matCellDef="let element" class="text-center pr-1">
                                <div class="pr-2">
                                    <i class="mr-2 iconInfoButton urbanicon-info-outline" matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                        (click)="openDeviceDetail(element.Id)">
                                    </i>
                                    <i class="mr-2 iconDeleteButton urbanicon-delete" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                        (click)="removeDevice(element)">
                                    </i>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="deviceColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let element; columns: deviceColumns;"></tr>
                    </table>
                </div>

                <div *ngIf="deviceGroup.Devices.length === 0" class="text-center my-5">
                    <h6 class="secondaryTextColor">{{ 'GENERAL.NO_DATA' | translate }}</h6>
                </div>

                <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
                    <div class="d-flex flex-wrap align-items-center">
                        <pagination-controls class="customNgxPaginator"
                            [maxSize]="7"
                            [directionLinks]="true"
                            [previousLabel]="''"
                            [nextLabel]="''"
                            [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                            [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                            [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                            (pageChange)="paginatorOnPageChange($event)">
                        </pagination-controls>

                        <div class="paginatorPageCounter">
                            {{config.currentPage}} {{'GENERAL.OF' | translate}}
                            {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                            {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                        </div>
                    </div>

                    <div>
                        <mat-form-field class="customMatFormField" appearance="outline">
                            <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                                <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                                    {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="deviceGroup?.Devices?.length > 0" class="col-6 flex-column panelBG customColTwo map">
            <span class="title3 secondaryTextColor">{{ 'GENERAL.MAP' | translate }}</span>

            <div class="h-100 w-100">
                <urban-maps-widget *ngIf="mapReady && deviceGroup?.Devices.length" [showInfo]="false"
                [darkModeStatus]="isDarkModeActive" [passedHeight]="600" [devicesList]="deviceGroup.Devices" [showInfo]="true">
                </urban-maps-widget>
            </div>
        </div>
    </div>
</div>
