import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from '../../../../shared/services/main-subscriptions/main-subscriptions.service';
import { AddDomainDialogComponent } from '../../../../shared/dialogs/add-domain-dialog/add-domain-dialog.component';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Domain } from '../../../../shared/models/domain';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { DomainVisibility } from 'src/app/shared/models/domainVisibility';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-domain-list',
  templateUrl: './domain-list.component.html',
  styleUrls: ['./domain-list.component.scss']
})
export class DomainListComponent implements OnInit, OnDestroy {

  public domainColumns = ['Name', 'Visibility'];
  public domains: Domain[] = [];
  public filteredData: Domain[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  private domainVisibilityItems: DomainVisibility[] = [];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: AdministratorApiService, 
    public dialog: MatDialog, 
    private mainService: MainSubscriptionsService
  ) {}


  ngOnInit(): void {
    this.apiService.domainVisibilityList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(items => {
      if (items) {
        this.domainVisibilityItems = items;
      }
    });

    this.apiService.domainList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(domains => {
      if (domains) {
        this.domains = domains;
        this.filteredData = this.domains;
      }
    })
  }

  public addDomain(): void {
    const addDomainDialogRef = this.dialog.open(AddDomainDialogComponent, {
      data: {parents: this.domains, domainVisibilityItems: this.domainVisibilityItems},
      maxWidth: '450px'
    });

    addDomainDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newDomain => {
      if (newDomain) {
        const addDomainConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addDomainConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.addDomain(newDomain).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this.apiService.domainList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(domains => {
                  this.domains = domains;
                  this.filteredData = this.domains;
                });
              }
            });
          }
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.domains?.filter((domain: Domain) =>
      [
        domain.Name?.toLowerCase(), 
        domain.DomainVisibility?.Description?.toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public domainDetail(domainId: string): void {
    if (!domainId || domainId === undefined || domainId === '') {
      return;
    }

    this.mainService.setNavigationInfoComand({Id: domainId, BackRoute: 'domain-list'});
    this.mainService.updateAllDataAndNavigateComand('domain-detail');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
