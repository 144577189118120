import { DeviceEventTrashBinAverage } from './../../../shared/models/deviceEventTrashBin';
import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { DeviceEventLatestResponse, EventLatest, EventsDeviceLatest } from './../../../shared/models/deviceEventLatest';
import { DeviceEventLatestRequest } from 'src/app/shared/models/deviceEventLatest';
import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import introJs from 'intro.js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChartDataList } from '../../../shared/models/ChartDataList';
import { Device } from '../../../shared/models/device';
import { DeviceEventTrashBin } from '../../../shared/models/deviceEventTrashBin';
import { ApiService } from '../../../shared/services/api.service';
import { LoaderService } from '../../../shared/services/loader/loader.service';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { DeviceEventRequest, DeviceEventsDevice, DeviceEventsEvent, DeviceEventsResponse } from 'src/app/shared/models/deviceEvent';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { AlertPanelInput } from 'src/app/shared/models/alertPanelInput';
import { DeviceModel } from 'src/app/shared/models/deviceModel';
import { EventParsedBody } from 'src/app/shared/models/administrator/eventBody';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';

@Component({
  selector: 'urban-trash-bin-dashboard',
  templateUrl: './trash-bin-dashboard.component.html',
  styleUrls: ['./trash-bin-dashboard.component.scss']
})
export class TrashBinDashboardComponent implements OnInit, OnDestroy {
  @HostBinding('style.--trash-bin-average-panel-height') private trashBinAveragePanelHeight: string;

  @ViewChild('trashBinAveragePanel') private trashBinAveragePanel: ElementRef;

  public trashBinDevices: Device[] = [];
  private trashBinEvents: DeviceEventTrashBin[];
  public trashBinDataSource: MatTableDataSource<DeviceEventTrashBin>;
  public averageTrashBinPercentages: ChartDataList;
  public displayedColumns: string[] = ['Name', 'Read', 'Percentage'];
  public chartReady: boolean;
  public loadingLatestData: boolean;
  public loadingEventsData: boolean;
  public chosenDatesSearch: boolean = false;
  public mapReady: boolean = false;
  public latestTrashBinEvents: DeviceEventTrashBin[];
  public lastUpdate: number = null;
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  private eventBodies: Record<string, EventParsedBody>;
  private introJS = introJs();
  private alertEventsDevicesInvolved: string;
  public alertPanelInput: AlertPanelInput;
  public isDomainAdmin: boolean = false;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private subscription: Subject<void> = new Subject<void>();


  constructor(
    private router: Router,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private apiService: ApiService,
    private apiSync: ApiSynchronizerService,
    private translate: TranslateService,
    private loader: LoaderService,
    private eventBodyService: EventBodyUtilityService
  ) {}

  ngOnInit(): void {
    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.loader.disable();

    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.currentLanguage = this.translate.currentLang.slice(-2);
    });

    this.loadData();

    this.translate.get('DEVICE.BACK').subscribe((data: string) => {
      if (data !== undefined) {
        if (data == 'Back') {
          this.currentLanguage = 'en'
        } else {
          this.currentLanguage = 'it'
        }
      }
    });

    this.trashBinAveragePanelHeight = '200px';

    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDomainAdmin = (res && res.some(x => x.Name === 'Administrators' || x.Name == 'Domain admin'));
    });
  }

  private setDynamicTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.getTranslations(phrases, (res: any) => afterTranslated(res));
    this.translate.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations(phrases, (res: any) => afterTranslated(res));
    });
  }

  private getTranslations(phrases: string[], afterTranslated: (phrasesTranslated: any) => void = () => {}): void {
    this.translate.get(phrases).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      afterTranslated(res);
    });
  }

  private afterErrorPhrasesTranslations(res: any, newAlertPanelInput: AlertPanelInput): void {
    this.alertPanelInput = {
      ...newAlertPanelInput,
      TitleText: res[newAlertPanelInput.TitleText],
      DescriptionText: res[newAlertPanelInput.DescriptionText] +
        ' ' + this.alertEventsDevicesInvolved
    };
  }

  public loadData(): void {
    let devicesByTypeFeatureLatest: number;
    let latestEventsFeature: number;
    const latestContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, latestContext);

    let devicesByTypeFeatureEvents: number;
    let deviceEventsFeature: number;
    const eventsContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, eventsContext);

    this.apiSync.waitFeaturesAndThen(
      (checkValues: boolean[], data: any) => {

        if (checkValues[devicesByTypeFeatureLatest]) {

          if (checkValues[latestEventsFeature]) {
            this.latestTrashBinEvents = this.mapToTrashBinEvents(data[latestEventsFeature], this.trashBinDevices, this.eventBodies);
          }

          if (this.latestTrashBinEvents.length > 0) {
            this.lastUpdate = Math.max(...this.latestTrashBinEvents.map((event: DeviceEventTrashBin) => event.Read));
            this.trashBinDataSource = new MatTableDataSource(this.latestTrashBinEvents);
          }
        }

        if (!checkValues[devicesByTypeFeatureLatest] || this.latestTrashBinEvents.length === 0) {
          this.lastUpdate = Math.round(Date.now() / 1000);
          this.trashBinDataSource = new MatTableDataSource();
        }

        this.loadingLatestData = false;
      },
      latestContext
    );

    this.apiSync.waitFeaturesAndThen(
      (checkValues: boolean[], data: any) => {
        if (checkValues[devicesByTypeFeatureEvents]) {
          if (checkValues[deviceEventsFeature]) {
            this.trashBinEvents = this.mapToTrashBinEvents(data[deviceEventsFeature], this.trashBinDevices, this.eventBodies);
          }

          if (this.trashBinEvents.length > 0) {
            let trashBinAverages: DeviceEventTrashBinAverage[] =
              this.calculateTrashBinEventsAverages(this.trashBinEvents, this.trashBinDevices);
            trashBinAverages.forEach((average: DeviceEventTrashBinAverage) => {
              this.averageTrashBinPercentages[average.Device.Name] = average.Percentage;
            });
          }
        }
        if (!checkValues[devicesByTypeFeatureEvents] || this.trashBinEvents.length === 0) {
          this.chartReady = true;
        }

        this.loadingEventsData = false;
      },
      eventsContext
    );

    this.apiService.getDevicesByType('trash-bin').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: Device[]) => {
      if (res && res.length > 0) {
        this.trashBinDevices = res;
        this.eventBodies = this.eventBodyService.initEventBody(this.trashBinDevices);

        devicesByTypeFeatureLatest = this.apiSync.loadedFeature(latestContext);
        devicesByTypeFeatureEvents = this.apiSync.loadedFeature(eventsContext);
      }
      else {
        devicesByTypeFeatureLatest = this.apiSync.failedFeature(latestContext);
        devicesByTypeFeatureEvents = this.apiSync.failedFeature(eventsContext);
      }

      this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
        this.mapReady = mapLoading;
      });
    });

    let latestEventsRequest: DeviceEventLatestRequest = { DeviceType: 'trash-bin' };

    this.apiService.getDeviceEventLatest(latestEventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventLatestResponse) => {
        this.latestTrashBinEvents = [];
        if (res && res.Devices?.length > 0) {
          latestEventsFeature = this.apiSync.loadedFeatureWithData(res, latestContext);
        }
        else {
          latestEventsFeature = this.apiSync.failedFeature(latestContext);
        }
      });

    let eventsRequest: DeviceEventRequest = { DeviceType: 'trash-bin' };

    this.apiService.getDeviceEvents(eventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventsResponse) => {
        this.resetAllData();

        if (res && res.Devices?.length > 0) {
          deviceEventsFeature = this.apiSync.loadedFeatureWithData(res, eventsContext);
        }
        else {
          deviceEventsFeature = this.apiSync.failedFeature(eventsContext);
        }
      });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;

    this.loadingEventsData = true;
    this.chartReady = false;

    let eventsRequest: DeviceEventLatestRequest = {
      DeviceType: 'trash-bin'
    };

    this.apiService.getDeviceEventLatest24HoursInfoGuaranteed(eventsRequest)
    .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription))
    .subscribe((res: DeviceEventsResponse) => {
      this.trashBinAveragePanelHeight = `${this.trashBinAveragePanel.nativeElement.offsetHeight}px`;
      this.resetAllData();
      this.checkAnomalousEvents(res);

      if (res && res.Devices?.length > 0) {
        this.trashBinEvents = this.mapToTrashBinEvents(res, this.trashBinDevices, this.eventBodies);
      }

      if (this.trashBinEvents.length > 0) {
        this.setLatestDates(res);
        let trashBinAverages: DeviceEventTrashBinAverage[] = this.calculateTrashBinEventsAverages(this.trashBinEvents, this.trashBinDevices);
        trashBinAverages.forEach((average: DeviceEventTrashBinAverage) => {
          this.averageTrashBinPercentages[average.Device.Name] = average.Percentage;
        });
      }
      else {
        this.trashBinEvents = [];
        this.chartReady = true;
      }

      this.loadingEventsData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.lastCreated = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp)) * 1000;
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates: SearchDates): void {
    this.loadingEventsData = true;
    this.chartReady = false;

    let eventsRequest: DeviceEventRequest = {
      DeviceType: 'trash-bin',
      Start: selectedDates.startDate,
      End: selectedDates.endDate
    }

    this.apiService.getDeviceEvents(eventsRequest)
      .pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventsResponse) => {
        this.trashBinAveragePanelHeight = `${this.trashBinAveragePanel.nativeElement.offsetHeight}px`;
        this.resetAllData();

        if (res && res.Devices?.length > 0) {
          this.trashBinEvents = this.mapToTrashBinEvents(res, this.trashBinDevices, this.eventBodies);
        }

        if (this.trashBinEvents.length > 0) {
          let trashBinAverages: DeviceEventTrashBinAverage[] = this.calculateTrashBinEventsAverages(this.trashBinEvents, this.trashBinDevices);
          trashBinAverages.forEach((average: DeviceEventTrashBinAverage) => {
            this.averageTrashBinPercentages[average.Device.Name] = average.Percentage;
          });
        }
        else {
          this.trashBinEvents = [];
          this.chartReady = true;
          this.last24hSearch = selectedDates.last24hSearch;
        }

        this.loadingEventsData = false;
      });
  }

  private resetAllData(): void {
    this.trashBinEvents = [];
    this.averageTrashBinPercentages = {};
    this.alertPanelInput = undefined;
    this.alertEventsDevicesInvolved = null;
  }

  private checkAnomalousEvents(res: DeviceEventLatestResponse): void {
    let alertEventsDevices: EventsDeviceLatest[] = [];
    let alertType: 'error' | 'warning' | 'info';
    let eventType: 'ERROR' | 'WARNING' | 'WRONG_BODY_EVENT';

    if (res?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.Devices,
        (event: EventLatest) => event.Level === 'Error');
    }

    if (res?.LatestBadEvents?.Devices?.length > 0) {
      alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
        (event: EventLatest) => event.Level === 'Error', alertEventsDevices);
    }

    if (alertEventsDevices.length > 0) {
      alertType = 'error';
      eventType = 'ERROR';
    }
    else {
      if (res?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(res.Devices, (event: EventLatest) =>
          ['Info', 'Debug'].includes(event.Level) &&
          !this.eventBodyService.isAProperEvent(event, this.getModelByEventId(res.Devices, event.Id), this.eventBodies)
        );
      }

      if (res?.LatestBadEvents?.Devices?.length > 0) {
        alertEventsDevices = this.getCertainEventsDevices(
          res.LatestBadEvents.Devices,
          (event: EventLatest) =>
            ['Info', 'Debug'].includes(event.Level) &&
            !this.eventBodyService.isAProperEvent(event, this.getModelByEventId(res.Devices, event.Id), this.eventBodies),
          alertEventsDevices
        );
      }

      if (alertEventsDevices.length > 0) {
        //Wrong body
        alertType = 'error';
        eventType = 'WRONG_BODY_EVENT';
      }
      else {
        if (res?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.Devices,
            (event: EventLatest) => event.Level === 'Warning');
        }

        if (res?.LatestBadEvents?.Devices?.length > 0) {
          alertEventsDevices = this.getCertainEventsDevices(res.LatestBadEvents.Devices,
            (event: EventLatest) => event.Level === 'Warning', alertEventsDevices);
        }

        if (alertEventsDevices.length > 0) {
          alertType = 'warning';
          eventType = 'WARNING';
        }
      }
    }

    if (alertEventsDevices.length > 0) {
      let alertEventsNumber: number = alertEventsDevices.reduce((sum, device) => sum + device.Events.length, 0);
      let errorPhrases: string[] = [
        'ALERT_PANEL.' + eventType + (alertEventsNumber > 1 ? 'S' : '') + '_DETECTED',
        'ALERT_PANEL.DEVICE' + (alertEventsDevices.length > 1 ? 'S' : '') + '_INVOLVED'
      ];

      this.alertEventsDevicesInvolved = alertEventsDevices
        .map(device => device.Name).join(', ');

      let newAlertPanelInput: AlertPanelInput = {
        AlertType: alertType,
        BoldPrefix: alertEventsNumber.toString(),
        TitleText: errorPhrases[0],
        DescriptionText: errorPhrases[1]
      };

      this.setDynamicTranslations(errorPhrases, (res: any) => {
        this.afterErrorPhrasesTranslations(res, newAlertPanelInput);
      });
    }
  }

  private getCertainEventsDevices(
    devices: EventsDeviceLatest[],
    eventCheck: (event: EventLatest) => boolean,
    initialArray: EventsDeviceLatest[] = []): EventsDeviceLatest[] {
    return devices.reduce<EventsDeviceLatest[]>((accumulator, currentDevice) => {
      let alertEvents: EventLatest[] = currentDevice.Events.filter((event: EventLatest) => eventCheck(event));
      let deviceIndex: number = accumulator.findIndex(device => device.Id === currentDevice.Id);
      if (deviceIndex !== -1) {
        accumulator[deviceIndex].Events.push(...alertEvents.filter(eventToAdd =>
          accumulator[deviceIndex].Events.find(event => event.Id === eventToAdd.Id) === undefined
        ));
        return accumulator;
      }
      else {
        return alertEvents.length > 0 ? [...accumulator, { ...currentDevice, Events: alertEvents } ] : accumulator;
      }
    }, initialArray);
  }

  private mapToTrashBinEvents(res: DeviceEventsResponse, trashBinDevices: Device[], eventBodies: Record<string, EventParsedBody>): DeviceEventTrashBin[] {
    let formattedEvents: DeviceEventTrashBin[] = [];
    res.Devices.forEach((device: DeviceEventsDevice) => {
      let trashBinDevice: Device;
      if ((trashBinDevice = trashBinDevices.find(oneDevice => oneDevice.Id === device.Id)) !== undefined) {
        let expectedBody: EventParsedBody = eventBodies[trashBinDevice.Model.Name];
        device.Events.forEach((event: DeviceEventsEvent) => {
          let eventBody: any = event.Body;
          if (this.eventBodyService.isAProperEvent(event, trashBinDevice.Model, eventBodies)) {
            let read: number;
            if (Object.keys(expectedBody.Mapping).includes('ReadPlus1Char')) {
              let readString = this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['ReadPlus1Char']).toString();
              read = (new Date(readString.substring(0, readString.length - 1))).getTime() / 1000;
            }
            else if (['ReadDate', 'ReadHour'].every((key: string) => Object.keys(expectedBody.Mapping).includes(key))) {
              let date: string = this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['ReadDate']).toString();
              let hour: string = this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['ReadHour']).toString();
              read = (new Date(`${date}T${hour}`)).getTime() / 1000;
            }

            let trashBinEvent: DeviceEventTrashBin = {
              Created: event.CreatedTimestamp,
              Device: trashBinDevice,
              Read: read,
              Percentage: +this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping['Percentage'])
            }
            formattedEvents.push(trashBinEvent);
          }
        });
      }
    });
    return formattedEvents;
  }

  private calculateTrashBinEventsAverages(events: DeviceEventTrashBin[], devices: Device[]): DeviceEventTrashBinAverage[] {
    let averages: DeviceEventTrashBinAverage[] = [];
    let averagePerDeviceId: Record<string, number> = {};
    let countPerDeviceId: Record<string, number> = {};

    devices.forEach((device: Device) => {
      averagePerDeviceId[device.Id] = 0;
      countPerDeviceId[device.Id] = 0;
    });

    events.forEach((event: DeviceEventTrashBin) => {
      let trashBinDevice: Device = event.Device;
      averagePerDeviceId[trashBinDevice.Id] += event.Percentage;
      countPerDeviceId[trashBinDevice.Id]++;
    });

    devices.forEach((device: Device) => {
      if(countPerDeviceId[device.Id] > 0) {
        averagePerDeviceId[device.Id] = averagePerDeviceId[device.Id] / countPerDeviceId[device.Id];
      }
      let average: DeviceEventTrashBinAverage = {
        Device: device,
        Percentage: Math.round(averagePerDeviceId[device.Id] * 100) / 100
      };
      averages.push(average);
    });

    return averages;
  }

  public startIntro(): void {
    this.translate.get([
      'INTRO.TRASH_BIN_WELCOME',
      'INTRO.TRASH_BIN_TABLE',
      'INTRO.SEARCH'
    ])
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(intros => {
        this.introJS
          .setOptions({
            steps: [
              {
                title: 'Welcome',
                intro: intros['INTRO.TRASH_BIN_WELCOME']
              },
              {
                title: 'Table',
                element: '#intro-trash-bin-table',
                intro: intros['INTRO.TRASH_BIN_TABLE'],
                position: 'bottom'
              },
              {
                title: 'Search bar',
                element: '#intro-trash-bin-search',
                intro: intros['INTRO.SEARCH'],
                position: 'right'
              }
            ],
            showProgress: true
          })
          .start();
      });
  }

  public objectKeysNumber(object: any): number {
    if (object) {
      return Object.keys(object).length;
    }
    else {
      return -1;
    }
  }

  public getModelByEventId(eventsDevices: EventsDeviceLatest[], eventId: string): DeviceModel {
    let deviceId: string, deviceModel: DeviceModel;

    deviceId = eventsDevices.find(device => device.Events.find(deviceEvent => deviceEvent.Id === eventId))?.Id;
    if (deviceId !== undefined) {
      deviceModel = this.trashBinDevices.find(device => device.Id === deviceId)?.Model;
    }

    return deviceModel;
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingEventsData = false;
    this.loadingLatestData = false;

    if (this.trashBinEvents.length === 0) {
      this.chartReady = true;
    }

    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  public goToDeviceDetails(deviceId: string): void {
    this.mainService.setNavigationInfoComand({ Id: deviceId, BackRoute: 'trash-bin' });
    this.router.navigate(['main/trash-bin-detail']);
  }

  public goToDomainEvents(): void {
    if (this.isDomainAdmin) {
      this.mainService.setNavigationInfoComand({ BackRoute: 'trash-bin' });
      this.router.navigate(['main/domain-events']);
    }
  }

  ngOnDestroy(): void {
    this.loader.enable();
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
