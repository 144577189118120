import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { PhonePrefix } from '../../../shared/models/phonePrefix';
import { AddAuthorizationUserDialogComponent } from '../../../shared/dialogs/add-authorization-user-dialog/add-authorization-user-dialog.component';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { UpdateUserRequest } from '../../../shared/models/administrator/updateUserRequest';
import { Domain } from '../../../shared/models/domain';
import { Authorization, User } from '../../../shared/models/loginResponse';
import { Role } from '../../../shared/models/userRoles';
import { ApiService } from '../../../shared/services/api.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-user-domain-detail',
  templateUrl: './user-domain-detail.component.html',
  styleUrls: ['./user-domain-detail.component.scss']
})
export class UserDomainDetailComponent implements OnInit, OnDestroy {

  public isAdmin: boolean = false;
  public isDomainAdmin: boolean = false;
  public editMode: boolean = false;
  public dataChanged: boolean;
  public canDelete: boolean;
  public roles: Role[] = [];
  public domain: Domain;
  public currentUser: User;
  public forbiddenRoles: string[] = [];
  public authorizations: Authorization[] = [];
  public displayedUserDomainDetailColumns: string[] = ['Domain', 'Role', 'Detail'];
  public filteredData: Authorization[] = [];
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public profileImage: string | null = null;
  public phoneNumber: string | null = null;
  public myPrefixesList: PhonePrefix[];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  public form: UntypedFormGroup = this.formBuilder.group({
    username: [{ value: '', disabled: false }, Validators.required],
    email: [{ value: '', disabled: false }, Validators.required],
    firstname: [{ value: '', disabled: false }, Validators.required],
    lastname: [{ value: '', disabled: false }, Validators.required],
    prefix: [{value: null}, Validators.required],
    telephone: [null, Validators.compose([Validators.required, Validators.pattern(/^[(]?[0-9]{3}[)]?[0-9]{3}?[0-9]{4,6}$/)])]
  });

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private router: Router,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService
  ) { }

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res?.some(x => x.Name === 'Administrators')) {
        this.forbiddenRoles.push("Administrators");
        this.isAdmin = true;
      } else if (res?.some(x => x.Name === 'Domain admin')) {
        this.forbiddenRoles.push("Domain admin", "Administrators");
        this.isDomainAdmin = true;
      } else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }
    });

    let userId: string;

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        userId = navInfo.Id;
      }
      else {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }
    });

    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((values: any) => {
      let prefix: string;
      let telephone: string;
  
      if (this.currentUser.Properties?.hasOwnProperty('PhoneNumber')) {
        this.phoneNumber = this.currentUser.Properties.PhoneNumber;

        prefix = this.phoneNumber.split(" ")[0].replace("+","");
        telephone = this.phoneNumber.split(" ")[1];
      }

      const userValues = {
        username: this.currentUser.Username,
        email: this.currentUser.Email,
        firstname: this.currentUser.Firstname,
        lastname: this.currentUser.Lastname,
        prefix: prefix != null ? +prefix : null,
        telephone: telephone ?? null
      }

      this.dataChanged = false;

      for (let key in userValues) {
        if (userValues[key] !== values[key]) {
          this.dataChanged = true;
          break;
        }
      }
    });

    this.apiService.roleList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      this.roles = x;
    });

    this.passDataService.currentDomain$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.domain = res;
    });

    this.preparePhoneData();

    this.loadData(userId);
  }

  private preparePhoneData(): void {
    this.myPrefixesList = [];

    this.apiService.getPhonePrefixes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res && res.length > 0) {
        this.myPrefixesList = res;
      }
    });
  }

  private loadData(userId: string): void {
    this.apiService.getUser(userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        this.currentUser = res;
        this.canDelete = !this.currentUser.Authorizations.some(auth => this.forbiddenRoles.includes(auth.Role.Name));

        let prefix: string;
        let telephone: string;

        if (this.currentUser.Properties?.hasOwnProperty('PhoneNumber')) {
          this.phoneNumber = this.currentUser.Properties.PhoneNumber;
  
          prefix = this.phoneNumber.split(" ")[0].replace("+","");
          telephone = this.phoneNumber.split(" ")[1];
        }

        this.form.setValue({
          username: this.currentUser.Username,
          email: this.currentUser.Email,
          firstname: this.currentUser.Firstname,
          lastname: this.currentUser.Lastname,
          prefix: prefix != null ? +prefix : null,
          telephone: telephone ?? null
        });

        this.currentUser.Properties?.hasOwnProperty('ProfileImage') ? this.profileImage = this.currentUser.Properties.ProfileImage : null;

        this.authorizations = this.currentUser.Authorizations;
        this.filteredData = this.authorizations;
      }
    });
  }

  public saveInfo(): void {
    if (this.form.valid) {
      let updateModel: UpdateUserRequest = {
        Id: '',
        Username: '',
        Email: '',
        Firstname: '',
        Lastname: ''
      }

      updateModel.Id = this.currentUser.Id;
      updateModel.Email = this.form.controls.email.value;
      updateModel.Firstname = this.form.controls.firstname.value;
      updateModel.Lastname = this.form.controls.lastname.value;
      updateModel.Username = this.form.controls.username.value;

      const updateUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          const formattedPhoneNumber: string = "+" + this.form.controls.prefix.value + " " + this.form.controls.telephone.value;

          if (this.phoneNumber !== formattedPhoneNumber) {
            this.updateUserTelephone(formattedPhoneNumber);
          }

          this.apiService.updateUser(updateModel).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: () => {
              this.dataChanged = false;
              this.editMode = false;

              this.loadData(this.currentUser.Id);
            }, error: () => {}
          });
        }
      });
    }
  }

  private updateUserTelephone(newPhoneNumber: string): void {
    this.mainService.changeUserPhoneNumberComand({ Name: 'PhoneNumber', Value: newPhoneNumber, UserId : this.currentUser.Id });
  }

  public delete(): void {
    if (this.canDelete) {

      const deleteUserConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      deleteUserConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.apiService.deleteUser(this.currentUser.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
            this.mainService.setNavigationInfoComand({ Back: true });
            this.router.navigate(['main/user-domain-list']);
          });
        }
      });
    }
  }

  public addAuthorization(): void {
    const availableRoles: Role[] = this.roles.filter(role => 
      !this.currentUser.Authorizations.some(auth => auth.Role.Id === role.Id)
    );
    
    const addAuthorizationDialogRef = this.dialog.open(AddAuthorizationUserDialogComponent, {
      data: {
        user: this.currentUser,
        domain: this.domain,
        roles: availableRoles
      }
    });

    addAuthorizationDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(authorization => {
      if (authorization) {
        const addAuthorizationConfirmDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addAuthorizationConfirmDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.addAuthorization(authorization).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
              next: () => {
                this.loadData(this.currentUser.Id);
              }, error: () => {}
            });
          }
        });
      }
    });
  }

  public deauthorize(domainId: string, roleId: string): void {
    const deauthorizeUserDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deauthorizeUserDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        let deauthorizeRequest = {
          User: this.currentUser.Id,
          Role: roleId
        }

        this.apiService.removeAuthorization(deauthorizeRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          if (this.currentUser.Authorizations.length === 1 && this.currentUser.Authorizations.some(x => x.Role.Id == roleId && x.Domain.Id == domainId)) {
            this.mainService.setNavigationInfoComand({ Back: true });
            this.router.navigate(['main/user-domain-list']);
          } else {
            this.loadData(this.currentUser.Id)
          }
        });
      }
    });
  }

  public enableEdit(): void {
    this.editMode = true;
  }

  public cancelEdit(): void {
    let prefix: string;
    let telephone: string;

    if (this.phoneNumber) {
      prefix = this.phoneNumber.split(" ")[0].replace("+","");
      telephone = this.phoneNumber.split(" ")[1];
    }

    this.form.reset({
      username: this.currentUser.Username,
      email: this.currentUser.Email,
      firstname: this.currentUser.Firstname,
      lastname: this.currentUser.Lastname,
      prefix: prefix != null ? +prefix : null,
      telephone: telephone ?? null
    });

    this.dataChanged = false;
    this.editMode = false;
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.authorizations?.filter((authorization: Authorization) =>
      [
        authorization.Domain?.Name?.toLowerCase(), 
        authorization.Role?.Name?.toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}