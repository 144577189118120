<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{deviceModel?.Name}}
        </span>
        <div class="d-flex flex-row">
            <button mat-button class="btn warnButton ml-2"
                id="deleteButton" type="button"
                (click)="deleteModel()">
                {{ 'DEVICE.DELETE' | translate }}
            </button>
        </div>
    </div>

    <form [formGroup]="form">
        <div class="panelBG roundedPanel infoPanel px-3 pt-3">
            <div class="row d-flex justify-content-between align-items-baseline mx-0 px-3 pt-3">
                <span class="title3">{{ 'GENERAL.INFO' | translate }}</span>
                <div>
                    <button mat-button class="btn actionButton" *ngIf="editMode"
                        type="button" id="updateButton" (click)="updateInfo()">
                        {{ 'GENERAL.SAVE' | translate }}
                    </button>
                    <div *ngIf="!editMode"
                        [matTooltip]="editMode === 'location' ? ('DEVICE.ONGOING_CHANGES_MESSAGE' | translate) : ''">
                        <button [disabled]="editMode === 'location'" mat-button class="btn actionButton"
                            type="button" id="editButton" (click)="editInfo()">
                            {{ 'GENERAL.EDIT' | translate }}
                        </button>
                    </div>
                    <button mat-button class="btn outlineActionButton ml-2" *ngIf="editMode"
                        type="button" id="cancelButton" (click)="cancelInfoEdit()">
                        {{ 'GENERAL.CANCEL' | translate }}
                    </button>
                </div>
            </div>
            <div class="row mx-0 pt-4">
                <div class="col-6 infoColumn">
                    <div *ngFor="let formField of formFields; let i=index" >
                        <ng-container *ngIf="i < (formFields.length / 2)" [ngTemplateOutlet]="infoForm"
                        [ngTemplateOutletContext]="{ $implicit: formField, formGroup: form }">
                        </ng-container>
                    </div>
                </div>
                <div  class="col-6 infoColumn">
                    <div *ngFor="let formField of formFields; let i=index" >
                        <ng-container *ngIf="i >= (formFields.length / 2)" [ngTemplateOutlet]="infoForm"
                        [ngTemplateOutletContext]="{ $implicit: formField, formGroup: form }">
                        </ng-container>
                    </div>
                </div>
            </div>
            <mat-error class="infoError" *ngIf="showFormError && !this.form.valid">
                {{ 'DEVICE.FORM_ERROR' | translate }}
            </mat-error>
        </div>

    </form>

    <ng-template #infoForm let-formField let-formGroup="formGroup">
        <ng-container [formGroup]="formGroup">
            <mat-form-field appearance="outline" class="customMatFormField mb-3" *ngIf="formField.formControl !== undefined && formField.formControl !== 'typeSelected'"
                [ngClass]="{'noUnderline': !editMode}">
                <mat-label>{{ formField.formLabel | translate }}</mat-label>
                <input matInput [formControlName]="formField.formControl" autocomplete="off" [readonly]="formField.readonly || !editMode" [required]="formField.required">
            </mat-form-field>

            <div *ngIf="formField.formControl === 'typeSelected'" class="d-flex mb-3">
                <mat-form-field appearance="outline" class="customMatFormField mr-3" [ngClass]="{'noUnderline': !editMode}">
                    <mat-label>{{ 'DEVICE_MODEL.TYPE' | translate }}</mat-label>
                    <mat-select formControlName="typeSelected"  hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let deviceType of deviceTypes" value={{deviceType.Id}}>
                            <span class="urbanicon-{{ deviceType?.Icon }}"></span>
                            {{deviceType.Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div [matTooltip]="editMode ? ('DEVICE.ONGOING_CHANGES_MESSAGE' | translate) : ''">
                    <button mat-button class="btn outlineActionButton no-wrap-text" (click)="typeDetail()" [disabled]="editMode" >
                        {{ 'DEVICE_MODEL.GO_TO_TYPE' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
    </ng-template>

    <div class="customTable marginTop-32">
        <div class="customTableRadius d-flex justify-content-between align-items-baseline px-4 pt-4">
            <span class="title3">
                {{ 'DEVICE_MODEL.EVENT_BODY' | translate }}
            </span>

            <button mat-button class="btn actionButton" type="button"
                    id="addFieldToEventBodyButton" (click)="addFieldToEventBody()">
                {{ 'DEVICE_MODEL.ADD_NEW_FIELD' | translate }}
            </button>
        </div>

        <div class="justify-content-between align-items-start px-4 pt-4 flex-wrap" [ngClass]="eventBody?.SingleFieldsToSet?.length === 0 ? 'd-none' : 'd-flex'">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input class="eventBodyFieldsData" matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="eventBodyFieldsActualFilter">
                <button *ngIf="eventBodyFieldsActualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); eventBodyFieldsActualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div *ngIf="eventBody?.SingleFieldsToSet?.length > 0" class="tableHeight">
            <table [dataSource]="filteredEventBodyFieldsData | paginate: eventBodyFieldsConfig"
                mat-table class="w-100 user-select-none noTableHover">
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef style="width: 18%;"> {{ 'GENERAL.NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let field">{{ field.Name }}</td>
                </ng-container>

                <ng-container matColumnDef="MapTo">
                    <th mat-header-cell *matHeaderCellDef style="width: 18%;"> {{ 'DEVICE_MODEL.MAP_TO' | translate }} </th>
                    <td mat-cell *matCellDef="let field">{{ field.MapTo ?? ('DEVICE_MODEL.N_A' | translate ) }}</td>
                </ng-container>

                <ng-container matColumnDef="Min/Max">
                    <th mat-header-cell *matHeaderCellDef style="width: 18%;"> {{ 'DEVICE_MODEL.MIN_MAX' | translate }} </th>
                    <td mat-cell *matCellDef="let field">
                        {{ (field.Min || field.Max) ? field.Min + '-' + field.Max : ('DEVICE_MODEL.N_A' | translate ) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Path">
                    <th mat-header-cell *matHeaderCellDef style="width: 18%;"> {{ 'DEVICE_MODEL.PATH' | translate }} </th>
                    <td mat-cell *matCellDef="let field">
                        <ng-container *ngIf="field.Ancestors else none">
                            <span *ngFor="let ancestor of field.Ancestors; let i = index"
                            >{{ ancestor.Name }}{{ ancestor.IsArray ? '[]' : '' }}{{ i < field.Ancestors.length - 1 ? '/' : '' }}</span>
                        </ng-container>
                        <ng-template #none>
                            {{ ('DEVICE_MODEL.N_A' | translate ) }}
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Optional">
                    <th mat-header-cell *matHeaderCellDef style="width: 18%;"> {{ 'DEVICE_MODEL.OPTIONAL' | translate }} </th>
                    <td mat-cell *matCellDef="let field">{{ (field.Optional ? 'GENERAL.YES' : 'GENERAL.NO') | translate }}</td>
                </ng-container>

                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%;"></th>
                    <td mat-cell *matCellDef="let field">
                        <div class="pr-2">
                            <i class="mr-2" matTooltip="{{'GENERAL.EDIT' | translate }}"
                                (click)="editField(field)" class="iconInfoButton urbanicon-edit">
                            </i>
                            <i class="mr-2" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                (click)="removeField(field)" class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="eventBodyColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: eventBodyColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius justify-content-between align-items-center px-4 pt-4 flex-wrap" [ngClass]="eventBody?.SingleFieldsToSet?.length === 0 ? 'd-none' : 'd-flex'">
            <div class="d-flex flex-wrap my-3 my-sm-0">
                <pagination-controls class="customNgxPaginator"
                    [id]="'eventBodyFieldsData'"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{eventBodyFieldsConfig.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredEventBodyFieldsData?.length / eventBodyFieldsConfig.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredEventBodyFieldsData?.length / eventBodyFieldsConfig.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customSelect" appearance="fill">
                    <mat-select [(value)]="eventBodyFieldsConfig.itemsPerPage">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="m-3 py-3" *ngIf="eventBody?.SingleFieldsToSet?.length === 0">
            {{ 'DEVICE_MODEL.NO_FIELDS_MESSAGE' | translate}}
        </div>
    </div>
</div>
