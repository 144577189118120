import {MainState} from "../../store/main/main.reducer";
import {LoaderService} from "./loader/loader.service";
import { HttpClient } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import * as MainActions from "../../store/main/main.actions";
import {DeviceCommandRequest} from "../models/device-command/deviceCommandRequest";
import {DeviceCommandResponse} from "../models/device-command/deviceCommandResponse";
import {DeviceCommandStatusResponse} from "../models/device-command/deviceCommandStatusResponse";
import {DeviceCommandStatusByDeviceResponse} from "../models/device-command/deviceCommandStatusByDeviceResponse";
import {DeviceCommandListRequest} from "../models/device-command/deviceCommandListRequest";
import {DeviceCommandListResponse} from "../models/device-command/deviceCommandListResponse";
import {DeviceCommandGetResponse} from "../models/device-command/deviceCommandGetResponse";
import { DeviceCommand } from "../models/device-command/deviceCommand";

@Injectable({
  providedIn: 'root'
})

export class DeviceCommandService{
  constructor(private http: HttpClient, private store: Store<MainState>, private readonly loaderService: LoaderService) {}

  public add(request: DeviceCommandRequest): Observable<DeviceCommandResponse> {
    return this.http.post<DeviceCommandRequest>(
      `uv.device.command.add.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public status(deviceCommandId: string): Observable<DeviceCommandStatusResponse> {
    return this.http.get<DeviceCommandStatusResponse>(
      `uv.device.command.status.api?id=${deviceCommandId}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public statusByDevice(deviceId: string, start: number, end: number): Observable<DeviceCommandStatusByDeviceResponse> {
    return this.http.get<DeviceCommandStatusByDeviceResponse>(
      `uv.device.command.status.device.api?d=${deviceId}&s=${start}&e=${end}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public list(request: DeviceCommandListRequest): Observable<DeviceCommandListResponse> {
    return this.http.post<DeviceCommandListRequest>(
      `uv.device.command.list.api`,
      request
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public get(id: string): Observable<DeviceCommandGetResponse> {
    return this.http.get<DeviceCommandGetResponse>(
      `uv.device.command.get.api?id=${id}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public getLatest24Hours(id: string): Observable<DeviceCommand[]> {
    return this.http.get<DeviceCommandListResponse>(
      `uv.device.command.last24hours.api?id=${id}`
    ).pipe(
      map((res: any) => res.DeviceCommands),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }

  public delete(id: string): Observable<any> {
    return this.http.get<any>(
      `uv.device.command.delete.api?id=${id}`
    ).pipe(
      map((res: any) => res),
      catchError(err => {
        this.loaderService.hide()
        return of(this.store.dispatch(MainActions.setError({error: err?.error?.Message ? err.error.Message : 'customError'})));
      })
    )
  }
}
