<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{locationEvent?.Name}}
        </span>
    </div>

    <form [formGroup]="form">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.DESCRIPTION' | translate }}</mat-label>
            <input matInput formControlName="description" autocomplete="off" required>
        </mat-form-field>

        <div class="d-flex mb-3">
            <mat-form-field appearance="outline" class="customDatePicker mr-2">
                <mat-label>{{'GENERAL.START_DATE' | translate }}</mat-label>
                <input matInput [owlDateTime]="dt1" (dateChange)="setDate($event.target.value)" formControlName="start" [owlDateTimeTrigger]="dt1" readonly>
    
                <i [owlDateTimeTrigger]="dt1" matSuffix class="urbanicon-monitoring-outline pointer"></i>
    
                <owl-date-time #dt1></owl-date-time>
            </mat-form-field>
    
            <mat-form-field appearance="outline" class="customDatePicker">
                <mat-label>{{'GENERAL.END_DATE' | translate }}</mat-label>
                <input matInput [owlDateTime]="dt2" (dateChange)="setDate($event.target.value)" formControlName="end" [owlDateTimeTrigger]="dt2" readonly>
    
                <i [owlDateTimeTrigger]="dt2" matSuffix class="urbanicon-monitoring-outline pointer"></i>
    
                <owl-date-time #dt2></owl-date-time>
            </mat-form-field>
        </div>

        <mat-error *ngIf="this.error && !this.form.valid">
            {{ 'DEVICE.FORM_ERROR' | translate }}
        </mat-error>

        <!-- <div class="buttonArea d-flex justify-content-start align-items-center pb-2"> -->
        <div class="d-flex flex-column flex-md-row flex-md-wrap">
            <button mat-button class="btn actionButton"
                    type="button" id="updateButton" (click)="update()">
                {{ 'GENERAL.UPDATE' | translate }}
            </button>
        </div>
    </form>

    <br>
    <div class="d-flex justify-content-end mx-0 mb-3">
        <button mat-button class="btn actionButton" type="button"
                (click)="addProperty()">
            {{ 'GENERAL.ADD_PROPERTY' | translate }}
        </button>
    </div>

    <div class="customTable mt-5">
        <div class="customTableRadius justify-content-between align-items-start px-4 pt-4 flex-wrap" [ngClass]="properties?.length === 0 ? 'd-none' : 'd-flex'">
            <span class="title3">
                {{ 'GENERAL.PROPERTIES' | translate }}
            </span>

            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight" *ngIf="properties?.length > 0">
            <table class="w-100 h-70 noTableHover" mat-table [dataSource]="filteredData | paginate: config">

                <ng-container matColumnDef="Key">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%"> {{ 'GENERAL.KEY' | translate }} </th>
                    <td mat-cell *matCellDef="let property">
                        {{ property.Key }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Value">
                    <th mat-header-cell *matHeaderCellDef style="width: 10%"> {{ 'GENERAL.VALUE' | translate }} </th>
                    <td mat-cell *matCellDef="let property">
                        {{ this.locationEvent.Properties[property.Key] }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 8%"></th>
                    <td mat-cell *matCellDef="let property" class="text-right pr-1">
                        <div class="pr-2">
                            <i matTooltip="{{'GENERAL.EDIT' | translate }}" (click)="openPropertyDetail(property, this.locationEvent.Properties[property])"
                                class="iconInfoButton urbanicon-edit">
                            </i>
                            <i matTooltip="{{'GENERAL.REMOVE' | translate }}" (click)="deleteProperty(property)"
                                class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="propertiesColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let property; columns: propertiesColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius justify-content-between align-items-center px-4 pt-4 flex-wrap" [ngClass]="properties?.length === 0 ? 'd-none' : 'd-flex'">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="m-3 py-3 text-center" *ngIf="properties?.length === 0">
            {{ 'LOCATIONS.NO_PROPERTIES_MESSAGE' | translate}}
        </div>
    </div>
</div>
