<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{deviceType?.Name}}
        </span>
    </div>

    <form [formGroup]="form" class="mb-5">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.NAME' | translate }}</mat-label>
            <input matInput formControlName="name" autocomplete="off"
                required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.ICON' | translate }}</mat-label>
            <input matInput formControlName="icon" autocomplete="off"
                required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.MAP_PIN' | translate }}</mat-label>
            <input matInput formControlName="mapPin"
                autocomplete="off" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.COLOR' | translate }}</mat-label>
            <input matInput formControlName="color" autocomplete="off"
                required>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.' + error | translate }}
        </mat-error>

        <div>
            <button type="button" mat-button class="btn actionButton"  (click)="update()">
                {{ 'GENERAL.UPDATE' | translate }}
            </button>
        </div>
    </form>

    <div class="customTable">
        <div class="customTableRadius d-flex justify-content-between align-items-center px-4 pt-4">
            <span class="title3">
                {{ 'DEVICE_TYPE.CAPABILITIES' | translate }}
            </span>
    
            <button type="button" mat-button class="btn actionButton" (click)="addCapability()">
                {{ 'DEVICE_TYPE.ADD_CAPABILITY' | translate }}
            </button>
        </div>
    
        <div class="d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)" autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear"
                    (click)="applyFilterString(''); actualFilter = ''" matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>
    
        <div class="tableHeight" *ngIf="deviceTypeCapabilities?.length > 0">
            <table [dataSource]="filteredData | paginate: config" mat-table class="w-100 noTableHover">
                <!-- Name Column -->
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.NAME' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Name }}
                    </td>
                </ng-container>
    
                <!-- Label Column -->
                <ng-container matColumnDef="Label">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.LABEL' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Label }}
                    </td>
                </ng-container>
    
                <!-- Action Column -->
                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.ACTION' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Action.length > 20 ? element.Action.slice(0, 30) +"..." : element.Action }}
                    </td>
                </ng-container>
    
                <!-- Action Column -->
                <ng-container matColumnDef="Order">
                    <th mat-header-cell *matHeaderCellDef> {{ 'GENERAL.ORDER' | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Order }}
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1" style="width: 15%"></th>
                    <td mat-cell *matCellDef="let element" class="text-center pr-1">
                        <div class="pr-2">
                            <i class="mr-2" matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                (click)="openCapabilityDetail(element)" class="iconInfoButton urbanicon-info-outline">
                            </i>
                            <i class="mr-2" matTooltip="{{'GENERAL.REMOVE' | translate }}"
                                (click)="capabilityDelete(element)" class="iconDeleteButton urbanicon-delete">
                            </i>
                        </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="capabilityColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: capabilityColumns;"></tr>
            </table>
        </div>
    
        <div class="py-5 text-center" *ngIf="deviceTypeCapabilities?.length === 0">
            {{ 'DEVICE_TYPE.NO_CAPABILITIES_MESSAGE' | translate}}
        </div>
    
        <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator" [maxSize]="7" [directionLinks]="true" [previousLabel]="''"
                    [nextLabel]="''" [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>
    
                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}} {{(filteredData?.length
                        / config.itemsPerPage)> 1 ? ('GENERAL.PAGES' | translate).toLowerCase() : ('GENERAL.PAGE' |
                        translate).toLowerCase()}}
                </div>
            </div>
    
            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
