import { takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';
import { DashboardManagementDialogComponent } from 'src/app/shared/dialogs/dashboard-management-dialog/dashboard-management-dialog.component';
import { Device } from 'src/app/shared/models/device';
import { DeviceType } from 'src/app/shared/models/deviceType';
import { Dashboard, DashboardRequest, SelectedDashboardUpdater } from 'src/app/shared/models/dashboard';
import { AssignedWidgets, Widget } from 'src/app/shared/models/widget';

@Component({
  selector: 'urban-dashboard-management',
  templateUrl: './dashboard-management.component.html',
  styleUrls: ['./dashboard-management.component.scss']
})
export class DashboardManagementComponent implements OnInit, OnDestroy {
  @Input('myDashboardList') public myDashboardList: Dashboard[];
  @Input('myCurrentDomainName') public myCurrentDomainName: string;
  @Input('myDevices') public myDevices: Device[];
  @Output() closeRightbar: EventEmitter<string> = new EventEmitter();

  public myDevicesTypes: DeviceType[] = [];
  public currentDashboardId: string;
  public myWidgetList: Widget[];
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    public dialog: MatDialog,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getAllDeviceTypes(this.myDevices);

    this.passDataService.dashboardToShow$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.currentDashboardId = res.Id;
      }
    });

    this.passDataService.widgets$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.myWidgetList = response;
    });
  }

  private getAllDeviceTypes(devices: Device[]): Device[] {
    let allLevelsDevices: Device[] = [];

    devices.forEach((singleDevice: Device) => {
      allLevelsDevices.push(singleDevice);

      if (singleDevice.Childs?.length > 0) {
        allLevelsDevices.push(...this.getAllDeviceTypes(singleDevice.Childs));
      }

      if (!this.myDevicesTypes.some(x => x.Id === singleDevice.Model.Type.Id)) {
        this.myDevicesTypes.push(singleDevice.Model.Type);
      }
    });

    return allLevelsDevices;
  }

  public createDashboard(): void {
    const dialogRef = this.dialog.open(DashboardManagementDialogComponent, {
      data: {
        currentDomain: this.myCurrentDomainName,
        widgetList: this.myWidgetList
      },
      width: '527px'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((request: DashboardRequest) => {
      if (request) {
        const selectedDashboard = this.myDashboardList.find(dashboard => dashboard.IsSelected);
  
        if (selectedDashboard) {
          const unselectDashboardRequest = this.composeDashboardRequest(selectedDashboard, false);
          this.mainService.updateDashboardComand(unselectDashboardRequest);
        }

        this.mainService.createDashboardComand(request);
      }
    })
  }

  public editDashboard(dashboard: Dashboard): void {
    const dialogRef = this.dialog.open(DashboardManagementDialogComponent, {
      data: {
        dashboard: dashboard,
        DevicesTypes: this.myDevicesTypes,
        currentDomain: this.myCurrentDomainName,
        widgetList: this.myWidgetList
      },
      width: '527px'
    });

    dialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((request: DashboardRequest) => {
      if (request) {
        this.mainService.updateAndReInitDashboardComand(request);
      }
    });
  }

  public deleteDashboard(dashboardId: string): void {
    const deleteDashboardConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteDashboardConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.myDashboardList = this.myDashboardList.filter(dashboard => dashboard.Id !== dashboardId);

        if (this.myDashboardList.length) {
          const currentSelectedDashboard = this.myDashboardList.find(dashboard => dashboard.IsSelected);

          if (currentSelectedDashboard?.Id === dashboardId) {
            const selectDashboardRequest = this.composeDashboardRequest(this.myDashboardList[0], true);
            this.mainService.updateDashboardComand(selectDashboardRequest);
            this.mainService.deleteDashboardComand(dashboardId);
          }
          else {
            this.mainService.deleteDashboardComand(dashboardId);
          }
        }
        else {
          this.mainService.deleteDashboardComand(dashboardId);
        }
      }
    });
  }

  public goToSelectedDashboard(clickedDashboard: Dashboard): void {
    const currentUrl = this.router.url.split('/').pop()?.split(';')[0];
  
    if (currentUrl !== 'dashboard') {
      this.mainService.setNavigationInfoComand({ BackRoute: currentUrl });
      this.mainService.updateAllDataAndGoToMainComand();
    }

    const currentSelectedDashboard = this.myDashboardList.find(dashboard => dashboard.IsSelected);

    const dashboardsToEdit: SelectedDashboardUpdater = {
      Old: currentSelectedDashboard ? this.composeDashboardRequest(currentSelectedDashboard, false) : null,
      New: this.composeDashboardRequest(clickedDashboard, true)
    };

    this.mainService.ChangeSelectedDashboardComand(dashboardsToEdit);
  }

  private mapWidgets(widgets: any[]): AssignedWidgets[] {
    return widgets?.map(({ Id, Position, Column, Configuration }) => ({
      widgetId: Id,
      Position,
      Column,
      Configuration
    })) ?? [];
  }
  
  private composeDashboardRequest(dashboard: Dashboard, isSelected: boolean): DashboardRequest {
    const { Id, Name, Widgets } = dashboard;
    return {
      Id,
      Name,
      Widgets: this.mapWidgets(Widgets),
      IsSelected: isSelected
    };
  }

  public closeThisRightbar(): void {
    this.closeRightbar.emit('dashboard');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}