<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span class="title2">{{ 'DEVICE_COMMAND.TITLE' | translate }}</span>
            <span *ngIf="currentDevice" class="title2 appearingTextRight">{{ ' - ' + this.currentDevice?.Name }}</span>
        </div>
    </div>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_COMMAND.TYPE' | translate }}</mat-label>
            <mat-select (selectionChange)="loadDeviceCommandOptions()" formControlName="deviceCommandTypeSelected">
                <mat-option *ngFor="let deviceCommandType of deviceCommandTypes" value={{deviceCommandType.Id}}>
                    {{deviceCommandType.Name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_COMMAND.AUTODELIVERY' | translate }}</mat-label>
            <mat-select formControlName="autoDelivery" hideSingleSelectionIndicator="true">
                <mat-option value="false">{{ 'GENERAL.NO' | translate }}</mat-option>
                <mat-option value="true">{{ 'GENERAL.YES' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="this.options && this.hasLength(this.options)">
            <div class="separator">{{ 'GENERAL.OPTIONS' | translate }}</div>
            <mat-form-field appearance="outline" class="customMatFormField mb-3" *ngFor="let option of getKeys(this.options)">
                <mat-label>{{option}}</mat-label>
                <input matInput value="" id="{{option}}" type="text"
                       autocomplete="off" [required]="this.options[option]">
            </mat-form-field>
            <br>
        </div>

        <mat-form-field appearance="outline" class="customDatePicker mb-3">
            <mat-label>{{ 'DEVICE_COMMAND.EXPIRATION' | translate }}</mat-label>
            <input matInput [owlDateTime]="dt1" dateChange)="setDate($event.target.value)" formControlName="expiration" [owlDateTimeTrigger]="dt1" readonly>

            <i [owlDateTimeTrigger]="dt1" matSuffix class="urbanicon-monitoring-outline pointer"></i>

            <owl-date-time #dt1></owl-date-time>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'DEVICE_COMMAND.REQUEST' | translate }}</mat-label>
            <textarea matInput
                      formControlName="command"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="20" 
                      autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-error *ngIf="error && !this.form.controls.command.dirty" class="mb-3">
            {{ 'GENERAL.ERROR_EMPTY' | translate }}
        </mat-error>

        <div>
            <button mat-button [disabled]="this.commandSent" class="btn actionButton mr-2"
                    type="button" id="updateButton" (click)="send()">
                {{ 'DEVICE_COMMAND.SEND' | translate }}
            </button>
            <button mat-button class="btn actionButton"
                    type="button" id="newButton" (click)="newCommand()">
                {{ 'DEVICE_COMMAND.NEW_COMMAND' | translate }}
            </button>
        </div>
    </form>

    <ng-template [ngIf]="this.deviceNotifications && this.deviceNotifications.length > 0">
        <div class="row mb-1 d-flex justify-content-between align-items-baseline py-2 m-0">
            <span class="h5 mb-1 mt-1">
                {{ 'DEVICE.NOTIFICATIONS' | translate }}
            </span>
        </div>

        <div class="customTable">
            <table class="w-100 h-100" mat-table [dataSource]="deviceNotificationsDataSource">
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'GENERAL.ID' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item"> {{item.Id}} </td>
                </ng-container>

                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'GENERAL.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let item"
                        style="word-break: keep-all;"> {{item.Created * 1000 | date: 'short'}} </td>
                </ng-container>

                <ng-container matColumnDef="Device">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.DEVICE' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        <span class="link">{{item.DeviceId}}</span>
                    </td>
                </ng-container>


                <ng-container matColumnDef="Command">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.DEVICE_COMMAND' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        <span class="link" style="word-break: keep-all;">{{item.DeviceCommandId}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Body">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.JSON' | translate }} </th>
                    <td mat-cell *matCellDef="let item" [matTooltip]="item.Json" [matTooltipPosition]="'above'">
                        <span class="link">
                            {{item.Json?.length > 15 ? item.Json.slice(0, 15) + "..." : item.Json }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1"></th>
                    <td mat-cell *matCellDef="let item" class="text-center pr-1">
                        <div class="pr-2">
                            <i matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                (click)="openNotificationDetailDialog(item)" class="iconInfoButton urbanicon-info-outline">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </ng-template>
</div>
