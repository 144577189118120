<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'STATISTICS.TITLE' | translate }}
        </span>

        <button type="button" class="btn outlineActionButton tutorialBtn" (click)="startIntro()">
            <i class="urbanicon-manual"></i>
        </button>
    </div>

    <div *ngIf="isChartDataReady">
        <div class="pb-3">
            <h5>{{ 'STATISTICS.CHART_SECTION_TITLE' | translate }}</h5>
            <p>{{ 'STATISTICS.CHART_SECTION_SUBTITLE' | translate }}</p>
        </div>

        <div class="mb-4">
            <span class="subheadText mr-2">{{ 'STATISTICS.SELECT_DOMAIN' | translate }}:</span>

            <mat-form-field class="customMatFormField" style="background-color: transparent !important;" appearance="outline">
                <mat-select [(ngModel)]="selectedDomainName" hideSingleSelectionIndicator="true">
                    <mat-option *ngFor="let domain of myAvailableDomains" [value]="domain.Name">
                        {{ domain.Name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <urban-searchbar id="intro-statistics-search" [passedMaxDaysRange]="10" (newSearch)="newSearch($event)"
            (stopPreviousSearch)="subscriptionsUnsubscribe()" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe"
            [loadingEvents]="loadingData !== false" [clearDateEvent]="clearDate" [setLatestDates]="setDates"
            [latestDates]="lastCreated">
        </urban-searchbar>

        <div *ngIf="(myAccessList && objectKeysNumber(myAccessList.Accesses) <= 0) ||
            (myAccessList && objectKeysNumber(myAccessList.Accesses) <= 0)"
            class="d-flex flex-column align-items-center noDataIllustration">
            <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
            <div class="d-flex">
                <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                </button>
            </div>
        </div>

        <div *ngIf="myAccessList && objectKeysNumber(myAccessList.Accesses) > 0"
            class="roundedPanel panelBG chartContainer">
            <urban-platform-statistics-barchart [barDataPassed]="myAccessList.Accesses"
                barChartTitle='STATISTICS.BAR_CHART' barChartError='STATISTICS.NO_CHART_DATA'
                valuesDescription='STATISTICS.OPERATIONS' [darkThemeActive]="this.isDarkActive">
            </urban-platform-statistics-barchart>
        </div>

        <div *ngIf="myOperationsList && objectKeysNumber(myOperationsList.Accesses) > 0"
            class="roundedPanel panelBG chartContainer">
            <urban-google-piechart [pieDataPassed]="myOperationsList.Accesses"
                passedValuesDescription='STATISTICS.OPERATIONS' passedPieChartTitle='STATISTICS.PIE_CHART'
                [darkThemeActive]="this.isDarkActive">
            </urban-google-piechart>
        </div>
        <br>

        <button type="button" class="btn actionButton mt-5" (click)="goToLogsStatistics()">
            {{ 'LOGS_STATISTICS.GO_LOGS' | translate }}
        </button>
    </div>
</div>
