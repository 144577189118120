import { NavigationInfo } from './../../shared/models/navigationExtra';
import { createReducer, on } from '@ngrx/store';
import { Resource } from '../../shared/models/resource';
import * as MainActions from './main.actions';
import * as AppActions from '../app/app.actions';
import { Device } from '../../shared/models/device';
import { DeviceBrand } from '../../shared/models/deviceBrand';
import { DeviceModel } from '../../shared/models/deviceModel';
import { ClientSettings } from '../../shared/models/clientSettings';
import { RemoteControlService } from '../../shared/models/siralab/remoteControlService';
import { Sensor } from '../../shared/models/weatherstation/sensor';
import { UVNotification } from '../../shared/models/notification';
import { StyleModel } from '../../shared/models/styleModel';
import { Location } from '../../shared/models/location';
import { Dashboard } from 'src/app/shared/models/dashboard';
import { Widget } from 'src/app/shared/models/widget';

export const mainFeatureKey = 'main';

export interface MainState {
  isLoading: boolean;
  error: string;
  success: string;
  resources: Resource[];
  googleMapsKey: string;
  dashboards: Dashboard[];
  widgets: Widget[];
  selectedDashboard: Dashboard;
  devices: Device[];
  brands: DeviceBrand[];
  models: DeviceModel[];
  clientSettings: ClientSettings;
  remoteControlServices: RemoteControlService[];
  weatherStationSensors: Sensor[];
  selectedNotification: UVNotification;
  customDomainStyle: StyleModel[];
  locations: Location[];
  navigationInfo: NavigationInfo
}

export const initialState: MainState = {
  isLoading: false,
  error: null,
  success: null,
  resources: null,
  googleMapsKey: null,
  dashboards: null,
  widgets: null,
  selectedDashboard: null,
  devices: null,
  brands: null,
  models: null,
  clientSettings: null,
  remoteControlServices: null,
  weatherStationSensors: null,
  selectedNotification: null,
  customDomainStyle: null,
  locations: null,
  navigationInfo: null
};

export const mainReducer = createReducer(
  initialState,
  on(MainActions.setResources, (state, action) => ({ ...state, resources: action.resources })),
  on(MainActions.setMapsKey, (state, action) => ({ ...state, googleMapsKey: action.googleMapsKey })),
  on(MainActions.setDashboards, (state, action) => ({ ...state, dashboards: action.dashboards, error: null })),
  on(MainActions.setWidgets, (state, action) => ({ ...state, widgets: action.widgets, error: null })),
  on(MainActions.setDashboardToShow, (state, action) => ({ ...state, selectedDashboard: action, error: null })),
  on(MainActions.setDevices, (state, action) => ({ ...state, devices: action.devices, error: null })),
  on(MainActions.setBrands, (state, action) => ({ ...state, brands: action.brands, error: null })),
  on(MainActions.setModels, (state, action) => ({ ...state, models: action.models, error: null })),
  on(MainActions.setClientSettings, (state, action) => ({ ...state, clientSettings: action.clientSettings, error: null })),
  on(MainActions.setRCServices, (state, action) => ({ ...state, remoteControlServices: action.remoteControlServices, error: null })),
  on(MainActions.setWSSensors, (state, action) => ({ ...state, weatherStationSensors: action.weatherStationSensors, error: null })),
  on(MainActions.setNotificationToShow, (state, action) => ({ ...state, selectedNotification: action, error: null })),
  on(MainActions.setDomainStyle, (state, action) => ({ ...state, customDomainStyle: action.styles, error: null })),
  on(MainActions.setLocations, (state, action) => ({ ...state, locations: action.locations, error: null })),
  on(MainActions.setNavigationInfo, (state, action) => ({ ...state, navigationInfo: action.navigationInfo, error: null })),
  on(MainActions.logout, () => ({ ...initialState })),
  on(AppActions.resetReducers, () => ({ ...initialState })),
  on(MainActions.setSuccessMessage, (state, action) => ({ ...state, success: action.success })),
  on(MainActions.setError, (state, action) => ({ ...state, error: action.error }))
);

export const selectResourcesList = (state: MainState) => state.resources;
export const selectGoogleMapsKey = (state: MainState) => state.googleMapsKey;
export const selectDashboardList = (state: MainState) => state.dashboards;
export const selectWidgetList = (state: MainState) => state.widgets;
export const selectDashboardToShow = (state: MainState) => state.selectedDashboard;
export const selectDeviceList = (state: MainState) => state.devices;
export const selectBrandsList = (state: MainState) => state.brands;
export const selectModelsList = (state: MainState) => state.models;
export const selectClientSettings = (state: MainState) => state.clientSettings;
export const selectRCServices = (state: MainState) => state.remoteControlServices;
export const selectWSSensors = (state: MainState) => state.weatherStationSensors;
export const selectNotificationToShow = (state: MainState) => state.selectedNotification;
export const selectCustomDomainStyle = (state: MainState) => state.customDomainStyle;
export const selectLocationList = (state: MainState) => state.locations;
export const selectNavigationInfo = (state: MainState) => state.navigationInfo;
export const selectSuccess = (state: MainState) => state.success;
export const selectError = (state: MainState) => state.error;


