<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'NOTIFICATION_DETAIL.TITLE' | translate }}
        </span>
    </div>

    <div class="notificationInfo panelBG">
        <form [formGroup]="form">
            <div class="mb-4">
                <span class="title3 secondaryTextColor">{{ 'GENERAL.INFO' | translate }}</span>
            </div>

            <div class="d-flex flex-wrap">
                <div class="d-flex flex-column col-12 col-md-6 pl-0">
                    <mat-form-field appearance="outline" class="customMatFormField noUnderline">
                        <mat-label>{{ 'NOTIFICATION_DETAIL.CREATED' | translate }}</mat-label>
                        <input matInput formControlName="created" autocomplete="off" readonly>
                    </mat-form-field>
            
                    <mat-form-field appearance="outline" class="customMatFormField noUnderline">
                        <mat-label>{{ 'NOTIFICATION_DETAIL.DESTINATION_TYPE' | translate }}</mat-label>
                        <input matInput formControlName="destinationType" autocomplete="off" readonly>
                    </mat-form-field>
                    
                    <div class="d-flex align-items-center flex-wrap">
                        <mat-form-field appearance="outline" class="customMatFormField noUnderline">
                            <mat-label>{{ 'NOTIFICATION_DETAIL.EXPIRATION' | translate }}</mat-label>
                            <input matInput formControlName="expiration" autocomplete="off" readonly>
                        </mat-form-field>

                        <span *ngIf="(currentNotification?.Expiration * 1000) > todayTimestamp" class="link linkText" (click)="setToExpired(currentNotification)">
                            {{'NOTIFICATION_LIST.EXPIRE' | translate }}
                        </span>
                    </div>
                </div>

                <div class="d-flex flex-column col-12 col-md-6 pl-0">
                    <mat-form-field appearance="outline" class="customMatFormField noUnderline">
                        <mat-label>{{ 'NOTIFICATION_DETAIL.PAYLOAD' | translate }}</mat-label>
                        <textarea matInput formControlName="payload" matTextareaAutosize></textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <div class="customTable mt-5">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <span class="title3">{{ 'NOTIFICATION_DETAIL.VIEW_BY' | translate }}</span>

            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)"
                    autocomplete="off" [(ngModel)]="actualFilter">
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>

        <div class="tableHeight">
            <table class="w-100 h-100 noTableHover" mat-table [dataSource]="filteredData | paginate: config">

                <ng-container matColumnDef="Firstname">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%">
                        {{'NOTIFICATION_DETAIL.FIRSTNAME' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{item.Firstname}} </td>
                </ng-container>


                <ng-container matColumnDef="Lastname">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%">
                        {{'NOTIFICATION_DETAIL.LASTNAME' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{item.Lastname}} </td>
                </ng-container>

                <ng-container matColumnDef="Username">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%">
                        {{'NOTIFICATION_DETAIL.USERNAME' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{item.Username}} </td>
                </ng-container>

                <ng-container matColumnDef="Domain">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%">
                        {{'NOTIFICATION_DETAIL.DOMAIN' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{item.Domain}} </td>
                </ng-container>

                <ng-container matColumnDef="Read">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%">
                        {{'NOTIFICATION_DETAIL.READ' | translate }} </th>
                    <td mat-cell *matCellDef="let item"> {{ item.Read * 1000 | date : 'short' }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator"
                    [maxSize]="7"
                    [directionLinks]="true"
                    [previousLabel]="''"
                    [nextLabel]="''"
                    [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>

                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>

            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
