import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarDialogComponent } from '../../../shared/dialogs/calendar-dialog/calendar-dialog.component';
import { ConfirmationDialogComponent } from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Calendar } from '../../../shared/models/calendar';
import { Location } from '../../../shared/models/location';
import { UserRoles } from '../../../shared/models/userRoles';
import { ApiService } from '../../../shared/services/api.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {

  calendarColumns = ['Text', 'Start', 'End', 'Location', 'Detail'];
  public calendars: Calendar[] = [];
  public locations: Location[] = [];
  public filteredData: Calendar[] = [];
  public isAdmin: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public userRoles: UserRoles['Roles'];
  public priorities: { [key: string]: string; };
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private passDataService: PassDataService
  ) { }

  ngOnInit(): void {
    this._initialize();
  }

  private _initialize(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.userRoles = res;

      this.apiService.calendars().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          this.calendars = result;
          this.filteredData = this.calendars;
        }

        this.apiService.locations().pipe(takeUntil(this.ngUnsubscribe)).subscribe(locs => {
          if (locs) {
            this.locations = locs;
          }
        })

        this.apiService.getClientSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(clientSettings => {
          if (clientSettings !== undefined && clientSettings.priorities !== undefined) {
            let value = clientSettings.priorities;
            this.priorities = JSON.parse(value);
          }
        })
      })
    })
  }

  public add(): void {
    const addDialogRef = this.dialog.open(CalendarDialogComponent, {
      data: {
        locations: this.locations,
        priorities: this.priorities
      },
      width: '50%',
      maxWidth: 500
    });

    addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.calendarAdd(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public detail(calendar: Calendar): void {
    if (!calendar || calendar === undefined) {
      return;
    }

    const addDialogRef = this.dialog.open(CalendarDialogComponent, {
      data: {
        calendar: calendar,
        locations: this.locations,
        priorities: this.priorities
      },
      width: '50%',
      maxWidth: 500
    });

    addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.calendarUpdate(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
              if (x) {
                this._initialize();
              }
            });
          }
        });
      }
    });
  }

  public delete(calendar: Calendar): void {
    if (!calendar || calendar === undefined) {
      return;
    }
    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.calendarDelete(calendar.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            this._initialize();
          }
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.calendars?.filter((key: Calendar) =>
      [key.Title?.toLowerCase(), key.Location?.Name.toLowerCase(),
        key.Start ? new Date(key.Start * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/') : '',
        key.End ? new Date(key.End * 1000).toISOString().slice(0,10).replace(/-/g,'/').split('/').reverse().join('/'): ''
      ]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
