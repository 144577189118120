<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'PASSAGE_DASHBOARD.TITLE' | translate }}
        </span>
        <!-- <span class="btn material-icons-outlined helpButton" (click)="startIntro()">
            live_help
        </span> -->
    </div>

    <div class="" *ngIf="alertPanelInput !== undefined">
        <urban-alert-panel
            [alertType]="alertPanelInput.AlertType" [boldPrefix]="alertPanelInput.BoldPrefix"
            [titleText]="alertPanelInput.TitleText | translate" [descriptionText]="alertPanelInput.DescriptionText"
            [buttonLabel]="isDomainAdmin ? ('DEVICE.VIEW_EVENTS' | translate) : null"
            (buttonAction)="isDomainAdmin ? goToDomainEvents() : null" (close)="alertPanelInput = undefined">
        </urban-alert-panel>
    </div>

    <div>
        <mat-tab-group id="intro-passage-dashboard-tab" animationDuration="0ms" dynamicHeight="true">
            <mat-tab [label]="'GENERAL.LAST_DATA' | translate">
                <div class="tabContent" *ngIf="passageDataSource">
                    <div *ngIf="passageDataSource.data.length > 0" class="customTable">
                        <table id="intro-passage-table" class="w-100 h-100" mat-table
                            [dataSource]="passageDataSource">

                            <ng-container matColumnDef="Device name">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'GENERAL.DEVICE' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item">{{ item.Device.Name }}</td>
                            </ng-container>

                            <ng-container matColumnDef="Last hour total">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'PASSAGE_DASHBOARD.LAST_HOUR_PASSAGES' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item">{{ item.LastHourTotal }}</td>
                            </ng-container>

                            <ng-container matColumnDef="Last 24h total">
                                <th mat-header-cell *matHeaderCellDef>
                                    {{'PASSAGE_DASHBOARD.LAST_24H_PASSAGES' | translate }}
                                </th>
                                <td mat-cell *matCellDef="let item">{{ item.Last24hTotal }}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                (click)="goToDeviceDetails(row.Device.Id)">
                            </tr>
                        </table>
                    </div>
                    <div *ngIf="passageDataSource.data.length === 0" class="d-flex align-items-center justify-content-center noDataIllustrationInTabs">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <div>
                            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                            <div class="d-flex noDataActions">
                                <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                                </button>
                                <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                                    {{ 'GENERAL.SHOW_DATA' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab [label]="'GENERAL.MAP' | translate" #mapTab>
                <div class="tabContent">
                    <urban-parking-maps-widget *ngIf="mapTab.isActive && mapReady" class="mapPanel"
                        [latestEvents]="[]" [devicesList]="passageDevices" [initialZoom]="10"
                        [darkThemeActive]="isDarkActive" [passedHeight]="650"></urban-parking-maps-widget>
                </div>
            </mat-tab>

            <mat-tab [label]="'GENERAL.CHARTS' | translate" #chartsTab>
                <div class="tabContent" *ngIf="chartsTab.isActive && passages">
                    <urban-searchbar id="intro-passage-dashboard-search" [passedMaxDaysRange]="3"
                        [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
                        (newSearch)="newSearch($event);" (stopPreviousSearch)="subscriptionsUnsubscribe()"
                        [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe"
                        [clearDateEvent]="clearDate" [setLatestDates]="setDates">
                    </urban-searchbar>

                    <div *ngIf="passages.length > 0">
                        <div class="roundedPanel panelBG chartContainer">
                            <urban-google-columnchart columnChartTitle="PASSAGE_DASHBOARD.HOURLY_AVERAGE_PASSAGES"
                                [columnChartSubtitle]="columnChartSubtitle" [passedChartData]="hourlyAveragePassagesChartData"
                                columnsTitle="REPORT.camera-geovision" columnChartId="hourlyAveragePassagesPerCamera"
                                valuesDescription="PASSAGE_DASHBOARD.HOURLY_AVERAGE" [darkThemeActive]="this.isDarkActive">
                            </urban-google-columnchart>
                        </div>
                    </div>
                    <div *ngIf="passages.length === 0" class="d-flex align-items-center justify-content-center noDataIllustrationInTabs">
                        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                        <div>
                            <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
                            <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
                            <div class="d-flex noDataActions">
                                <button type="button" class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                                    {{ 'GENERAL.NEW_SEARCH' | translate }}
                                </button>
                                <button type="button" class="btn outlineActionButton" (click)="loadLatestData()">
                                    {{ 'GENERAL.SHOW_DATA' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<div>
    <urban-loader [localized]="true" [onContent]="true" [disappearCondition]="loadingData === false">
    </urban-loader>
</div>
