import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationDialogComponent} from '../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import {LocationDialogComponent} from '../../../shared/dialogs/location-dialog/location-dialog.component';
import {Location} from '../../../shared/models/location';
import {ApiService} from '../../../shared/services/api.service';
import * as MainActions from '../../../store/main/main.actions';
import {MainState} from '../../../store/main/main.reducer';
import {MainSubscriptionsService} from "../../../shared/services/main-subscriptions/main-subscriptions.service";
import {Router} from "@angular/router";
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit, OnDestroy {

  public locationColumns = ['Name', 'Address', 'Zip', 'City', 'State', 'Country', 'Detail'];
  public locations: Location[] = [];
  public filteredData: Location[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
    private router: Router,
    private store: Store<MainState>
  ) {
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.apiService.locations().pipe(takeUntil(this.ngUnsubscribe)).subscribe(locations => {
      if (locations) {
        this.locations = locations;
        this.filteredData = this.locations;

        this.store.dispatch(MainActions.setLocations({locations: this.locations}));
      }
    });
  }

  public add(): void {
    const addDialogRef = this.dialog.open(LocationDialogComponent, {
      width: '50%',
      maxWidth: 500
    });

    addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.apiService.locationAdd(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }

  public delete(location: Location): void {
    if (!location || location === undefined) {
      return;
    }

    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.apiService.locationDelete(location.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.loadData();
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.locations?.filter((location: Location) =>
      [
        location.Name?.toLowerCase(), 
        location.Address?.toLowerCase(), 
        location.Zip?.toLowerCase(), 
        location.City?.toLowerCase(),
        location.Zip?.toLowerCase(), 
        location.State?.toLowerCase(),
        location.Country?.toLowerCase()
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public detail(location: Location): void {
    if (!location) {
      return;
    }

    this.mainService.setNavigationInfoComand({Id: location.Id, BackRoute: 'location-list'});
    this.router.navigate(['main/location-detail']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
