import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { DeviceEventLatestRequest, DeviceEventLatestResponse, EventsDeviceLatest } from 'src/app/shared/models/deviceEventLatest';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { DeviceEventDetailDialogComponent } from '../../../shared/dialogs/device-event-detail-dialog/device-event-detail-dialog.component';
import { Device } from '../../../shared/models/device';
import { DeviceEvent } from '../../../shared/models/deviceEvent';
import { DeviceEventsRequest } from '../../../shared/models/deviceEventsRequest';
import { ApiService } from '../../../shared/services/api.service';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { ManualEventDialogComponent } from 'src/app/shared/dialogs/manual-event-dialog/manual-event-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AdministratorApiService } from 'src/app/shared/services/administrator-api.service';
import { DeviceEventRequest } from 'src/app/shared/models/administrator/deviceEventRequest';
import { EventBody, EventBodyField, EventParsedBody, EventRandomType, FieldAncestor, FieldsGroup, FieldValue } from 'src/app/shared/models/administrator/eventBody';
import { PaginationInstance } from 'ngx-pagination';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';

@Component({
  selector: 'urban-device-events',
  templateUrl: './device-events.component.html',
  styleUrls: ['./device-events.component.scss']
})
export class DeviceEventsComponent implements OnInit, OnDestroy {

  public isAdmin: boolean;
  public isDomainAdmin: boolean;
  public displayedColumns: string[] = ['Id', 'Created', 'Type', 'Level', 'Body', 'Detail'];
  public events: DeviceEvent[] = [];
  public filteredData: DeviceEvent[] = [];
  public isDarkActive: boolean;
  public actualFilter: string = '';
  public currentDevice: Device;
  public last24hSearch: boolean = true;
  public lastAvailabletDataSearch: boolean;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public startDate: number;
  public endDate: number;
  public loadingData: boolean = false;
  public eventLevelColors: string[] = [];
  public randomParsedBodyEvent: EventParsedBody;
  // public selectedEvents: Record<string, boolean>;
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  }
  private subscription: Subject<void> = new Subject<void>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private administratorApiService: AdministratorApiService,
    private apiSync: ApiSynchronizerService,
    private dialog: MatDialog,
    private passDataService: PassDataService,
    private router: Router,
    private mainService: MainSubscriptionsService,
    private eventBodyService: EventBodyUtilityService
  ) {}

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isAdmin = res?.some(x => x.Name == 'Administrators');
      this.isDomainAdmin = res?.some(x => x.Name == 'Domain admin');

      if (!this.isAdmin && !this.isDomainAdmin) {
        this.setErrorAndGoToMain();
        return;
      }

      this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
        if (!navInfo?.Id) {
          this.setErrorAndGoToMain();
          return;
        }

        this.loadData(navInfo.Id);
      });
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });

    this.eventLevelColors = this.getEventLevelColors();
  }

  private loadData(deviceId: string): void {
    let deviceFeature: number, eventListFeature: number;
    const context: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, context);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues[deviceFeature]) {
        this.initRandomEventGeneration();

        if (checkValues[eventListFeature]) {
          this.config.totalItems = data[eventListFeature].Total;
          this.events = data[eventListFeature].Events;
          this.filteredData = this.events;
        }
      }
      this.loadingData = false;
    }, context);

    let firstRequest: DeviceEventsRequest = {
      Device: deviceId,
      Page: 0,
      Limit: this.config.itemsPerPage
    };

    this.apiService.getDevice(deviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(device => {
      if (device) {
        this.currentDevice = device;
        deviceFeature = this.apiSync.loadedFeature(context);
      }
      else {
        deviceFeature = this.apiSync.failedFeature(context);
        this.setErrorAndGoToMain();
      }
    });

    this.apiService.deviceEventsList(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res) {
        eventListFeature = this.apiSync.loadedFeatureWithData(res, context);
      }
      else {
        eventListFeature = this.apiSync.failedFeature(context);
      }
    });
  }

  public loadLatestData(): void {
    this.loadingData = true;
    this.clearDate = !this.clearDate;
    this.lastAvailabletDataSearch = true;

    let latestEventsRequest: DeviceEventLatestRequest = { DeviceId: this.currentDevice.Id };

    this.apiService.getDeviceEventLatest24Hours(latestEventsRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventLatestResponse) => {
      if(res?.Devices?.length > 0) {
        this.setLatestDates(res);

        this.config.totalItems = res.Devices[0].Events.length;
        this.events = this.mapToEventList(res);
      }
      else {
        this.events = [];
      }
      this.filteredData = this.events;

      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventLatestResponse): void {
    this.endDate = Math.max(...res.Devices.map((device: EventsDeviceLatest) => device.Events[0].CreatedTimestamp));
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates?: SearchDates): void {
    this.actualFilter = '';
    this.loadingData = true;
    this.lastAvailabletDataSearch = false;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
      this.config.currentPage = 1;
    }

    let serverRequest: DeviceEventsRequest = {
      Device: this.currentDevice.Id,
      Page: this.config.currentPage - 1,
      Limit: this.config.itemsPerPage,
      Start: this.startDate,
      End: this.endDate
    }

    this.apiService.deviceEventsList(serverRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      if (res) {
        this.events = res.Events;
        this.filteredData = this.events;
        this.config.totalItems = res.Total;
      }

      if (selectedDates !== null && selectedDates !== undefined) {
        this.last24hSearch = selectedDates.last24hSearch;
      }
      this.loadingData = false;
    });
  }

  public addEvent(): void {
    if (this.isAdmin) {
      const addEventDialog = this.dialog.open(ManualEventDialogComponent, {
        disableClose: false,
        data:{
          deviceId: this.currentDevice.Id
        }
      });

      addEventDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(deviceEvent => {
        if (deviceEvent) {
          const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: false
          })

          confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
            if(confirmed){
              this.administratorApiService.deviceEventAdd(deviceEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                this.loadLatestData();
              });
            }
          });
        }
      });
    }
  }

  private initRandomEventGeneration(): void {
    let randomEventRules: string = this.currentDevice.Model.EventBody;

    if (randomEventRules) {
      try {
        let randomEvent: EventBody = JSON.parse(randomEventRules);
        if (randomEvent.SingleFieldsToSet) {
          this.randomParsedBodyEvent = {
            MandatorySingleFields: randomEvent.SingleFieldsToSet,
            Mapping: {}
          };
        }
      }
      catch(e){};
    }
  }

  public addRandomEvent(): void {
    let newBody: any = {};
    let fieldsGroups: FieldsGroup[] = [];

    this.randomParsedBodyEvent.MandatorySingleFields.filter(field =>
      field.Type === EventRandomType.NotRandom &&
      (field.Default !== undefined || field.Defaults?.length > 0)
    ).forEach(field => { this.checkDefaultsAndInsertIntoFieldsGroup(field, fieldsGroups); });

    this.randomParsedBodyEvent.MandatorySingleFields.filter(field =>
      field.Type === EventRandomType.RandomValue &&
      typeof field.Min === 'number' &&
      typeof field.Max === 'number'
    ).forEach(field => { this.checkCountAndInsertIntoFieldsGroup(field, +field.Max, fieldsGroups); });

    this.randomParsedBodyEvent.MandatorySingleFields.filter(field =>
      field.Type === EventRandomType.RandomValue &&
      typeof field.Min === 'number' &&
      typeof field.Max === 'string'
    ).forEach(field => {
      let foundField: EventBodyField = this.randomParsedBodyEvent.MandatorySingleFields.find(fieldToFind => fieldToFind.Name === field.Max);
      let fieldMax: number = this.eventBodyService.getFieldMax(newBody, foundField, field.MaxOffset);
      if (fieldMax === undefined) {
        return;
      }

      this.checkCountAndInsertIntoFieldsGroup(field, fieldMax, fieldsGroups);
    });

    let remainingPercentage: number = 100;
    let percentageValueFields: EventBodyField[] = [];
    this.randomParsedBodyEvent.MandatorySingleFields
    .filter((field: EventBodyField) => field.Type === EventRandomType.RandomPercentage).forEach(field => {
      if (field.RandomValuesCount && field.RandomValuesCount > 1) {
        percentageValueFields.push(...Array<EventBodyField>(field.RandomValuesCount).fill(field))
      }
      else {
        percentageValueFields.push(field)
      }
    });
    let percentageValuesCount: number = percentageValueFields.length;

    if (percentageValuesCount > 0) {
      percentageValueFields.slice(0, -1).forEach(field => {
        let newPercValue: number = Math.round(Math.random() * remainingPercentage);
        this.insertIntoFieldsGroup({Field: field, Value: newPercValue}, fieldsGroups);

        remainingPercentage -= newPercValue;
      });
      this.insertIntoFieldsGroup({Field: percentageValueFields[percentageValuesCount - 1], Value: remainingPercentage}, fieldsGroups);
    }

    fieldsGroups.forEach((fieldsGroup: FieldsGroup) => {
      this.eventBodyService.setNestedValues(newBody, fieldsGroup);
    });

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if(result){
        let deviceEvent: DeviceEventRequest = {
          Device: this.currentDevice.Id,
          Type: 'add',
          Level: 'info',
          Body: JSON.stringify(newBody),
          Created: Math.floor(Date.now() / 1000)
        };

        this.administratorApiService.deviceEventAdd(deviceEvent).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.loadLatestData();
        });
      }
    });
  }

  private insertIntoFieldsGroup(fieldValue: FieldValue, fieldsGroups: FieldsGroup[]) {
    let field: EventBodyField = fieldValue.Field;
    let indexFound: number = fieldsGroups.findIndex((fieldsGroup: FieldsGroup) => {
      let notSameFields: boolean = !fieldsGroup.some(singleFieldValue => singleFieldValue.Field.Name === fieldValue.Field.Name);
      let noAncestors: boolean = !(field.Ancestors?.length > 0) && fieldsGroup.some(singleFieldValue => !(singleFieldValue.Field.Ancestors?.length > 0));
      let sameAncestors: boolean = fieldsGroup.some((singleFieldValue: FieldValue) =>
        singleFieldValue.Field.Ancestors?.length === field.Ancestors?.length &&
        singleFieldValue.Field.Ancestors?.every((ancestor, index) => ancestor.Name === field.Ancestors?.[index]?.Name));
      return notSameFields && (noAncestors || sameAncestors);}
    );
    if (indexFound !== -1) {
      fieldsGroups[indexFound].push(fieldValue);
    }
    else {
      fieldsGroups.push([fieldValue]);
    }
  }

  private checkDefaultsAndInsertIntoFieldsGroup(field: EventBodyField, fieldsGroups: FieldsGroup[]) {
    if (field.Defaults?.length > 0) {
      if (field.Ancestors?.length > 0 && field.Ancestors.some((ancestor: FieldAncestor) => ancestor.IsArray)) {
        field.Defaults.forEach((defaultValue: number | string) => {
          this.insertIntoFieldsGroup({Field: field, Value: defaultValue}, fieldsGroups);
        });
      }
      else {
        this.insertIntoFieldsGroup({Field: field, Value: field.Defaults[0]}, fieldsGroups);
      }
    }
    else {
      this.insertIntoFieldsGroup({Field: field, Value: field.Default}, fieldsGroups);
    }
  }

  private checkCountAndInsertIntoFieldsGroup(field: EventBodyField, fieldMax: number, fieldsGroups: FieldsGroup[]) {
    if (field.RandomValuesCount && field.RandomValuesCount> 1) {
      for (let i: number = 0; i < field.RandomValuesCount; i++) {
        this.insertIntoFieldsGroup({Field: field, Value: this.generateRandomValue(field, fieldMax)}, fieldsGroups);
      }
    }
    else {
      this.insertIntoFieldsGroup({Field: field, Value: this.generateRandomValue(field, fieldMax)}, fieldsGroups);
    }
  }

  private generateRandomValue(field: EventBodyField, fieldMax: number): number {
    let randomValue: number = Math.random() * (fieldMax - field.Min) + field.Min;
    let resolution: number = typeof field.DecimalsCount === 'number' ? Math.pow(10, field.DecimalsCount) : 0;
    return Math.round(randomValue * resolution)/resolution;
  }

  private mapToEventList(res: DeviceEventLatestResponse): DeviceEvent[] {
    let deviceEvents: DeviceEvent[] = [];

    res.Devices[0].Events.forEach(event => {
      deviceEvents.push({
        Id: event.Id,
        DeviceId: res.Devices[0].Id,
        DeviceName: res.Devices[0].Name,
        DeviceType: res.Devices[0].Model.Type.Name,
        Type: event.Type,
        Level: event.Level,
        Created: event.CreatedTimestamp,
        Body: JSON.stringify(event.Body),
        Icon: res.Devices[0].Model.Type.Icon
      })
    });

    return deviceEvents
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.events?.filter((event: DeviceEvent) =>
      [
        event.Id.toLowerCase(),
        event.Created ? new Date(event.Created * 1000).toLocaleString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        }) : '',
        event.Type?.toLowerCase(),
        event.Level?.toLowerCase(),
        event.Body?.toLowerCase(),
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;

    if (!this.lastAvailabletDataSearch) {
      this.newSearch();
    }
  }

  public paginatorOnItemsPerPageChange(): void {
    this.config.currentPage = 1;
    this.newSearch();
  }

  public paginatorGetMaxPage(): number {
    return Math.ceil(this.config.totalItems / this.config.itemsPerPage);
  }

  private getEventLevelColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push('#BC9A29');

    return colors;
  }

  public openDetailDialog(singleEvent: DeviceEvent): void {
    this.dialog.open(DeviceEventDetailDialogComponent, {
      data: { targetEvent: singleEvent },
      maxWidth: 1000,
      width: '70%'
    });
  }

  // public selectEvent(eventId: string, event: Event): void {
  //   event.preventDefault();
  //   if (this.selectedEvents === undefined) {
  //     this.selectedEvents = {};
  //   }

  //   this.selectedEvents[eventId] = this.selectedEvents[eventId] !== true;

  //   if (Object.values(this.selectedEvents).every(value => !value)) {
  //     this.selectedEvents = undefined;
  //   }
  // }

  // public deselectEvents(event: Event): void {
  //   event.preventDefault();
  //   this.selectedEvents = undefined;
  // }

  // public deleteEvents(): void {
  //   const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
  //     disableClose: false
  //   })


  //   let eventsIds: string = Object.keys(this.selectedEvents)
  //   .filter((key: string) => this.selectedEvents[key]).join(',');
  //   console.log(eventsIds);

  //   confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
  //     if(result){\
  //       let eventsIds: string = Object.keys(this.selectedEvents)
  //         .filter((key: string) => this.selectedEvents[key]).join(',');
  //       this.administratorApiService.deviceEventDelete(eventsIds)
  //         .pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
  //         this.selectedEvents = undefined;
  //         this.loadLatestData();
  //       });
  //     }
  //   });
  // }

  public deleteEvent(eventId: string): void {
    if (this.isAdmin) {
      const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      })

      confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if(result){
          this.administratorApiService.deviceEventDelete(eventId)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.loadLatestData();
          });
        }
      });
    }
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
