import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { WalledGardenDialogComponent } from '../../../../shared/dialogs/walled-garden-dialog/walled-garden-dialog.component';
import { SMSSDomain } from '../../../../shared/models/smss/smssDomain';
import { SMSSGateway } from '../../../../shared/models/smss/smssGateway';
import { SMSSManager } from '../../../../shared/models/smss/smssManager';
import { SMSSWpTemplate } from '../../../../shared/models/smss/smssWpTemplate';
import { SMSSWpWalledGarden } from '../../../../shared/models/smss/smssWpWalledGarden';
import { MainSubscriptionsService } from '../../../../shared/services/main-subscriptions/main-subscriptions.service';
import { WifiSmssService } from '../../../../shared/services/wifi-smss.service';

@Component({
  selector: 'urban-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss']
})
export class GatewayComponent implements OnInit, OnDestroy {

  public gateway: SMSSGateway;
  public managers: SMSSManager[] = [];
  public manager: SMSSManager;
  public domain: SMSSDomain;
  public hardwareTypes: { [key: string]: number };
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  public isAdd: boolean = false;
  public error: string | null;
  private gatewayId: string | null;

  public walledGardens: SMSSWpWalledGarden[] = [];
  public displayedWalledGardensColumns = ['Id', 'DestHost', 'DestHostPort', 'Detail'];
  public wpWalledGardensDataSource: MatTableDataSource<SMSSWpWalledGarden>;

  public wpTemplates: SMSSWpTemplate[] = [];
  public displayedwpTemplateColumns = ['Id', 'Code', 'Detail'];
  public wpTemplatesDataSource: MatTableDataSource<SMSSWpTemplate>;

  public form: UntypedFormGroup = this.formBuilder.group({
    Id: [{ value: '', disabled: true }],
    CreationDate: [{ value: '', disabled: true }],
    DomainId: [{ value: '', disabled: true }, Validators.required],
    ManagerId: [{ value: '', disabled: true }, Validators.required],
    GatewayName: ['', Validators.required],
    MacAddress: [''],
    MultipleLoginTitle: [''],
    Address: [''],
    Zip: [''],
    City: [''],
    State: [''],
    Country: [''],
    Phone: [''],
    MobilePhone: [''],
    Log: ['0'],
    IPAddress: [''],
    TemplateID: [''],
    ManifacturerWebAdminAddress: [''],
    HardwareType: [''],
    HardwareVersion: [''],
    Uptime: [{ value: '', disabled: true }],
    MaxUsers: [0],
    UseTemplateColor: [0],
    Advertising: [0],
    Survey: [0],
    Longitude: [''],
    Latitude: [''],
    GPSTrackingEnabled: [''],
    GPSTrackingKeepLogsFor: [0],
    Timezone: [''],
    Timetables: [0],
    DaysOfWeekTimetable: [0],
    MonthsTimetable: [0],
    Injection: [''],
    InjectionStartSeconds: [0],
    InjectionFrequencySeconds: [0],
    InjectionShowToolBar: [''],
    InjectionInWelcomePortal: [''],
    COINGatewayGUID: [''],
    COINWOL: [''],
    COINDns: [''],
    CustomApps: [''],
    FacebookFanPageURL: [''],
    FacebookPublishUponConnection: [''],
    TwitterFollowerUser: [''],
    DisableAppleCaptivePortal: [''],
    DisableAndroidCaptivePortal: [''],
    DisableWindowsPhoneCaptivePortal: [''],
    Notes: [''],
    EnableNotifications: [''],
    WalledGardensOwnForEachGateway: [''],
    Locked: [''],
    AuthUsersByMACLogin: [''],
    AuthUsersWithoutRealm: [''],
    EnableWireless: ['0'],
    SSID: [''],
    WANConfigType: [''],
    HotspotInLAN: [''],
    NetworkAddressingMode: ['0'],
    WanInterface: [''],
    UseVLAN: [''],
    VLANID: [''],
    WANUseDHCP: [''],
    WANIPAddress: [''],
    WANNetMask: [''],
    WANGatewayIP: [''],
    PPPoEClientUserName: [''],
    PPPoEClientPassword: [''],
    PPPoEClientDialOnDemand: [''],
    PPPoEClientMTU: [''],
    USBModemAPN: [''],
    LTEAuthentication: [''],
    USBModemUserName: [''],
    USBModemPassword: [''],
    USBModemInitATCommand: [''],
    USBModemDialATCommand: [''],
    USBModemUSBPort: [''],
    USBModemPhoneNumber: [''],
    CertificateID: [''],
    CertificateDomain: [''],
    EnableHotspotBridgeOnEth1: [''],
    EnableHotspotBridgeOnEth2: [''],
    EnableHotspotBridgeOnEth3: [''],
    EnableHotspotBridgeOnEth4: [''],
    EnableHotspotBridgeOnEth5: [''],
    EnableHotspotBridgeOnEth6: [''],
    EnableHotspotBridgeOnEth7: [''],
    EnableHotspotBridgeOnEth8: [''],
    EnableHotspotBridgeOnEth9: [''],
    EnableHotspotBridgeOnEth10: [''],
    EnableHotspotBridgeOnEth11: [''],
    EnableHotspotBridgeOnEth12: [''],
    KeepAliveTimeout: [0],
    HotspotIPAddress: [''],
    HotspotNetMask: [''],
    DNSIPAddress: [''],
    UsersPoolFromIP: [''],
    UsersPoolToIP: [''],
    UsersPoolLeaseTimeHours: [0],
    HotspotUseVLAN: [''],
    HotspotVLANID: [''],
    PPTPEnabled: [''],
    PPTPServer: [''],
    PPTPUserName: [''],
    PPTPPassword: [''],
    GPSEnable: [''],
    GPSType: [''],
    GPSUSBPort: [''],
    WalledGardenUpdateMins: [0],
    UpdateGatewayStatusMins: [0],
    AdminPassword: [''],
    StoresUserDataRates: [''],
    CustomCommands: [''],
    UpdatableConfig: [''],
    ImportUMUsers: [''],
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: WifiSmssService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService
  ) { }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this._init();
  }

  private _loadForm(): void {

    this.form.controls.Id.setValue(this.gateway.id);
    this.form.controls.CreationDate.setValue(this.gateway.CreationDate);
    this.form.controls.GatewayName.setValue(this.gateway.GatewayName);
    this.form.controls.MacAddress.setValue(this.gateway.MacAddress);
    this.form.controls.MultipleLoginTitle.setValue(this.gateway.MultipleLoginTitle);
    this.form.controls.Address.setValue(this.gateway.Address);
    this.form.controls.Zip.setValue(this.gateway.Zip);
    this.form.controls.City.setValue(this.gateway.City);
    this.form.controls.State.setValue(this.gateway.State);
    this.form.controls.Country.setValue(this.gateway.Country);
    this.form.controls.Phone.setValue(this.gateway.Phone);
    this.form.controls.MobilePhone.setValue(this.gateway.MobilePhone);
    this.form.controls.Log.setValue(this.gateway.Log);
    this.form.controls.IPAddress.setValue(this.gateway.IPAddress);
    this.form.controls.ManifacturerWebAdminAddress.setValue(this.gateway.ManifacturerWebAdminAddress);
    this.form.controls.HardwareType.setValue(this.gateway.HardwareType);
    this.form.controls.HardwareVersion.setValue(this.gateway.HardwareVersion);
    this.form.controls.Uptime.setValue(this.gateway.Uptime);
    this.form.controls.MaxUsers.setValue(+this.gateway.MaxUsers);
    this.form.controls.TemplateID.setValue(this.gateway.TemplateID);
    this.form.controls.UseTemplateColor.setValue(+this.gateway.UseTemplateColor);
    this.form.controls.Advertising.setValue(+this.gateway.Advertising);
    this.form.controls.Survey.setValue(+this.gateway.Survey);
    this.form.controls.Longitude.setValue(this.gateway.Longitude);
    this.form.controls.Latitude.setValue(this.gateway.Latitude);
    this.form.controls.GPSTrackingEnabled.setValue(this.gateway.GPSTrackingEnabled);
    this.form.controls.GPSTrackingKeepLogsFor.setValue(+this.gateway.GPSTrackingKeepLogsFor);
    this.form.controls.Timezone.setValue(this.gateway.Timezone);
    this.form.controls.Timetables.setValue(+this.gateway.Timetables);
    this.form.controls.DaysOfWeekTimetable.setValue(+this.gateway.DaysOfWeekTimetable);
    this.form.controls.MonthsTimetable.setValue(+this.gateway.MonthsTimetable);
    this.form.controls.Injection.setValue(this.gateway.Injection);
    this.form.controls.InjectionStartSeconds.setValue(+this.gateway.InjectionStartSeconds);
    this.form.controls.InjectionFrequencySeconds.setValue(+this.gateway.InjectionFrequencySeconds);
    this.form.controls.InjectionShowToolBar.setValue(this.gateway.InjectionShowToolBar);
    this.form.controls.InjectionInWelcomePortal.setValue(this.gateway.InjectionInWelcomePortal);
    this.form.controls.COINGatewayGUID.setValue(this.gateway.COINGatewayGUID);
    this.form.controls.COINWOL.setValue(this.gateway.COINWOL);
    this.form.controls.COINDns.setValue(this.gateway.COINDns);
    this.form.controls.CustomApps.setValue(this.gateway.CustomApps?.join(','));
    this.form.controls.FacebookFanPageURL.setValue(this.gateway.FacebookFanPageURL);
    this.form.controls.FacebookPublishUponConnection.setValue(this.gateway.FacebookPublishUponConnection);
    this.form.controls.TwitterFollowerUser.setValue(this.gateway.TwitterFollowerUser);
    this.form.controls.DisableAppleCaptivePortal.setValue(this.gateway.DisableAppleCaptivePortal);
    this.form.controls.DisableAndroidCaptivePortal.setValue(this.gateway.DisableAndroidCaptivePortal);
    this.form.controls.DisableWindowsPhoneCaptivePortal.setValue(this.gateway.DisableWindowsPhoneCaptivePortal);
    this.form.controls.Notes.setValue(this.gateway.Notes);
    this.form.controls.EnableNotifications.setValue(this.gateway.EnableNotifications);
    this.form.controls.WalledGardensOwnForEachGateway.setValue(this.gateway.WalledGardensOwnForEachGateway);
    this.form.controls.Locked.setValue(this.gateway.Locked);
    this.form.controls.AuthUsersByMACLogin.setValue(this.gateway.AuthUsersByMACLogin);
    this.form.controls.AuthUsersWithoutRealm.setValue(this.gateway.AuthUsersWithoutRealm);
    this.form.controls.EnableWireless.setValue(this.gateway.EnableWireless);
    this.form.controls.SSID.setValue(this.gateway.SSID);
    this.form.controls.WANConfigType.setValue(this.gateway.WANConfigType);
    this.form.controls.HotspotInLAN.setValue(this.gateway.HotspotInLAN);
    this.form.controls.NetworkAddressingMode.setValue(this.gateway.NetworkAddressingMode);
    this.form.controls.WanInterface.setValue(this.gateway.WanInterface);
    this.form.controls.UseVLAN.setValue(this.gateway.UseVLAN);
    this.form.controls.VLANID.setValue(this.gateway.VLANID);
    this.form.controls.WANUseDHCP.setValue(this.gateway.WANUseDHCP);
    this.form.controls.WANIPAddress.setValue(this.gateway.WANIPAddress);
    this.form.controls.WANNetMask.setValue(this.gateway.WANNetMask);
    this.form.controls.WANGatewayIP.setValue(this.gateway.WANGatewayIP);
    this.form.controls.PPPoEClientUserName.setValue(this.gateway.PPPoEClientUserName);
    this.form.controls.PPPoEClientPassword.setValue(this.gateway.PPPoEClientPassword);
    this.form.controls.PPPoEClientDialOnDemand.setValue(this.gateway.PPPoEClientDialOnDemand);
    this.form.controls.PPPoEClientMTU.setValue(this.gateway.PPPoEClientMTU);
    this.form.controls.USBModemAPN.setValue(this.gateway.USBModemAPN);
    this.form.controls.LTEAuthentication.setValue(this.gateway.LTEAuthentication);
    this.form.controls.USBModemUserName.setValue(this.gateway.USBModemUserName);
    this.form.controls.USBModemPassword.setValue(this.gateway.USBModemPassword);
    this.form.controls.USBModemInitATCommand.setValue(this.gateway.USBModemInitATCommand);
    this.form.controls.USBModemDialATCommand.setValue(this.gateway.USBModemDialATCommand);
    this.form.controls.USBModemUSBPort.setValue(this.gateway.USBModemUSBPort);
    this.form.controls.USBModemPhoneNumber.setValue(this.gateway.USBModemPhoneNumber);
    this.form.controls.CertificateID.setValue(this.gateway.CertificateID);
    this.form.controls.CertificateDomain.setValue(this.gateway.CertificateDomain);
    this.form.controls.EnableHotspotBridgeOnEth1.setValue(this.gateway.EnableHotspotBridgeOnEth1);
    this.form.controls.EnableHotspotBridgeOnEth2.setValue(this.gateway.EnableHotspotBridgeOnEth2);
    this.form.controls.EnableHotspotBridgeOnEth3.setValue(this.gateway.EnableHotspotBridgeOnEth3);
    this.form.controls.EnableHotspotBridgeOnEth4.setValue(this.gateway.EnableHotspotBridgeOnEth4);
    this.form.controls.EnableHotspotBridgeOnEth5.setValue(this.gateway.EnableHotspotBridgeOnEth5);
    this.form.controls.EnableHotspotBridgeOnEth6.setValue(this.gateway.EnableHotspotBridgeOnEth6);
    this.form.controls.EnableHotspotBridgeOnEth7.setValue(this.gateway.EnableHotspotBridgeOnEth7);
    this.form.controls.EnableHotspotBridgeOnEth8.setValue(this.gateway.EnableHotspotBridgeOnEth8);
    this.form.controls.EnableHotspotBridgeOnEth9.setValue(this.gateway.EnableHotspotBridgeOnEth9);
    this.form.controls.EnableHotspotBridgeOnEth10.setValue(this.gateway.EnableHotspotBridgeOnEth10);
    this.form.controls.EnableHotspotBridgeOnEth11.setValue(this.gateway.EnableHotspotBridgeOnEth11);
    this.form.controls.EnableHotspotBridgeOnEth12.setValue(this.gateway.EnableHotspotBridgeOnEth12);
    this.form.controls.KeepAliveTimeout.setValue(+this.gateway.KeepAliveTimeout);
    this.form.controls.HotspotIPAddress.setValue(this.gateway.HotspotIPAddress);
    this.form.controls.HotspotNetMask.setValue(this.gateway.HotspotNetMask);
    this.form.controls.DNSIPAddress.setValue(this.gateway.DNSIPAddress);
    this.form.controls.UsersPoolFromIP.setValue(this.gateway.UsersPoolFromIP);
    this.form.controls.UsersPoolToIP.setValue(this.gateway.UsersPoolToIP);
    this.form.controls.UsersPoolLeaseTimeHours.setValue(+this.gateway.UsersPoolLeaseTimeHours);
    this.form.controls.HotspotUseVLAN.setValue(this.gateway.HotspotUseVLAN);
    this.form.controls.HotspotVLANID.setValue(this.gateway.HotspotVLANID);
    this.form.controls.PPTPEnabled.setValue(this.gateway.PPTPEnabled);
    this.form.controls.PPTPServer.setValue(this.gateway.PPTPServer);
    this.form.controls.PPTPUserName.setValue(this.gateway.PPTPUserName);
    this.form.controls.PPTPPassword.setValue(this.gateway.PPTPPassword);
    this.form.controls.GPSEnable.setValue(this.gateway.GPSEnable);
    this.form.controls.GPSType.setValue(this.gateway.GPSType);
    this.form.controls.GPSUSBPort.setValue(this.gateway.GPSUSBPort);
    this.form.controls.WalledGardenUpdateMins.setValue(+this.gateway.WalledGardenUpdateMins);
    this.form.controls.UpdateGatewayStatusMins.setValue(+this.gateway.UpdateGatewayStatusMins);
    this.form.controls.AdminPassword.setValue(this.gateway.AdminPassword);
    this.form.controls.StoresUserDataRates.setValue(this.gateway.StoresUserDataRates);
    this.form.controls.CustomCommands.setValue(this.gateway.CustomCommands);
    this.form.controls.UpdatableConfig.setValue(this.gateway.UpdatableConfig);
    this.form.controls.ImportUMUsers.setValue(this.gateway.ImportUMUsers);
  }

  private _init(): void {
    const gatewayId = this.route.snapshot.paramMap.get('id');
    if (gatewayId === undefined || gatewayId === null || gatewayId === '' && this.gatewayId === null)
      this.isAdd = true;

    if (this.gatewayId !== null) {
      this.isAdd = false;
    }

    const managerId = this.route.snapshot.paramMap.get('manager');
    if (managerId === undefined || managerId === null || managerId === '')
      this.router.navigate(['main/dashboard']);

    const domainId = this.route.snapshot.paramMap.get('domain');
    if (domainId === undefined || domainId === null || domainId === '')
      this.router.navigate(['main/dashboard']);


    this.apiService.siceHardwareList().pipe(takeUntil(this.ngUnsubscribe)).subscribe(hardwareList => {
      if (hardwareList) {
        this.hardwareTypes = hardwareList;

        this.apiService.domainRead(domainId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(domain => {
          if (domain) {
            this.domain = domain;

            this.apiService.managerRead(managerId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(manager => {
              if (manager) {
                this.manager = manager;
                this.form.controls.ManagerId.setValue(this.manager.CompanyName);
                this.form.controls.DomainId.setValue(this.domain.DomainName);

                if (!this.isAdd) {
                  this.apiService.gatewayRead(gatewayId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(gateway => {
                    if (gateway) {
                      this.gateway = gateway;
                      this._loadForm();

                      this.apiService.getWpWalledGardens(this.domain.id, gatewayId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(walledGardens => {
                        if(walledGardens){
                          this.walledGardens = walledGardens;
                          this.wpWalledGardensDataSource = new MatTableDataSource(this.walledGardens)
                        }
                      })

                      this.apiService.getWpTemplates(this.domain.id, this.gateway.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(templates => {
                        if (templates) {
                          this.wpTemplates = templates;
                          this.wpTemplatesDataSource = new MatTableDataSource(this.wpTemplates);
                        }
                      })
                    }
                  });
                }

                this.apiService.getWpTemplates(this.domain.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(templates => {
                  if (templates) {
                    this.wpTemplates = templates;
                    this.wpTemplatesDataSource = new MatTableDataSource(this.wpTemplates);
                  }
                })
              }
            })
          }
        })
      }
    });
  }

  public save(): void {
    if (!this.form.valid) {
      this.error = "ERROR_EMPTY";
      return;
    }

    let customApps: string[] = this.form.controls.CustomApps.value?.toString().split(',');

    this.gateway = {
      id: this.form.controls.Id.value,
      CreationDate: this.form.controls.CreationDate.value,
      DomainID: this.domain.id,
      ManagerID: this.manager.id,
      GatewayName: this.form.controls.GatewayName.value,
      MacAddress: this.form.controls.MacAddress.value,
      MultipleLoginTitle: this.form.controls.MultipleLoginTitle.value,
      Address: this.form.controls.Address.value,
      Zip: this.form.controls.Zip.value,
      City: this.form.controls.City.value,
      State: this.form.controls.State.value,
      Country: this.form.controls.Country.value,
      Phone: this.form.controls.Phone.value,
      MobilePhone: this.form.controls.MobilePhone.value,
      Log: this.form.controls.Log.value,
      IPAddress: this.form.controls.IPAddress.value,
      ManifacturerWebAdminAddress: this.form.controls.ManifacturerWebAdminAddress.value,
      HardwareType: this.form.controls.HardwareType.value,
      HardwareVersion: this.form.controls.HardwareVersion.value,
      Uptime: this.form.controls.Uptime.value,
      MaxUsers: this.form.controls.MaxUsers.value?.toString(),
      TemplateID: this.form.controls.TemplateID.value?.toString(),
      UseTemplateColor: this.form.controls.UseTemplateColor.value?.toString(),
      Advertising: this.form.controls.Advertising.value?.toString(),
      Survey: this.form.controls.Survey.value?.toString(),
      Longitude: this.form.controls.Longitude.value,
      Latitude: this.form.controls.Latitude.value,
      GPSTrackingEnabled: this.form.controls.GPSTrackingEnabled.value,
      GPSTrackingKeepLogsFor: this.form.controls.GPSTrackingKeepLogsFor.value?.toString(),
      Timezone: this.form.controls.Timezone.value,
      Timetables: this.form.controls.Timetables.value?.toString(),
      DaysOfWeekTimetable: this.form.controls.DaysOfWeekTimetable.value?.toString(),
      MonthsTimetable: this.form.controls.MonthsTimetable.value?.toString(),
      Injection: this.form.controls.Injection.value,
      InjectionStartSeconds: this.form.controls.InjectionStartSeconds.value?.toString(),
      InjectionFrequencySeconds: this.form.controls.InjectionFrequencySeconds.value?.toString(),
      InjectionShowToolBar: this.form.controls.InjectionShowToolBar.value,
      InjectionInWelcomePortal: this.form.controls.InjectionInWelcomePortal.value,
      COINGatewayGUID: this.form.controls.COINGatewayGUID.value,
      COINWOL: this.form.controls.COINWOL.value,
      COINDns: this.form.controls.COINDns.value,
      CustomApps: customApps,
      FacebookFanPageURL: this.form.controls.FacebookFanPageURL.value,
      FacebookPublishUponConnection: this.form.controls.FacebookPublishUponConnection.value,
      TwitterFollowerUser: this.form.controls.TwitterFollowerUser.value,
      DisableAppleCaptivePortal: this.form.controls.DisableAppleCaptivePortal.value,
      DisableAndroidCaptivePortal: this.form.controls.DisableAndroidCaptivePortal.value,
      DisableWindowsPhoneCaptivePortal: this.form.controls.DisableWindowsPhoneCaptivePortal.value,
      Notes: this.form.controls.Notes.value,
      EnableNotifications: this.form.controls.EnableNotifications.value,
      WalledGardensOwnForEachGateway: this.form.controls.WalledGardensOwnForEachGateway.value,
      Locked: this.form.controls.Locked.value,
      AuthUsersByMACLogin: this.form.controls.AuthUsersByMACLogin.value,
      AuthUsersWithoutRealm: this.form.controls.AuthUsersWithoutRealm.value,
      EnableWireless: this.form.controls.EnableWireless.value,
      SSID: this.form.controls.SSID.value,
      WANConfigType: this.form.controls.WANConfigType.value,
      HotspotInLAN: this.form.controls.HotspotInLAN.value,
      NetworkAddressingMode: this.form.controls.NetworkAddressingMode.value,
      WanInterface: this.form.controls.WanInterface.value,
      UseVLAN: this.form.controls.UseVLAN.value,
      VLANID: this.form.controls.VLANID.value,
      WANUseDHCP: this.form.controls.WANUseDHCP.value,
      WANIPAddress: this.form.controls.WANIPAddress.value,
      WANNetMask: this.form.controls.WANNetMask.value,
      WANGatewayIP: this.form.controls.WANGatewayIP.value,
      PPPoEClientUserName: this.form.controls.PPPoEClientUserName.value,
      PPPoEClientPassword: this.form.controls.PPPoEClientPassword.value,
      PPPoEClientDialOnDemand: this.form.controls.PPPoEClientDialOnDemand.value,
      PPPoEClientMTU: this.form.controls.PPPoEClientMTU.value,
      USBModemAPN: this.form.controls.USBModemAPN.value,
      LTEAuthentication: this.form.controls.LTEAuthentication.value,
      USBModemUserName: this.form.controls.USBModemUserName.value,
      USBModemPassword: this.form.controls.USBModemPassword.value,
      USBModemInitATCommand: this.form.controls.USBModemInitATCommand.value,
      USBModemDialATCommand: this.form.controls.USBModemDialATCommand.value,
      USBModemUSBPort: this.form.controls.USBModemUSBPort.value,
      USBModemPhoneNumber: this.form.controls.USBModemPhoneNumber.value,
      CertificateID: this.form.controls.CertificateID.value,
      CertificateDomain: this.form.controls.CertificateDomain.value,
      EnableHotspotBridgeOnEth1: this.form.controls.EnableHotspotBridgeOnEth1.value,
      EnableHotspotBridgeOnEth2: this.form.controls.EnableHotspotBridgeOnEth2.value,
      EnableHotspotBridgeOnEth3: this.form.controls.EnableHotspotBridgeOnEth3.value,
      EnableHotspotBridgeOnEth4: this.form.controls.EnableHotspotBridgeOnEth4.value,
      EnableHotspotBridgeOnEth5: this.form.controls.EnableHotspotBridgeOnEth5.value,
      EnableHotspotBridgeOnEth6: this.form.controls.EnableHotspotBridgeOnEth6.value,
      EnableHotspotBridgeOnEth7: this.form.controls.EnableHotspotBridgeOnEth7.value,
      EnableHotspotBridgeOnEth8: this.form.controls.EnableHotspotBridgeOnEth8.value,
      EnableHotspotBridgeOnEth9: this.form.controls.EnableHotspotBridgeOnEth9.value,
      EnableHotspotBridgeOnEth10: this.form.controls.EnableHotspotBridgeOnEth10.value,
      EnableHotspotBridgeOnEth11: this.form.controls.EnableHotspotBridgeOnEth11.value,
      EnableHotspotBridgeOnEth12: this.form.controls.EnableHotspotBridgeOnEth12.value,
      KeepAliveTimeout: this.form.controls.KeepAliveTimeout.value?.toString(),
      HotspotIPAddress: this.form.controls.HotspotIPAddress.value,
      HotspotNetMask: this.form.controls.HotspotNetMask.value,
      DNSIPAddress: this.form.controls.DNSIPAddress.value,
      UsersPoolFromIP: this.form.controls.UsersPoolFromIP.value,
      UsersPoolToIP: this.form.controls.UsersPoolToIP.value,
      UsersPoolLeaseTimeHours: this.form.controls.UsersPoolLeaseTimeHours.value?.toString(),
      HotspotUseVLAN: this.form.controls.HotspotUseVLAN.value,
      HotspotVLANID: this.form.controls.HotspotVLANID.value,
      PPTPEnabled: this.form.controls.PPTPEnabled.value,
      PPTPServer: this.form.controls.PPTPServer.value,
      PPTPUserName: this.form.controls.PPTPUserName.value,
      PPTPPassword: this.form.controls.PPTPPassword.value,
      GPSEnable: this.form.controls.GPSEnable.value,
      GPSType: this.form.controls.GPSType.value,
      GPSUSBPort: this.form.controls.GPSUSBPort.value,
      WalledGardenUpdateMins: this.form.controls.WalledGardenUpdateMins.value?.toString(),
      UpdateGatewayStatusMins: this.form.controls.UpdateGatewayStatusMins.value?.toString(),
      AdminPassword: this.form.controls.AdminPassword.value,
      StoresUserDataRates: this.form.controls.StoresUserDataRates.value,
      CustomCommands: this.form.controls.CustomCommands.value,
      UpdatableConfig: this.form.controls.UpdatableConfig.value,
      ImportUMUsers: this.form.controls.ImportUMUsers.value,
    };

    const saveGatewayConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    saveGatewayConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(dialogResult => {
      if (dialogResult) {
        this.apiService.gatewayWrite(this.gateway).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            let message = result.Error !== null && result.Error !== undefined && result.Error !== '' ? result.Error : '';
            this.mainService.setSuccessMessageComand(message);
            if (result.Error !== null && result.Error !== undefined) {
              this.gatewayId = result.Id;
              this._init();
            }
          }
        })
      }
    })
  }

  back(): void {
    this.router.navigate(['main/net-wifi/gateways', { id: this.gateway.DomainID }]);
  }

  getKeys(element: object): string[] {
    if (!element) {
      return [];
    }

    return Object.keys(element);
  }

  addWalledGarden(): void {
    const addDialogRef = this.dialog.open(WalledGardenDialogComponent, {
      data: {
        domain: this.domain,
        gateway: this.gateway
      },
      width: '50%',
      maxWidth: 500
    });

    addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.wpWalledGardenWrite(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
              if (result) {
                let message = result.Error !== null && result.Error !== undefined && result.Error !== '' ? result.Error : '';
                this.mainService.setSuccessMessageComand(message);
                if (result.Error !== null && result.Error !== undefined) {
                  this._init();
                }
              }
            });
          }
        });
      }
    });
  }

  walledGardenDetail(walledGarden: SMSSWpWalledGarden): void {
    if (!walledGarden || walledGarden === null || walledGarden === undefined) {
      return;
    }

    const detailDialogRef = this.dialog.open(WalledGardenDialogComponent, {
      data: {
        walledGarden: walledGarden,
        domain: this.domain,
        gateway: this.gateway
      },
      width: '50%',
      maxWidth: 500
    });

    detailDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.apiService.wpWalledGardenWrite(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
              if (result) {
                let message = result.Error !== null && result.Error !== undefined && result.Error !== '' ? result.Error : '';
                this.mainService.setSuccessMessageComand(message);
                if (result.Error !== null && result.Error !== undefined) {
                  this._init();
                }
              }
            });
          }
        });
      }
    });
  }

  walledGardenDelete(walledGarden: SMSSWpWalledGarden) {
    if (!walledGarden || walledGarden === undefined) {
      return;
    }

    const deleteWalledGardenConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteWalledGardenConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.wpWalledGardenDelete(walledGarden.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            if (x) {
              let message = x.Error !== null && x.Error !== undefined && x.Error !== '' ? x.Error : '';
              this.mainService.setSuccessMessageComand(message);
              this._init();
            }
          }
        });
      }
    });
  }

  addWpTemplate(): void {
    this.router.navigate(['main/wptemplate', { gateway: this.gateway.id, domain: this.gateway.DomainID }])
  }

  wpTemplateDetail(wpTemplate: SMSSWpTemplate): void {
    if (!wpTemplate || wpTemplate === null || wpTemplate === undefined) {
      return;
    }

    this.router.navigate(['main/wptemplate', { id: wpTemplate.id, gateway: this.gateway.id, domain: this.gateway.DomainID }])
  }

  wpTemplateDelete(wpTemplate: SMSSWpTemplate) {
    if (!wpTemplate || wpTemplate === undefined) {
      return;
    }

    const deleteWalledGardenConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteWalledGardenConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
      if (result) {
        this.apiService.wpTemplateDelete(wpTemplate.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
          if (x) {
            if (x) {
              let message = x.Error !== null && x.Error !== undefined && x.Error !== '' ? x.Error : '';
              this.mainService.setSuccessMessageComand(message);
              this._init();
            }
          }
        });
      }
    });
  }
}
