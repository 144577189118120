<div class="container-fluid">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{ 'DEVICE_COMMAND_DETAIL.TITLE' | translate }} - {{this.deviceCommand?.Device?.Name}}
        </span>
    </div>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.ID' | translate }}
            </mat-label>
            <input matInput formControlName="id" type="text" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.DEVICE_ID' | translate }}</mat-label>
            <input matInput formControlName="deviceId" type="text" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.DEVICE_NAME' | translate }}</mat-label>
            <input matInput formControlName="deviceName" type="text" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_COMMAND.TYPE' | translate }}</mat-label>
            <input matInput formControlName="deviceCommandType" type="text" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.CREATED' | translate }}
            </mat-label>
            <input matInput formControlName="created" type="text" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_COMMAND.EXPIRATION' | translate }}
            </mat-label>
            <input matInput formControlName="expiration" type="text" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'DEVICE_COMMAND.DELIVERED' | translate }}
            </mat-label>
            <input matInput formControlName="delivered" type="text" autocomplete="off" readonly>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'DEVICE_COMMAND.REQUEST' | translate }}</mat-label>
            <textarea   readonly 
                        matInput
                        formControlName="command"                         
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="20"
                        autocomplete="off">
            </textarea>
        </mat-form-field>
    </form>

    <ng-template [ngIf]="this.deviceNotifications && this.deviceNotifications.length > 0">
        <div class="row mb-1 d-flex justify-content-between align-items-baseline py-2 m-0">
            <span class="h5 mb-1 mt-1">
                {{ 'DEVICE.NOTIFICATIONS' | translate }}
            </span>
        </div>
        <div class="customTable">
            <table class="w-100 h-100" mat-table [dataSource]="deviceNotificationsDataSource">
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'GENERAL.ID' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item"> {{item.Id}} </td>
                </ng-container>

                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{'GENERAL.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let item"
                        style="word-break: keep-all;"> {{item.Created * 1000 | date: 'short'}} </td>
                </ng-container>

                <ng-container matColumnDef="Device">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.DEVICE' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        {{item.DeviceId}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Command">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.DEVICE_COMMAND' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        {{item.DeviceCommandId}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="Body">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.JSON' | translate }} </th>
                    <td mat-cell *matCellDef="let item" [matTooltip]="item.Json" [matTooltipPosition]="'above'">
                        <span class="link">
                            {{item.Json?.length > 15 ? item.Json.slice(0, 15) + "..." : item.Json }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1"></th>
                    <td mat-cell *matCellDef="let item" class="text-center pr-1">
                        <div class="pr-2">
                            <i matTooltip="{{'GENERAL.DETAIL' | translate }}"
                                (click)="openNotificationDetailDialog(item)" class="iconInfoButton urbanicon-info-outline">
                            </i>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </ng-template>
</div>
