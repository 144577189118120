import { AdministratorApiService } from './../../../shared/services/administrator-api.service';
import { ApiService } from './../../../shared/services/api.service';
import { first, takeUntil } from 'rxjs/operators';
import { PassDataService } from './../../../shared/services/pass-data/pass-data.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { DeviceGroupServiceRequest, GetServiceDetailResponse, GetServiceTypeResponse, Service, ServiceType, UpdateServiceRequest } from 'src/app/shared/models/service/service';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DeviceGroup } from 'src/app/shared/models/deviceGroup';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PaginationInstance } from 'ngx-pagination';
import { ServiceDialogComponent } from 'src/app/shared/dialogs/service-dialog/service-dialog.component';

@Component({
  selector: 'urban-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent implements OnInit, OnDestroy {

  public isAdmin: boolean = false;
  public service: Service;
  public serviceTypes: ServiceType[] = [];
  public deviceGroups: DeviceGroup[] = [];
  public filteredData: DeviceGroup[] = [];
  public dataChanged: boolean;
  public editMode: boolean = false;
  public form: UntypedFormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    typeId: [{value: 0, disabled: true}, Validators.required]
  });
  public displayedColumns = ['Name', 'Actions'];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private ApiService: ApiService,
    private passDataService: PassDataService,
    private formBuilder: UntypedFormBuilder,
    private mainService: MainSubscriptionsService,
    private AdministratorApiService: AdministratorApiService,
    private apiSync: ApiSynchronizerService,
    private router: Router,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isAdmin = res?.some(x => x.Name === 'Administrators');
    });

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if(!navInfo?.Id) {
        this.setErrorAndGoToMain('Access denied. Retry with proper navigation');
        return;
      }

      this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((values: any) => {
        const serviceValues = {
          name: this.service.Name,
          description: this.service.Description,
          typeId: this.service.ServiceType.Id
        }
  
        this.dataChanged = false;
  
        for (let key in serviceValues) {
          if (serviceValues[key] !== values[key]) {
            this.dataChanged = true;
            break;
          }
        }
      });
  
      this.loadData(navInfo.Id);
    });
  }

  private loadData(serviceId: string): void {
    let serviceData: GetServiceDetailResponse, serviceTypeData: GetServiceTypeResponse, deviceGroupsData: DeviceGroup[];
    let serviceApi: number, serviceTypeApi: number, deviceGroupsApi: number;

    this.apiSync.initialize();
    this.apiSync.addFeatures(3);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[]) => {
      if (checkValues[serviceApi]) {
        this.service = serviceData.Item;
        this.form.setValue({
          name: this.service.Name,
          description: this.service.Description,
          typeId: this.service.ServiceType.Id
        });
      }
      else {
        this.setErrorAndGoToMain('Could not find a service with passed ID');
        return;
      }

      if (checkValues[serviceTypeApi]) {
        this.serviceTypes = serviceTypeData.Items;
      }

      if (checkValues[deviceGroupsApi]) {
        this.deviceGroups = deviceGroupsData;
        this.filteredData = this.service.DeviceGroups;
      }
    });

    this.ApiService.serviceDetail(serviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: GetServiceDetailResponse) => {
      if (res?.Item) {
        serviceData = res;
        serviceApi = this.apiSync.loadedFeature();
      } else {
        serviceApi = this.apiSync.failedFeature();
      }
    });

    this.ApiService.getServiceTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: GetServiceTypeResponse) => {
      if (res?.Items?.length > 0) {
        serviceTypeData = res;
        serviceTypeApi = this.apiSync.loadedFeature();
      } else {
        serviceTypeApi = this.apiSync.failedFeature();
      }
    });

    this.AdministratorApiService.deviceGroupList().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceGroup[]) => {
      if (res?.length > 0) {
        deviceGroupsData = res;
        deviceGroupsApi = this.apiSync.loadedFeature();
      } else {
        deviceGroupsApi = this.apiSync.failedFeature();
      }
    });
  }

  public saveInfo(): void {
    if (this.form.valid) {
      const updateDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      updateDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if (result) {
          let request: UpdateServiceRequest = {
            Id: this.service.Id,
            Name: this.form.controls['name'].value,
            Description: this.form.controls['description'].value,
            TypeId: this.form.controls['typeId'].value
          }

          this.ApiService.updateService(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: () => {
              this.dataChanged = false;
              this.editMode = false;
              this.form.controls['typeId'].disable();
              this.refreshData(this.service.Id);
            }, error: () => {}
          });
        }
      });
    }
  }

  public addDeviceGroupToService(): void {
    const remainingDeviceGroups = this.deviceGroups.filter(group => {
      return !this.service.DeviceGroups.some(present => {
        return present.Id === group.Id;
      });
    });

    const addTypeDialogRef = this.dialog.open(ServiceDialogComponent, {
      data: {
        groups: remainingDeviceGroups
      }
    });

    addTypeDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((newGroupId: string) => {
      if (newGroupId) {
        const addTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            const addDeviceGroupRequest: DeviceGroupServiceRequest = {
              ServiceId: this.service.Id,
              GroupId: newGroupId
            };

            this.ApiService.addDeviceGroupToService(addDeviceGroupRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
              next: () => {
                this.refreshData(this.service.Id);
              }, error: () => {}
            });
          }
        });
      }
    });
  }

  public removeDeviceGroupFromService(groupIdToDelete: string): void {
    const addTypeConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    addTypeConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        const removeDeviceGroupRequest: DeviceGroupServiceRequest = {
          ServiceId: this.service.Id,
          GroupId: groupIdToDelete
        };

        this.ApiService.removeDeviceGroupFromService(removeDeviceGroupRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: () => {
            this.refreshData(this.service.Id);
          }
        });
      }
    });
  }

  private refreshData(serviceId: string): void {
    this.ApiService.serviceDetail(serviceId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: GetServiceDetailResponse) => {
      if (res?.Item) {
        this.service = res.Item;
        this.filteredData = res.Item.DeviceGroups;
      }
    });
  }

  public enableEdit(): void {
    this.form.controls['typeId'].enable();
    this.editMode = true;
  }

  public cancelEdit(): void {
    this.form.setValue({
      name: this.service.Name,
      description: this.service.Description,
      typeId: this.service.ServiceType.Id,
    });

    this.form.controls['typeId'].disable();
    this.dataChanged = false;
    this.editMode = false;
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.service.DeviceGroups?.filter((group: DeviceGroup) =>
      [group.Name?.toLowerCase()].some((field: string) => field?.includes(filterValue.toLowerCase()))
    );

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  public goToServiceTypes(): void {
    this.mainService.setNavigationInfoComand({Id: this.service.Id, BackRoute: 'service-detail'});
    this.router.navigate(['main/service-type-list']);
  }

  public goToDeviceGroupDetail(groupId): void {
    this.mainService.setNavigationInfoComand({Id: groupId, BackRoute: 'service-list'});
    this.router.navigate(['main/device-group-list']);
  }

  private setErrorAndGoToMain(error: string): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand(error);
    this.router.navigate(['main/dashboard']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
