import { DomainProperty } from './../../../shared/models/domainProperty';
import { ApiSynchronizerService } from './../../../shared/services/api-synchronizer.service';
import { SingleEvent, WeatherElementInfo, WeatherInfoPerType } from './../../../shared/models/weatherstation/weatherStationEvent';
import { DeviceEventLatestResponse, EventsDeviceLatest } from './../../../shared/models/deviceEventLatest';
import { PassDataService } from './../../../shared/services/pass-data/pass-data.service';
import { WeatherElementsValues, WeatherstationHeatmapElement } from './../../../shared/models/weatherstationHeatmapElement';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, interval } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { Device } from '../../../shared/models/device';
import { ApiService } from '../../../shared/services/api.service';
import { Router } from '@angular/router';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { WeatherStationEvent } from '../../../shared/models/weatherstation/weatherStationEvent';
import { DeviceEventLatestRequest } from 'src/app/shared/models/deviceEventLatest';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DeviceEventsEvent } from 'src/app/shared/models/deviceEvent';
import { EventBodyField, EventParsedBody } from 'src/app/shared/models/administrator/eventBody';
import { EventBodyUtilityService } from 'src/app/shared/services/event-body-utility.service';


const defaultSecondsToRefresh = 90;
@Component({
  selector: 'urban-heatmap-devices',
  templateUrl: './heatmap-devices.component.html',
  styleUrls: ['./heatmap-devices.component.scss']
})
export class HeatmapDevicesComponent implements OnInit, OnDestroy {
  public wsDevices: Device[] = []
  public wsHeatmapElements: WeatherstationHeatmapElement[] = [];
  public weatherElements: WeatherInfoPerType[];
  public selectedElement: string;
  public weatherMaxValues: WeatherElementsValues = null;
  private weatherStationInfo: WeatherElementInfo[] = [];
  public isASingleDevice: boolean;
  public singleWsDevice: Device;
  public readyToShow: boolean = false;
  public noData: boolean = false;
  public lastUpdate: number = null;
  private eventBodies: Record<string, EventParsedBody>;
  private heatmapTiming: number = defaultSecondsToRefresh;
  public isDarkActive: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private router: Router,
    private mainService: MainSubscriptionsService,
    private apiSync: ApiSynchronizerService,
    private passDataService: PassDataService,
    private eventBodyService: EventBodyUtilityService
  ) { }

  ngOnInit(): void {
    let deviceId: string;

    let initContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, initContext);
    this.apiSync.waitFeaturesAndThen(() => {
      this.getDataforHeatmap(deviceId);
    }, initContext);

    this.passDataService.currentDomainProperties$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (res?.length > 0) {
        let wsInfo: DomainProperty, wsHeatmapRefreshSeconds: DomainProperty;
        if (wsInfo = res.find((property: DomainProperty) => property.Key === 'WeatherStationLimits')) {
          if (wsInfo && wsInfo?.Value) {
            let parsedWsInfo = JSON.parse(wsInfo.Value);
            if(parsedWsInfo?.Limits?.length > 0) {
              this.weatherMaxValues = {};
              parsedWsInfo?.Limits.forEach((elementInfo) => {
                if (['Key', 'Unit', 'Value', 'Type'].every((key:string) => Object.keys(elementInfo).includes(key)) && elementInfo.Value !== 'N.A.') {
                  let formattedKey: string = elementInfo.Key.toUpperCase().replace('.', '');
                  let singleInfo: WeatherElementInfo = {
                    ...elementInfo,
                    Key: formattedKey
                  }
                  this.weatherStationInfo.push(singleInfo);
                  this.weatherMaxValues[formattedKey] = elementInfo.Value ? +elementInfo.Value : null;
                }
              });
            }
          }
        }

        if (wsHeatmapRefreshSeconds = res.find((property: DomainProperty) => property.Key === 'WsHeatmapRefreshSeconds')) {
          this.heatmapTiming = +wsHeatmapRefreshSeconds.Value;
        }
      }

      this.apiSync.loadedFeature(initContext);
    });

    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (navInfo?.Id) {
        deviceId = navInfo.Id;
        this.isASingleDevice = true;
      }
      else {
        this.isASingleDevice = false;
      }

      this.apiSync.loadedFeature(initContext);
    });

    this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.isDarkActive = res === true;
    });
  }

  public getDataforHeatmap(deviceId: string): void {
    this.loadData(true, deviceId);
    interval(this.heatmapTiming * 1000).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loadData(false, deviceId);
    });
  }

  private loadData(firstCall: boolean, deviceId?: string): void {
    let latestEventRequest: DeviceEventLatestRequest;

    let eventsFeature: number;
    let syncContext: number =  this.apiSync.initialize();

    this.apiSync.addFeatures(firstCall ? 2 : 1, syncContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[], data: any) => {
      if (checkValues.every(value => value)) {
        if (this.isASingleDevice) {
          if (firstCall) {
            this.singleWsDevice = this.wsDevices.find((device) => device.Id === deviceId);
            if (this.singleWsDevice === undefined) {
              this.setErrorAndGoToMain();
              return;
            }
          }
          this.loadSingleDeviceData(data[eventsFeature])
        }
        else {
          this.loadMultipleDevicesData(data[eventsFeature]);
        }
      }

      if (this.wsHeatmapElements.length > 0) {
        this.showMap();
        this.noData = false;
      }
      else if (firstCall) {
        this.noData = true;
      }
    }, syncContext);

    if (firstCall) {
      this.apiService.getDevicesByType('weatherstation').pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: Device[]) => {
        this.weatherElements = [];

        if (res?.length > 0) {
          this.wsDevices = res;
          this.eventBodies = this.eventBodyService.initEventBody(this.wsDevices);
          this.wsDevices.sort((a, b) => a.Name.localeCompare(b.Name));

          this.apiSync.loadedFeature(syncContext);
        }
        else {
          this.apiSync.failedFeature(syncContext);
        }
      });
    }

    latestEventRequest = this.isASingleDevice ?
      { DeviceId: deviceId } : { DeviceType: 'weatherstation' };

    this.apiService.getDeviceEventLatest(latestEventRequest)
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceEventLatestResponse) => {
      if (res && res.Devices?.length > 0) {
        this.wsHeatmapElements = [];
        eventsFeature = this.apiSync.loadedFeatureWithData(res, syncContext);
      }
      else {
        eventsFeature = this.apiSync.failedFeature(syncContext);
      }
    });
  }

  private loadSingleDeviceData(eventsResult: DeviceEventLatestResponse): void {
    let weatherValues: WeatherElementsValues = {};
    let events: WeatherStationEvent[] =
      this.mapToWeatherStationEvents(eventsResult.Devices[0], this.weatherStationInfo, this.eventBodies);

    events.forEach(singleEvent => {
      weatherValues[singleEvent.Name] = singleEvent.Events[0].Value;
      if (this.lastUpdate === undefined || this.lastUpdate < singleEvent.Events[0].Created) {
        this.lastUpdate = singleEvent.Events[0].Created;
      }

      let wsElementType: string = this.weatherStationInfo
        .find((singleInfo: WeatherElementInfo) => singleInfo.Key === singleEvent.Name).Type;

      let selectedWsElement = this.weatherElements.find(wsElement => wsElement.Type === wsElementType);
      if (selectedWsElement !== undefined) {
        selectedWsElement.Elements.push(singleEvent.Name);
      }
      else {
        let newWeatherElement: WeatherInfoPerType = {
          Type: wsElementType,
          Elements: [singleEvent.Name]
        }
        this.weatherElements.push(newWeatherElement);
      }
    });

    if (this.weatherElements.length > 0 && (
      this.selectedElement === undefined ||
      !this.weatherElements.some(tab => tab.Elements.includes(this.selectedElement))
    )) {
      this.selectedElement = this.weatherElements[0].Elements[0];
    }

    if (!(this.singleWsDevice.Latitude === 0 && this.singleWsDevice.Longitude === 0)) {
      let newWsHeatmapElement: WeatherstationHeatmapElement = {
        lat: this.singleWsDevice.Latitude,
        lng: this.singleWsDevice.Longitude,
        values: weatherValues
      };
      this.wsHeatmapElements.push(newWsHeatmapElement);
    }
  }

  private loadMultipleDevicesData(eventsResult: DeviceEventLatestResponse): void {
    eventsResult.Devices.forEach((device: EventsDeviceLatest) => {
      let weatherValues: WeatherElementsValues = {};
      let events: WeatherStationEvent[] =
        this.mapToWeatherStationEvents(device, this.weatherStationInfo, this.eventBodies);

      events.forEach(singleEvent => {
        weatherValues[singleEvent.Name] = singleEvent.Events[0].Value;
        if (this.lastUpdate === undefined || this.lastUpdate < singleEvent.Events[0].Created) {
          this.lastUpdate = singleEvent.Events[0].Created;
        }

        let wsElementType: string = this.weatherStationInfo
          .find((singleInfo: WeatherElementInfo) => singleInfo.Key === singleEvent.Name).Type;

        let selectedWsElement = this.weatherElements.find(wsElement => wsElement.Type === wsElementType);
        if (selectedWsElement !== undefined) {
          if (selectedWsElement.Elements.find((element: string) => element === singleEvent.Name) === undefined) {
            selectedWsElement.Elements.push(singleEvent.Name);
          }
        }
        else {
          let newWeatherElement: WeatherInfoPerType = {
            Type: wsElementType,
            Elements: [singleEvent.Name]
          }
          this.weatherElements.push(newWeatherElement);
        }
      });

      if (this.weatherElements.length > 0 && (
        this.selectedElement === undefined ||
        !this.weatherElements.some(tab => tab.Elements.includes(this.selectedElement))
      )) {
        this.selectedElement = this.weatherElements[0].Elements[0];
      }

      let devicesWeatherValues: Record<string, WeatherElementsValues> = {};
      let wsDevice: Device = this.wsDevices.find(wDevice => wDevice.Id === device.Id);
      if (Object.keys(weatherValues).length > 0 && wsDevice !== undefined &&
        !(wsDevice.Latitude === 0 && wsDevice.Longitude === 0)) {
        devicesWeatherValues[device.Id] = weatherValues;
        let newWsHeatmapElement: WeatherstationHeatmapElement = {
          lat: wsDevice.Latitude,
          lng: wsDevice.Longitude,
          values: devicesWeatherValues[device.Id]
        }
        this.wsHeatmapElements.push(newWsHeatmapElement);
      }
    });
  }

  private mapToWeatherStationEvents(device: EventsDeviceLatest, weatherStationInfo: WeatherElementInfo[], eventBodies: Record<string, EventParsedBody>): WeatherStationEvent[] {
    let wsEvents: WeatherStationEvent[] = [];
    let expectedBody: EventParsedBody = eventBodies[device.Model.Name];

    weatherStationInfo.forEach((wsElementInfo: WeatherElementInfo) => {
      let wsElementEvents: SingleEvent[] = [];

      device.Events.forEach((event: DeviceEventsEvent) => {
        if (this.eventBodyService.isAProperEvent(event, device.Model, eventBodies)) {
          let eventBody: any = event.Body;
          let wsElementField: EventBodyField = expectedBody.MandatorySingleFields.find((field: EventBodyField) =>
            field.Name.toUpperCase().replace('.', '') === wsElementInfo.Key);

          if (wsElementField !== undefined) {
            let value: number | string = this.eventBodyService.getNestedValue(eventBody, expectedBody.Mapping[wsElementField.MapTo]);
            if (typeof value === 'number') {
              let wsSingleEvent: SingleEvent = {
                Created: event.CreatedTimestamp,
                Value: +value
              };
              wsElementEvents.push(wsSingleEvent);
            }
          }
        }
      });

      if (wsElementEvents.length > 0) {
        let wsEvent: WeatherStationEvent = {
          Name: wsElementInfo.Key,
          Unit: wsElementInfo ? wsElementInfo.Unit : '',
          Limit: wsElementInfo ? wsElementInfo.Value : '',
          Events: wsElementEvents
        };

        wsEvents.push(wsEvent);
      }
    });

    return wsEvents;
  }

  private showMap(): void {
    this.passDataService.mapReady$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(mapLoading => {
      this.readyToShow = mapLoading;
    });
  }

  public changeSelectedElement(chosenElement: string): void {
    this.selectedElement = chosenElement;
  }

  public onTabChange(event: MatTabChangeEvent): void {
    let index: number = event.index;
    this.selectedElement = this.weatherElements[index].Elements[0];
  }

  public goToMain(): void {
    let currentUrl: string = this.router.url.split('/').pop();
    this.mainService.setNavigationInfoComand({ BackRoute: currentUrl });
    this.mainService.updateAllDataAndGoToMainComand();
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  ngOnDestroy(): void {
    this.apiSync.abort();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
