<div class="container-fluid" [ngClass]="{'h-100' : noData}">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div *ngIf="isASingleDevice === true">
            <span *ngIf="singleWsDevice" class="title2 appearingTextLeft">
                {{ singleWsDevice.Name + ' -' }}
            </span>
            <span class="title2">{{ 'HEATMAP.WEATHER_SINGLE' | translate }}</span>
        </div>
        <span *ngIf="isASingleDevice === false" class="title2">
            {{ 'HEATMAP_WEATHER.TITLE'| translate }}
        </span>
    </div>

    <mat-tab-group (selectedTabChange)="onTabChange($event)" id="intro-devices-table-tab" animationDuration="0ms" dynamicHeight="true">
        <mat-tab *ngFor="let element of weatherElements" [label]="(element.Type.charAt(0).toUpperCase()) + (element.Type.slice(1))">
            <div class="tabContent">
                <div *ngIf="readyToShow && !noData">
                    <br>
                    <p>{{ 'HEATMAP_WEATHER.INSTRUCTIONS' | translate }}</p>
                    <mat-button-toggle-group class="mr-3 mb-4 searchRangeBtn" appearance="standard" [value]="selectedElement" hideSingleSelectionIndicator="true">
                        <mat-button-toggle *ngFor="let el of element.Elements" [value]="el" (click)="changeSelectedElement(el)">
                            {{ 'WEATHERSTATION.' + el | translate }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>

                    <mat-form-field appearance="outline" class="customMatFormField smallDeviceSelect">
                        <mat-label>{{ 'WEATHERSTATION.SELECT_ELEMENT' | translate }}</mat-label>
                        <mat-select [(value)]="selectedElement" hideSingleSelectionIndicator="true">
                          <mat-option *ngFor="let el of element.Elements" (click)="changeSelectedElement(el)" [value]="el">
                            {{ 'WEATHERSTATION.' + el | translate }}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-center noDataIllustration responsiveIllustration" *ngIf="noData">
                    <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                    <h2>{{ 'HEATMAP_WEATHER.NO_DATA' | translate }}</h2>
                    <div>
                        <button type="button" class="btn outlineActionButton" (click)="goToMain()">{{ 'GENERAL.RETURN_DASHBOARD' | translate }}</button>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>

    <div *ngIf="readyToShow && !noData && weatherMaxValues">
        <urban-heatmap-weather-maps class="mapPanel"
        [heatmapElements]='wsHeatmapElements'
        [elementToAnalyze]='selectedElement'
        [maxValuesHeatmap]='weatherMaxValues'
        [passedLastUpdate]='lastUpdate'
        [darkModeStatus]="isDarkActive"
        ></urban-heatmap-weather-maps>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center noDataIllustration responsiveIllustration mt-3" *ngIf="noData && isASingleDevice === false">
        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <h2>{{ 'HEATMAP_WEATHER.NO_DATA' | translate }}</h2>
        <div>
            <button type="button" class="btn outlineActionButton" (click)="goToMain()">{{ 'GENERAL.RETURN_DASHBOARD' | translate }}</button>
        </div>
    </div>
</div>
