<div class="container-fluid px-1 px-md-3">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <div>
            <span class="title2">{{ 'DEVICE_NOTIFICATIONS.TITLE' | translate }}</span>
            <span *ngIf="currentDevice" class="title2 appearingTextRight">
                {{ ' - ' + this.currentDevice.Name }}
            </span>
        </div>
    </div>

    <urban-searchbar id="intro-device-notifications-search" [passedMaxDaysRange]="3" [latestDates]="lastCreated" [loadingEvents]="loadingData !== false"
        (newSearch)="newSearch($event)" (stopPreviousSearch)="subscriptionsUnsubscribe()" [buttonToggleSelected]="24" [clearDateUnsubscribeEvent]="clearDateAndUnsubscribe" [clearDateEvent]="clearDate" [setLatestDates]="setDates">
    </urban-searchbar>

    <div class="customTable" *ngIf="deviceNotifications?.length > 0">
        <div class="customTableRadius d-flex justify-content-between align-items-start px-4 pt-4 flex-wrap">
            <span class="title3">{{ 'DEVICE_NOTIFICATIONS.TITLE' | translate }}</span>
    
            <mat-form-field class="tableSearch" appearance="outline">
                <div matPrefix class="prefix">
                    <i class="urbanicon-search"></i>
                </div>
    
                <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
    
                <input matInput (keyup)="applyFilter($event)" autocomplete="off" [(ngModel)]="actualFilter">
    
                <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear"
                    (click)="applyFilterString(''); actualFilter = ''" matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                    <i class="urbanicon-close"></i>
                </button>
            </mat-form-field>
        </div>
    
        <div class="tableHeight">
            <table class="w-100 h-100" mat-table [dataSource]="filteredData | paginate: config">
                <ng-container matColumnDef="Id">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.ID' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let item"> {{item.Id}} </td>
                </ng-container>
    
                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.CREATED' | translate }} </th>
                    <td mat-cell *matCellDef="let item" style="word-break: keep-all;"> {{item.Created * 1000 |
                        date:'short'}} </td>
                </ng-container>
    
                <ng-container matColumnDef="Device">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.DEVICE' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        {{item.DeviceId}}
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="Command">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.DEVICE_COMMAND' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        <span class="link" style="word-break: keep-all;"
                            (click)="goToDeviceCommandDetail(item.DeviceCommandId)">{{item.DeviceCommandId}}</span>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="CommandType">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.DEVICE_COMMAND_TYPE' | translate }} </th>
                    <td mat-cell *matCellDef="let item">
                        {{item.DeviceCommandType.Name}}
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="Body">
                    <th mat-header-cell *matHeaderCellDef> {{'GENERAL.JSON' | translate }} </th>
                    <td mat-cell *matCellDef="let item" [matTooltip]="item.Json" [matTooltipPosition]="'above'">
                        {{item.Json?.length > 15 ? item.Json.slice(0, 15) + "..." : item.Json }}
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="Detail">
                    <th mat-header-cell *matHeaderCellDef class="text-center pr-1"></th>
                    <td mat-cell *matCellDef="let item" class="text-center pr-1">
                        <div class="pr-2">
                            <i matTooltip="{{'GENERAL.DETAIL' | translate }}" (click)="openDetailDialog(item)"
                                class="iconInfoButton urbanicon-info-outline">
                            </i>
                        </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="customTableRadius paginatorControls d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-wrap align-items-center">
                <pagination-controls class="customNgxPaginator" [maxSize]="7" [directionLinks]="true" [previousLabel]="''"
                    [nextLabel]="''" [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                    [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                    [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                    (pageChange)="paginatorOnPageChange($event)">
                </pagination-controls>
        
                <div class="paginatorPageCounter">
                    {{config.currentPage}} {{'GENERAL.OF' | translate}}
                    {{paginatorGetMaxPage()}}
                    {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase() :
                    ('GENERAL.PAGE' | translate).toLowerCase()}}
                </div>
            </div>
        
            <div>
                <mat-form-field class="customMatFormField" appearance="outline">
                    <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true" (selectionChange)="paginatorOnItemsPerPageChange()">
                        <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                            {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center noDataIllustration responsiveIllustration" *ngIf="deviceNotifications?.length === 0">
        <img [src]="isDarkActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
        <h2>{{ (last24hSearch === true ? 'GENERAL.NO_DATA_IN_24H' : 'GENERAL.FILTER_NO_DATA') | translate }}</h2>
        <h6 class="secondaryTextColor">{{ 'GENERAL.FILTER_TRY_AGAIN' | translate }}</h6>
        <div class="d-flex">
            <button type="button" mat-button class="btn outlineActionButton" (click)="clearDateAndUnsubscribe = !clearDateAndUnsubscribe">
                {{ 'GENERAL.NEW_SEARCH' | translate }}
            </button>
            <button type="button" mat-button class="btn outlineActionButton" (click)="loadLatestData()">
                {{ 'GENERAL.SHOW_DATA' | translate }}
            </button>
        </div>
    </div>
</div>
