import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DeviceModelEventBodyDialogComponent } from 'src/app/shared/dialogs/device-model-event-body-dialog/device-model-event-body-dialog.component';
import { DeviceModelRequest } from 'src/app/shared/models/administrator/deviceModelRequest';
import { EventBody, EventBodyField, FieldAncestor } from 'src/app/shared/models/administrator/eventBody';
import { DeviceModel } from 'src/app/shared/models/deviceModel';
import { DeviceType } from 'src/app/shared/models/deviceType';
import { AdministratorApiService } from 'src/app/shared/services/administrator-api.service';
import { ApiSynchronizerService } from 'src/app/shared/services/api-synchronizer.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from 'src/app/shared/services/pass-data/pass-data.service';

@Component({
  selector: 'urban-device-model-detail',
  templateUrl: './device-model-detail.component.html',
  styleUrls: ['./device-model-detail.component.scss']
})
export class DeviceModelDetailComponent implements OnInit, OnDestroy {
  public deviceModel: DeviceModel;
  public deviceTypes: DeviceType[] = [];
  public editMode: boolean = false;
  private dataEditBackup: Record<string, string> = null;
  private dataChanged: boolean = false;
  public eventBodyColumns: string[] = ['Name', 'MapTo', 'Min/Max', 'Path', 'Optional', 'Actions'];
  public eventBodyFieldsActualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public eventBodyFieldsConfig: PaginationInstance = {
    id: 'eventBodyFieldsData',
    itemsPerPage: 10,
    currentPage: 1,
  };
  public eventBody: EventBody;
  public filteredEventBodyFieldsData: EventBodyField[] = [];

  public form: UntypedFormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    brand: ['', Validators.required],
    typeSelected: ['', Validators.required],
    properties: ['']
  });
  public formFields: Record<string, string>[];
  public showFormError: boolean = false;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private mainService: MainSubscriptionsService,
    public dialog: MatDialog,
    private apiService: ApiService,
    private administratorApiService: AdministratorApiService,
    private apiSync: ApiSynchronizerService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private passDataService: PassDataService
  ) { }

  ngOnInit(): void {
    this.passDataService.navigationInfo$.pipe(first()).subscribe(navInfo => {
      if (!navInfo?.Id) {
        this.mainService.setNavigationInfoComand();
        this.router.navigate(['main/dashboard']);
        return;
      }

      this.loadData(navInfo.Id);
    });

    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: any) => {
      this.showFormError = false;

      if(this.dataEditBackup && !this.dataChanged) {
        this.dataChanged = true;
      }
    });
  }

  private loadData(modelId: string): void {
    let deviceModelFeature: number;
    const syncContext: number = this.apiSync.initialize();
    this.apiSync.addFeatures(2, syncContext);

    this.apiSync.waitFeaturesAndThen((checkValues: boolean[]) => {
      if (!checkValues[deviceModelFeature]) {
        this.setErrorAndGoToMain();
        return;
      }

      if (this.deviceTypes.length === 0 || !this.deviceTypes.includes(this.deviceModel.Type)) {
        this.deviceTypes.push(this.deviceModel.Type);
      }
      this.form.controls.typeSelected.disable();
    }, syncContext);

    this.apiService.getDeviceModelById(modelId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceModel) => {
      if (res) {
        this.deviceModel = res;
        this.initializeFormFields();
        this.form.patchValue({
          name: this.deviceModel.Name,
          brand: this.deviceModel.Brand.Name,
          typeSelected: this.deviceModel.Type.Id,
          properties: this.deviceModel.Properties?.length > 0 ? this.deviceModel.Properties.join(',') : ''
        });

        this.form.controls.name.disable();
        this.form.controls.brand.disable();
        this.form.controls.properties.disable();

        deviceModelFeature = this.apiSync.loadedFeature(syncContext);

        this.setEventBodyTable();
      }
      else {
        deviceModelFeature = this.apiSync.failedFeature(syncContext);
      }
    });

    this.apiService.getDeviceType().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: DeviceType[]) => {
      if (res?.length > 0) {
        this.deviceTypes = res;

        this.apiSync.loadedFeature(syncContext);
      }
      else {
        this.apiSync.failedFeature(syncContext);
      }
    });
  }

  private initializeFormFields(): void {
    this.formFields = [
      { formControl: 'name', formLabel: 'DEVICE_MODEL.NAME', required: 'true'},
      { formControl: 'brand', formLabel: 'DEVICE_MODEL.BRAND', readonly: 'true' },
      { formControl: 'typeSelected' },
      { formControl: 'properties', formLabel: 'DEVICE.PROPERTIES' }
    ];
  }

  private setEventBodyTable(): void {
    try {
      this.eventBody = JSON.parse(this.deviceModel.EventBody);
    }
    catch (e) {}

    if(!this.eventBody) {
      this.eventBody = {
        Body: '',
        SingleFieldsToSet: []
      };
    }
    else if (!this.eventBody.SingleFieldsToSet) {
      this.eventBody.SingleFieldsToSet = [];
    }

    this.filteredEventBodyFieldsData = [...this.eventBody.SingleFieldsToSet];
  }

  public editInfo(): void {
    if (!this.editMode) {
      this.editMode = true;
      this.form.controls.name.enable();
      this.form.controls.typeSelected.enable();
      this.form.controls.properties.enable();
      this.dataEditBackup = this.form.value;
    }
  }

  public updateInfo(): void {
    if (this.editMode && this.form.valid) {
      const updateModelConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      let deviceModelRequest: DeviceModelRequest = {
        id: this.deviceModel.Id,
        name: this.form.controls.name.value,
        brand: this.deviceModel.Brand.Id,
        type: this.form.controls.typeSelected.value,
        properties: this.form.controls.properties.value,
        eventBody: this.deviceModel.EventBody
      };

      updateModelConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
        if (confirmed) {
          this.dataEditBackup = null;
          this.editMode = false;
          this.dataChanged = false;

          this.administratorApiService.updateDeviceModel(deviceModelRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.loadData(this.deviceModel.Id);
          });
        }
      });
    }
    else if (!this.form.valid) {
      this.showFormError = true;
    }
  }

  public cancelInfoEdit(): void {
    if (this.editMode) {
      if (this.dataChanged) {
        const cancelDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        cancelDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
          if (result) {
            this.form.patchValue(this.dataEditBackup);
            this.dataEditBackup = null;
            this.editMode = false;
            this.dataChanged = false;
            this.form.controls.name.disable();
            this.form.controls.typeSelected.disable();
            this.form.controls.properties.disable();
          }
        });
      }
      else {
        this.dataEditBackup = null;
        this.editMode = false;
        this.form.controls.name.disable();
        this.form.controls.typeSelected.disable();
        this.form.controls.properties.disable();
      }
    }
  }

  public addFieldToEventBody(): void {
    const addDialog = this.dialog.open(DeviceModelEventBodyDialogComponent, {
      width: '50%'
    });

    addDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((addedField: EventBodyField) => {
      if (addedField) {
        const addModelConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addModelConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.eventBody.SingleFieldsToSet.push(addedField);
            this.updateDeviceModel();
          }
        });
      }
    });
  }

  public editField(fieldToUpdate: EventBodyField): void {
    const updateDialog = this.dialog.open(DeviceModelEventBodyDialogComponent, {
      data: { field: fieldToUpdate },
      width: '50%'
    });

    updateDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedField: EventBodyField) => {
      if (updatedField) {
        const updateModelConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        updateModelConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            let fieldToUpdateIndex: number = this.getFieldIndex(fieldToUpdate);
            if (fieldToUpdateIndex !== -1) {
              this.eventBody.SingleFieldsToSet[fieldToUpdateIndex] = updatedField;
              this.updateDeviceModel();
            }
          }
        });
      }
    });
  }

  private updateDeviceModel(): void {
    let eventBodyString: string = JSON.stringify(this.eventBody);
    let deviceModelRequest: DeviceModelRequest = {
      id: this.deviceModel.Id,
      name: this.deviceModel.Name,
      brand: this.deviceModel.Brand.Id,
      type: this.deviceModel.Type.Id,
      properties: this.deviceModel.Properties?.length > 0 ? this.deviceModel.Properties.join(',') : '',
      eventBody: eventBodyString
    };
    this.administratorApiService.updateDeviceModel(deviceModelRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loadData(this.deviceModel.Id);
    });
  }

  public removeField(fieldToRemove: EventBodyField): void {
    const deleteDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        let fieldToRemoveIndex: number = this.getFieldIndex(fieldToRemove);
        if (fieldToRemoveIndex !== -1) {
          let eventBodyString: string;
          if (this.eventBody.SingleFieldsToSet.length > 1) {
            this.eventBody.SingleFieldsToSet.splice(fieldToRemoveIndex, 1);
            eventBodyString = JSON.stringify(this.eventBody);
          }
          else {
            eventBodyString = '';
          }

          let deviceModelRequest: DeviceModelRequest = {
            id: this.deviceModel.Id,
            name: this.deviceModel.Name,
            brand: this.deviceModel.Brand.Id,
            type: this.deviceModel.Type.Id,
            properties: this.deviceModel.Properties?.length > 0 ? this.deviceModel.Properties.join(',') : '',
            eventBody: eventBodyString
          };

          this.administratorApiService.updateDeviceModel(deviceModelRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.loadData(this.deviceModel.Id);
          });
        }
      }
    });
  }

  private getFieldIndex(fieldToFind: EventBodyField): number {
    return this.eventBody.SingleFieldsToSet
    .findIndex((field: EventBodyField) => fieldToFind.Name === field.Name && (
      !(fieldToFind.Ancestors?.length === 0) ||
      fieldToFind.Ancestors.every((ancestor: FieldAncestor) => field.Ancestors?.includes(ancestor))
    ));
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    this.filteredEventBodyFieldsData = this.eventBody.SingleFieldsToSet?.filter((field: EventBodyField) =>
      [field.Name.toLowerCase()]
      .some((field: string) => field.includes(filterValue)
    ));
  }

  public applyFilterString(filter: string): void {
    const filterValue = filter.trim().toLowerCase();

    this.filteredEventBodyFieldsData = this.eventBody.SingleFieldsToSet?.filter((field: EventBodyField) =>
      [field.Name.toLowerCase()]
      .some((field: string) => field.includes(filterValue)
    ));
  }

  public paginatorOnPageChange(number: number) {
    this.eventBodyFieldsConfig.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.eventBody.SingleFieldsToSet?.length / this.eventBodyFieldsConfig.itemsPerPage;
    maxPage = Math.ceil(maxPage);
    return maxPage;
  }

  public deleteModel(): void {
    const deleteDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.administratorApiService.deleteDeviceModel(this.deviceModel.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
          this.mainService.setNavigationInfoComand({ Back: true });
          this.router.navigate(['main/device-brand-detail']);
        });
      }
    });
  }

  public typeDetail(): void {
    this.mainService.setNavigationInfoComand({ Id: this.deviceModel.Type.Id, BackRoute: 'device-model-detail' });
    this.router.navigate(['main/device-type-detail']);
  }

  private setErrorAndGoToMain(): void {
    this.mainService.setNavigationInfoComand();
    this.mainService.setCustomErrorComand('Access denied. Retry with proper navigation');
    this.router.navigate(['main/dashboard']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
