import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainSubscriptionsService } from 'src/app/shared/services/main-subscriptions/main-subscriptions.service';
import {
  ConfirmationDialogComponent
} from '../../../../shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AdministratorApiService } from '../../../../shared/services/administrator-api.service';
import { ServerSetting } from "../../../../shared/models/administrator/serverSetting";
import {
  ServerSettingDialogComponent
} from "../../../../shared/dialogs/server-setting-dialog/server-setting-dialog.component";
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-server-setting-list',
  templateUrl: './server-setting-list.component.html',
  styleUrls: ['./server-setting-list.component.scss']
})
export class ServerSettingListComponent implements OnInit, OnDestroy {

  public serverSettingsColumns = ['Key', 'Detail'];
  public serverSettings: ServerSetting[] = [];
  public filteredData: ServerSetting[] = [];
  public actualFilter: string = '';
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private administratorService: AdministratorApiService,
    public dialog: MatDialog,
    private mainService: MainSubscriptionsService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.administratorService.listServerSetting().pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => {
      if (x) {
        this.serverSettings = x;
        this.filteredData = this.serverSettings;
      }
    });
  }

  public addServerSetting(): void {
    const addDialogRef = this.dialog.open(ServerSettingDialogComponent);

    addDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(newSetting => {
      if (newSetting) {
        const addConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        addConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.administratorService.addServerSetting(newSetting).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }

  public serverSettingDetail(serverSetting: ServerSetting): void {
    if (!serverSetting || serverSetting === undefined) {
      return;
    }
    const updateDialogRef = this.dialog.open(ServerSettingDialogComponent, {
      data: {
        serverSetting: serverSetting
      },
      width: '50%',
      maxWidth: 500
    });

    updateDialogRef.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data) {
        const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
          disableClose: false
        });

        confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
          if (confirmed) {
            this.administratorService.updateServerSetting(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
              this.loadData();
            });
          }
        });
      }
    });
  }

  public serverSettingDelete(serverSetting: ServerSetting): void {
    if (!serverSetting || serverSetting === undefined) {
      return;
    }

    const deleteConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    deleteConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.administratorService.deleteServerSetting(serverSetting.Id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.loadData();
        });
      }
    });
  }

  public propagateSettings(): void {
    const propagateConfirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'SERVER_SETTINGS.PROPAGATE'
      },
      disableClose: false
    });

    propagateConfirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(confirmed => {
      if (confirmed) {
        this.administratorService.propagateSettings().pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
          this.mainService.setSuccessMessageComand('Settings propagation done');
        });
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.serverSettings.filter((setting: ServerSetting) =>
      [setting.Key?.toLowerCase()].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
