import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceEventResponse } from '../../../shared/models/deviceEventListResponse';
import { DeviceEventDetailDialogComponent } from '../../../shared/dialogs/device-event-detail-dialog/device-event-detail-dialog.component';
import { DeviceEvent } from '../../../shared/models/deviceEvent';
import { Domain } from '../../../shared/models/domain';
import { DomainEventsRequest } from '../../../shared/models/domainEventsRequest';
import { ApiService } from '../../../shared/services/api.service';
import { MainSubscriptionsService } from '../../../shared/services/main-subscriptions/main-subscriptions.service';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { PaginationInstance } from 'ngx-pagination';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AdministratorApiService } from 'src/app/shared/services/administrator-api.service';

@Component({
  selector: 'urban-domain-device-events',
  templateUrl: './domain-device-events.component.html',
  styleUrls: ['./domain-device-events.component.scss']
})
export class DomainDeviceEventsComponent implements OnInit, OnDestroy {

  public displayedColumns: string[] = ['Id', 'Device', 'Created', 'Type', 'Level', 'Detail'];
  public events: DeviceEvent[] = [];
  public filteredData: DeviceEvent[] = [];
  public actualFilter: string = '';
  public isDarkActive: boolean;
  public startDate: number;
  public endDate: number;
  public deviceTypes: string[] = [];
  public eventTypes: string[] = [];
  public eventLevels: string[] = [];
  public filterForm: UntypedFormGroup = this.formBuilder.group({
    deviceType: '',
    eventType: '',
    eventLevel: ''
  });
  public orderColumn: string = null;
  public orderDirection: string = null;
  public isAdmin: boolean = false;
  public currentDomain: Domain;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public loadingData: boolean;
  public eventLevelColors: string[] = [];
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  }
  private subscription: Subject<void> = new Subject<void>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private administratorApiService: AdministratorApiService,
    private dialog: MatDialog,
    private passDataService: PassDataService,
    private mainService: MainSubscriptionsService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.passDataService.currentDomain$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(domain => {
      if (domain) {
        this.currentDomain = domain;

        this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
          if (response?.length > 0) {
            if (response.some(x => x.Name == 'Administrators')) {
              this.isAdmin = true;
            }
          }
        });

        this.passDataService.currentDarkModeStatus$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.isDarkActive = res === true;
        });
      }

      this.loadData();
      this.eventLevelColors = this.getEventLevelColors();
      this.loadingData = false;
    });

    this.filterForm.valueChanges.subscribe((newFilterValues: Record<string,string>) => {
      if (Object.values(newFilterValues).some(value => value !== null)) {
        this.config.currentPage = 1;
        this.newSearch();
      }
    });
  }

  private loadData(): void {
    let firstRequest: DomainEventsRequest = {
      Page: this.config.currentPage - 1,
      Limit: this.config.itemsPerPage,
      Filter: ''
    };

    this.apiService.domainEvents(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if(res?.Events.length > 0) {
        this.config.totalItems = res.Total;
        this.events = res.Events;
        this.filteredData = this.events;
        this.setFilters();
      }
    });
  }

  public loadLatestData(): void {
    this.clearDate = !this.clearDate;
    this.loadingData = true;
    this.filterForm.reset();

    this.apiService.domainEventsLatest24Hours().pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe((res: DeviceEventResponse) => {
      this.setLatestDates(res);

      if (res && res.Events.length > 0) {
        this.config.totalItems = res.Total;
        this.events = res.Events;
        this.filteredData = this.events;
        this.setFilters();
      }
      else {
        this.resetFilters();
      }

      this.loadingData = false;
    });
  }

  public setLatestDates(res: DeviceEventResponse): void {
    this.endDate = res.Events[0].Created;
    this.lastCreated = this.endDate * 1000;
    let start: Date = new Date(this.lastCreated);
    start.setDate(start.getDate() - 1);
    this.startDate = Math.round(start.getTime() / 1000) - 1;
    this.endDate++; //1 second after to include last data
    this.setDates = !this.setDates;
  }

  public newSearch(selectedDates?: SearchDates): void {
    this.actualFilter = '';
    this.loadingData = true;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
      this.filterForm.reset();
    }

    let serverRequest: DomainEventsRequest = {
      Page: this.config.currentPage - 1,
      Limit: this.config.itemsPerPage,
      Filter: '',
      Start: this.startDate,
      End: this.endDate,
      ...this.filterForm.value
    };

    this.apiService.domainEvents(serverRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
      if(res) {
        this.config.totalItems = res.Total;
        this.events = res.Events;
        this.filteredData = this.events;
        this.setFilters();
      }
      else {
        this.resetFilters();
      }

      if (selectedDates !== null && selectedDates !== undefined) {
        this.last24hSearch = selectedDates.last24hSearch;
      }
      this.loadingData = false;
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
    this.setFilters();
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.events?.filter((device: DeviceEvent) =>
      [
        device.Id?.toLowerCase(),
        device.Level?.toLowerCase(),
        device.Type?.toLowerCase(),
        device.DeviceName.toLowerCase(),
        device.Created ? new Date(device.Created * 1000).toLocaleString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        }) : '',
      ]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public openDetailDialog(singleEvent: DeviceEvent): void {
    this.dialog.open(DeviceEventDetailDialogComponent, {
      data: { targetEvent: singleEvent },
      maxWidth: 1000,
      width: '70%'
    });
  }

  public deleteEvent(eventId: string): void {
    if (this.isAdmin) {
      const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      })

      confirmationDialog.afterClosed().pipe(takeUntil(this.ngUnsubscribe)).subscribe(result => {
        if(result){
          this.administratorApiService.deviceEventDelete(eventId)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.loadLatestData();
          });
        }
      });
    }
  }

  private getEventLevelColors(): string[] {
    let colors: string[] = [];

    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-red-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-green-color'));
    colors.push(window.getComputedStyle(document.body).getPropertyValue('--custom-chart-1-color'));
    colors.push('#BC9A29');

    return colors;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.config.totalItems / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage
  }

  private setFilters(): void {
    this.resetFilters();

    this.filteredData.forEach((event: DeviceEvent) => {
      if (!this.deviceTypes.includes(event.DeviceType)) {
        this.deviceTypes.push(event.DeviceType);
      }

      if (!this.eventTypes.includes(event.Type)) {
        this.eventTypes.push(event.Type);
      }

      if (!this.eventLevels.includes(event.Level)) {
        this.eventLevels.push(event.Level);
      }
    });
  }

  private resetFilters(): void {
    this.deviceTypes = [];
    this.eventTypes = [];
    this.eventLevels = [];
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
    this.newSearch();
  }

  public paginatorOnItemsPerPageChange(): void {
    this.config.currentPage = 1;
    this.newSearch();
  }

  public openDeviceDetail(singleEvent: DeviceEvent): void {
    this.mainService.setNavigationInfoComand({ Id: singleEvent.DeviceId, BackRoute: 'domain-events' });
    this.router.navigate(['main/device']);
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
