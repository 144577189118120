<div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center mx-0 pageTop">
        <h3 *ngIf="!this.isAdd" class="title3">
            {{this.theme?.Hostname}}
        </h3>

        <h3 *ngIf="this.isAdd" class="title3">
            {{ 'GENERAL.ADD' | translate }} -   {{ 'GENERAL.THEME' | translate }}
        </h3>

        <div>
            <button *ngIf="!this.isAdd" mat-button class="btn actionButton"
                    type="button" id="updateButton" (click)="addition()">
                {{ 'GENERAL.UPDATE' | translate }}
            </button>
    
            <button *ngIf="this.isAdd" mat-button class="btn actionButton"
                    type="button" id="addButton" (click)="addition()">
                {{ 'GENERAL.ADD' | translate }}
            </button>
        </div>
    </div>

    <form [formGroup]="form" class="mb-4">
        <mat-form-field appearance="outline" class="customMatFormField mb-3">
            <mat-label>{{ 'GENERAL.HOSTNAME' | translate }}</mat-label>
            <input matInput formControlName="hostname"
                   autocomplete="off"
                   required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'GENERAL.STYLE' | translate }}</mat-label>
            <textarea   matInput formControlName="scssVariables"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"
                        autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="customTextArea mb-3">
            <mat-label>{{ 'GENERAL.TRANSLATIONS' | translate }}</mat-label>
            <textarea   matInput formControlName="translations"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10"
                        autocomplete="off">
            </textarea>
        </mat-form-field>

        <mat-error *ngIf="error">
            {{ 'GENERAL.ERROR_EMPTY' | translate }}
        </mat-error>
    </form>

    <div class="d-flex justify-content-around">
        <div>
            <div class="image-container mx-auto text-center">
                <mat-label>{{ 'GENERAL.LOGO' | translate }}</mat-label>
                <img *ngIf="this.logoBaseData" src="{{ this.logoBaseData }}" class="img-fluid" alt="Profile Image">
                <img *ngIf="!this.logoBaseData" src="assets/img/default-profile.png" class="img-fluid" alt="Profile Image">
                <input style="display: none;" type="file" (change)="logoImageUpload($event)"
                       accept=".png, .jpg, .jpeg, .gif" #imageInput required>
                <button mat-mini-fab color="primary" class="my-fab" (click)="imageInput.click()">
                    <i class="urbanicon-edit"></i>
                </button>
            </div>
    
            <mat-error *ngIf="logoError" class="text-center">
                {{ 'ERROR.' + logoError | translate }}
            </mat-error>
        </div>

        <div>
            <div class="image-container mx-auto text-center">
                <mat-label>{{ 'GENERAL.SPLASHSCREEN_LOGO' | translate }}</mat-label>
                <img *ngIf="this.splashScreenLogoBaseData" src="{{ this.splashScreenLogoBaseData }}" class="img-fluid" alt="Profile Image">
                <img *ngIf="!this.splashScreenLogoBaseData" src="assets/img/default-profile.png" class="img-fluid" alt="Profile Image">
                <input style="display: none;" type="file" (change)="splashScreenLogoImageUpload($event)"
                       accept=".png, .svg, .jpg, .jpeg, .gif" #imageSplashInput required>
                <button mat-mini-fab color="primary" class="my-fab" (click)="imageSplashInput.click()">
                    <i class="urbanicon-edit"></i>
                </button>
            </div>
    
            <mat-error *ngIf="splashLogoError" class="text-center">
                {{ 'ERROR.' + splashLogoError | translate }}
            </mat-error>
        </div>
    </div>
</div>
