import { Component, OnDestroy, OnInit } from '@angular/core';
import { PassDataService } from '../../../shared/services/pass-data/pass-data.service';
import { ApiService } from '../../../shared/services/api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TraceItem, TraceListRequest } from '../../../shared/models/traceListElements';
import { Domain } from '../../../shared/models/domain';
import { MatDialog } from '@angular/material/dialog';
import { PlatformStatisticsDetailDialogComponent } from '../../../shared/dialogs/platform-statistics-detail-dialog/platform-statistics-detail-dialog.component';
import { Role } from 'src/app/shared/models/userRoles';
import { SearchDates } from 'src/app/shared/models/searchDates';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'urban-logs-statistics',
  templateUrl: './logs-statistics.component.html',
  styleUrls: ['./logs-statistics.component.scss']
})
export class LogsStatisticsComponent implements OnInit, OnDestroy {

  public traces: TraceItem[] = [];
  public filteredData: TraceItem[] = [];
  public displayedColumns: string[] = ['Created', 'ClientIp', 'SessionId', 'Url', 'Username', 'Domain', 'ExecutionTime', 'Detail'];
  public isDarkActive: boolean;
  public actualFilter: string = '';
  public myAvailableDomains: Domain[] = [];
  public myCurrentDomain: Domain = null;
  public isOnlyDomainAdmin: boolean = false;
  public currentLanguage: string;
  public last24hSearch: boolean = true;
  public lastCreated: number;
  public clearDateAndUnsubscribe: boolean;
  public clearDate: boolean;
  public setDates: boolean;
  public startDate: number;
  public endDate: number;
  public loadingData: boolean;
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  }
  private subscription: Subject<void> = new Subject<void>();
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private passDataService: PassDataService,
  ) {}

  ngOnInit(): void {
    this.passDataService.currentUserRoles$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.loadData(response);
    });

    this.passDataService.currentDarkModeStatus$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.isDarkActive = res === true;
    });
  }

  private loadData(roles: Role[]): void {
    this.loadingData = true;

    let firstRequest: TraceListRequest = {
      page: 0,
      limit: this.config.itemsPerPage,
      filter: '',
      order: 'Created',
      direction: 'DESC'
    }

    if (roles.some(x => x.Name == 'Administrators')) {
      this.isOnlyDomainAdmin = false;

      this.apiService.getTracesList(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if(res?.Traces?.length > 0) {
          this.config.totalItems = res.Total;
          this.traces = res.Traces;
          this.filteredData = this.traces;
        }

        this.loadingData = false;
      });
    } else {
      this.isOnlyDomainAdmin = true;

      this.apiService.getDomainTracesList(firstRequest).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if(res?.Traces?.length > 0) {
          this.config.totalItems = res.Total;
          this.traces = res.Traces;
          this.filteredData = this.traces;
        }

        this.loadingData = false;
      });
    }
  }

  public newSearch(selectedDates?: SearchDates): void {
    this.actualFilter = '';
    this.loadingData = true;

    if(selectedDates !== null && selectedDates !== undefined) {
      this.startDate = selectedDates.startDate;
      this.endDate = selectedDates.endDate;
      this.config.currentPage = 1;
    }
    
    let serverRequest: TraceListRequest = {
      page: this.config.currentPage - 1,
      limit: this.config.itemsPerPage,
      filter: "",
      order: "Created",
      direction: "DESC",
      start: this.startDate,
      end: this.endDate
    }

    if (this.isOnlyDomainAdmin) {
      this.apiService.getDomainTracesList(serverRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
        if (res) {
          this.config.totalItems = res.Total;
          this.traces = res.Traces;
          this.filteredData = this.traces;
        }

        if (selectedDates !== null && selectedDates !== undefined) {
          this.last24hSearch = selectedDates.last24hSearch; 
        }
        this.loadingData = false;
      });
    }
    else {
      this.apiService.getTracesList(serverRequest).pipe(takeUntil(this.ngUnsubscribe), takeUntil(this.subscription)).subscribe(res => {
        if (res) {
          this.config.totalItems = res.Total;
          this.traces = res.Traces;
          this.filteredData = this.traces;
        }

        if (selectedDates !== null && selectedDates !== undefined) {
          this.last24hSearch = selectedDates.last24hSearch; 
        }
        this.loadingData = false;
      });
    }
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.traces?.filter((trace: TraceItem) =>
      [
        trace.Created ? new Date(trace.Created * 1000).toLocaleString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        }) : '',
        trace.ClientIp?.toLowerCase(),
        trace.SessionId?.toLowerCase(),
        trace.Resource?.toLowerCase(),
        trace.Username?.toLowerCase(),
        trace.Domain?.toLowerCase(),
        trace.Millis?.toString() + " ms"
      ].some((field: string) => field?.includes(filterValue.toLowerCase())
    ));
  }

  public paginatorOnPageChange(number: number): void {
    this.config.currentPage = number;
    this.newSearch();
  }

  public paginatorOnItemsPerPageChange(): void {
    this.config.currentPage = 1;
    this.newSearch();
  }

  public paginatorGetMaxPage(): number {
    return Math.ceil(this.config.totalItems / this.config.itemsPerPage);
  }

  public openDetailDialog(singleTrace: TraceItem, highlight: string): void {
    this.dialog.open(PlatformStatisticsDetailDialogComponent, {
      data: { targetTrace: singleTrace, highlight },
      maxWidth: 1000,
      width: '70%'
    });
  }

  public subscriptionsUnsubscribe(): void {
    this.loadingData = false;
    this.subscription.next();
    this.subscription.complete();
    this.subscription = new Subject<void>();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
