import { Component, OnInit } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceEventRejected } from 'src/app/shared/models/administrator/deviceEventsRejectedResponse';
import { AdministratorApiService } from 'src/app/shared/services/administrator-api.service';

@Component({
  selector: 'urban-device-events-rejected',
  templateUrl: './device-events-rejected.component.html',
  styleUrls: ['./device-events-rejected.component.scss']
})
export class DeviceEventsRejectedComponent implements OnInit {
  public displayedColumns = ['Id', 'Origin', 'Body', 'Exception', 'Resource', 'Created'];
  public filteredData: DeviceEventRejected[] = [];
  public deviceEventsRejected: DeviceEventRejected[] = [];
  public actualFilter: string = '';
  public noDataAvailable: boolean;
  public myPageSizeOptions: number[] = [10, 20, 50, 100];
  public config: PaginationInstance = {
    itemsPerPage: 10,
    currentPage: 1,
  }
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  
  constructor(
    private AdministratorApiService: AdministratorApiService,
  ) { }

  ngOnInit(): void {
    this.AdministratorApiService.deviceEventsRejected(2880).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res?.Items.length > 0) {
        res.Items.forEach(event => {
          event.Created = new Date(event.Created).toLocaleString('it-IT', {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', second: '2-digit'
          });
        });

        this.deviceEventsRejected = res.Items;
        this.filteredData = res.Items;
      }
      else {
        this.noDataAvailable = true;
      }
    });
  }

  public applyFilter(event: KeyboardEvent): void {
    const filterValue: string = (event.target as HTMLInputElement).value.trim();
    this.applyFilterString(filterValue);
  }

  public applyFilterString(filterValue: string): void {
    this.filteredData = this.deviceEventsRejected?.filter((event: DeviceEventRejected) =>
      [
        event.Id?.toLowerCase(), 
        event.Body?.toLowerCase(), 
        event.Exception?.toLowerCase(),
        event.Origin?.toLowerCase(),
        event.Resource?.toLowerCase(),
        event.Created.toLowerCase(),
      ]
      .some((field: string) => field?.includes(filterValue.toLowerCase())
    ));

    this.config.currentPage = 1;
  }

  public paginatorOnPageChange(number: number) {
    this.config.currentPage = number;
  }

  public paginatorGetMaxPage(): number {
    let maxPage: number = this.filteredData.length / this.config.itemsPerPage;
    maxPage = Math.ceil(maxPage);

    return maxPage;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
