<div class="container-fluid appearing" *ngIf="deviceType">
    <div class="row d-flex justify-content-between mx-0 pageTop">
        <span class="title2">
            {{'REPORT.' + deviceType | translate }}
        </span>
    </div>

    <mat-tab-group id="intro-devices-table-tab" animationDuration="0ms" dynamicHeight="true">
        <mat-tab label="Devices list">
            <div class="tabContent">
                <div class="customTable">
                    <div class="customTableRadius justify-content-between align-items-start px-4 pt-4 flex-wrap" [ngClass]="noData === true ? 'd-none' : 'd-flex'">
                        <mat-form-field class="tableSearch" appearance="outline">
                            <div matPrefix class="prefix">
                                <i class="urbanicon-search"></i>
                            </div>
                            <mat-label>{{ 'GENERAL.SEARCH' | translate }}</mat-label>
                            <input matInput (keyup)="applyFilter($event)"
                                autocomplete="off" [(ngModel)]="actualFilter">
                            <button *ngIf="actualFilter" matSuffix mat-icon-button aria-label="Clear" (click)="applyFilterString(''); actualFilter = ''"  matTooltip="{{'GENERAL.CLEAR_ALL' | translate }}">
                                <i class="urbanicon-close"></i>
                            </button>
                        </mat-form-field>
                    </div>

                    <div class="tableHeight" *ngIf="devices?.length > 0 && noData === false">
                        <table class="w-100 appearing" mat-table [dataSource]="filteredData | paginate: config">

                            <!-- Name Column -->
                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef style="width: 30%"> {{ 'DEVICES_TABLE.NAME' | translate }} </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.Name }}
                                </td>
                            </ng-container>

                            <!-- Type Column -->
                            <ng-container matColumnDef="Type">
                                <th mat-header-cell *matHeaderCellDef> {{ 'DEVICES_TABLE.TYPE' | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.Model.Type.Name}} </td>
                            </ng-container>

                            <!-- Brand Column -->
                            <ng-container matColumnDef="BrandName">
                                <th mat-header-cell *matHeaderCellDef style="width: 20%"> {{ 'DEVICES_TABLE.BRAND_NAME' | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.Model.Brand.Name}} </td>
                            </ng-container>

                            <!-- BrandModel -->
                            <ng-container matColumnDef="BrandModel">
                                <th mat-header-cell *matHeaderCellDef style="width: 30%"> {{ 'DEVICES_TABLE.MODEL_NAME' | translate }} </th>
                                <td mat-cell *matCellDef="let element"> {{element.Model.Name}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDetail(row.Id)"></tr>
                        </table>
                    </div>

                    <div class="customTableRadius justify-content-between align-items-center px-4 pt-4 flex-wrap" [ngClass]="noData === true ? 'd-none' : 'd-flex'">
                        <div class="d-flex flex-wrap align-items-center">
                            <pagination-controls class="customNgxPaginator"
                                [maxSize]="7"
                                [directionLinks]="true"
                                [previousLabel]="''"
                                [nextLabel]="''"
                                [screenReaderPaginationLabel]="'PAGINATOR.PAGINATOR' | translate"
                                [screenReaderPageLabel]="'GENERAL.PAGE' | translate"
                                [screenReaderCurrentLabel]="'PAGINATOR.CURRENT_PAGE' | translate"
                                (pageChange)="paginatorOnPageChange($event)">
                            </pagination-controls>

                            <div class="paginatorPageCounter">
                                {{config.currentPage}} {{'GENERAL.OF' | translate}}
                                {{(filteredData?.length / config.itemsPerPage) < 1 ? 1 : paginatorGetMaxPage()}}
                                {{(filteredData?.length / config.itemsPerPage) > 1 ? ('GENERAL.PAGES' | translate).toLowerCase()  : ('GENERAL.PAGE' | translate).toLowerCase()}}
                            </div>
                        </div>

                        <div>
                            <mat-form-field class="customMatFormField" appearance="outline">
                                <mat-select [(value)]="config.itemsPerPage" hideSingleSelectionIndicator="true">
                                    <mat-option *ngFor="let pageSize of myPageSizeOptions" [value]="pageSize">
                                        {{ 'PAGINATOR.SHOW' | translate }} {{pageSize}} {{ 'PAGINATOR.PER_PAGE' | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="noData === true" class="customTableRadius d-flex justify-content-center align-items-center noDataIllustrationInTabs">
                    <img [src]="isDarkModeActive ? '/assets/img/blankpageDarkMode.png' : '/assets/img/blankpage.png'"/>
                    <div>
                        <h2>{{ 'DEVICES_TABLE.NO_DEVICES' | translate }} {{ ('REPORT.' + deviceType | translate).toLowerCase() }}</h2>
                        <div class="noDataActions">
                            <button type="button" class="btn outlineActionButton" (click)="goToMain()">{{ 'GENERAL.RETURN_DASHBOARD' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Map" #mapTab>
            <div class="tabContent">
                <urban-parking-maps-widget *ngIf="mapTab.isActive && mapReady" class="mapPanel"
                    [latestEvents]="[]" [devicesList]="devices" [initialZoom]="10"
                    [darkThemeActive]="isDarkModeActive" [passedHeight]="650"></urban-parking-maps-widget>
            </div>
        </mat-tab>

    </mat-tab-group>
</div>
